import {getUserJson} from "../../firebase/Firebase";

const defaultState = {
    basketArray : [],
    user: getUserJson(),
    adminSettings: {
        displayName: "Drop Master",
        email: "dropmaster.director@gmail.com",
        paymentCard: "5168 7453 2154 9963",
        paymentName: "Перетятько Б.О.",
        phone: "+38 (000) 000-00-00",
        telegramChatID: ["714268390", "399896836"],
        telegramOrderDismiss: true,
        telegramOrderSend: true,
        telegramOrderSuccess: true,
        telegramPaymentSend: true,
        telegramSystemMessage: true,
        uid: "t3N6QVs2KRT2TNv3x2FHv5scCo92",
    }
};

export const basketReducer = (state = defaultState , action) => {
    if (action.type === 'BASKET_ADD_PRODUCT') {
        return {
            ...state,
            basketArray: state.basketArray.concat(action.payload)
        }
    }else if (action.type === 'BASKET_REMOVE_PRODUCT'){
        var newState = state.basketArray.filter(item => item !== action.payload);
        return {
            ...state,
            basketArray: newState
        }
        // return state
    }else if (action.type === 'BASKET_REMOVE_ALL'){
        return {
            ...state,
            basketArray: []
        }
    }else if (action.type === 'BASKET_CHANGE_PRODUCT'){
        var indexEl = state.basketArray.indexOf(action.oldProduct);
        var newStateChanged = state.basketArray.map((item, index) => index === indexEl ? action.newProduct : item);
        return {
            ...state,
            basketArray: newStateChanged
        }
    }else if (action.type === 'USER_SET') {
        return {
            ...state,
            user: action.payload
        }
    }else if (action.type === 'USER_CLEAR'){
        return {
            ...state,
            user: {
                displayName: '',
                email: '',
                emailVerified: false,
                phone: '',
                phoneVerified: false,
                photoUrl: '',
                uid: '',
                position: '',
                active: false,
                // Partner
                quantityOrdersPartner: 0,
                summFinishedPrepaymentPartner: 0,
                quantityFinishedPrepaymentPartner: 0,
                summHoldPrepaymentPartner: 0,
                quantityHoldPrepaymentPartner: 0,
                summBackPrepaymentPartner: 0,
                quantityBackPrepaymentPartner: 0,
                summFinishedPrepaymentDropMaster: 0,
                summHoldPrepaymentDropMaster: 0,
                summFinishedCODPartner: 0,
                quantityFinishedCODPartner: 0,
                summHoldCODPartner: 0,
                quantityHoldCODPartner: 0,
                summBackCODPartner: 0,
                quantityBackCODPartner: 0,
                summFinishedCODDropMaster: 0,
                summHoldCODDropMaster: 0,
                summHoldPartner: 0,
                summFinishedPartner: 0,
                summBackPartner: 0,
                summBalancePartner: 0,
                // Provider
                quantityOrdersProvider: 0,
                summFinishedPrepaymentProvider: 0,
                quantityFinishedPrepaymentProvider: 0,
                summHoldPrepaymentProvider: 0,
                quantityHoldPrepaymentProvider: 0,
                summBackPrepaymentProvider: 0,
                quantityBackPrepaymentProvider: 0,
                summFinishedCODProvider: 0,
                quantityFinishedCODProvider: 0,
                summHoldCODProvider: 0,
                quantityHoldCODProvider: 0,
                summBackCODProvider: 0,
                quantityBackCODProvider: 0,
                summHoldProvider: 0,
                summFinishedProvider: 0,
                summBackProvider: 0,
                summBalanceProvider: 0,
                // Financial
                summInput: 0,
                quantityInput: 0,
                summPayed: 0,
                quantityPayed: 0,
                summBalance: 0,


                dateCreation: '',
                dateLastSignIn: '',
                comments: '',
                other: '',
                paymentCard: '',
                paymentName: '',
                telegramChatID: [],
                telegramOrderSend: false,
                telegramPaymentSend: false,
                telegramOrderDismiss: false,
                telegramOrderSuccess: false,
                telegramSystemMessage: false,
            }
        }
    }else if (action.type === 'ADMIN_SET') {
        return {
            ...state,
            adminSettings: action.payload
        }
    }else if (action.type === 'ADMIN_CLEAR'){
        return {
            ...state,
            adminSettings: {
                paymentCard: '',
                paymentName: '',
                telegramChatID: [],
                telegramOrderSend: false,
                telegramPaymentSend: false,
                telegramOrderDismiss: false,
                telegramOrderSuccess: false,
                telegramSystemMessage: false,
            }
        }
    }
    return state;
};