import React from 'react';
import '../products/Products.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {
    getAdminSettings, getDataFromCollection, fire,
    getDataFromCollectionWhereKeyValue, setDocumentToCollection,
    updateDocumentInCollection, deleteDocumentFromCollectionWithID
} from '../firebase/Firebase';
import {Card, CardBody, CardHeader, CardFooter, CardTitle, CustomInput, FormGroup, ButtonGroup, Button} from "reactstrap";
import {Col, Form, Row, Tab, Tabs} from "react-bootstrap";
import NumberFormat from "react-number-format";
import {setAdmin, clearAdmin, addProduct, changeProduct, clearUser, removeAll, removeProduct, setUser} from "../storeReducers/basket/actions";
import {connect} from "react-redux";
import TableNews from "./TableNews";
import ModalAddNews from "./ModalAddNews";
import TableCategory from "./TableCategory";


class App extends React.Component {
    /*** ================================================================================
     *          Main methods
     * ================================================================================*/
    constructor (props, context) {
        super(props, context);
        this.state = {
            // news
            news: [],
            isAddNewsClick: false,
            // Result Message
            isResult: false,
            resultMessage: '',
            // Current user
            idCurrentUser: '',
            // Handle
            email: '',
            name: '',
            phone: '',
            paymentCard: '',
            paymentName: '',
            telegramChatID: '',
            telegramChatIDArray: [],
            telegramOrderSend: false,
            telegramPaymentSend: false,
            telegramOrderDismiss: false,
            telegramOrderSuccess: false,
            telegramSystemMessage: false,
            referalCode: '',
            summReferal: 0,
            // referal
            referalMessage: '',
            referalMessageStyle: {color: 'black'},
        };
        this.saveButtonClickHandler = this.saveButtonClickHandler.bind(this);
        this.refresh = this.refresh.bind(this);
    }
    componentDidMount() {
        this.refresh();
    }
    refresh(){
        if (localStorage.getItem('position') === 'Админ'){
            fire.firestore().collection("adminSettings").doc('adminSettings')
                .get()
                .then(querySnapshot => {
                    this.setState({summReferal: querySnapshot.data().summReferal});
                    this.getNews();
                });
        }
        getDataFromCollectionWhereKeyValue("usders", "uid", localStorage.getItem("uid")).then(r => {
            this.setState({
                idCurrentUser: r.data[0].id,
                email: r.data[0].email,
                name: r.data[0].displayName,
                phone: r.data[0].phone,
                paymentCard: r.data[0].paymentCard,
                paymentName: r.data[0].paymentName,
                telegramChatIDArray: r.data[0].telegramChatID,
                telegramOrderSend: r.data[0].telegramOrderSend,
                telegramPaymentSend: r.data[0].telegramPaymentSend,
                telegramOrderDismiss: r.data[0].telegramOrderDismiss,
                telegramOrderSuccess: r.data[0].telegramOrderSuccess,
                telegramSystemMessage: r.data[0].telegramSystemMessage,
                referalCode: r.data[0].referalCode,
            });
            this.checkReferalCode(r.data[0].referalCode);
        })
    }
    /*** ================================================================================
     *          NEWS
     * ================================================================================*/
    getNews(){
        getDataFromCollection('news').then( r => {
            this.setState({news: r.data});
        })
    }
    deleteNews = (e) => {
        deleteDocumentFromCollectionWithID('news', e.id).then( r => {
            this.refresh();
        })
    };
    addNewsClick = (e) => {
        if (!this.state.isAddNewsClick){
            this.setState({isAddNewsClick: true});
        }else if (this.state.isAddNewsClick){
            this.setState({isAddNewsClick: false});
        }
    };
    /*** ================================================================================
     *          Handle And Save
     * ================================================================================*/
    handleEmail = e => {
        this.setState({email: e.target.value})
    };
    handleName = e => {
        this.setState({name: e.target.value})
    };
    handlePhone = e => {
        this.setState({phone: e.target.value})
    };
    handlePaymentCard = e => {
        this.setState({paymentCard: e.target.value})
    };
    handlePaymentName = e => {
        this.setState({paymentName: e.target.value})
    };
    handleTelegramChatID = e => {
        this.setState({telegramChatID: e.target.value})
    };
    handleTelegramChatIDAddToArray = e => {
        var chatIDArray = this.state.telegramChatIDArray;
        chatIDArray.push(this.state.telegramChatID);
        this.setState({telegramChatID: '', telegramChatIDArray: chatIDArray});
    };
    handleTelegramChatIDRemoveFromArray = (a) =>{
        var chatIDArray = this.state.telegramChatIDArray;
        var newArray =[];
        chatIDArray.forEach(c => {
            if (a !== c){
                newArray.push(c);
            }
        });
        this.setState({telegramChatIDArray: newArray});
    };
    handleTelegramOrderSend = e => {
        this.setState({telegramOrderSend: e.target.checked})
    };
    handleTelegramPaymentSend = e => {
        this.setState({telegramPaymentSend: e.target.checked})
    };
    handleTelegramOrderDismiss = e => {
        this.setState({telegramOrderDismiss: e.target.checked})
    };
    handleTelegramOrderSuccess = e => {
        this.setState({telegramOrderSuccess: e.target.checked})
    };
    handleTelegramSystemMessage = e => {
        this.setState({telegramSystemMessage: e.target.checked})
    };
    handleReferalCode = e => {
        const r = e.target.value;
        this.setState({referalCode: r});
        this.checkReferalCode(r);
    };
    handleSummReferal = e => {
        this.setState({summReferal: e.target.value})
    };
    checkReferalCode(r){
        getDataFromCollectionWhereKeyValue('usders', 'uid', r).then( u => {
            const users = u.data;
            if (users.length > 0){
                const referalUser = users[0];
                if (referalUser.uid === localStorage.getItem('uid')){
                    this.setState({
                        referalMessage: 'Вы не можете быть рефералом сам для себя',
                        referalMessageStyle: {color: 'red'},
                        // referalCode: '',
                    });
                }else {
                    this.setState({
                        referalMessage: 'Ваш реферальный партнер - ' + referalUser.displayName + ' (' + referalUser.email + ')',
                        referalMessageStyle: {color: 'black'},
                    });
                }
            }else {
                this.setState({
                    referalMessage: 'Такого партнера в базе нет.',
                    referalMessageStyle: {color: 'red'},
                });
            }
        });
    }
    // Save Button
    saveButtonClickHandler() {
        // getDataFromCollection('orders').then( u => {
        //     const users = u.data;
        //     users.forEach( user => {
        //         updateDocumentInCollection('orders', user.id, {
        //             referalCode: ''
        //         })
        //     })
        // });
        const settings = {
            email: this.state.email,
            displayName: this.state.name,
            phone: this.state.phone,
            paymentCard: this.state.paymentCard,
            paymentName: this.state.paymentName,
            telegramChatID: this.state.telegramChatIDArray,
            telegramOrderSend: this.state.telegramOrderSend,
            telegramPaymentSend: this.state.telegramPaymentSend,
            telegramOrderDismiss: this.state.telegramOrderDismiss,
            telegramOrderSuccess: this.state.telegramOrderSuccess,
            telegramSystemMessage: this.state.telegramSystemMessage,
            referalCode: this.state.referalCode,
        };
        // Update then save settings for user
        updateDocumentInCollection("usders", this.state.idCurrentUser, settings ).then(r => {
            // fire.auth().currentUser.updatePhoneNumber(this.state.phone);
            // Message that we save settings
            this.setState({isResult: true, resultMessage: 'Изменения успешно сохранены'});
            setTimeout(function() {this.setState({isResult: false});}.bind(this), 3000);
            window.scrollTo(0, 0);
            getDataFromCollectionWhereKeyValue("usders", "uid", localStorage.getItem("uid")).then( r => {
                // save settings for current user
                this.props.setUser(r.data[0]);
                // If user is admin save admin settings to firebase
                if (localStorage.getItem("position") === 'Админ'){
                    updateDocumentInCollection("adminSettings", "adminSettings", {
                        ...settings,
                        summReferal: this.state.summReferal,
                        uid: localStorage.getItem("uid"),
                    }).then(r => {
                        getAdminSettings().then(r =>{
                            // console.log(r);
                            this.props.setAdmin(r);
                            // console.log(this.props.adminSettings);
                        });
                    })
                }
            });
        }).catch(e => {
            this.setState({isResult: true, resultMessage: e.toString()});
        })
    }
    /*** ================================================================================
     *          DEFAULT RENDER
     * ================================================================================*/
    render() {
        const telegramBotInfo = (
            <ul>
                <li>Что бы получить Telegram Chat ID, напишите боту в чате
                    <a target='_blank' href={'https://t.me/DropMaster2Bot'}  >{' DropMaster2Bot '}</a>
                    сообщение "/chatId" (без кавычек).</li>
            </ul>
        );
        return (
            <div>
                <ModalAddNews
                    isAddNewsClick={this.state.isAddNewsClick}
                    addNewsClick={this.addNewsClick}
                    refresh={this.refresh}
                />
                {localStorage.getItem('position') === 'Партнер' && (
                    <div>
                        {this.state.isResult && (
                            <Row>
                                <Col md={12}>
                                    <Card>
                                        <CardBody>
                                            {this.state.resultMessage}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col md={6}>
                                <Row style={{padding: 15}}>
                                    <Col md={12}>
                                        <Card>
                                            <CardHeader>Учетная запись</CardHeader>
                                            <CardBody>
                                                <Form.Group controlId="email">
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control
                                                        disabled={true}
                                                        type="email"
                                                        name={'email'}
                                                        placeholder="Введите email"
                                                        onChange={this.handleEmail}
                                                        value={this.state.email}
                                                    />
                                                </Form.Group>
                                                <Form.Group controlId="name">
                                                    <Form.Label>ФИО</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="name"
                                                        placeholder="Введите ФИО"
                                                        onChange={this.handleName}
                                                        value={this.state.name}
                                                    />
                                                </Form.Group>
                                                <Form.Group controlId="phoneSO">
                                                    <Form.Label>Телефон покупателя</Form.Label>
                                                    <div>
                                                        <NumberFormat
                                                            placeholder="Укажите телефон покупателя"
                                                            onChange={this.handlePhone}
                                                            value={this.state.phone}
                                                            type="tel"
                                                            format="+38 (###) ###-##-##"
                                                            mask=""
                                                            style={{
                                                                display: 'block',
                                                                width: '100%',
                                                                height: 'calc(1.5em + 0.75rem + 2px)',
                                                                padding: '0.375rem 0.75rem',
                                                                fontSize: '1rem',
                                                                fontWeight: '400',
                                                                lineHeight: '1.5',
                                                                color: '#495057',
                                                                backgroundColor: '#fff',
                                                                backgroundClip: 'padding-box',
                                                                border: '1px solid #ced4da',
                                                                borderRadius: '0.25rem',
                                                                outline: 'none',
                                                            }}
                                                        />
                                                    </div>
                                                </Form.Group>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row style={{padding: 15}}>
                                    <Col md={12}>
                                        <Card>
                                            <CardHeader>Данные для выплат</CardHeader>
                                            <CardBody>
                                                <Form.Group controlId="cardNumber">
                                                    <Form.Label>Карта для выплат</Form.Label>
                                                    <NumberFormat
                                                        placeholder="Введите номер карты"
                                                        onChange={this.handlePaymentCard}
                                                        value={this.state.paymentCard}
                                                        type="card"
                                                        format="#### #### #### ####"
                                                        mask=""
                                                        style={{
                                                            display: 'block',
                                                            width: '100%',
                                                            height: 'calc(1.5em + 0.75rem + 2px)',
                                                            padding: '0.375rem 0.75rem',
                                                            fontSize: '1rem',
                                                            fontWeight: '400',
                                                            lineHeight: '1.5',
                                                            color: '#495057',
                                                            backgroundColor: '#fff',
                                                            backgroundClip: 'padding-box',
                                                            border: '1px solid #ced4da',
                                                            borderRadius: '0.25rem',
                                                            outline: 'none',
                                                        }}
                                                    />
                                                </Form.Group>
                                                <Form.Group controlId="cardName">
                                                    <Form.Label>ФИО держателя карты</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        name="cardOwner"
                                                        placeholder="Введите ФИО держателя карты"
                                                        onChange={this.handlePaymentName}
                                                        value={this.state.paymentName}
                                                    />
                                                </Form.Group>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row style={{padding: 15}}>
                                    <Col md={12}>
                                        <Card>
                                            <CardHeader>Реферальная система</CardHeader>
                                            <CardBody>
                                                <ul>
                                                    <li>Ваша реферальная ссылка -
                                                        <a >{window.location.origin + '/registration/' + localStorage.getItem('uid')}</a>
                                                    </li>
                                                </ul>
                                                {/*<Form.Group>*/}
                                                {/*    <Form.Label>Добавить реферальный код партнера:</Form.Label>*/}
                                                {/*    {this.state.referalCode !== '' && (*/}
                                                {/*        <ul>*/}
                                                {/*            <li style={this.state.referalMessageStyle}>{this.state.referalMessage}</li>*/}
                                                {/*        </ul>*/}
                                                {/*    )}*/}
                                                {/*    <Form.Control*/}
                                                {/*        placeholder="Введите реферальный код"*/}
                                                {/*        type="text"*/}
                                                {/*        onChange={this.handleReferalCode}*/}
                                                {/*        value={this.state.referalCode}*/}
                                                {/*    />*/}
                                                {/*</Form.Group>*/}
                                            </CardBody>
                                            <CardFooter>
                                                <Button
                                                    color="primary"
                                                    onClick={() => {navigator.clipboard.writeText(window.location.origin + '/registration/' + localStorage.getItem('uid'))}}
                                                    style={{display: 'block', width: '100%', marginTop: 5}}
                                                >Скопировать ссылку</Button>
                                            </CardFooter>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={6}>
                                <Row style={{padding: 15}}>
                                    <Col md={12}>
                                        <Card>
                                            <CardHeader>Telegram уведомления</CardHeader>
                                            <CardBody>
                                                {telegramBotInfo}
                                                <Form.Group>
                                                    <Form.Label>Добавить Telegram Chat ID:</Form.Label>
                                                    <Form.Control
                                                        placeholder="Введите Chat ID"
                                                        type="text"
                                                        onChange={this.handleTelegramChatID}
                                                        value={this.state.telegramChatID}
                                                    />
                                                    <Button
                                                        color="primary"
                                                        onClick={() => this.handleTelegramChatIDAddToArray()}
                                                        style={{display: 'block', width: '100%', marginTop: 5}}
                                                    >
                                                        Добавить Chat ID
                                                    </Button>
                                                </Form.Group>
                                                <Row>
                                                    <Col md={12}>
                                                        {this.state.telegramChatIDArray.map((a, index) =>
                                                            <div className="widget-content p-1" key={index}>
                                                                <div className="widget-content-wrapper">
                                                                    <div className="widget-content-left">
                                                                        <div className="widget-heading">
                                                                            {a}
                                                                        </div>
                                                                    </div>
                                                                    <div className="widget-content-right">
                                                                        <Button color="primary" onClick={() => this.handleTelegramChatIDRemoveFromArray(a)}>
                                                                            Удалить
                                                                        </Button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row style={{padding: 15}}>
                                    <Col md={12}>
                                        <Card>
                                            <CardHeader>Push уведомления</CardHeader>
                                            <CardBody>
                                                <FormGroup>
                                                    <div>
                                                        <CustomInput type="checkbox" id="2"
                                                                     name="getOrderNotifications"
                                                                     onChange={this.handleTelegramOrderSend}
                                                                     checked={this.state.telegramOrderSend}
                                                                     label="Сообщения о заказе"/>
                                                        <CustomInput type="checkbox" id="3"
                                                                     name="getPayingNotifications"
                                                                     onChange={this.handleTelegramPaymentSend}
                                                                     checked={this.state.telegramPaymentSend}
                                                                     label="Сообщения о балансе"/>
                                                        <CustomInput type="checkbox" id="6"
                                                                     name="getSystemNotifications"
                                                                     onChange={this.handleTelegramSystemMessage}
                                                                     checked={this.state.telegramSystemMessage}
                                                                     label="Системные сообщение"/>
                                                        {/*<CustomInput type="checkbox" id="4"*/}
                                                        {/*             name="getRefuseNotifications"*/}
                                                        {/*             onChange={this.handleTelegramOrderDismiss}*/}
                                                        {/*             checked={this.state.telegramOrderDismiss}*/}
                                                        {/*             label="Отказ получения"/>*/}
                                                        {/*<CustomInput type="checkbox" id="5"*/}
                                                        {/*             name="getOrderCompleteNotifications"*/}
                                                        {/*             onChange={this.handleTelegramOrderSuccess}*/}
                                                        {/*             checked={this.state.telegramOrderSuccess}*/}
                                                        {/*             label="Заказ получен"/>*/}

                                                    </div>
                                                </FormGroup>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row style={{padding: 15}}>
                                    <Col md={12}>
                                        <Card>
                                            <CardBody>
                                                <Button color="primary" style={{width: '100%'}} onClick={this.saveButtonClickHandler}>Сохранить</Button>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                )}
                {localStorage.getItem('position') === 'Админ' && (
                    <Tabs variant="pills"  defaultActiveKey="Настройки" transition={false} id="noanim-tab-example">
                        <Tab eventKey="Настройки" title='Настройки'>
                            {this.state.isResult && (
                                <Row>
                                    <Col md={12}>
                                        <Card>
                                            <CardBody>
                                                {this.state.resultMessage}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            )}
                            <Row>
                                <Col md={6}>
                                    <Row style={{padding: 15}}>
                                        <Col md={12}>
                                            <Card>
                                                <CardHeader>Учетная запись</CardHeader>
                                                <CardBody>
                                                    <Form.Group controlId="email">
                                                        <Form.Label>Email</Form.Label>
                                                        <Form.Control
                                                            disabled={true}
                                                            type="email"
                                                            name={'email'}
                                                            placeholder="Введите email"
                                                            onChange={this.handleEmail}
                                                            value={this.state.email}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group controlId="name">
                                                        <Form.Label>ФИО</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="name"
                                                            placeholder="Введите ФИО"
                                                            onChange={this.handleName}
                                                            value={this.state.name}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group controlId="phoneSO">
                                                        <Form.Label>Ваш номер телефона</Form.Label>
                                                        <div>
                                                            <NumberFormat
                                                                placeholder="Укажите телефон покупателя"
                                                                onChange={this.handlePhone}
                                                                value={this.state.phone}
                                                                type="tel"
                                                                format="+38 (###) ###-##-##"
                                                                mask=""
                                                                style={{
                                                                    display: 'block',
                                                                    width: '100%',
                                                                    height: 'calc(1.5em + 0.75rem + 2px)',
                                                                    padding: '0.375rem 0.75rem',
                                                                    fontSize: '1rem',
                                                                    fontWeight: '400',
                                                                    lineHeight: '1.5',
                                                                    color: '#495057',
                                                                    backgroundColor: '#fff',
                                                                    backgroundClip: 'padding-box',
                                                                    border: '1px solid #ced4da',
                                                                    borderRadius: '0.25rem',
                                                                    outline: 'none',
                                                                }}
                                                            />
                                                        </div>
                                                    </Form.Group>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row style={{padding: 15}}>
                                        <Col md={12}>
                                            <Card>
                                                <CardHeader>Данные для выплат</CardHeader>
                                                <CardBody>
                                                    <Form.Group controlId="cardNumber">
                                                        <Form.Label>Карта для выплат</Form.Label>
                                                        <NumberFormat
                                                            placeholder="Введите номер карты"
                                                            onChange={this.handlePaymentCard}
                                                            value={this.state.paymentCard}
                                                            type="card"
                                                            format="#### #### #### ####"
                                                            mask=""
                                                            style={{
                                                                display: 'block',
                                                                width: '100%',
                                                                height: 'calc(1.5em + 0.75rem + 2px)',
                                                                padding: '0.375rem 0.75rem',
                                                                fontSize: '1rem',
                                                                fontWeight: '400',
                                                                lineHeight: '1.5',
                                                                color: '#495057',
                                                                backgroundColor: '#fff',
                                                                backgroundClip: 'padding-box',
                                                                border: '1px solid #ced4da',
                                                                borderRadius: '0.25rem',
                                                                outline: 'none',
                                                            }}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group controlId="cardName">
                                                        <Form.Label>ФИО держателя карты</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="cardOwner"
                                                            placeholder="Введите ФИО держателя карты"
                                                            onChange={this.handlePaymentName}
                                                            value={this.state.paymentName}
                                                        />
                                                    </Form.Group>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row style={{padding: 15}}>
                                        <Col md={12}>
                                            <Card>
                                                <CardHeader>Реферальной выплаты</CardHeader>
                                                <CardBody>
                                                    <Form.Group controlId="referalOwner">
                                                        <Form.Label>Сумма реферальной выплаты</Form.Label>
                                                        <Form.Control
                                                            type="number"
                                                            name="referalOwner"
                                                            placeholder="Введите сумму реферальной выплаты"
                                                            onChange={this.handleSummReferal}
                                                            value={this.state.summReferal}
                                                        />
                                                    </Form.Group>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Row style={{padding: 15}}>
                                        <Col md={12}>
                                            <Card>
                                                <CardHeader>Telegram уведомления</CardHeader>
                                                <CardBody>
                                                    {telegramBotInfo}
                                                    <Form.Group>
                                                        <Form.Label>Добавить Telegram Chat ID:</Form.Label>
                                                        <Form.Control
                                                            placeholder="Введите Chat ID"
                                                            type="text"
                                                            onChange={this.handleTelegramChatID}
                                                            value={this.state.telegramChatID}
                                                        />
                                                        <Button
                                                            color="primary"
                                                            onClick={() => this.handleTelegramChatIDAddToArray()}
                                                            style={{display: 'block', width: '100%', marginTop: 5}}
                                                        >
                                                            Добавить Chat ID
                                                        </Button>
                                                    </Form.Group>
                                                    <Row>
                                                        <Col md={12}>
                                                            {this.state.telegramChatIDArray.map((a, index) =>
                                                                <div className="widget-content p-1" key={index}>
                                                                    <div className="widget-content-wrapper">
                                                                        <div className="widget-content-left">
                                                                            <div className="widget-heading">
                                                                                {a}
                                                                            </div>
                                                                        </div>
                                                                        <div className="widget-content-right">
                                                                            <Button color="primary" onClick={() => this.handleTelegramChatIDRemoveFromArray(a)}>
                                                                                Удалить
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row style={{padding: 15}}>
                                        <Col md={12}>
                                            <Card>
                                                <CardBody>
                                                    <Button color="primary" style={{width: '100%'}} onClick={this.saveButtonClickHandler}>Сохранить</Button>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="Новости" title='Новости'>
                            <Row>
                                <Col>
                                    <Card>
                                        <CardHeader>
                                            <Row style={{width: '100%'}}>
                                                <Col md={6}>Новости</Col>
                                                <Col md={6}>
                                                    <Button color="primary" style={{width: '100%'}} onClick={this.addNewsClick}>Добавить новость</Button>
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        <CardBody>
                                            <TableNews
                                                news={this.state.news}
                                                deleteNews={this.deleteNews}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="Категории" title='Категории'>
                            <TableCategory/>
                        </Tab>
                    </Tabs>
                )}
            </div>
        )}
}
const mapStateToProps = state => {
    return {
        basketArray: state.basket,
        user: state.basket.user,
        adminSettings: state.basket.adminSettings
    }
};

const mapDispatchToProps = {
    setAdmin,
    clearAdmin,
    setUser,
    clearUser,
    addProduct,
    removeProduct,
    removeAll,
    changeProduct
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
