import React from "react";
import CountUp from "react-countup/build";
import {Button} from "react-bootstrap";
import ReactTable from "react-table";
import {getDateFromMilliseconds} from "../firebase/Firebase";

export default class App extends React.Component {
    /*** ================================================================================
     *          Change ALL
     * ================================================================================*/
    isOrderInChangeAll(p){
        return this.props.orderChangeAll.find(el => el.id === p.id);
    };
    /*** ================================================================================
     *          Get info about user
     * ================================================================================*/
    getUserInfo(uid){
        var users = this.props.users;
        var result = {
            active: false,
            summHold: 0,
            summFinished: 0,
            summBack: 0,
            summBalance: 0,
            summPayed: 0,
            summInput: 0,

            quantityHold: 0,
            quantityFinished: 0,
            quantityBack: 0,
            quantityBalance: 0,
            quantityPayed: 0,
            quantityInput: 0,

            quantityOrders: 0,
            quantityProducts: 0,
        };
        try {
            users.forEach(u => {
                if (u.uid === uid){
                    result = u;
                }
            });
        }catch (e) {
            console.log(e);
        }
        return result;
    }
    isActiveUser(bool){
        if (bool){
            return 'Активен';
        }else {
            return 'Не активен';
        }
    }
    /*** ================================================================================
     *          Default Render
     * ================================================================================*/
    render() {

        const user = (d) => (
            <div style={{overflow: 'auto', height: '100%', textAlign: 'top', letterSpacing: '.05em'}}>
                <div>
                    <i className="header-icon pe-7s-user"> </i>
                    {d.fromEmail}
                </div>
                <div>
                    <i className="header-icon pe-7s-user"> </i>
                    {d.fromName}
                </div>
                <div>
                    <i className="header-icon pe-7s-user"> </i>
                    {this.isActiveUser(this.getUserInfo(d.fromUID).active)}
                </div>
                <div>
                    <i className="header-icon pe-7s-cash"> </i>
                    <b>Холд:</b> {this.getUserInfo(d.fromUID).summHold}
                </div>
                <div>
                    <i className="header-icon pe-7s-cash"> </i>
                    <b>Отказ:</b> {this.getUserInfo(d.fromUID).summBack}
                </div>
                <div>
                    <i className="header-icon pe-7s-cash"> </i>
                    <b>Завершено:</b> {this.getUserInfo(d.fromUID).summFinished}
                </div>
                <div>
                    <i className="header-icon pe-7s-cash"> </i>
                    <b>Баланс:</b> {this.getUserInfo(d.fromUID).summBalance}
                </div>
                <div>
                    <i className="header-icon pe-7s-cash"> </i>
                    <b>Выплачено:</b> {this.getUserInfo(d.fromUID).summPayed}
                </div>
            </div>
        );
        const client = (d) => (
            <div style={{overflow: 'auto', height: '100%', textAlign: 'top', letterSpacing: '.05em'}}>
                <div>
                    <i className="header-icon pe-7s-paperclip"> </i>
                    <b>ID:</b> {d.id}
                </div>
                <div>
                    <i className="header-icon pe-7s-date"> </i>
                    <b>Дата:</b> {getDateFromMilliseconds(d.date)}
                </div>
                <div>
                    <i className="header-icon pe-7s-cash"> </i>
                    <b>Тип оплаты:</b> {d.paymentType}
                </div>
                <div>
                    <i className="header-icon pe-7s-user"> </i>
                    <b>ФИО:</b> {d.name}
                </div>
                <div>
                    <i className="header-icon pe-7s-phone"> </i>
                    <b>Телефон:</b> {d.phone}
                </div>
                <div>
                    <i className="header-icon pe-7s-map-marker"> </i>
                    <b>Город:</b> {d.city}
                </div>
                <div>
                    <i className="header-icon pe-7s-tools"> </i>
                    <b>Статус:</b> {d.status}
                </div>
                <div>
                    <i className="header-icon pe-7s-map"> </i>
                    <b>ТТН:</b> {d.TTNNP}
                </div>
                <div>
                    <i className="header-icon pe-7s-map-2"> </i>
                    <b>НП Статус:</b> {d.TTNStatusNP}
                </div>
            </div>
        );
        const order = (d) => (
            <div style={{overflow: 'auto', height: '100%', width: '100%', textAlign: 'top'}}>
                {d.products.map( p => (
                    <div key={p.id} className="widget-content p-0">
                        <div>
                            <i className="header-icon pe-7s-paperclip"> </i>
                            <b>Название:</b> {p.name}
                        </div>
                        <div>
                            <i className="header-icon pe-7s-tools"> </i>
                            <b>Количество:</b> {' (' + p.quantity + ' x ' + p.price + ' = ' +  p.quantity*p.price + ')'}
                        </div>
                    </div>
                ))}
                <div>
                    <i className="header-icon pe-7s-cash"> </i>
                    <b>Итого:</b> {d.summ}
                </div>
                <div>
                    <i className="header-icon pe-7s-cash"> </i>
                    <b>Дроп:</b> {d.summDrop}
                </div>
                <div>
                    <i className="header-icon pe-7s-cash"> </i>
                    <b>Клиент:</b>{d.summClient}
                </div>
            </div>
        );
        const btnAll = (d) => (
            <div style={{overflow: 'none', height: '100%', textAlign: 'top'}}>
                <Button
                    className="mb-2 mr-2"
                    color="primary"
                    onClick={() => this.props.clickBalanceShow(d.fromUID)}
                    style={{width: '100%'}}
                >
                    Баланс
                </Button>
                <Button
                    className="mb-2 mr-2"
                    color="primary"
                    onClick={() => this.props.clickOrdersShow(d.fromUID)}
                    style={{width: '100%'}}
                >
                    Все заказы
                </Button>
                {localStorage.getItem('position') === 'Админ' && (
                    <div>
                        <Button
                            className="mb-2 mr-2"
                            color="primary"
                            onClick={() => this.props.clickChangeOrder(d)}
                            style={{width: '100%'}}
                        >
                            Редактировать
                        </Button>
                        <Button
                            style={{textAlign: 'center', width: '100%'}}
                            className="mb-2 mr-2"
                            color="primary"
                            onClick={() => {
                                if (this.isOrderInChangeAll(d))
                                    this.props.deleteOrderFromChangeAll(d);
                                else this.props.addOrderToChangeAll(d);
                            }}>
                            {this.isOrderInChangeAll(d)?'Удалить из массового изменения':'Добавить в массовое изменение'}
                        </Button>
                    </div>
                )}
                {localStorage.getItem('position') === 'Партнер' && (
                    <div>
                        <Button
                            className="mb-2 mr-2"
                            color="primary"
                            onClick={() => this.props.clickChangeOrder(d)}
                            style={{width: '100%'}}
                            disabled={d.active}
                        >
                            Редактировать
                        </Button>
                        <Button
                            disabled={d.active}
                            style={{textAlign: 'center', width: '100%'}}
                            className="mb-2 mr-2"
                            color="primary"
                            onClick={() => {
                                if (this.isOrderInChangeAll(d))
                                    this.props.deleteOrderFromChangeAll(d);
                                else this.props.addOrderToChangeAll(d);
                            }}>
                            {this.isOrderInChangeAll(d)?'Удалить из массового изменения':'Добавить в массовое изменение'}
                        </Button>
                    </div>
                )}
            </div>
        );
        const btnChange = (d) => (
            <div style={{overflow: 'none', height: '100%', textAlign: 'top'}}>
                {localStorage.getItem('position') === 'Админ' && (
                    <div>
                        <Button
                            className="mb-2 mr-2"
                            color="primary"
                            onClick={() => this.props.clickChangeOrder(d)}
                            style={{width: '100%'}}
                        >
                            Редактировать
                        </Button>
                        <Button
                            style={{textAlign: 'center', width: '100%'}}
                            className="mb-2 mr-2"
                            color="primary"
                            onClick={() => {
                                if (this.isOrderInChangeAll(d))
                                    this.props.deleteOrderFromChangeAll(d);
                                else this.props.addOrderToChangeAll(d);
                            }}>
                            {this.isOrderInChangeAll(d)?'Удалить из массового изменения':'Добавить в массовое изменение'}
                        </Button>
                    </div>
                )}
                {localStorage.getItem('position') === 'Партнер' && (
                    <div>
                        <Button
                            className="mb-2 mr-2"
                            color="primary"
                            onClick={() => this.props.clickChangeOrder(d)}
                            style={{width: '100%'}}
                            disabled={d.active}
                        >
                            Редактировать
                        </Button>
                        <Button
                            disabled={d.active}
                            style={{textAlign: 'center', width: '100%'}}
                            className="mb-2 mr-2"
                            color="primary"
                            onClick={() => {
                                if (this.isOrderInChangeAll(d))
                                    this.props.deleteOrderFromChangeAll(d);
                                else this.props.addOrderToChangeAll(d);
                            }}>
                            {this.isOrderInChangeAll(d)?'Удалить из массового изменения':'Добавить в массовое изменение'}
                        </Button>
                    </div>
                )}
            </div>
        );
        return(
            <div>
                {this.props.adminChange && (
                    <ReactTable
                        previousText={'Предыдущая страница'}
                        nextText={'Следующая страница'}
                        pageText='Страница'
                        ofText='из'
                        rowsText='строк'
                        data={this.props.orders}
                        columns={[
                            {
                                columns: [
                                    {
                                        Header: "Пользователь",
                                        id: "user",
                                        minWidth: 200,
                                        maxWidth: 500,
                                        accessor: d => (user(d))
                                    },
                                    {
                                        Header: "Заказчик",
                                        id: "client",
                                        minWidth: 200,
                                        maxWidth: 500,
                                        accessor: d => (client(d))
                                    },
                                    {
                                        Header: "Заявка",
                                        id: "order",
                                        minWidth: 200,
                                        accessor: d => (order(d))
                                    },
                                    {
                                        Header: "Действия",
                                        id: "doing",
                                        maxWidth: 200,
                                        minWidth: 200,
                                        accessor: d => (btnAll(d))
                                    },
                                ]}
                        ]}
                        defaultPageSize={10}
                        className="-striped -highlight"
                    />
                )}
                {this.props.adminNoChange && (
                    <ReactTable
                        previousText={'Предыдущая страница'}
                        nextText={'Следующая страница'}
                        pageText='Страница'
                        ofText='из'
                        rowsText='строк'
                        data={this.props.orders}
                        columns={[
                            {
                                columns: [
                                    {
                                        Header: "Пользователь",
                                        id: "user",
                                        minWidth: 200,
                                        maxWidth: 500,
                                        accessor: d => (user(d))
                                    },
                                    {
                                        Header: "Заказчик",
                                        id: "client",
                                        minWidth: 200,
                                        maxWidth: 500,
                                        accessor: d => (client(d))
                                    },
                                    {
                                        Header: "Заявка",
                                        id: "order",
                                        minWidth: 200,
                                        accessor: d => (order(d))
                                    },
                                ]}
                        ]}
                        defaultPageSize={10}
                        className="-striped -highlight"
                    />
                )}
                {this.props.partnerChange && (
                    <ReactTable
                        previousText={'Предыдущая страница'}
                        nextText={'Следующая страница'}
                        pageText='Страница'
                        ofText='из'
                        rowsText='строк'
                        data={this.props.orders}
                        columns={[
                            {
                                columns: [
                                    {
                                        Header: "Заказчик",
                                        id: "client",
                                        minWidth: 200,
                                        maxWidth: 500,
                                        accessor: d => (client(d))
                                    },
                                    {
                                        Header: "Заявка",
                                        id: "order",
                                        minWidth: 200,
                                        accessor: d => (order(d))
                                    },
                                    {
                                        Header: "Действия",
                                        id: "doing",
                                        maxWidth: 200,
                                        minWidth: 200,
                                        accessor: d => (btnChange(d))
                                    },
                                ]}
                        ]}
                        defaultPageSize={10}
                        className="-striped -highlight"
                    />
                )}
                {this.props.partnerNoChange && (
                    <ReactTable
                        previousText={'Предыдущая страница'}
                        nextText={'Следующая страница'}
                        pageText='Страница'
                        ofText='из'
                        rowsText='строк'
                        data={this.props.orders}
                        columns={[
                            {
                                columns: [
                                    {
                                        Header: "Заказчик",
                                        id: "client",
                                        minWidth: 200,
                                        maxWidth: 500,
                                        accessor: d => (client(d))
                                    },
                                    {
                                        Header: "Заявка",
                                        id: "order",
                                        minWidth: 200,
                                        accessor: d => (order(d))
                                    },
                                ]}
                        ]}
                        defaultPageSize={10}
                        className="-striped -highlight"
                    />
                )}
            </div>

        )
    }
}