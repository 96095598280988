import React, {Fragment} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Navbar, Nav, Button, Card, CardColumns, Form, Modal, FormControl, Carousel} from 'react-bootstrap';
import './LandingPage.css';
import {
    Col,
    CardBody,
    CardHeader,
    CardFooter,
    Row,
    CardText,
    CardLink
} from "reactstrap";
import AppHeader from "../../Layout/AppHeader/index";
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import ResizeDetector from 'react-resize-detector';
import NumberFormat from 'react-number-format';

import img1 from './images/1.png'
import img2 from './images/2.png'
import img3 from './images/3.png'
import img4 from './images/4.png'

import pr1 from '../../images/1.jpeg';
import pr2 from '../../images/2.jpeg';
import pr3 from '../../images/3.jpeg';
import pr4 from '../../images/4.jpeg';

import ModalRegisterUser from "./ModalRegisterUser";
import FirstComponent from "./components/FirstComponent";
import CardStep from "./components/CardStep";
import Header from './components/Header';
import ExampleLeft from "./components/ExampleLeft";
import ExampleRight from "./components/ExampleRight";
import HeaderAndSubHeader from "./components/HeaderAndSubHeader";
import CardProducts from "./components/CardProducts";
import SendMessage from "./components/SendMessage";
import Contacts from "../contacts/Contacts";



class App extends React.Component {
    /*** ================================================================================
     *          Main methods
     * ================================================================================*/
    constructor(props, context) {
        super(props, context);
        this.state = {
            // DB
            products: [],
            // Cards
            cards: [1, 2, 3],
            // registration
            isRegisterBoxClicked: false,
        };
        this.clickRegisterBox = this.clickRegisterBox.bind(this);
    }
    componentWillMount() {
        // console.log(window.location)
        var array = [];
        array.push({
            imagesUrlP: [pr1],
            priceDropP: 699,
            nameP: 'Блендер NutriBullet RX 1700W (12 предметов)',
            categoryTextP: 'Электронные сигареты, батарейные моды, атомайзеры',
        });
        array.push({
            imagesUrlP: [pr2],
            priceDropP: 440,
            nameP: 'Машинка-перевертыш Skidding Hyper Leopard King с управлением жестами',
            categoryTextP: 'Акустические системы',
        });
        array.push({
            imagesUrlP: [pr3],
            priceDropP: 1490,
            nameP: 'Квадрокоптер Phantom c WiFi Камерой',
            categoryTextP: 'Квадрокоптеры',
        });
        array.push({
            imagesUrlP: [pr4],
            priceDropP: 499,
            nameP: 'Стартовый набор Smok Alien 220W Kit',
            categoryTextP: 'Универсальные мобильные батареи',
        });
        this.setState({products: array});
    }
    /*** ================================================================================
     *          Register
     * ================================================================================*/
    clickRegisterBox = () => {
        if (!this.state.isRegisterBoxClicked) {
            this.setState({isRegisterBoxClicked: true});
        } else {
            this.setState({isRegisterBoxClicked: false});
        }
    };
    /*** ================================================================================
     *          DEFAULT RENDER
     * ================================================================================*/
    render() {
        return (
            <div style={{overflow: 'hidden'}}>
                {/*Header*/}
                <ResizeDetector
                    handleWidth
                    render={({width}) => (
                        <Fragment>
                            <Fragment>
                                <AppHeader/>
                            </Fragment>
                        </Fragment>
                    )}
                />
                {this.state.isRegisterBoxClicked && (
                    <ModalRegisterUser
                        isRegisterBoxClicked={this.state.isRegisterBoxClicked}
                        clickRegisterBox={this.clickRegisterBox}
                    />
                )}
                {/*Content*/}
                <FirstComponent
                    clickRegisterBox={this.clickRegisterBox}
                />

                <Header text={'С нами все просто!'}/>
                <Row>
                    <Col sm={12} style={{padding: 20}}>
                        <Row>
                            <Col md={3}>
                                <CardStep
                                    icon={1}
                                    title={'Добавьте заказ'}
                                    liArray={['Войдите на платформу', 'Выберите товар', 'Укажите данные о заказе']}
                                />
                            </Col>
                            <Col md={3}>
                                <CardStep
                                    icon={2}
                                    title={'Мы отправим товар'}
                                    liArray={['Наш склад получит заявку', 'Упакует товар', 'В тот же день отправит']}
                                />
                            </Col>
                            <Col md={3}>
                                <CardStep
                                    icon={3}
                                    title={'Клиент забирает посылку'}
                                    liArray={['Клиент проверит посылку', 'Оплатит наложенный платеж', 'Получит свой товар']}
                                />
                            </Col>
                            <Col md={3}>
                                <CardStep
                                    icon={4}
                                    title={'Заказ выплаты на карту'}
                                    liArray={['Моментально после оплаты Ваш баланс пополнится', 'Получите деньги на карту']}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <br/>
                <Header text={'И очень удобно!'}/>
                <Row>
                    <Col>
                        <ExampleLeft
                            image={img1}
                            title={'Доступ к финансам'}
                            subTitle={''}
                            text={'Вы всегда можете заказать выплату средств на свои реквизиты и моментально получить результат'}
                        />
                        <ExampleRight
                            image={img2}
                            title={'Актуальные товары'}
                            subTitle={''}
                            text={'Что может быть лучше постоянной осведомленности о актуальности условий сотрудничества. С нами Вы всегда будете в курсе обновления цен, наличия и информации о товаре.'}
                        />
                        <ExampleLeft
                            image={img3}
                            title={'Статистика'}
                            subTitle={''}
                            text={'Невероятно удобно когда все цифры перед глазами. В личном кабинете Вы всегда сможете распологать самой актуальной информацией о вашем бизнесе.'}
                        />
                        <ExampleRight
                            image={img4}
                            title={'Своевременная информация'}
                            subTitle={''}
                            text={'Будьте уверенны что всегда сможете иметь самую свежую информацию о ситуации с заказом.'}
                        />
                    </Col>
                </Row>
                <HeaderAndSubHeader
                    title={'Только посмотрите какие у нас товары!'}
                    // subTitle={'- А заодно и цены'}
                />
                <Row style={{textAlign: 'center', padding: 25}}>
                    <Col md="3" xl="3">
                        <CardProducts
                            name={this.state.products[0].nameP}
                            image={this.state.products[0].imagesUrlP[0]}
                            category={this.state.products[0].categoryTextP}
                            price={this.state.products[0].priceDropP}
                            click={this.clickRegisterBox}
                        />
                    </Col>
                    <Col md="3" xl="3">
                        <CardProducts
                            name={this.state.products[1].nameP}
                            image={this.state.products[1].imagesUrlP[0]}
                            category={this.state.products[1].categoryTextP}
                            price={this.state.products[1].priceDropP}
                            click={this.clickRegisterBox}
                        />
                    </Col>
                    <Col md="3" xl="3">
                        <CardProducts
                            name={this.state.products[2].nameP}
                            image={this.state.products[2].imagesUrlP[0]}
                            category={this.state.products[2].categoryTextP}
                            price={this.state.products[2].priceDropP}
                            click={this.clickRegisterBox}
                        />
                    </Col>
                    <Col md="3" xl="3">
                        <CardProducts
                            name={this.state.products[3].nameP}
                            image={this.state.products[3].imagesUrlP[0]}
                            category={this.state.products[3].categoryTextP}
                            price={this.state.products[3].priceDropP}
                            click={this.clickRegisterBox}
                        />
                    </Col>
                </Row>
                <HeaderAndSubHeader
                    title={'Свяжитесь с нами!'}
                    subTitle={'- Мы всегда на связи'}
                />
                <Row style={{padding: 25}}>
                    <Col sm="12" md="6">
                        <SendMessage/>
                    </Col>
                    <Col sm="12" md="6">
                        <Card className="no-shadow mb-3">
                            <CardHeader>
                                <div
                                    className="card-header-title font-size-lg text-capitalize font-weight-normal">
                                    <i className="header-icon lnr-apartment icon-gradient bg-amy-crisp"> </i>
                                    Наши контакты:
                                </div>
                            </CardHeader>
                            <CardBody className="p-0">
                                <Row>
                                    <Col md={12}>
                                        <Card>
                                            <CardHeader>Вопросы по продажам и товару:</CardHeader>
                                            <CardBody>
                                                <CardText>Нужна помощь? Возникли вопросы или предложения при работе с сайтом - задайте их нашему консультанту по продажам</CardText>
                                                <CardLink className="btn btn-primary" target='_blank' href="http://t.me/dropmaster_sales">Консультант по продажам</CardLink>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <br/>
                                <Row>
                                    <Col md={12}><Card>
                                        <CardHeader>Технические вопросы:</CardHeader>
                                        <CardBody>
                                            <CardText>Если у вас возникли проблемы технического характера при работе с сайтом - обратитесь к нашему тех менеджеру</CardText>
                                            <CardLink className="btn btn-primary" target='_blank' href="http://t.me/dropmaster_manager">Технический консультант</CardLink>
                                        </CardBody>
                                    </Card></Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}


const mapStateToProp = state => ({
    colorScheme: state.ThemeOptions.colorScheme,
    enableFixedHeader: state.ThemeOptions.enableFixedHeader,
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    enableFixedFooter: state.ThemeOptions.enableFixedFooter,
    enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
    enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
    enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,

});

export default withRouter(connect(mapStateToProp)(App));
