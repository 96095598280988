import React from "react";
import {Button, Col, Row} from "reactstrap";
import ReactTable from "react-table";
import {getDateFromMilliseconds} from '../firebase/Firebase'

const styleCell = {overflow: 'auto', height: '100%', textAlign: 'top'};

export default class App extends React.Component {
    /*** ================================================================================
     *          Default Render
     * ================================================================================*/
    render() {
        return (
            <Row>
                <Col md={12}>
                    <div className="ProductWindow-Container">
                        <div className="ProductWindow-Table-Products-Container">
                            {this.props.isBtnChange && (
                                <ReactTable
                                    previousText={'Предыдущая страница'}
                                    nextText={'Следующая страница'}
                                    pageText='Страница'
                                    ofText='из'
                                    rowsText='строк'
                                    data={this.props.balanceOperation}
                                    columns={[{
                                        columns: [
                                            {
                                                Header: "ID",
                                                id: "ID",
                                                minWidth: 180,
                                                accessor: d => (
                                                    <div style={styleCell}>
                                                        <div>
                                                            <i className="header-icon pe-7s-paperclip"> </i>
                                                            <b>ID:</b> {d.id}
                                                        </div>
                                                        <div>
                                                            <i className="header-icon pe-7s-user"> </i>
                                                            {d.fromEmail}
                                                        </div>
                                                        <div>
                                                            <i className="header-icon pe-7s-user"> </i>
                                                            {d.fromName}
                                                        </div>
                                                    </div>
                                                )
                                            },
                                            {
                                                Header: "Тип операции",
                                                id: "type",
                                                accessor: d => (
                                                    <div className=""  style={styleCell}>
                                                        {d.typeOperation}
                                                    </div>
                                                )
                                            },
                                            {
                                                Header: "Дата",
                                                id: "date",
                                                accessor: d => (
                                                    <div style={styleCell}>
                                                        {getDateFromMilliseconds(d.date)}
                                                    </div>
                                                )
                                            },
                                            {
                                                Header: "Сумма",
                                                id: "summ",
                                                accessor: d => (
                                                    <div style={styleCell}>
                                                        {d.summ}
                                                    </div>
                                                )
                                            },
                                            {
                                                Header: "Реквизиты",
                                                id: "requisites",
                                                accessor: d => (
                                                    <div style={styleCell}>
                                                        {d.requisites}
                                                    </div>
                                                )
                                            },
                                            {
                                                Header: "Статус",
                                                id: "status",
                                                accessor: d => (
                                                    <div>
                                                        <div style={styleCell}>
                                                            {d.status}
                                                        </div>
                                                    </div>
                                                )
                                            },
                                            {
                                                Header: "Действия",
                                                id: "doing",
                                                accessor: d => (
                                                    <div>
                                                        <Button className="mb-2 mr-2" color="primary"
                                                                onClick={() => this.props.changeCallBack(d)}  >
                                                            Редактировать
                                                        </Button>
                                                    </div>
                                                )
                                            },
                                        ]},
                                    ]}
                                    defaultPageSize={5}
                                    className="-striped -highlight"
                                />
                            )}
                            {!this.props.isBtnChange && (
                                <ReactTable
                                    previousText={'Предыдущая страница'}
                                    nextText={'Следующая страница'}
                                    pageText='Страница'
                                    ofText='из'
                                    rowsText='строк'
                                    data={this.props.balanceOperation}
                                    columns={[{
                                        columns: [
                                            {
                                                Header: "ID",
                                                id: "ID",
                                                minWidth: 180,
                                                accessor: d => (
                                                    <div className=""  style={styleCell}>
                                                        {d.id}
                                                    </div>
                                                )
                                            },
                                            {
                                                Header: "Тип операции",
                                                id: "type",
                                                accessor: d => (
                                                    <div className=""  style={styleCell}>
                                                        {d.typeOperation}
                                                    </div>
                                                )
                                            },
                                            {
                                                Header: "Дата",
                                                id: "date",
                                                accessor: d => (
                                                    <div style={styleCell}>
                                                        {getDateFromMilliseconds(d.date)}
                                                    </div>
                                                )
                                            },
                                            {
                                                Header: "Сумма",
                                                id: "summ",
                                                accessor: d => (
                                                    <div style={styleCell}>
                                                        {d.summ}
                                                    </div>
                                                )
                                            },
                                            {
                                                Header: "Реквизиты",
                                                id: "requisites",
                                                accessor: d => (
                                                    <div style={styleCell}>
                                                        {d.requisites}
                                                    </div>
                                                )
                                            },
                                            {
                                                Header: "Статус",
                                                id: "status",
                                                accessor: d => (
                                                    <div>
                                                        <div style={styleCell}>
                                                            {d.status}
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        ]},
                                    ]}
                                    defaultPageSize={5}
                                    className="-striped -highlight"
                                />
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
        )
    }
}