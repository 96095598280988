import React, {Fragment} from 'react';

import {Form, Card, Modal,} from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {DropdownToggle, DropdownMenu, Nav, Col, Row, Button, NavItem, UncontrolledButtonDropdown, FormGroup, Input, Label} from 'reactstrap';
import {faAngleDown} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import city3 from '../../../assets/utils/images/dropdown-header/city3.jpg';
import hipster from '../../../images/hipster.png'
import {
    fire,
    calcAllUsers,
    createNewUserAndGoToAccount,
    getAdminSettings,
    getDataFromCollectionWhereKeyValue,
    sendMessageTelegramBotWithSettings,
    getDataFromCollection,
    getAllSummsAndQuantityAllUsers, filterOrdersHold, filterOrdersFinished, filterOrdersBack, summInArray, getUserJson
} from '../../../componentsWithOutTheme/firebase/Firebase';
import {provider} from "../../../componentsWithOutTheme/firebase/FirebaseProvider";
import ModalRegisterUser from "../../../componentsWithOutTheme/landing/ModalRegisterUser";
import {connect} from "react-redux";
import {setAdmin, clearAdmin, setUser, clearUser, addProduct, removeProduct, removeAll, changeProduct} from "../../../componentsWithOutTheme/storeReducers/basket/actions";
import * as moment from "moment";

const styleFont = {fontSize: '15px'};

class UserBox extends React.Component {
    /*** ================================================================================
     *          Main methods
     * ================================================================================*/
    constructor(props) {
        super(props);
        this.state = {
            // Is Signed In
            signedIn: false,
            // Data Base
            currentUser: getUserJson(),
            // Handle Sign In
            emailSignIn: '',
            passwordSignIn: '',
            isRememberMeSignIn: false,
            // Registration Modal Window
            isRegisterBoxClicked: false,
            // Admin Info
            users: [],
            summBalanceUsers: {},
            resultUsersCalc: {},
            adminCalc: {},
            resultOrders: {},
            orders: [],
            // Referal
            referalSummFinished: 0,
            referaSummlHold: 0,
            referalQuantityFinished: 0,
            referalQuantityHold: 0,
        };
        this.goToAccount = this.goToAccount.bind(this);
        this.signInWithEmail = this.signInWithEmail.bind(this);
    }
    componentDidMount() {

        this.refresh();
        fire.auth().onAuthStateChanged( (user) => {
            if (user) {
                this.setState({signedIn: true});
            } else {
                this.setState({signedIn: false});
            }
        });
    }
    refresh(){
        try {
            this.getUser();
            this.getAdminSettings();
            if (localStorage.getItem('position') === 'Админ'){
                this.getUsers();
                this.getOrders();
            }else {
                this.getReferal();
            }
        }catch (e) {

        }
    }
    /*** ================================================================================
     *          Data Base
     *              - Get User and write to reducer
     *              - Get Admin Settings
     * ================================================================================*/
    getUser(){
        getDataFromCollectionWhereKeyValue("usders", "uid", localStorage.getItem('uid')).then( r => {
            this.props.setUser(r.data[0]);
            // console.log(r.data[0]);
            this.setState({currentUser: r.data[0]});
        });
    };
    getAdminSettings(){
        getAdminSettings().then(r =>{
            // console.log(r)
            this.props.setAdmin(r);
        })
    }
    getUsers() {
        getDataFromCollectionWhereKeyValue('usders', 'uid', this.props.adminSettings.uid).then( admin => {
            getDataFromCollection("usders").then(u => {
                getAllSummsAndQuantityAllUsers(false).then( result => {
                    this.setState({
                        users: u.data,
                        summBalanceUsers: result.summBalance,
                        resultUsersCalc: result,
                        adminCalc: admin.data[0],
                    });
                });
            });
        });
    }
    getOrders(){
        getDataFromCollection("orders").then(o => {
            const ordersAll = o.data;
            const ordersHoldAll = filterOrdersHold(ordersAll);
            const ordersFinishedAll = filterOrdersFinished(ordersAll);
            const ordersBackAll = filterOrdersBack(ordersAll);
            // Admins orders
            const ordersAdmin = [];
            // Partner orders
            const ordersPartner = [];
            // Divide all orders to Admin and Partner orders
            ordersAll.forEach( order => {
                if ((order.summDrop - order.summPurchase) === 0){
                    ordersAdmin.push(order);
                }else if ((order.summDrop - order.summPurchase) > 0){
                    ordersPartner.push(order);
                }
            });
            const ordersHoldAdmin = filterOrdersHold(ordersAdmin);
            const ordersFinishedAdmin = filterOrdersFinished(ordersAdmin);
            const ordersBackAdmin = filterOrdersBack(ordersAdmin);
            //
            const ordersHoldPartner = filterOrdersHold(ordersPartner);
            const ordersFinishedPartner = filterOrdersFinished(ordersPartner);
            const ordersBackPartner = filterOrdersBack(ordersPartner);
            //
            const summHoldAll = summInArray(ordersHoldAll, 'summ');
            const summFinishedAll = summInArray(ordersFinishedAll, 'summ');
            const summBackAll = summInArray(ordersBackAll, 'summBack');
            //
            const summHoldAdmin = summInArray(ordersHoldAdmin, 'summ');
            const summFinishedAdmin = summInArray(ordersFinishedAdmin, 'summ');
            const summBackAdmin = summInArray(ordersBackAdmin, 'summBack');
            //
            const summHoldPartner = summInArray(ordersHoldPartner, 'summ');
            const summFinishedPartner = summInArray(ordersFinishedPartner, 'summ');
            const summBackPartner = summInArray(ordersBackPartner, 'summBack');
            //
            const earnedAdmin = summInArray(ordersFinishedAdmin , 'summPurchase');
            const earnedPartner = summInArray( ordersFinishedPartner , 'summPurchase');
            //
            const resultOrders = {
                ordersAll: ordersAll,
                ordersHoldAll: ordersHoldAll,
                ordersFinishedAll: ordersFinishedAll,
                ordersBackAll: ordersBackAll,
                summHoldAll: summHoldAll,
                summFinishedAll: summFinishedAll,
                summBackAll: summBackAll,

                ordersAdmin: ordersAdmin,
                ordersHoldAdmin: ordersHoldAdmin,
                ordersFinishedAdmin: ordersFinishedAdmin,
                ordersBackAdmin: ordersBackAdmin,
                summHoldAdmin: summHoldAdmin,
                summFinishedAdmin: summFinishedAdmin,
                summBackAdmin: summBackAdmin,

                ordersPartner: ordersPartner,
                ordersHoldPartner: ordersHoldPartner,
                ordersFinishedPartner: ordersFinishedPartner,
                ordersBackPartner: ordersBackPartner,
                summHoldPartner: summHoldPartner,
                summFinishedPartner: summFinishedPartner,
                summBackPartner: summBackPartner,
                //
                earnedAdmin: earnedAdmin,
                earnedPartner: earnedPartner,
            };
            this.setState({
                resultOrders: resultOrders,
                orders: ordersAll,
            });
        });
    }
    getReferal() {
        getDataFromCollectionWhereKeyValue("usders", "referal", localStorage.getItem('uid')).then( referal => {
            const users = referal.data;
            // console.log(users);
            var referalSummFinished = 0;
            var referaSummlHold = 0;
            var referalQuantityFinished = 0;
            var referalQuantityHold = 0;
            users.forEach( u => {
                referalSummFinished = referalSummFinished + u.referalSummFinished;
                referaSummlHold = referaSummlHold + u.referaSummlHold;
                referalQuantityFinished = referalQuantityFinished + u.referalQuantityFinished;
                referalQuantityHold = referalQuantityHold + u.referalQuantityHold;
            });
            this.setState({
                referalSummFinished: referalSummFinished,
                referaSummlHold: referaSummlHold,
                referalQuantityFinished: referalQuantityFinished,
                referalQuantityHold: referalQuantityHold,
            })
        });
    }
    /*** ================================================================================
     *          Hanndle In box
     * ================================================================================*/
    hcEmailSignIn = e => {
        this.setState({emailSignIn: e.target.value})
    };
    hcPasswordSignIn = e => {
        this.setState({passwordSignIn: e.target.value})
    };
    hcIsRememberMeSignIn = e => {
        this.setState({isRememberMeSignIn: e.target.value})
    };
    /*** ================================================================================
     *          LOG IN/OUT
     * ================================================================================*/
    signInGooglePopUp(){
        var currentUser = null;
        fire.auth().signInWithPopup(provider)
            .then(result => {
                fire.firestore().collection("usders")
                    .get()
                    .then(querySnapshot => {
                        querySnapshot.docs.forEach(doc => {
                            if (result.user.email.toString() === doc.data().email){
                                // has signed up
                                this.changeDateLastSignIn(doc);

                                currentUser = doc.data();
                            }
                        });
                        if (currentUser === 'null' ){
                            // this email hasn't signed up yet
                            this.props.setUser(result);
                            this.createNewUserFromGoogleAndGotoAccount(result);
                        } else if (currentUser === null){
                            // this email hasn't signed up yet
                            this.props.setUser(result);
                            this.createNewUserFromGoogleAndGotoAccount(result);
                        }else{
                            this.goToAccount(currentUser);
                        }
                    });
            }).catch(error => {
            console.log(error);
        });
    }
    signInWithEmail(){
        var currentUser = null;
        fire.auth().signInWithEmailAndPassword(this.state.emailSignIn, this.state.passwordSignIn)
            .then(result => {
                if(!result.user.emailVerified) fire.auth().currentUser.sendEmailVerification();
                // Проверка на существующего пользователя...
                fire.firestore().collection("usders")
                    .get()
                    .then(querySnapshot => {
                        querySnapshot.docs.forEach(doc => {
                            if (result.user.email === doc.data().email){
                                this.changeDateLastSignIn(doc);
                                currentUser = doc.data();
                                this.goToAccount(currentUser);
                                return;
                            }else {

                            }
                        });
                    });
            }).catch(e => {
                alert(e.message);
            });
    }
    signOut(){
        fire.auth().signOut().then(result => {
            this.cleanLocalStorage();
            this.checkSignedAndWriteState();
            window.location.pathname = '/';
        }).catch(function(error) {
            console.log(error);
        });
    }

    createNewUserFromGoogleAndGotoAccount(result){
        createNewUserAndGoToAccount(
            result.user.displayName,
            result.user.email,
            result.user.emailVerified,
            result.user.phoneNumber,
            false,
            result.user.photoURL,
            result.user.uid,
            '').then( r => {
                this.props.setUser(r.user);
                this.checkVerefiedAccountComponents();
            });
    }
    goToAccount(result){
        localStorage.setItem('displayName' , result.displayName);
        localStorage.setItem('email' , result.email);
        localStorage.setItem('emailVerified' , result.emailVerified);
        localStorage.setItem('phone' , result.phone);
        localStorage.setItem('phoneVerified' , result.phoneVerified);
        localStorage.setItem('photoUrl' , result.photoUrl);
        localStorage.setItem('uid' , result.uid);
        localStorage.setItem('position' , result.position);
        // localStorage.setItem('orders' , result.orders);
        // localStorage.setItem('products' , result.products);
        localStorage.setItem('active' , result.active);
        localStorage.setItem('balance' , result.summBalance);
        localStorage.setItem('dateCreation' , result.dateCreation);


        this.setState({currentUser: result});
        this.props.setUser(result);
        this.checkVerefiedAccountComponents();
        window.location.pathname = '/account';
    }
    changeDateLastSignIn(doc){
        fire.firestore().collection("usders").doc(doc.id).update({
            dateLastSignIn: new Date().getTime(),
        }).then(doc =>{
            // console.log("Document data:", doc);
        })
    }
    cleanLocalStorage(){
        localStorage.setItem('displayName' , null);
        localStorage.setItem('email' , null);
        localStorage.setItem('emailVerified' , null);
        localStorage.setItem('phone' , null);
        localStorage.setItem('phoneVerified' , null);
        localStorage.setItem('photoUrl' , null);
        localStorage.setItem('uid' , null);
        localStorage.setItem('position' , null);
        localStorage.setItem('orders' , null);
        localStorage.setItem('products' , null);
        localStorage.setItem('active' , null);
        localStorage.setItem('balance' , null);
        localStorage.setItem('dateCreation' , null);
    }

    checkPhotoUrl(){
        if (localStorage.getItem('photoUrl') === null){
            return hipster;
        }else if (localStorage.getItem('photoUrl') === 'null'){
            return hipster;
        }else {
            return localStorage.getItem('photoUrl');
        }
    }
    checkSignedAndWriteState(){
        if (localStorage.getItem('email') === null){
            this.setState({signedIn: false});
            this.setState({signedOut: true});
        }else if (localStorage.getItem('email') === 'null'){
            this.setState({signedIn: false});
            this.setState({signedOut: true});
        }else {
            this.setState({signedIn: true});
            this.setState({signedOut: false});
        }
    }
    /*** ================================================================================
     *          Register
     * ================================================================================*/
    clickRegisterBox = e => {
        if (!this.state.isRegisterBoxClicked){
            this.setState({isRegisterBoxClicked: true});
        }else {
            this.setState({isRegisterBoxClicked: false});
        }
    };
    /*** ================================================================================
     *          Activation Account
     * ================================================================================*/
    checkVerefiedAccountComponents(){
        if (localStorage.getItem('position') === "Партнер"){
            if (localStorage.getItem('emailVerified') === "false"){
                this.setState({isVerifiedEmailFragment: true});
                this.setState({isNeedVerification: true});
            }
            if (localStorage.getItem('phoneVerified') === "false"){
                this.setState({isVerifiedPhoneFragment: true});
                this.setState({isNeedVerification: true});
            }
            if (localStorage.getItem('balance') < 100){
                this.setState({isVerifiedBalanceFragment: true});
                this.setState({isNeedVerification: true});
            }
        }
    }
    // Activation Now dont need
    hcrEmailActivation = e => {
        this.setState({rEmailActivation: e.target.value})
    };
    hcrPhoneActivation = e => {
        this.setState({rPhoneActivation: e.target.value})
    };
    /*** ================================================================================
     *          DEFAULT RENDER
     * ================================================================================*/
    render() {
        
        return (
            <div>
                {this.state.isRegisterBoxClicked && (
                    <ModalRegisterUser
                        isRegisterBoxClicked={this.state.isRegisterBoxClicked}
                        clickRegisterBox={this.clickRegisterBox}
                    />
                )}
                {!this.state.signedIn && (
                    <Fragment>
                        <div className="header-btn-lg pr-0">
                            <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                    <div className="widget-content-left">
                                        <UncontrolledButtonDropdown>
                                            <DropdownToggle color="link" className="p-0">
                                                <img width={42} className="rounded-circle" src={hipster} alt=""/>
                                                <FontAwesomeIcon className="ml-2 opacity-8" icon={faAngleDown}/>
                                            </DropdownToggle>
                                            <DropdownMenu right className="rm-pointers dropdown-menu-lg">
                                                <Card.Header>
                                                    <div>Авторизация</div>
                                                </Card.Header>
                                                <Card.Body>
                                                    <Form.Group controlId="formBasicEmail">
                                                        <Form.Label>Email:</Form.Label>
                                                        <Form.Control
                                                            type="email"
                                                            placeholder="Введите email"
                                                            onChange={this.hcEmailSignIn}
                                                        />
                                                    </Form.Group>

                                                    <Form.Group controlId="formBasicPassword">
                                                        <Form.Label>Пароль:</Form.Label>
                                                        <Form.Control
                                                            type="password"
                                                            placeholder="Пароль"
                                                            onChange={this.hcPasswordSignIn}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group controlId="formBasicCheckbox">
                                                        <Form.Check
                                                            type="checkbox"
                                                            label="Запомнить меня"
                                                            onChange={this.hcIsRememberMeSignIn}
                                                        />
                                                    </Form.Group>
                                                </Card.Body>

                                                <Card.Footer>
                                                    <Button
                                                        className="mb-2 mr-2"
                                                        color="primary"
                                                        onClick={() => this.signInWithEmail()}
                                                    >
                                                        Войти
                                                    </Button>
                                                    <Button
                                                        className="mb-2 mr-2 border-0 btn-transition"
                                                        color="primary"
                                                        onClick={() => this.signInGooglePopUp()}
                                                    >
                                                        Вход с Google
                                                    </Button>
                                                    <Button
                                                        className="mb-2 mr-2 border-0 btn-transition"
                                                        color="primary"
                                                        onClick={() => this.clickRegisterBox()}
                                                    >
                                                        Регистрация
                                                    </Button>
                                                </Card.Footer>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </div>
                                    <div className="widget-content-left  ml-3 header-user-info">
                                        <div className="widget-heading">
                                            Вход
                                        </div>
                                        <div className="widget-subheading">
                                            Регистрация
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </Fragment>
                )}
                {this.state.signedIn && (
                    <Fragment>
                        <div className="header-btn-lg pr-0">
                            <div className="widget-content p-0">
                                <div className="widget-content-wrapper">
                                    <div className="widget-content-left">
                                        <UncontrolledButtonDropdown>
                                            <DropdownToggle color="link" className="p-0">
                                                <img width={42} className="rounded-circle" src={this.checkPhotoUrl()} alt=""/>
                                                <FontAwesomeIcon className="ml-2 opacity-8" icon={faAngleDown}/>
                                            </DropdownToggle>
                                            <DropdownMenu right className="rm-pointers dropdown-menu-lg">
                                                <div className="dropdown-menu-header">
                                                    <div className="dropdown-menu-header-inner bg-info">
                                                        <div className="menu-header-image opacity-2"
                                                             style={{
                                                                 backgroundImage: 'url(' + city3 + ')'
                                                             }}
                                                        />
                                                        <div className="menu-header-content text-left">
                                                            <div className="widget-content p-0">
                                                                <div className="widget-content-wrapper">
                                                                    <div className="widget-content-left mr-3">
                                                                        <img width={42} className="rounded-circle"
                                                                             style={{backgroundColor: 'white'}}
                                                                             src={this.checkPhotoUrl()}
                                                                             alt=""/>
                                                                    </div>
                                                                    <div className="widget-content-left">
                                                                        <div className="widget-heading">
                                                                            {localStorage.getItem('displayName')}
                                                                        </div>
                                                                        <div className="widget-subheading opacity-8">
                                                                            {localStorage.getItem('email')}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="scroll-area-xs" style={{height: '170px'}}>
                                                    <Button
                                                        className="mb-2 mr-2 border-0 btn-transition"
                                                        color="primary"
                                                        onClick={() => this.refresh()}
                                                        style={{width: 'calc(100% - 10px)', marginLeft: 5, marginRight: 5}}
                                                    >Обновить</Button>
                                                    <PerfectScrollbar>
                                                        {localStorage.getItem('position') === 'Админ' && (
                                                            <Nav vertical>
                                                                <NavItem className="nav-item-header" style={styleFont}>
                                                                    {'Кол-во пользователей: ' + this.state.users.length}
                                                                </NavItem>
                                                                <NavItem className="nav-item-header" style={styleFont}>
                                                                    {'Активные пользователи: ' + this.state.users.filter(user => user.quantityOrders > 0).length}
                                                                </NavItem>
                                                                <NavItem className="nav-item-header" style={styleFont}>
                                                                    {'Кол-во заказов: ' + (this.state.orders.length)}
                                                                </NavItem>
                                                                <NavItem className="nav-item-header" style={styleFont}>
                                                                    {'Заработано Админом: ' + '₴' + this.state.resultOrders.earnedAdmin}
                                                                </NavItem>
                                                                <NavItem className="nav-item-header" style={styleFont}>
                                                                    {'Заработано Партнерами: ' + '₴' + this.state.resultOrders.earnedPartner}
                                                                </NavItem>
                                                                <NavItem className="nav-item-header" style={styleFont}>
                                                                    {'Заработано Платформой: ' + '₴' + (this.state.resultUsersCalc.summFinishedPrepaymentDropMaster + this.state.resultUsersCalc.summFinishedCODDropMaster)}
                                                                </NavItem>
                                                                <NavItem className="nav-item-header" style={styleFont}>
                                                                    {'Пополнения баланса: ' + '₴' + this.state.resultUsersCalc.summInput}
                                                                </NavItem>
                                                                <NavItem className="nav-item-header" style={styleFont}>
                                                                    {'Выплаты баланса: ' + '₴' + this.state.resultUsersCalc.summPayed}
                                                                </NavItem>
                                                                <NavItem className="nav-item-header" style={styleFont}>
                                                                    {'Сумма всех балансов: ' + '₴' + this.state.resultUsersCalc.summBalance}
                                                                </NavItem>
                                                            </Nav>
                                                        )}
                                                        {localStorage.getItem('position') === 'Партнер' && (
                                                            <Nav vertical>
                                                                <NavItem className="nav-item-header" style={styleFont}>
                                                                    {'Баланс: ' + parseInt(parseInt(this.state.currentUser.summBalance) + parseInt(this.state.referalSummFinished))} грн.
                                                                </NavItem>
                                                                <NavItem className="nav-item-header" style={styleFont}>
                                                                    {'Доход партнер: ' + this.state.currentUser.summBalancePartner} грн.
                                                                </NavItem>
                                                                <NavItem className="nav-item-header" style={styleFont}>
                                                                    {'Доход поставщик: ' + (+ this.state.currentUser.summBalanceProvider)} грн.
                                                                </NavItem>
                                                                <NavItem className="nav-item-header" style={styleFont}>
                                                                    {'Завершено: ' + this.state.currentUser.summFinished} грн.
                                                                </NavItem>
                                                                <NavItem className="nav-item-header" style={styleFont}>
                                                                    {'Холд: ' + this.state.currentUser.summHold} грн.
                                                                </NavItem>
                                                                <NavItem className="nav-item-header" style={styleFont}>
                                                                    {'Возврат: ' + this.state.currentUser.summBack} грн.
                                                                </NavItem>
                                                                <NavItem className="nav-item-header" style={styleFont}>
                                                                    {'Выплачено: ' + this.state.currentUser.summPayed} грн.
                                                                </NavItem>
                                                                <NavItem className="nav-item-header" style={styleFont}>
                                                                    {'Пополнено: ' + this.state.currentUser.summInput} грн.
                                                                </NavItem>
                                                            </Nav>
                                                        )}
                                                    </PerfectScrollbar>
                                                </div>
                                                <Nav vertical>
                                                    <NavItem className="nav-item-divider mb-0"/>
                                                </Nav>
                                                <div className="grid-menu grid-menu-2col">
                                                    <Row className="no-gutters">
                                                        <Col sm="6">
                                                            <Button
                                                                className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"
                                                                outline color="primary"
                                                                onClick={() => this.signOut()}
                                                            >
                                                                <i className="pe-7s-back-2 icon-gradient bg-amy-crisp btn-icon-wrapper mb-2"> </i>
                                                                <b>Выйти</b>
                                                            </Button>
                                                        </Col>
                                                        <Col sm="6">
                                                            <Button
                                                                className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"
                                                                outline color="primary"
                                                                onClick={() => (window.location.pathname = '/account')}
                                                            >
                                                                <i className="pe-7s-home icon-gradient bg-amy-crisp btn-icon-wrapper mb-2"> </i>
                                                                <b>Личный кабинет</b>
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                    </div>
                                    <div className="widget-content-left  ml-3 header-user-info">
                                        <div className="widget-heading">
                                            {localStorage.getItem('displayName')}
                                        </div>
                                        <div className="widget-subheading">
                                            {localStorage.getItem('position')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                )}
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        basketArray: state.basket,
        user: state.basket.user,
        adminSettings: state.basket.adminSettings
    }
};

const mapDispatchToProps = {
    setAdmin,
    clearAdmin,
    setUser,
    clearUser,
    addProduct,
    removeProduct,
    removeAll,
    changeProduct
};
export default connect(mapStateToProps, mapDispatchToProps)(UserBox);
