import React from 'react';
import '../products/Products.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Tabs, Tab, Button, InputGroup, Row, Col, Modal, Form} from 'react-bootstrap';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {
    filterOrdersBack,
    filterOrdersFinished,
    filterOrdersHold,
    fire,
    getDataFromCollection,
    getPartnerSumms,
    updateDocumentInCollection,
    getDataFromCollectionWhereKeyValue,
    getDateFromMilliseconds,
    summInArray,
    setDocumentToCollection,
    sendMessageTelegramBotWithSettings,
    calcSummsAndQuantity, getReferal
} from '../firebase/Firebase';
import ReactTable from "react-table";
import {Input, InputGroupAddon, Card, CardBody} from "reactstrap";
import Select from "react-select";
import CountUp from "react-countup/build";
import TableOrders from "../orders/TableOrders";
import BalanceTable from "../balance/BalanceTable";
import TableUsers from "./tables/TableUsers";
import TableUsersPartners from "./tables/TableUsersPartners";
import SearchTableUsers from "./otherComponents/SearchTableUsers";
import * as moment from "moment";


const styleCell = {overflow: 'auto', height: '100%', textAlign: 'top'};
const styleCellIn = {overflow: 'auto', height: '100%', textAlign: 'top'};




class App extends React.Component {
    /*** ================================================================================
     *          Main
     * ================================================================================*/
    constructor (props, context) {
        super(props, context);
        this.state = {
            // DB Array
            users: [],
            usersRender: [],
            usersOrders: [],
            usersRenderOrders: [],
            usersPartner: [],
            usersRenderPartner: [],
            usersReferal: [],
            usersRenderReferal: [],
            //
            partnersData: [],
            // Modal Windows
            isOrdersShow: false,
            isBalanceShow: false,
            isChangeUser: false,
            // Arrays for modal windows
            ordersShow: [],
            balanceShow: [],
            // Hanndle
            currentChangeUser: {},
            statusUser: '',
            minusBalance: 0,
            plusBalance: 0,
        };
        this.closeUserEditing = this.closeUserEditing.bind(this);
    }
    componentDidMount() {
        this.getUsers();
        getReferal().then( ref => {
            this.setState({
                usersReferal: ref,
                usersRenderReferal: ref,
            })
        })
    }
    /*** ================================================================================
     *          Data Base
     * ================================================================================*/
    getUsers () {
        getDataFromCollection("usders").then(u => {
            const users = u.data.filter(function (el) {
                return el.position !== 'Админ';
            });
            users.sort(function(a,b){
                return new Date(b.dateLastSignIn) - new Date(a.dateLastSignIn);
            });
            const usersOrders = users.filter(el => el.quantityProducts === 0);
            const usersPartner = users.filter(el => el.quantityProducts > 0);
            const usersPartnerData = [];
            usersPartner.forEach(user => {
                getPartnerSumms(user.uid).then(r => {
                    usersPartnerData.push(r.data);
                });
            });
            this.setState({
                users: users,
                usersRender: users,
                usersOrders: usersOrders,
                usersRenderOrders: usersOrders,
                usersPartner: usersPartner,
                usersRenderPartner: usersPartner,
                partnersData: usersPartnerData,
            });
        });
    };

    getOrders (uid) {
        try {
            fire.firestore().collection("orders").where("fromUID", "==", uid)
                .get()
                .then(querySnapshot => {
                    const data = [];
                    querySnapshot.docs.forEach(d => {
                        data.push({
                            id: d.id,
                            ...d.data()
                        })
                    });
                    this.setState({ ordersShow: data });
                });
        }catch (e) {
            console.log(e);
        }
    };
    getBalance(uid){
        try {
            fire.firestore().collection("balance").where("fromUID", "==", uid)
                .get()
                .then(querySnapshot => {
                    const data = [];
                    querySnapshot.docs.forEach(d => {
                        data.push({
                            id: d.id,
                            ...d.data()
                        })
                    });
                    this.setState({ balanceShow: data });
                });
        }catch (e) {
            console.log(e);
        }
    };
    updateUser(){
        console.log(this.state.minusBalance);
        console.log(this.state.plusBalance);
        var typeOperation = '';
        var summ = '';
        const uid = this.state.currentChangeUser.uid;
        if (this.state.minusBalance > 0){
            typeOperation = 'Отьем от баланса';
            summ = this.state.minusBalance;
        }else if (this.state.plusBalance > 0){
            typeOperation = 'Прибавка к балансу';
            summ = this.state.plusBalance;
        }
        updateDocumentInCollection('usders', this.state.currentChangeUser.id, {
            active: this.state.statusUser,
            comment: this.state.currentChangeUser.comments,
            // summBalance: this.state.currentChangeUser.summBalance
        }).then( users => {
            if (this.state.minusBalance > 0 || this.state.plusBalance > 0){
                setDocumentToCollection('balance', {
                    typeOperation: typeOperation,
                    date: new Date().getTime(),
                    summ: summ,
                    requisites: '',
                    url: '',
                    status: 'Завершен',
                    fromUID: uid,
                    fromEmail: this.state.currentChangeUser.email,
                    fromName: this.state.currentChangeUser.displayName,
                }).then( r => {
                    calcSummsAndQuantity(uid);
                    calcSummsAndQuantity(localStorage.getItem('uid'));
                    sendMessageTelegramBotWithSettings("NEW_UPDATE_BALANCE_REQUEST", {
                        summ: this.state.summGetMoney,
                        user: this.state.currentChangeUser,
                        admin: this.props.adminSettings,
                    });
                });
            }
            this.getUsers(true);
            this.closeUserEditing();
        });

    };
    /*** ================================================================================
     *          Modal Windows
     * ================================================================================*/
    clickOrdersShow = (uid) => {
        if (!this.state.isOrdersShow){
            this.getOrders(uid);
            this.setState({isOrdersShow: true});
        }else {
            this.setState({isOrdersShow: false});
        }
    };
    clickBalanceShow = (uid) => {
        if (!this.state.isBalanceShow){
            this.getBalance(uid);
            this.setState({isBalanceShow: true});
        }else {
            this.setState({isBalanceShow: false});
        }
    };
    clickChangeUser = (user) => {
        // this.getUserSettingsById(user.uid);
        this.setState({currentChangeUser: user});
        this.setState({statusUser: user.active});
        this.setState({isChangeUser: true});
    };
    closeUserEditing() {
        this.setState({isChangeUser: false});
    }
    /*** ================================================================================
     *          Handle
     * ================================================================================*/
    updateStatus = e => {
        if (e.value === 'Включен'){
            this.setState({statusUser: true});
        }else {
            this.setState({statusUser: false});
        }
    };
    updateMinusBalance = e => {
        this.setState({minusBalance: e.target.value});
    };
    updatePlusBalance = e => {
        this.setState({plusBalance: e.target.value});
    };
    /*** ================================================================================
     *          Else Methods
     * ================================================================================*/
    isVerifiedElement(bool, text){
        if (text === undefined){
            text = "Не указан"
        }
        if (bool){
            return (
                <div>
                    <i className="header-icon pe-7s-check"> </i>
                    {text}
                </div>
            )
        }else {
            return (
                <div>
                    <i className="header-icon attention"> </i>
                    {text}
                </div>
            )
        }
    }
    isActiveAccount(bool){
        if (bool){
            return (
                <div className="pe-7s-check" style={styleCellIn}>
                    Активен
                </div>
            )
        }else {
            return (
                <div className="pe-7s-attention" style={styleCellIn}>
                    Не активен
                </div>
            )
        }
    }
    isActiveUser(bool){
        if (bool){
            return 'Включен'
        }else {
            return 'Выключен'
        }
    }
    /*** ================================================================================
     *          Search
     * ================================================================================*/
    handleSearch = e => {
        try {
            // console.log(e.target.value);
            // console.log(this.state.users);
            // console.log(this.state.usersOrders);
            // console.log(this.state.usersPartner);
            const newArray = this.state.users.filter(function (el) {
                return String(el.email).includes(e.target.value) ||
                    String(el.displayName).includes(e.target.value) ||
                    String(el.phone).includes(e.target.value) ||
                    String(el.position).includes(e.target.value);
            });
            const newArrayOrders = this.state.usersOrders.filter(function (el) {
                return String(el.email).includes(e.target.value) ||
                    String(el.displayName).includes(e.target.value) ||
                    String(el.phone).includes(e.target.value) ||
                    String(el.position).includes(e.target.value);
            });
            const newArrayPartner = this.state.usersPartner.filter(function (el) {
                return String(el.email).includes(e.target.value) ||
                    String(el.displayName).includes(e.target.value) ||
                    String(el.phone).includes(e.target.value) ||
                    String(el.position).includes(e.target.value);
            });
            const newArrayReferal = this.state.usersReferal.filter(function (el) {
                return String(el.email).includes(e.target.value) ||
                    String(el.displayName).includes(e.target.value) ||
                    String(el.phone).includes(e.target.value) ||
                    String(el.position).includes(e.target.value);
            });
            this.setState({
                usersRender: newArray,
                usersRenderOrders: newArrayOrders,
                usersRenderPartner: newArrayPartner,
                usersRenderReferal: newArrayReferal,
            });
        }catch (e) {
            console.log(e);
        }
    };
    /*** ================================================================================
     *          Default Render
     * ================================================================================*/
    render() {
        return (
            <div>
                {/*Show Orders*/}
                {this.state.isOrdersShow && (
                    <Modal
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        // centered
                        show={this.state.isOrdersShow}
                        onHide={() => this.clickOrdersShow()}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                {'Все заказы'}
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <TableOrders
                                isBtnChange={false}
                                adminNoChange={true}
                                // users={this.state.users}
                                orders={this.state.ordersShow}
                            />
                        </Modal.Body>
                    </Modal>
                )}
                {/*Show Balance*/}
                {this.state.isBalanceShow && (
                    <Modal
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        // centered
                        show={this.state.isBalanceShow}
                        onHide={() => this.clickBalanceShow()}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                {'Все выплаты'}
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <BalanceTable balanceOperation={this.state.balanceShow}/>
                        </Modal.Body>
                    </Modal>
                )}
                {/*Change Balance Operation*/}
                {this.state.isChangeUser && (
                    <Modal
                        size="xs"
                        aria-labelledby="contained-modal-title-vcenter"
                        // centered
                        show={this.state.isChangeUser}
                        onHide={() => this.closeUserEditing()}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                {this.state.currentChangeUser.displayName}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Row>
                                <Col md={12}>
                                    <Form.Label>{'Email: ' + this.state.currentChangeUser.email}</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Form.Label>{'Позиция: ' + this.state.currentChangeUser.position}</Form.Label>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <Form.Label>{'Номер телефона: ' + this.state.currentChangeUser.phone}</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Form.Label>{'Карта для выплат: ' + this.state.currentChangeUser.paymentCard }</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Form.Label>{'ФИО держателя карты: ' + this.state.currentChangeUser.paymentName }</Form.Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Form.Label>{'Комментарий: '}</Form.Label>
                                    <Form.Control type={'text'}
                                                  value={this.state.currentChangeUser.comments}
                                                  onChange={e => {
                                                      const val = e.target.value;
                                                      this.setState(oldState => {
                                                          oldState.currentChangeUser.comments = val;
                                                          return oldState;
                                                      })
                                                  }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <Form.Label>{'Отнять от баланса: '}</Form.Label>
                                    <Form.Control type={'number'}
                                                  value={this.state.minusBalance}
                                                  onChange={this.updateMinusBalance}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Form.Label>{'Прибавить к балансу: '}</Form.Label>
                                    <Form.Control type={'number'}
                                                  value={this.state.plusBalance}
                                                  onChange={this.updatePlusBalance}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Form.Group controlId="formGroupStatus">
                                        <Form.Label>Статус</Form.Label>
                                        <Select
                                            value={ {value: this.isActiveUser(this.state.statusUser), label: this.isActiveUser(this.state.statusUser)} }
                                            onChange={this.updateStatus}
                                            options={[
                                                { value: 'Включен', label: 'Включен' },
                                                { value: 'Выключен', label: 'Выключен' },
                                            ]}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button color="primary" onClick={() => this.updateUser()}>Сохранить</Button>
                            <Button color="primary" onClick={() => this.closeUserEditing()}>Закрыть</Button>
                        </Modal.Footer>
                    </Modal>
                )}
                {/*Content*/}
                <div>
                    <Tabs variant="pills"  defaultActiveKey="Все" transition={false} id="noanim-tab-example">
                        <Tab eventKey="Все" title='Все'>
                            <Card>
                                <CardBody>
                                    <SearchTableUsers
                                        handleSearch={this.handleSearch}
                                    />
                                    <TableUsers
                                        users={this.state.usersRender}
                                        clickOrdersShow={this.clickOrdersShow}
                                        clickBalanceShow={this.clickBalanceShow}
                                        clickChangeUser={this.clickChangeUser}
                                    />
                                </CardBody>
                            </Card>
                        </Tab>
                        <Tab eventKey="Пользователи" title='Пользователи'>
                            <Card>
                                <CardBody>
                                    <SearchTableUsers
                                        handleSearch={this.handleSearch}
                                    />
                                    <TableUsers
                                        users={this.state.usersRenderOrders}
                                        clickOrdersShow={this.clickOrdersShow}
                                        clickBalanceShow={this.clickBalanceShow}
                                        clickChangeUser={this.clickChangeUser}
                                    />
                                </CardBody>
                            </Card>
                        </Tab>
                        <Tab eventKey="Поставщики" title='Поставщики'>
                            <Card>
                                <CardBody>
                                    <SearchTableUsers
                                        handleSearch={this.handleSearch}
                                    />
                                    <TableUsers
                                        users={this.state.usersRenderPartner}
                                        // partnerData={this.state.partnersData}
                                        clickOrdersShow={this.clickOrdersShow}
                                        clickBalanceShow={this.clickBalanceShow}
                                        clickChangeUser={this.clickChangeUser}
                                    />
                                </CardBody>
                            </Card>
                        </Tab>
                        <Tab eventKey="Рефералы" title='Рефералы'>
                            <Card>
                                <CardBody>
                                    <SearchTableUsers
                                        handleSearch={this.handleSearch}
                                    />
                                    <TableUsers
                                        users={this.state.usersRenderReferal}
                                        isReferal={true}
                                        // partnerData={this.state.partnersData}
                                        clickOrdersShow={this.clickOrdersShow}
                                        clickBalanceShow={this.clickBalanceShow}
                                        clickChangeUser={this.clickChangeUser}
                                    />
                                </CardBody>
                            </Card>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        )}
}

export default App;
