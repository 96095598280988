import React from "react";
import CountUp from "react-countup/build";
import {Button} from "react-bootstrap";
import ReactTable from "react-table";
import {getDateFromMilliseconds, getPartnerSumms} from "../../firebase/Firebase";

const styleCell = {overflow: 'auto', height: '100%', textAlign: 'top'};

export default class App extends React.Component {
    /*** ================================================================================
     *          Main
     * ================================================================================*/
    constructor (props, context) {
        super(props, context);
        this.state = {
            data: {
                uid: this.props.uid,
                summHold: 0,
                summFinished: 0,
                summBack: 0,
                //summClient
                summClientHold: 0,
                summClientFinished: 0,
                //summDrop
                summDropHold: 0,
                summDropFinished: 0,
                //summPurchase
                summPurchaseHold: 0,
                summPurchaseFinished: 0,
            }
        };
    }
    componentDidMount() {
        getPartnerSumms(this.props.uid).then(r => {
            this.setState({data: r.data})
        });
    }
    /*** ================================================================================
     *          Default Render
     * ================================================================================*/
    render() {
        return(
            <div>

                <div className=""  style={styleCell}>
                    <b>Сумма в холде:</b> {this.state.data.summHold}
                </div>
                <div className=""  style={styleCell}>
                    <b>Сумма дохода:</b> {this.state.data.summFinished}
                </div>
                <div className=""  style={styleCell}>
                    <b>Сумма возвратов:</b> {this.state.data.summBack}
                </div>
                <br/>

                <div className=""  style={styleCell}>
                    <b>Холд пользователей:</b> {this.state.data.summClientHold}
                </div>
                <div className=""  style={styleCell}>
                    <b>Доход пользователей:</b> {this.state.data.summClientFinished}
                </div>
                <div className=""  style={styleCell}>
                    <b>Возврат пользователей:</b> {this.state.data.summBack}
                </div>
                <br/>

                <div className=""  style={styleCell}>
                    <b>Холд Админа:</b> {this.state.data.summDropHold - this.state.data.summPurchaseHold}
                </div>
                <div className=""  style={styleCell}>
                    <b>Доход Админа:</b> {this.state.data.summDropFinished - this.state.data.summPurchaseFinished}
                </div>
                <div className=""  style={styleCell}>
                    <b>Возврат Админа:</b> {this.state.data.summBack}
                </div>
                <br/>


                <div className=""  style={styleCell}>
                    <b>Холд поставщика:</b> {this.state.data.summPurchaseHold}
                </div>
                <div className=""  style={styleCell}>
                    <b>Доход поставщика:</b> {this.state.data.summPurchaseFinished}
                </div>
                <div className=""  style={styleCell}>
                    <b>Возврат поставщика:</b> {this.state.data.summBack}
                </div>

            </div>
        )
    }
}