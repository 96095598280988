import React from "react";
import {Form, FormControl, Modal} from "react-bootstrap";
import {Button} from "reactstrap";
import {addProduct, changeProduct, removeAll, removeProduct} from "../storeReducers/basket/actions";
import {connect} from "react-redux";
import {sendMessageTelegramBotWithSettings, setDocumentToCollection} from "../firebase/Firebase";


class App extends React.Component{
    /*** ================================================================================
     *          Main
     * ================================================================================*/
    constructor (props, context) {
        super(props, context);
        this.state = {
            header: '',
            text: '',
        };
    }
    componentDidMount() {

    }
    /*** ================================================================================
     *          Basket
     * ================================================================================*/
    updateHeader = e => {
        this.setState({header: e.target.value});
    };
    updateText = e => {
        this.setState({text: e.target.value});
    };
    saveNews(){
        setDocumentToCollection('news', {
            date: new Date().getTime(),
            headerNews: this.state.header,
            textNews: this.state.text,
        }).then( r => {
            sendMessageTelegramBotWithSettings("NEW_NEWS", {
                headerNews: this.state.header,
                textNews: this.state.text,
            });
            this.props.refresh();
            this.props.addNewsClick();
        })
    }
    /*** ================================================================================
     *          Default Render
     * ================================================================================*/
    render() {
        return (
            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                show={this.props.isAddNewsClick}
                onHide={() => this.props.addNewsClick()}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Добавьте новость
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formGroupEmail">
                            <Form.Label>Заголовок:</Form.Label>
                            <FormControl
                                type="text"
                                placeholder="Введите заголовок"
                                onChange={this.updateHeader}
                            />
                        </Form.Group>
                        <Form.Group controlId="formGroupPassword">
                            <Form.Label>Текст:</Form.Label>
                            <Form.Control
                                type="text"
                                as="textarea" rows="10"
                                placeholder="Введите текст"
                                onChange={this.updateText}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="mb-2 mr-2" color="primary"
                        onClick={() => this.saveNews()}>Сохранить</Button>
                    <Button
                        outline className="mb-2 mr-2 border-0 btn-transition" color="primary"
                        onClick={() => this.props.addNewsClick()}>Закрыть</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        basketArray: state.basket
    }
};

const mapDispatchToProps = {
    addProduct,
    removeProduct,
    removeAll,
    changeProduct
};
export default connect(mapStateToProps, mapDispatchToProps)(App);