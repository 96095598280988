import React, {Fragment} from 'react';
import './BasketOrders.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Button, Card, Badge} from 'react-bootstrap';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import ReactTable from "react-table";
import ModalToBasket from "../products/modals/ModalToBasket";
import ModalSendOrder from "./ModalSendOrder";
import {faAngleDown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


class App extends React.Component {
    /*** ================================================================================
     *          Main
     * ================================================================================*/
    constructor (props, context) {
        super(props, context);
        this.state = {
            // Basket Styles
            basketWidth: 'calc(15vh + 15vw)',
            basketPosition: {left: '100%'},
            // Change Product in Basket
            isChangeOrderInBasket: false,
            productOldCO: {},
            // Send Order
            isSendOrder: false,
        };
        this.clickChangeOrderInBasket = this.clickChangeOrderInBasket.bind(this);
        this.clickSendOrder = this.clickSendOrder.bind(this);
    }
    /*** ================================================================================
     *          Basket Methods
     * ================================================================================*/
    clickChangeOrderInBasket(p){
        if (!this.state.isChangeOrderInBasket){
            this.setState({productOldCO: p, priceCO: p.price, priceMinCO: p.priceMin, quantityCO: p.quantity});
            this.setState({isChangeOrderInBasket: true});
        }else {
            this.setState({isChangeOrderInBasket: false});
        }
    };
    basketRemoveProduct(p){
        this.props.removeProduct(p);
    }
    summInBasketFromRedux = e => {
        var result = 0;
        var array = this.props.basket.basketArray;

        array.forEach(a => {
            result += (a.quantity * a.price);
        });
        return result;
    };
    calcSummBaskey(products){
        var res = 0;
        for (let i = 0; i < products.length ; i++) {
            res = res + (products[i].price * products[i].quantity);
        }
        return res;
    }
    calcSummDropBasket(products){
        var res = 0;
        for (let i = 0; i < products.length ; i++) {
            res = res + (products[i].priceMin * products[i].quantity);
        }
        return res;
    }
    totalSummOrder(){
        var array = this.props.basket.basketArray;
        var result = 0;
        array.forEach(a => {
            result = result + parseInt(a.quantity) * parseInt(a.price);
        });
        return result;
    }
    /*** ================================================================================
     *          Send Order
     * ================================================================================*/
    clickSendOrder(){
        if (!this.state.isSendOrder){
            this.setState({isSendOrder: true});
        }else {
            this.setState({isSendOrder: false});
        }
    };
    /*** ================================================================================
     *          Default Render
     * ================================================================================*/
    render() {
        return (
            <div>
                {/*Modal Windows*/}
                {/*Send order to DB*/}
                {this.state.isSendOrder && (
                    <ModalSendOrder
                        isSendOrder={this.state.isSendOrder}
                        clickSendOrder={this.clickSendOrder}
                    />
                )}
                {/*Cnange Order*/}
                {this.state.isChangeOrderInBasket && (
                    <ModalToBasket
                        isToBasketClick={this.state.isChangeOrderInBasket}
                        toBasketClick={this.clickChangeOrderInBasket}
                        isTobasketCurrentProduct={this.state.isTobasketCurrentProduct}
                        isChange={true}
                        oldProduct={this.state.productOldCO}
                    />
                )}
                {/*Basket Container*/}
                <Fragment>
                    <div className="header-dots">
                        <UncontrolledDropdown>
                            <DropdownToggle className="p-0 mr-2" color="link">
                                <div className="icon-wrapper icon-wrapper-alt rounded-circle">
                                    <div className="icon-wrapper-bg bg-success "/>
                                    <i className="pe-7s-cart "> </i>
                                    <Badge style={{
                                        position: 'absolute',
                                        top: '10%',
                                        left: '90%',
                                        marginRight: '-50%',
                                        transform: 'translate(-50%, -50%)'
                                    }} variant="danger">{this.props.basket.basketArray.length}</Badge>
                                    {/*<FontAwesomeIcon className="ml-2 opacity-8" icon={faAngleDown}/>*/}
                                </div>
                            </DropdownToggle>
                            <DropdownMenu right className="dropdown-menu-xl rm-pointers" style={{padding: '0'}}>
                                <div className="Basket-Container" style={this.state.basketPosition}>
                                    {(!this.props.basket.basketArray.length > 0) && (
                                        <Card>
                                            <Card.Header style={{textAlign: 'center'}}>Корзина пуста</Card.Header>
                                        </Card>
                                    )}
                                    {(this.props.basket.basketArray.length > 0) && (
                                        <Card >
                                            <Card.Header>Общая сумма заказа: {this.totalSummOrder()}</Card.Header>
                                            <Card.Body style={{overflow: 'auto', height: 200}}>
                                                <ReactTable
                                                    previousText={'Предыдущая страница'}
                                                    nextText={'Следующая страница'}
                                                    data={this.props.basket.basketArray}
                                                    showPagination={false}
                                                    columns={[
                                                        {
                                                            columns: [
                                                                {
                                                                    Header: "Фото",
                                                                    id: "photo",
                                                                    maxWidth: 140,
                                                                    minWidth: 140,
                                                                    accessor: d => (
                                                                        <div style={{width: '100%', height: '100%'}}>
                                                                            <img
                                                                                src={d.image}
                                                                                alt="slide"
                                                                                style={{textAlign: 'center', height: '120px', borderRadius: '5%'}}
                                                                            />
                                                                        </div>
                                                                    )
                                                                },
                                                                {
                                                                    Header: "Информация",
                                                                    id: "info",
                                                                    minWidth: 300,
                                                                    accessor: d => (
                                                                        <div style={{overflow: 'auto', height: '100%', textAlign: 'top'}}>
                                                                            <div>
                                                                                {'Название: ' + d.name}
                                                                            </div>
                                                                            <div>
                                                                                {'Цена продажи: ' + d.price}
                                                                            </div>
                                                                            <div>
                                                                                {'Кол-во: ' + d.quantity}
                                                                            </div>
                                                                            <div>
                                                                                {'Сумма: ' + parseInt(d.quantity) * parseInt(d.price)}
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                },
                                                                {
                                                                    Header: "Действия",
                                                                    id: "doing",
                                                                    minWidth: 150,
                                                                    accessor: d => (
                                                                        <div style={{overflow: 'auto', height: '100%', textAlign: 'top'}}>
                                                                            <div>
                                                                                <Button style={{textAlign: 'center', width: 130}} className="mb-2 mr-2" color="primary" onClick={() => this.clickChangeOrderInBasket(d)}>
                                                                                    Изменить
                                                                                </Button>
                                                                            </div>
                                                                            <div>
                                                                                <Button style={{textAlign: 'center', width: 130}} className="mb-2 mr-2" color="primary" onClick={() => this.basketRemoveProduct(d)}>
                                                                                    Удалить
                                                                                </Button>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                },
                                                            ]}
                                                    ]}
                                                    defaultPageSize={this.props.basket.basketArray.length}
                                                    className="-striped -highlight"
                                                />
                                            </Card.Body>

                                            <Card.Footer className="text-muted">
                                                <Button
                                                    className="mb-2 mr-2 border-0 btn-transition" color="primary"
                                                    onClick={() => this.clickSendOrder()}>
                                                    Отправить заказ
                                                </Button>
                                                <Button
                                                    className="mb-2 mr-2" color="primary"
                                                    onClick={this.props.removeAll}>Очистить</Button>
                                            </Card.Footer>
                                        </Card>
                                    )}
                                </div>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </Fragment>
            </div>
        )}
}


export default App;
