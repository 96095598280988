import React from "react";
import {Col, Modal,  Row, Card, } from "react-bootstrap";
import {Button, CardFooter, ListGroup, ListGroupItem,} from "reactstrap";
import Slider from "react-slick/lib";
import PerfectScrollbar from "react-perfect-scrollbar";
import CountUp from "react-countup/build";
import {checkInStock} from "./ModalChangeCreateProduct";

export default class App extends React.Component{
    /*** ================================================================================
     *          Main
     * ================================================================================*/
    constructor (props, context) {
        super(props, context);
        this.state = {
            currentDetailProduct: {vendoreCodeP: ''},
        };
    }
    componentDidMount() {
        // console.log(this.props.currentDetailProduct)
    }
    /*** ================================================================================
     *          Default Render
     * ================================================================================*/
    render() {
        const settings = {
            className: "",
            centerMode: false,
            infinite: true,
            slidesToShow: 1,
            speed: 500,
            dots: true,
        };
        return (
            <Modal
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                show={this.props.isDetailsProduct}
                onHide={() => this.props.clickDetailProduct()}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {'Артикул: ' + this.props.currentDetailProduct.vendoreCodeP}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Row>
                        <Col md={6}>
                            <Slider {...settings}>
                                {this.props.currentDetailProduct.imagesUrlP.map( (i, index) =>
                                    <img
                                        key={index}
                                        className="d-block w-100"
                                        src={i}
                                        alt="First slide"
                                    />
                                )}
                            </Slider>
                        </Col>
                        <Col md={6} >
                            <Card className="card-shadow-primary card-border mb-3">
                                <div className="dropdown-menu-header">
                                    <div className="dropdown-menu-header-inner bg-primary">
                                        <div className="menu-header-content">
                                            <div>
                                                <h5 className="menu-header-title">{this.props.currentDetailProduct.nameP}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{overflow: 'auto', height: 350}}>
                                    <PerfectScrollbar>
                                        <ListGroup flush>
                                            <ListGroupItem>
                                                <div className="widget-content p-0">
                                                    <div className="widget-content-wrapper">
                                                        <div className="widget-content-left">
                                                            <div className="widget-heading">
                                                                Категория:
                                                            </div>
                                                            <div className="widget-subheading">
                                                                {this.props.currentDetailProduct.categoryTextP}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ListGroupItem>
                                            <ListGroupItem>
                                                <div className="widget-content p-0">
                                                    <div className="widget-content-wrapper">
                                                        <div className="widget-content-left">
                                                            <div className="widget-heading">
                                                                Бренд:
                                                            </div>
                                                            <div className="widget-subheading">
                                                                {this.props.currentDetailProduct.brandP}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ListGroupItem>
                                            <ListGroupItem>
                                                <div className="widget-content p-0">
                                                    <div className="widget-content-wrapper">
                                                        <div className="widget-content-left">
                                                            <div className="widget-heading">
                                                                Ваша цена:
                                                            </div>
                                                            <div className="widget-subheading">
                                                                <CountUp start={-25.9}
                                                                         end={this.props.currentDetailProduct.priceDropP}
                                                                         separator=""
                                                                         decimals={0}
                                                                         decimal=","
                                                                         prefix=""
                                                                         duration="2"/> грн.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ListGroupItem>
                                            <ListGroupItem>
                                                <div className="widget-content p-0">
                                                    <div className="widget-content-wrapper">
                                                        <div className="widget-content-left">
                                                            <div className="widget-heading">
                                                                Наличие:
                                                            </div>
                                                            <div className="widget-subheading">
                                                                {checkInStock(this.props.currentDetailProduct.inStockP)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ListGroupItem>
                                            <ListGroupItem>
                                                {this.props.currentDetailProduct.attributesP.map( (a, index) =>
                                                    <div className="widget-content p-0" key={index}>
                                                        <div className="widget-content-wrapper">
                                                            <div className="widget-content-left">
                                                                <div className="widget-heading">
                                                                    {a.name}
                                                                </div>
                                                                <div className="widget-subheading">
                                                                    {a.text}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </ListGroupItem>
                                            <ListGroupItem>
                                                <div className="widget-content p-0">
                                                    <div className="widget-content-wrapper">
                                                        <div className="widget-content-left">
                                                            <div className="widget-heading">
                                                                Описание:
                                                            </div>
                                                            <div className="widget-subheading"
                                                                 dangerouslySetInnerHTML={{__html: this.props.currentDetailProduct.descriptionP}}
                                                            >
                                                                {/*{this.state.currentDetailProduct.descriptionP.replace(/(?!(\<br\>|<br>|\<br\s\/\>))<\/?[^>]+>/g, '').replace(/&nbsp;/gi,'')}*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ListGroupItem>
                                        </ListGroup>
                                    </PerfectScrollbar>
                                </div>
                                <CardFooter className="text-center d-block">
                                    <Row className="no-gutters">
                                        <Col sm="6">
                                            <Button
                                                className="btn-icon-vertical btn-square btn-transition"
                                                outline color="link"
                                                onClick={() => this.props.toBasketClick(this.props.currentDetailProduct)}
                                            >
                                                <i className="lnr-cart btn-icon-wrapper btn-icon-lg mb-3"> </i>
                                                Добавить в корзину
                                            </Button>
                                        </Col>
                                        <Col sm="6">
                                            <Button
                                                className="btn-icon-vertical btn-square btn-transition"
                                                outline color="link"
                                                onClick={() => this.props.clickDetailProduct()}
                                            >
                                                <i className="lnr-cross-circle btn-icon-wrapper btn-icon-lg mb-3"> </i>
                                                Закрыть
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        )
    }
}