import React from "react";
import CountUp from "react-countup/build";
import {Button, Col, Form, FormControl, InputGroup, Modal, Row} from "react-bootstrap";
import ReactTable from "react-table";
import {Card, CardBody, CardHeader, Input} from "reactstrap";
import {
    fire,
    setDocumentToCollection,
    deleteDocumentFromCollectionWithID,
    addCategoriesToXML, deleteCategoryFromXML,
    getDataFromCollection, getDataFromCollectionWhereKeyValue
} from "../firebase/Firebase";
//onClick={() => this.props.deleteNews(d)}
export default class App extends React.Component {
    /*** ================================================================================
     *          Main
     * ================================================================================*/
    constructor (props, context) {
        super(props, context);
        this.state = {
            // Categories
            categories: [],
            categoriesRender: [],
            //
            isAddCategoriesClick: false,
            id: '',
            parentId: '',
            text: '',
            //
            searchText: '',
        };
    }
    componentDidMount() {
        this.getCategories();
    }
    /*** ================================================================================
     *          Data Base
     * ================================================================================*/
    getCategories(){
        try {
            fire.firestore().collection("categories")
                .get()
                .then(querySnapshot => {
                    const data = [];
                    querySnapshot.docs.forEach(d => {
                        data.push({
                            idDB: d.id,
                            ...d.data()
                        })
                    });
                    data.sort(function(a,b){
                        return new Date(b.id) - new Date(a.id);
                    });
                    this.setState({ categories: data, categoriesRender: data, searchText: '' });
                    this.forceUpdate();
                });
        }catch (e) {
            console.log(e);
        }
    };
    saveCategory(){
        setDocumentToCollection('categories', {
            id: this.state.id,
            parentId: this.state.parentId,
            text: this.state.text,
        }).then( r => {
            addCategoriesToXML(r.result.id).then(r => {
                this.getCategories();
                this.clickAddCategory();
            }).catch( e => {
                console.log(e);
            })
        }).catch( e => {
            console.log(e)
        })
    };
    deleteCategory(cat){
        deleteDocumentFromCollectionWithID('categories', cat.idDB ).then( r => {
            deleteCategoryFromXML().then( d => {
                this.getCategories();
                this.forceUpdate();
            });
        });
        // getDataFromCollection('products').then( p => {
        //     const products = p.data;
        //     console.log(products)
        // })

    }
    /*** ================================================================================
     *          Handle Categories
     * ================================================================================*/
    clickAddCategory = () => {
        if (this.state.isAddCategoriesClick) {
            this.setState({isAddCategoriesClick: false});
        }  else {
            this.setState({isAddCategoriesClick: true});
        }
    };
    handleSearchCat = e => {
        const filterFunctionCreator = (query) => {
            return (el) => String(el.text).toUpperCase().includes(query.toUpperCase()) ||
                String(el.id).toUpperCase().includes(query.toUpperCase()) ||
                String(el.parentId).toUpperCase().includes(query.toUpperCase());
        };
        try {
            const newArray = this.state.categories.filter(filterFunctionCreator(e.target.value));
            this.setState({categoriesRender: newArray, searchText: e.target.value});
        }catch (e) {
            console.log(e);
        }
    };
    updateId = e => {
        this.setState({id: e.target.value});
    };
    updateParentId = e => {
        this.setState({parentId: e.target.value});
    };
    updateText = e => {
        this.setState({text: e.target.value});
    };
    /*** ================================================================================
     *          Default Render
     * ================================================================================*/
    render() {
        return(

            <div>
                <Modal
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    show={this.state.isAddCategoriesClick}
                    onHide={() => this.clickAddCategory()}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Добавьте категорию
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId="1">
                                <Form.Label>ID:</Form.Label>
                                <FormControl
                                    type="text"
                                    placeholder="Введите ID"
                                    onChange={this.updateId}
                                />
                            </Form.Group>
                            <Form.Group controlId="2">
                                <Form.Label>Parent ID:</Form.Label>
                                <FormControl
                                    type="text"
                                    placeholder="Введите Parent ID"
                                    onChange={this.updateParentId}
                                />
                            </Form.Group>
                            <Form.Group controlId="3">
                                <Form.Label>Текст:</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Введите текст"
                                    onChange={this.updateText}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            className="mb-2 mr-2" color="primary"
                            onClick={() => this.saveCategory()}>Сохранить</Button>
                        <Button
                            className="mb-2 mr-2" color="primary"
                            onClick={() => this.clickAddCategory()}>Закрыть</Button>
                    </Modal.Footer>
                </Modal>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader>
                                <Row style={{width: '100%'}}>
                                    <Col md={6}>Категории</Col>
                                    <Col md={6}>
                                        <Button color="primary" style={{width: '100%'}}
                                                onClick={() => this.clickAddCategory()}
                                        >Добавить категорию</Button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <ReactTable
                                    previousText={'Предыдущая страница'}
                                    nextText={'Следующая страница'}
                                    pageText='Страница'
                                    ofText='из'
                                    rowsText='строк'
                                    data={this.state.categoriesRender}
                                    columns={[
                                        {
                                            Header: (
                                                <InputGroup>
                                                    <Input
                                                        placeholder="Поиск категории"
                                                        value={this.state.searchText}
                                                        onChange={this.handleSearchCat}
                                                    />
                                                </InputGroup>
                                            ),
                                            columns: [
                                                {
                                                    Header: "Название",
                                                    id: "text",
                                                    minWidth: 200,
                                                    maxWidth: 500,
                                                    accessor: d => (
                                                        <div style={{textAlign: 'top'}}>
                                                            {d.text}
                                                        </div>
                                                    )
                                                },
                                                {
                                                    Header: "ID",
                                                    id: "id",
                                                    minWidth: 100,
                                                    maxWidth: 300,
                                                    accessor: d => (
                                                        <div style={{textAlign: 'top'}}>
                                                            {d.id}
                                                        </div>
                                                    )
                                                },
                                                {
                                                    Header: "Родительский ID",
                                                    id: "parentId",
                                                    minWidth: 100,
                                                    maxWidth: 300,
                                                    accessor: d => (
                                                        <div style={{textAlign: 'top'}}>
                                                            {d.parentId}
                                                        </div>
                                                    )
                                                },
                                                {
                                                    Header: "Действия",
                                                    id: "doing",
                                                    maxWidth: 200,
                                                    minWidth: 200,
                                                    accessor: d => (
                                                        <div style={{textAlign: 'top', width: '100%'}}>
                                                            <Button
                                                                className="mb-2 mr-2"
                                                                color="primary"
                                                                onClick={() => this.deleteCategory(d)}
                                                                style={{width: '100%'}}
                                                            >
                                                                Удалить
                                                            </Button>
                                                        </div>
                                                    )
                                                },
                                            ]}
                                    ]}
                                    defaultPageSize={5}
                                    className="-striped -highlight"
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }
}