import React from "react";
import {Button, CardFooter, CardHeader, DropdownMenu, DropdownToggle, Input, UncontrolledDropdown} from "reactstrap";
import {Card, Col, InputGroup, Row} from "react-bootstrap";
import ReactTable from "react-table";


export default class App extends React.Component{
    constructor (props, context) {
        super(props, context);
        this.state = {

        };
    }
    componentDidMount() {

    }
    render() {
        return (
            <Card>
                <Card.Header>
                    Скачать товары в XML
                </Card.Header>
                <Card.Body style={{overflow: 'auto', height: 200}}>
                    {(this.props.productsXMLLength > 0) && (
                        <ReactTable
                            previousText={'Предыдущая страница'}
                            nextText={'Следующая страница'}
                            pageText='Страница'
                            ofText='из'
                            rowsText='строк'
                            data={this.props.productsXML}
                            showPagination={false}
                            columns={[
                                {
                                    columns: [
                                        {
                                            Header: "Информация",
                                            id: "info",
                                            minWidth: 300,
                                            accessor: d => (
                                                <div style={{overflow: 'auto', height: '100%', textAlign: 'top', color: '#495057'}}>
                                                    <div style={{textAlign: 'left'}}>
                                                        <b>Названия:</b> {d.nameP}
                                                    </div>
                                                </div>
                                            )
                                        },
                                        {
                                            Header: "Действия",
                                            id: "doing",
                                            minWidth: 150,
                                            accessor: d => (
                                                <div style={{overflow: 'none', height: '100%', textAlign: 'top'}}>
                                                    <div>
                                                        <Button style={{textAlign: 'center', width: 130}} className="mb-2 mr-2" color="primary" onClick={() => this.props.deleteProductFromXML(d)}>
                                                            Удалить
                                                        </Button>
                                                    </div>
                                                </div>
                                            )
                                        },
                                    ]}
                            ]}
                            defaultPageSize={this.props.productsXMLLength}
                            className="-striped -highlight"
                        />
                    )}
                    {(this.props.productsXMLLength === 0) && (
                        "Добавьте товары для скачивания или скачайте все"
                    )}
                </Card.Body>
                <Card.Footer>
                    {(this.props.productsXMLLength === 0) && (
                        <div>
                            <Button className="mb-2 mr-2" color="primary" onClick={() => this.props.xmlBuilder()}>Скачать все товары</Button>
                            <Button className="mb-2 mr-2" color="primary" onClick={() => this.props.updateXmlInDB()}>Сохранить</Button>
                        </div>
                    )}
                    {(this.props.productsXMLLength > 0) && (
                        <div>
                            <Button className="mb-2 mr-2" color="primary" onClick={() => this.props.xmlBuilder()}>Скачать все товары</Button>
                            <Button className="mb-2 mr-2" color="primary" onClick={() => this.props.xmlBuilderChoosed()}>Скачать выбранные</Button>
                            <Button className="mb-2 mr-2" color="primary" onClick={() => this.props.updateXmlInDB()}>Сохранить</Button>
                            <Button className="mb-2 mr-2" color="primary" onClick={() => this.props.clearProductXML()}>Очистить выбранные</Button>
                        </div>
                    )}
                </Card.Footer>
            </Card>

        )
    }
}