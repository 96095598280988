import React from 'react';
import '../products/Products.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Tabs, Tab, Button, Form} from 'react-bootstrap';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {
    filterOrdersBack,
    filterOrdersFinished,
    filterOrdersHold,
    fire,
    getDataFromCollection
} from '../firebase/Firebase';
import {Card, CardBody, CardTitle, Col, Row} from "reactstrap";
import CountUp from "react-countup/build";
import {Pie} from "react-chartjs-2";

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import ReactTable from "react-table";
import './style.css'

import * as moment from 'moment';
import 'moment-timezone';



const styleCell = {overflow: "hidden",fontFamily: "Pe-icon-7-stroke", fontWeight: "400",};
const styleCellIn = {fontFamily: "Pe-icon-7-stroke", fontWeight: "550",};


function momentToString(mom){
    return (
        (mom.get('year')) + '-' +
        (mom.get('month') + 1) + '-' +
        (mom.get('date'))
    )
}




const colLg = 4;

class App extends React.Component {
    /*** ================================================================================
     *          Main
     * ================================================================================*/
    constructor (props, context) {
        super(props, context);
        this.state = {

        };
    }
    componentDidMount() {

    }
    /*** ================================================================================
     *          Else Methods
     * ================================================================================*/
    calcMidleValue(x, y){
        if (parseInt(parseInt(x)/parseInt(y)).toString() === 'NaN'){
            return 0
        }else {
            return parseInt(parseInt(x)/parseInt(y)).toString();
        }
    }
    calcMidleValuePercent(x, y){
        if (parseInt((parseInt(x)/parseInt(y))*100).toString() === 'NaN'){
            return '0'
        }else {
            return parseInt((parseInt(x)/parseInt(y))*100).toString();
        }
    }
    calcDiagrammQuantity(){
        var data = {
            labels: [
                'Активные',
                'Не активные',
            ],
            datasets: [{
                data: [
                    this.props.users.filter(user => user.quantityOrders > 0).length,
                    this.props.users.length - this.props.users.filter(user => user.quantityOrders > 0).length
                ],
                backgroundColor: [
                    '#71deb9',
                    '#ef869e'
                ],
                hoverBackgroundColor: [
                    '#71deb9',
                    '#ef869e'
                ]
            }]};
        return data;
    };
    calcDiagrammSumm(){
        var data = {
            labels: [
                'Заработано DropMaster',
                'Заработано партнером',
            ],
            datasets: [{
                data: [
                    this.props.earnedAdmin,
                    this.props.earnedPartner
                ],
                backgroundColor: [
                    '#8dace7',
                    '#71deb9',
                ],
                hoverBackgroundColor: [
                    '#8dace7',
                    '#71deb9',
                ]
            }]};
        return data;
    };
    /*** ================================================================================
     *          Default Render
     * ================================================================================*/
    render() {
        return (
            <div>
                <Row>
                    <Col lg={3}>
                        <Card className="main-card mb-3 card-bg-4">
                            <CardBody>
                                <CardTitle>Кол-во операций</CardTitle>
                                <h3><CountUp end={this.props.quantityBalanceOperation} duration="4"/></h3>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={3}>
                        <Card className="main-card mb-3 card-bg-2">
                            <CardBody>
                                <CardTitle>На счетах, грн</CardTitle>
                                <h3>₴<CountUp end={this.props.summBalance} duration="4"/></h3>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={3}>
                        <Card className="main-card mb-3 card-bg-3">
                            <CardBody>
                                <CardTitle>Выплаты, грн</CardTitle>
                                <h3>₴<CountUp end={this.props.summPayed} duration="4"/></h3>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={3}>
                        <Card className="main-card mb-3 card-bg-3">
                            <CardBody>
                                <CardTitle>Пополнения, грн</CardTitle>
                                <h3>₴<CountUp end={this.props.summInput} duration="4"/></h3>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg={3}>
                        <Card className="main-card mb-3 card-bg-3">
                            <CardBody>
                                <CardTitle>Всего пользователей</CardTitle>
                                <h3><CountUp end={this.props.users.length} duration="4"/></h3>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={3}>
                        <Card className="main-card mb-3 card-bg-4">
                            <CardBody>
                                <CardTitle>Активных пользователей</CardTitle>
                                <h3><CountUp end={this.props.users.filter(user => user.quantityOrders > 0).length} duration="4"/></h3>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={3}>
                        <Card className="main-card mb-3 card-bg-5">
                            <CardBody>
                                <CardTitle>Заработано DropMaster</CardTitle>
                                <h3><CountUp end={this.props.earnedAdmin} duration="4"/></h3>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={3}>
                        <Card className="main-card mb-3 card-bg-5">
                            <CardBody>
                                <CardTitle>Заработано партнерами</CardTitle>
                                <h3><CountUp end={this.props.earnedPartner} duration="4"/></h3>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg="6">
                        <Card className="main-card mb-6">
                            <CardBody>
                                <div>
                                    <Pie dataKey="value" data={this.calcDiagrammQuantity()} />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="6">
                        <Card className="main-card mb-6">
                            <CardBody>
                                <div>
                                    <Pie dataKey="value1" data={this.calcDiagrammSumm()} />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )}
}
export default App;
