import React from 'react';
import '../products/Products.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Tabs, Tab, Button, Form} from 'react-bootstrap';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {
    filterOrdersBack,
    filterOrdersFinished,
    filterOrdersHold,
    fire,
    getDataFromCollection
} from '../firebase/Firebase';
import {Card, CardBody, CardTitle, Col, Row} from "reactstrap";
import CountUp from "react-countup/build";
import {Pie} from "react-chartjs-2";

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import ReactTable from "react-table";
import './style.css'

import * as moment from 'moment';
import 'moment-timezone';



const styleCell = {overflow: "hidden",fontFamily: "Pe-icon-7-stroke", fontWeight: "400",};
const styleCellIn = {fontFamily: "Pe-icon-7-stroke", fontWeight: "550",};


function momentToString(mom){
    return (
        (mom.get('year')) + '-' +
        (mom.get('month') + 1) + '-' +
        (mom.get('date'))
    )
}


const colLg = 4;



class App extends React.Component {
    /*** ================================================================================
     *          Main
     * ================================================================================*/
    constructor (props, context) {
        super(props, context);
        this.state = {

        };
    }
    componentDidMount() {

    }
    /*** ================================================================================
     *          Else Methods
     * ================================================================================*/
    calcMidleValue(x, y){
        if (parseInt(parseInt(x)/parseInt(y)).toString() === 'NaN'){
            return 0
        }else {
            return parseInt(parseInt(x)/parseInt(y)).toString();
        }
    }
    calcMidleValuePercent(x, y){
        if (parseInt((parseInt(x)/parseInt(y))*100).toString() === 'NaN'){
            return '0'
        }else {
            return parseInt((parseInt(x)/parseInt(y))*100).toString();
        }
    }
    calcDiagrammQuantity(){
        var data = {
            labels: [
                'Заявки',
                'Забрано',
                'Отказы'
            ],
            datasets: [{
                data: [
                    this.props.quantityHold,
                    this.props.quantityFinished,
                    this.props.quantityBack
                ],
                backgroundColor: [
                    '#8dace7',
                    '#71deb9',
                    '#ef869e'
                ],
                hoverBackgroundColor: [
                    '#8dace7',
                    '#71deb9',
                    '#ef869e'
                ]
            }]};
        return data;
    };
    calcDiagrammSumm(){
        var data = {
            labels: [
                'Заявки, грн',
                'Забрано, грн',
                'Отказы, грн'
            ],
            datasets: [{
                data: [
                    this.props.summHold,
                    this.props.summFinished,
                    this.props.summBack
                ],
                backgroundColor: [
                    '#8dace7',
                    '#71deb9',
                    '#ef869e'
                ],
                hoverBackgroundColor: [
                    '#8dace7',
                    '#71deb9',
                    '#ef869e'
                ]
            }]};
        return data;
    };
    /*** ================================================================================
     *          Default Render
     * ================================================================================*/
    render() {
        return (
            <div>
                <Row>
                    <Col lg={colLg}>
                        <Card className="main-card mb-3 card-bg-1">
                            <CardBody>
                                <CardTitle>Холд</CardTitle>
                                <h3><CountUp end={this.props.quantityHold} duration="1"/></h3>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={colLg}>
                        <Card className="main-card mb-3 card-bg-2">
                            <CardBody>
                                <CardTitle>Забрано</CardTitle>
                                <h3><CountUp end={this.props.quantityFinished} duration="2"/></h3>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={colLg}>
                        <Card className="main-card mb-3 card-bg-3">
                            <CardBody>
                                <CardTitle>Отказ</CardTitle>
                                <h3><CountUp end={this.props.quantityBack} duration="3"/></h3>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg={colLg}>
                        <Card className="main-card mb-3 card-bg-5">
                            <CardBody>
                                <CardTitle>Потенциал, грн</CardTitle>
                                <h3>₴<CountUp end={this.props.summHold} duration="1"/></h3>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={colLg}>
                        <Card className="main-card mb-3 card-bg-1">
                            <CardBody>
                                <CardTitle>Забрано, грн</CardTitle>
                                <h3>₴<CountUp end={this.props.summFinished} duration="2"/></h3>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={colLg}>
                        <Card className="main-card mb-3 card-bg-2">
                            <CardBody>
                                <CardTitle>Отказы, грн</CardTitle>
                                <h3>₴<CountUp end={this.props.summBack} duration="3"/></h3>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg={colLg}>
                        <Card className="main-card mb-3 card-bg-4">
                            <CardBody>
                                <CardTitle>Средн. цена</CardTitle>
                                <h3>₴<CountUp end={this.calcMidleValue(this.props.summHold , this.props.quantityHold )} duration="1"/></h3>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={colLg}>
                        <Card className="main-card mb-3 card-bg-5">
                            <CardBody>
                                <CardTitle>Средн. цена</CardTitle>
                                <h3>₴<CountUp end={this.calcMidleValue(this.props.summFinished , this.props.quantityFinished)} duration="2"/></h3>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={colLg}>
                        <Card className="main-card mb-3 card-bg-1">
                            <CardBody>
                                <CardTitle>Средн. стоимость</CardTitle>
                                <h3>₴<CountUp end={this.calcMidleValue(this.props.summBack , this.props.quantityBack)} duration="3"/></h3>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col lg="6">
                        <Card className="main-card mb-6">
                            <CardBody>
                                <div>
                                    <Pie dataKey="value" data={this.calcDiagrammQuantity()} />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="6">
                        <Card className="main-card mb-6">
                            <CardBody>
                                <div>
                                    <Pie dataKey="value1" data={this.calcDiagrammSumm()} />
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )}
}
export default App;
