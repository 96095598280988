import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import cx from 'classnames';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import PerfectScrollbar from 'react-perfect-scrollbar';
import HeaderLogo from '../AppLogo';

import {
    setEnableMobileMenu
} from '../../reducers/ThemeOptions';
import MetisMenu from "react-metismenu";
import TableProductsReduxContainer from "../../componentsWithOutTheme/products/ProductsReduxContainer";
import AdminTableOrders from "../../componentsWithOutTheme/orders/AdminOrders";
import AdminTableUsers from '../../componentsWithOutTheme/users/AdminTableUsers';
import AdminTableBalance from '../../componentsWithOutTheme/balance/AdminBalance';
import AdminTableStatistic from '../../componentsWithOutTheme/statistic/AdminTableStatistic';
import AdminPartnerRules from "../../componentsWithOutTheme/rules/AdminPartnerRules";
import AdminPartnerSettings from '../../componentsWithOutTheme/settings/AdminPartnerSettings';

import TableOrders from "../../componentsWithOutTheme/orders/PartnerOrders";
import TableBalance from '../../componentsWithOutTheme/balance/PartnerBalance';
import TableStatistic from '../../componentsWithOutTheme/statistic/PartnerStatistic';
import Contacts from "../../componentsWithOutTheme/contacts/Contacts";




class AppSidebar extends Component {
    /*** ================================================================================
     *          Main methods
     * ================================================================================*/
    componentDidMount() {
        this.detectUser();
    }
    constructor (props, context) {
        super(props, context);
        this.state = {
            // Pages in left menu
            isMainPage: false,
            isProductPage: true,
            isOrderPage: false,
            isFinancePage: false,
            isMarketPlacePage: false,
            isMessagesPage: false,

            isUsersPage: false,
            isRulesPage: false,
            isSettingsPage: false,


            // DetectUser
            isPartnerPage: false,
            isAdminPage: false,

        };
    }
    /*** ================================================================================
     *          Menu
     * ================================================================================*/
    toggleMobileSidebar = () => {
        let {enableMobileMenu, setEnableMobileMenu} = this.props;
        setEnableMobileMenu(!enableMobileMenu);
    };
    onMenuClick = e =>{
        var label = e.target.text;
        switch (label) {
            case 'Главная':
                this.onMenuClickAllFalse();
                this.setState({isMainPage: true});
                localStorage.setItem('lastPage', 'Главная');
                break;
            case 'Товары':
                this.onMenuClickAllFalse();
                this.setState({isProductPage: true});
                localStorage.setItem('lastPage', 'Товары');
                break;
            case 'Заказы':
                this.onMenuClickAllFalse();
                this.setState({isOrderPage: true});
                localStorage.setItem('lastPage', 'Заказы');
                break;
            case 'Баланс':
                this.onMenuClickAllFalse();
                this.setState({isFinancePage: true});
                localStorage.setItem('lastPage', 'Баланс');
                break;
            case 'Контакты':
                this.onMenuClickAllFalse();
                this.setState({isMessagesPage: true});
                localStorage.setItem('lastPage', 'Контакты');
                break;
            case 'Пользователи':
                this.onMenuClickAllFalse();
                this.setState({isUsersPage: true});
                localStorage.setItem('lastPage', 'Пользователи');
                break;
            case 'Правила':
                this.onMenuClickAllFalse();
                this.setState({isRulesPage: true});
                localStorage.setItem('lastPage', 'Правила');
                break;
            case 'Настройки':
                this.onMenuClickAllFalse();
                this.setState({isSettingsPage: true});
                localStorage.setItem('lastPage', 'Настройки');
                break;
            default:

        }

    };
    onMenuClickAllFalse(){
        this.setState({
            isMainPage: false,
            isProductPage: false,
            isOrderPage: false,
            isFinancePage: false,
            isMessagesPage: false,
            isMarketPlacePage: false,
            isNewsPage: false,
            isRulesPage: false,
            isSettingsPage: false,
            isUsersPage: false,
        });
    }
    /*** ================================================================================
     *          Navigation wich user detected
     * ================================================================================*/
    detectUser(){
        if (localStorage.getItem('position') === "Партнер"){
            this.setState({isPartnerPage: true});
            this.setState({isAdminPage: false});
        }else if (localStorage.getItem('position') === "Админ"){
            this.setState({isPartnerPage: false});
            this.setState({isAdminPage: true});
        }
        switch (localStorage.getItem('lastPage')) {
            case 'Главная':
                this.onMenuClickAllFalse();
                this.setState({isMainPage: true});
                break;
            case 'Товары':
                this.onMenuClickAllFalse();
                this.setState({isProductPage: true});
                break;
            case 'Заказы':
                this.onMenuClickAllFalse();
                this.setState({isOrderPage: true});
                break;
            case 'Баланс':
                this.onMenuClickAllFalse();
                this.setState({isFinancePage: true});
                break;
            case 'Контакты':
                this.onMenuClickAllFalse();
                this.setState({isMessagesPage: true});
                break;
            case 'Пользователи':
                this.onMenuClickAllFalse();
                this.setState({isUsersPage: true});
                break;
            case 'Правила':
                this.onMenuClickAllFalse();
                this.setState({isRulesPage: true});
                break;
            case 'Настройки':
                this.onMenuClickAllFalse();
                this.setState({isSettingsPage: true});
                break;
            default:

        }
    }
    detectNav(){
        const PartnerNav = [
            {
                icon: 'pe-7s-home',
                label: 'Главная',
            },
            {
                icon: 'pe-7s-albums',
                label: 'Товары',
            },
            {
                icon: 'pe-7s-note2',
                label: 'Заказы',
            },
            {
                icon: 'pe-7s-wallet',
                label: 'Баланс',
            },
            {
                icon: 'pe-7s-users',
                label: 'Контакты',
            },
            {
                icon: 'pe-7s-news-paper',
                label: 'Правила',
            },
            {
                icon: 'pe-7s-tools',
                label: 'Настройки',
            },
        ];
        const AdminNav = [
            {
                icon: 'pe-7s-home',
                label: 'Главная',
            },
            {
                icon: 'pe-7s-albums',
                label: 'Товары',
            },
            {
                icon: 'pe-7s-note2',
                label: 'Заказы',
            },
            {
                icon: 'pe-7s-wallet',
                label: 'Баланс',
            },
            {
                icon: 'pe-7s-users',
                label: 'Контакты',
            },
            {
                icon: 'pe-7s-news-paper',
                label: 'Правила',
            },
            {
                icon: 'pe-7s-user',
                label: 'Пользователи',
            },
            {
                icon: 'pe-7s-tools',
                label: 'Настройки',
            },
        ];
        if (localStorage.getItem('position') === "Партнер"){
            return PartnerNav;
        }else if (localStorage.getItem('position') === "Админ"){
            return AdminNav;
        }
    }
    /*** ================================================================================
     *          Default Render
     * ================================================================================*/
    render() {
        let {
            backgroundColor,
            enableBackgroundImage,
            enableSidebarShadow,
            backgroundImage,
            backgroundImageOpacity,
        } = this.props;

        return (
            <div className="app-main">
                <Fragment>
                    <div className="sidebar-mobile-overlay" onClick={this.toggleMobileSidebar}/>
                    <ReactCSSTransitionGroup
                        component="div"
                        className={cx("app-sidebar", backgroundColor, {'sidebar-shadow': enableSidebarShadow})}
                        transitionName="SidebarAnimation"
                        transitionAppear={true}
                        transitionAppearTimeout={1500}
                        transitionEnter={false}
                        transitionLeave={false}>
                        <HeaderLogo/>
                        <PerfectScrollbar>
                            <div className="app-sidebar__inner">
                                <Fragment>
                                    <h5 className="app-sidebar__heading">Меню</h5>
                                    <MetisMenu
                                        content={this.detectNav()}
                                        className="vertical-nav-menu"
                                        iconNamePrefix=""
                                        classNameStateIcon="pe-7s-angle-down"
                                        classNameItemActive={'active-item'}
                                        onSelected={this.onMenuClick}
                                    />
                                </Fragment>
                            </div>
                        </PerfectScrollbar>
                        <div
                            className={cx("app-sidebar-bg", backgroundImageOpacity)}
                            style={{
                                backgroundImage: enableBackgroundImage ? 'url(' + backgroundImage + ')' : null
                            }}>
                        </div>
                    </ReactCSSTransitionGroup>
                </Fragment>
                <div className="app-main__outer">
                    <div className="app-main__inner">
                        {this.state.isPartnerPage && (
                            <div>
                                {this.state.isMainPage && (
                                    <div>
                                        <TableStatistic/>
                                    </div>
                                )}
                                {this.state.isProductPage && (
                                    <div>
                                        <TableProductsReduxContainer/>
                                    </div>
                                )}
                                {this.state.isOrderPage && (
                                    <div>
                                        <TableOrders/>
                                    </div>
                                )}
                                {this.state.isFinancePage && (
                                    <div>
                                        <TableBalance/>
                                    </div>
                                )}
                                {this.state.isMessagesPage && (
                                    <div>
                                        <Contacts/>
                                    </div>
                                )}
                                {this.state.isRulesPage && (
                                    <div>
                                        <AdminPartnerRules/>
                                    </div>
                                )}
                                {this.state.isSettingsPage && (
                                    <div>
                                        <AdminPartnerSettings/>
                                    </div>
                                )}
                            </div>
                        )}
                        {this.state.isAdminPage && (
                            <div>
                                {this.state.isMainPage && (
                                    <div>
                                        <AdminTableStatistic/>
                                    </div>
                                )}
                                {this.state.isProductPage && (
                                    <div>
                                        <TableProductsReduxContainer/>
                                    </div>
                                )}
                                {this.state.isOrderPage && (
                                    <div>
                                        <AdminTableOrders/>
                                    </div>
                                )}
                                {this.state.isFinancePage && (
                                    <div>
                                        <AdminTableBalance/>
                                    </div>
                                )}
                                {this.state.isMessagesPage && (
                                    <div>
                                        <Contacts />
                                    </div>
                                )}
                                {this.state.isUsersPage && (
                                    <div>
                                        <AdminTableUsers/>
                                    </div>
                                )}
                                {this.state.isRulesPage && (
                                    <div>
                                        <AdminPartnerRules/>
                                    </div>
                                )}
                                {this.state.isSettingsPage && (
                                    <div>
                                        <AdminPartnerSettings/>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = state => ({
    enableBackgroundImage: state.ThemeOptions.enableBackgroundImage,
    enableSidebarShadow: state.ThemeOptions.enableSidebarShadow,
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    backgroundColor: state.ThemeOptions.backgroundColor,
    backgroundImage: state.ThemeOptions.backgroundImage,
    backgroundImageOpacity: state.ThemeOptions.backgroundImageOpacity,
});

const mapDispatchToProps = dispatch => ({
    setEnableMobileMenu: enable => dispatch(setEnableMobileMenu(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppSidebar);
