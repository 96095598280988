import React from "react";
import {CardBody, CardHeader, Col, Row} from "reactstrap";
import {Button, Card} from "react-bootstrap";
import Ionicon from "react-ionicons";
import man from "../../../images/happy man.png";

export default class App extends React.Component{
    constructor (props, context) {
        super(props, context);
        this.state = {};
    }
    componentDidMount() {
    }

    render() {
        const icon = () => {
            if (this.props.icon === 1) {
                return <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                    <i className="header-icon lnr-cart icon-gradient bg-amy-crisp"> </i>
                    {this.props.title}
                </div>
            }else if (this.props.icon === 2) {
                return <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                    <i className="header-icon lnr-rocket icon-gradient bg-amy-crisp"> </i>
                    {this.props.title}
                </div>
            }
            else if (this.props.icon === 3) {
                return <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                    <i className="header-icon lnr-gift icon-gradient bg-amy-crisp"> </i>
                    {this.props.title}
                </div>
            }
            else if (this.props.icon === 4) {
                return <div className="card-header-title font-size-lg text-capitalize font-weight-normal">
                    <i className="header-icon lnr-thumbs-up icon-gradient bg-amy-crisp"> </i>
                    {this.props.title}
                </div>
            }

        };
        return (
            <Card className="CardProductsAnimation">
                <CardHeader>
                    {icon()}
                </CardHeader>
                <CardBody>
                    <ul>
                        {this.props.liArray.map((li, index) => (
                            <li key={index}>{li.toString()}</li>
                        ))}
                    </ul>
                </CardBody>
            </Card>
        )
    }
}