import React from "react";
import {Button, Col, InputGroup, Row} from "react-bootstrap";
import {DropdownMenu, DropdownToggle, Input, InputGroupAddon, UncontrolledDropdown} from "reactstrap";
import ChangeAllDropDown from "./ChangeAllDropDown";

function checkBools(bool1, bool2){
    if (bool1){
        return true;
    }else if (bool2){
        return true;
    }
}

export default class App extends React.Component {
    /*** ================================================================================
     *          Default Render
     * ================================================================================*/
    render() {
        return(
            <Row style={{marginBottom: 15}}>
                <Col md={6}>
                    <Input
                        placeholder="Поиск"
                        onChange={(e) => this.props.handleSearch(e)}
                    />
                </Col>
                <Col md={6} style={{textAlign: 'center'}}>
                    <Button
                        className="mb-2 mr-2"
                        color="primary"
                        onClick={() => this.props.checkTTN()}
                    >
                        Проверить статусы ТТН
                    </Button>

                    {checkBools(this.props.adminChange, this.props.partnerChange) && (
                        <UncontrolledDropdown style={{display: 'inline-block'}}>
                            <DropdownToggle className="mb-2 mr-2" color="primary">Массовое изменение</DropdownToggle>
                            <DropdownMenu right className="dropdown-menu-xl rm-pointers" style={{padding: '0', }}>
                                <ChangeAllDropDown
                                    orderChangeAll={this.props.orderChangeAll}
                                    orderChangeAllLength={this.props.orderChangeAllLength}
                                    deleteOrderFromChangeAll={this.props.deleteOrderFromChangeAll}
                                    updateOrdersInDB={this.props.updateOrdersInDB}
                                />
                            </DropdownMenu>
                        </UncontrolledDropdown>
                    )}
                    {/*refresh*/}
                    <Button
                        className="mb-2 mr-2"
                        color="primary"
                        onClick={() => this.props.refresh()}
                    >
                        Обновить
                    </Button>
                </Col>
            </Row>
        )
    }
}