import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import $ from "jquery";

const config = {
    apiKey: "AIzaSyCS-ZSL-K5OxFlffCjn4UY1xpvYZmtXIPs",
    authDomain: "dropmasterproject.firebaseapp.com",
    databaseURL: "https://dropmasterproject.firebaseio.com",
    projectId: "dropmasterproject",
    storageBucket: "dropmasterproject.appspot.com",
    messagingSenderId: "949361084541",
    appId: "1:949361084541:web:41e71505a71a5d0e834914",
    measurementId: "G-YRKSH1M1GP"
};
export const fire = firebase.initializeApp(config);

/*** ================================================================================
 *          Calculate Summs and Quantities of user
 * ================================================================================*/
export function calcAllUsers() {
    getDataFromCollection('usders').then(u => {
        u.data.forEach( user => {
            calcSummsAndQuantity(user.uid);
        });
    });
}
export function getCalcJson() {
    return {
        // Summs and Quantity
        // Partner
        quantityOrdersPartner: 0,
        summFinishedPrepaymentPartner: 0,
        quantityFinishedPrepaymentPartner: 0,
        summHoldPrepaymentPartner: 0,
        quantityHoldPrepaymentPartner: 0,
        summBackPrepaymentPartner: 0,
        quantityBackPrepaymentPartner: 0,
        summFinishedPrepaymentDropMaster: 0,
        summHoldPrepaymentDropMaster: 0,
        summFinishedCODPartner: 0,
        quantityFinishedCODPartner: 0,
        summHoldCODPartner: 0,
        quantityHoldCODPartner: 0,
        summBackCODPartner: 0,
        quantityBackCODPartner: 0,
        summFinishedCODDropMaster: 0,
        summHoldCODDropMaster: 0,
        summHoldPartner: 0,
        summFinishedPartner: 0,
        summBackPartner: 0,
        summBalancePartner: 0,
        // Provider
        quantityOrdersProvider: 0,
        summFinishedPrepaymentProvider: 0,
        quantityFinishedPrepaymentProvider: 0,
        summHoldPrepaymentProvider: 0,
        quantityHoldPrepaymentProvider: 0,
        summBackPrepaymentProvider: 0,
        quantityBackPrepaymentProvider: 0,
        summFinishedCODProvider: 0,
        quantityFinishedCODProvider: 0,
        summHoldCODProvider: 0,
        quantityHoldCODProvider: 0,
        summBackCODProvider: 0,
        quantityBackCODProvider: 0,
        summHoldProvider: 0,
        summFinishedProvider: 0,
        summBackProvider: 0,
        summBalanceProvider: 0,
        // Financial
        summInput: 0,
        quantityInput: 0,
        summPayed: 0,
        quantityPayed: 0,
        summBalance: 0,
        // Referal
        referaSummlHold: 0,
        referalSummFinished: 0,
        referalQuantityHold: 0,
        referalQuantityFinished: 0,
        // Summs
        summHold:0,
        summFinished:0,
        summBack:0,
    }
}
export function getUserJson() {
    return {
        displayName: 'displayName',
        email: 'email',
        emailVerified: false,
        phone: 'phone',
        phoneVerified: false,
        photoUrl: 'photoUrl',
        uid: 'uid',
        position: 'Партнер',
        active: false,

        // Summs and Quantity
        // Partner
        quantityOrdersPartner: 0,
        summFinishedPrepaymentPartner: 0,
        quantityFinishedPrepaymentPartner: 0,
        summHoldPrepaymentPartner: 0,
        quantityHoldPrepaymentPartner: 0,
        summBackPrepaymentPartner: 0,
        quantityBackPrepaymentPartner: 0,
        summFinishedPrepaymentDropMaster: 0,
        summHoldPrepaymentDropMaster: 0,
        summFinishedCODPartner: 0,
        quantityFinishedCODPartner: 0,
        summHoldCODPartner: 0,
        quantityHoldCODPartner: 0,
        summBackCODPartner: 0,
        quantityBackCODPartner: 0,
        summFinishedCODDropMaster: 0,
        summHoldCODDropMaster: 0,
        summHoldPartner: 0,
        summFinishedPartner: 0,
        summBackPartner: 0,
        summBalancePartner: 0,
        // Provider
        quantityOrdersProvider: 0,
        summFinishedPrepaymentProvider: 0,
        quantityFinishedPrepaymentProvider: 0,
        summHoldPrepaymentProvider: 0,
        quantityHoldPrepaymentProvider: 0,
        summBackPrepaymentProvider: 0,
        quantityBackPrepaymentProvider: 0,
        summFinishedCODProvider: 0,
        quantityFinishedCODProvider: 0,
        summHoldCODProvider: 0,
        quantityHoldCODProvider: 0,
        summBackCODProvider: 0,
        quantityBackCODProvider: 0,
        summHoldProvider: 0,
        summFinishedProvider: 0,
        summBackProvider: 0,
        summBalanceProvider: 0,
        // Financial
        summInput: 0,
        quantityInput: 0,
        summPayed: 0,
        quantityPayed: 0,
        summBalance: 0,
        // Referal
        referaSummlHold: 0,
        referalSummFinished: 0,
        referalQuantityHold: 0,
        referalQuantityFinished: 0,
        // Summs
        summHold:0,
        summFinished:0,
        summBack:0,

        dateCreation: new Date().getTime(),
        dateLastSignIn: new Date().getTime(),
        comments: '',
        other: '',

        paymentCard: '',
        paymentName: '',
        telegramChatID: [],
        telegramOrderSend: false,
        telegramPaymentSend: false,
        telegramOrderDismiss: false,
        telegramOrderSuccess: false,
        telegramSystemMessage: false,

        referalCode: '',
    }
}
export function calcSummsAndQuantity(uid){
    var result = getCalcJson();
    // Get user products quantity
    getDataFromCollectionWhereKeyValue("products", "ownerUIDP", uid).then( products => {
        // Calc Financial Operations
        getDataFromCollectionWhereKeyValue("balance", "fromUID", uid).then( balance => {
            // console.log(balance);
            calcFinancialOperations(result, balance).then( resultFinancial => {
                result = resultFinancial.result;
                result.quantityProducts = products.data.length;
                // Calc orders Quantity and Summs for user partner
                getDataFromCollectionWhereKeyValue("orders", "fromUID", uid).then( orders => {
                    // Calc OrdersSummAndQuantityPartner
                    calcOrdersSummAndQuantityPartner(result, orders).then( resultOrders => {
                        result = resultOrders.result;
                        calcReferal(result, orders).then( resultReferal => {
                            result = resultReferal.result;
                            result.summHold = result.summHoldProvider + result.summHoldPartner;
                            result.summFinished = result.summFinishedProvider + result.summFinishedPartner;
                            result.summBack = result.summBackProvider + result.summBackPartner;
                            // Activate user if balance more then 100
                            activateIfBalanceMore100(uid, result.summBalance);
                            // Update users BD with new data
                            updateUser(uid, result).then( r => {
                                // console.log(uid);
                                //================================================================================================
                                //      IF partner have Product or Admin or Partner
                                //================================================================================================
                                if (products.data.length > 0){
                                    getDataFromCollectionWhereKeyValue("orders", "ownerUIDP", uid).then( ordersOwner => {
                                        // Calc orders Quantity and Summs
                                        calcUsersProductsSummAndQuantity(result, ordersOwner).then( resultUsersProducts => {
                                            result = resultUsersProducts.result;
                                            result.summHold = result.summHoldProvider + result.summHoldPartner;
                                            result.summFinished = result.summFinishedProvider + result.summFinishedPartner;
                                            result.summBack = result.summBackProvider + result.summBackPartner;
                                            // Activate user if balance more then 100
                                            activateIfBalanceMore100(uid, result.summBalance);
                                            // Update users BD with new data
                                            updateUser(uid, result).then( r => {
                                                // console.log(uid);
                                            });
                                        });
                                    });
                                }
                            });
                        });
                    });
                });
            });
        });
    });
}
function calcReferal(result, orders) {
    return new Promise(function (resolve, reject) {
        try {
            const ordersAll = filterReferal(orders.data);
            const finishOrders = filterOrdersFinished(ordersAll);
            const holdOrders = filterOrdersHold(ordersAll);
            const summFinished = summInArray(finishOrders, 'summReferal');
            const summHold = summInArray(holdOrders, 'summReferal');

            result.referalSummFinished = summFinished;
            result.referaSummlHold = summHold;
            result.referalQuantityFinished = finishOrders.length;
            result.referalQuantityHold = holdOrders.length;

            // console.log('=================================');
            // console.log(ordersAll);
            // console.log(finishOrders);
            // console.log(holdOrders);
            // console.log(summFinished);
            // console.log(summHold);
            // console.log('=================================');

            resolve({result: result});
        }catch (e) {
            reject(e)
        }
    })
}
export function activateIfBalanceMore100(uid, summBalance){
    if (summBalance > 100){
        updateUser(uid, {active: true}).then( r => {

        });
    }else if (summBalance < 100){
        updateUser(uid, {active: false}).then( r => {

        });
    }
}
function calcOrdersSummAndQuantityPartner(result, orders) {
    return new Promise(function (resolve, reject) {
        try {
            // Calc orders Quantity and Summs
            result.quantityOrdersPartner = orders.data.length;
            const ordersAll = orders.data;
            const ordersPrepayment = filterOrdersPrepayment(ordersAll);
            const ordersCOD = filterOrdersCOD(ordersAll);
            //PrepaymentOrders
            const finishOrdersPrepayment = filterOrdersFinished(ordersPrepayment);
            const holdOrdersPrepayment = filterOrdersHold(ordersPrepayment);
            const backOrdersPrepayment = filterOrdersBack(ordersPrepayment);
            const summFinishedPrepayment = summInArray(finishOrdersPrepayment, 'summDrop');
            result.summFinishedPrepaymentPartner = summFinishedPrepayment;
            result.quantityFinishedPrepaymentPartner = finishOrdersPrepayment.length;
            const summHoldPrepayment = summInArray(holdOrdersPrepayment, 'summDrop');
            result.summHoldPrepaymentPartner = summHoldPrepayment;
            result.quantityHoldPrepaymentPartner = holdOrdersPrepayment.length;
            const summBackPrepayment = summInArray(backOrdersPrepayment, 'summBack');
            result.summBackPrepaymentPartner = summBackPrepayment;
            result.quantityBackPrepaymentPartner = backOrdersPrepayment.length;
            const summFinishedPrepaymentDropMaster = summInArrayMinusSumm(finishOrdersPrepayment, 'summDrop', 'summPurchase');
            const summHoldPrepaymentDropMaster = summInArrayMinusSumm(holdOrdersPrepayment, 'summDrop', 'summPurchase');
            result.summFinishedPrepaymentDropMaster = summFinishedPrepaymentDropMaster;
            result.summHoldPrepaymentDropMaster = summHoldPrepaymentDropMaster;
            //COD Orders
            const finishOrdersCOD = filterOrdersFinished(ordersCOD);
            const holdOrdersCOD = filterOrdersHold(ordersCOD);
            const backOrdersCOD = filterOrdersBack(ordersCOD);
            const summFinishedCOD = summInArray(finishOrdersCOD, 'summClient');
            result.summFinishedCODPartner = summFinishedCOD;
            result.quantityFinishedCODPartner = finishOrdersCOD.length;
            const summHoldCOD = summInArray(holdOrdersCOD, 'summClient');
            result.summHoldCODPartner = summHoldCOD;
            result.quantityHoldCODPartner = holdOrdersCOD.length;
            const summBackCOD = summInArray(backOrdersCOD, 'summBack');
            result.summBackCODPartner = summBackCOD;
            result.quantityBackCODPartner = backOrdersCOD.length;
            const summFinishedCODDropMaster = summInArrayMinusSumm(finishOrdersCOD, 'summDrop', 'summPurchase');
            const summHoldCODDropMaster = summInArrayMinusSumm(holdOrdersCOD, 'summDrop', 'summPurchase');
            result.summFinishedCODDropMaster = summFinishedCODDropMaster;
            result.summHoldCODDropMaster = summHoldCODDropMaster;

            result.summHoldPartner = summHoldCOD - summHoldPrepayment;
            result.summFinishedPartner = summFinishedCOD - summFinishedPrepayment;
            result.summBackPartner = - summBackCOD - summBackPrepayment;

            const summBalancePartner =  result.summBalancePartner +  summFinishedCOD - summFinishedPrepayment - summBackCOD - summBackPrepayment;
            result.summBalancePartner = summBalancePartner;
            result.summBalance = result.summBalance + summBalancePartner;
            // Return
            resolve({result: result});
        }catch (e) {
            reject(e);
        }
    })
}
function calcFinancialOperations(result, balance) {
    return new Promise(function (resolve, reject) {
        try {
            // Calc Financial Operations
            var finishBalance = balance.data.filter(function (el) {
                return el.status.includes('Завершен');
            });
            var finishBalanceInput = finishBalance.filter(function (el) {
                return el.typeOperation.includes('Пополнение баланса')||
                    el.typeOperation.includes('Прибавка к балансу');
            });
            var finishBalancePayed = finishBalance.filter(function (el) {
                return el.typeOperation.includes('Вывод средств')||
                    el.typeOperation.includes('Отьем от баланса');
            });
            var summInput = 0;
            for (let i = 0; i < finishBalanceInput.length; i++) {
                summInput = summInput + parseInt(finishBalanceInput[i].summ);
            }
            result.summInput = summInput;
            result.quantityInput = finishBalanceInput.length;
            var summPayed = 0;
            for (let i = 0; i < finishBalancePayed.length; i++) {
                summPayed = summPayed + parseInt(finishBalancePayed[i].summ);
            }
            result.summPayed = summPayed;
            result.quantityPayed = finishBalancePayed.length;
            var summBalance = parseInt(summInput) - parseInt(summPayed);
            result.summBalance = result.summBalance + summBalance;
            resolve({result: result});
        }catch (e) {
            reject(e);
        }
    })
}
function calcUsersProductsSummAndQuantity(result, ordersOwner) {
    return new Promise(function (resolve, reject) {
        try {
            // Calc orders Quantity and Summs
            result.quantityOrdersProvider = ordersOwner.data.length;
            const ordersAll = ordersOwner.data;
            const ordersPrepayment = filterOrdersPrepayment(ordersAll);
            const ordersCOD = filterOrdersCOD(ordersAll);
            //PrepaymentOrders
            const finishOrdersPrepayment = filterOrdersFinished(ordersPrepayment);
            const holdOrdersPrepayment = filterOrdersHold(ordersPrepayment);
            const backOrdersPrepayment = filterOrdersBack(ordersPrepayment);
            const summFinishedPrepayment = summInArray(finishOrdersPrepayment, 'summPurchase');
            result.summFinishedPrepaymentProvider = summFinishedPrepayment;
            result.quantityFinishedPrepaymentProvider = finishOrdersPrepayment.length;
            const summHoldPrepayment = summInArray(holdOrdersPrepayment, 'summPurchase');
            result.summHoldPrepaymentProvider = summHoldPrepayment;
            result.quantityHoldPrepaymentProvider = holdOrdersPrepayment.length;
            const summBackPrepayment = summInArray(backOrdersPrepayment, 'summBack');
            result.summBackPrepaymentProvider = summBackPrepayment;
            result.quantityBackPrepaymentProvider = backOrdersPrepayment.length;
            //COD Orders
            const finishOrdersCOD = filterOrdersFinished(ordersCOD);
            const holdOrdersCOD = filterOrdersHold(ordersCOD);
            const backOrdersCOD = filterOrdersBack(ordersCOD);
            const summFinishedCOD = summInArray(finishOrdersCOD, 'summClient') + summInArrayMinusSumm(finishOrdersCOD, 'summDrop', 'summPurchase');
            result.summFinishedCODProvider = summFinishedCOD;
            result.quantityFinishedCODProvider = finishOrdersCOD.length;
            const summHoldCOD = summInArray(holdOrdersCOD, 'summClient')  + summInArrayMinusSumm(holdOrdersCOD, 'summDrop', 'summPurchase');;
            result.summHoldCODProvider = summHoldCOD;
            result.quantityHoldCODProvider = holdOrdersCOD.length;
            const summBackCOD = summInArray(backOrdersCOD, 'summBack');
            result.summBackCODProvider = summBackCOD;
            result.quantityBackCODProvider = backOrdersCOD.length;



            result.summHoldProvider = summHoldPrepayment - summHoldCOD;
            result.summFinishedProvider = summFinishedPrepayment - summFinishedCOD ;
            result.summBackProvider = summBackCOD + summBackPrepayment;

            const summBalanceProvider =  result.summBalanceProvider + summFinishedPrepayment - summFinishedCOD + summBackCOD + summBackPrepayment;
            result.summBalanceProvider = summBalanceProvider;
            result.summBalance = result.summBalance + summBalanceProvider;
            resolve({result: result});
        }catch (e) {
            reject(e);
        }
    })
}
export function getAllSummsAndQuantityAllUsers() {
    return new Promise(function (resolve, reject) {
        var result = getCalcJson();
        getDataFromCollection("usders").then(u => {
            const users = u.data;
            users.forEach( user => {
                if (user.position !== 'Админ'){
                    // Partner
                    result.quantityOrdersPartner = result.quantityOrdersPartner + parseInt(user.quantityOrdersPartner);
                    result.summFinishedPrepaymentPartner = result.summFinishedPrepaymentPartner + parseInt(user.summFinishedPrepaymentPartner);
                    result.quantityFinishedPrepaymentPartner = result.quantityFinishedPrepaymentPartner + parseInt(user.quantityFinishedPrepaymentPartner);
                    result.summHoldPrepaymentPartner = result.summHoldPrepaymentPartner + parseInt(user.summHoldPrepaymentPartner);
                    result.quantityHoldPrepaymentPartner = result.quantityHoldPrepaymentPartner + parseInt(user.quantityHoldPrepaymentPartner);
                    result.summBackPrepaymentPartner = result.summBackPrepaymentPartner + parseInt(user.summBackPrepaymentPartner);
                    result.quantityBackPrepaymentPartner = result.quantityBackPrepaymentPartner + parseInt(user.quantityBackPrepaymentPartner);
                    result.summFinishedPrepaymentDropMaster = result.summFinishedPrepaymentDropMaster + parseInt(user.summFinishedPrepaymentDropMaster);
                    result.summHoldPrepaymentDropMaster = result.summHoldPrepaymentDropMaster + parseInt(user.summHoldPrepaymentDropMaster);
                    result.summFinishedCODPartner = result.summFinishedCODPartner + parseInt(user.summFinishedCODPartner);
                    result.quantityFinishedCODPartner = result.quantityFinishedCODPartner + parseInt(user.quantityFinishedCODPartner);
                    result.summHoldCODPartner = result.summHoldCODPartner + parseInt(user.summHoldCODPartner);
                    result.quantityHoldCODPartner = result.quantityHoldCODPartner + parseInt(user.quantityHoldCODPartner);
                    result.summBackCODPartner = result.summBackCODPartner + parseInt(user.summBackCODPartner);
                    result.quantityBackCODPartner = result.quantityBackCODPartner + parseInt(user.quantityBackCODPartner);
                    result.summFinishedCODDropMaster = result.summFinishedCODDropMaster + parseInt(user.summFinishedCODDropMaster);
                    result.summHoldCODDropMaster = result.summHoldCODDropMaster + parseInt(user.summHoldCODDropMaster);
                    result.summHoldPartner = result.summHoldPartner + parseInt(user.summHoldPartner);
                    result.summFinishedPartner = result.summFinishedPartner + parseInt(user.summFinishedPartner);
                    result.summBackPartner = result.summBackPartner + parseInt(user.summBackPartner);
                    result.summBalancePartner = result.summBalancePartner + parseInt(user.summBalancePartner);
                    // Provider
                    result.quantityOrdersProvider = result.quantityOrdersProvider + parseInt(user.quantityOrdersProvider);
                    result.summFinishedPrepaymentProvider = result.summFinishedPrepaymentProvider + parseInt(user.summFinishedPrepaymentProvider);
                    result.quantityFinishedPrepaymentProvider = result.quantityFinishedPrepaymentProvider + parseInt(user.quantityFinishedPrepaymentProvider);
                    result.summHoldPrepaymentProvider = result.summHoldPrepaymentProvider + parseInt(user.summHoldPrepaymentProvider);
                    result.quantityHoldPrepaymentProvider = result.quantityHoldPrepaymentProvider + parseInt(user.quantityHoldPrepaymentProvider);
                    result.summBackPrepaymentProvider = result.summBackPrepaymentProvider + parseInt(user.summBackPrepaymentProvider);
                    result.quantityBackPrepaymentProvider = result.quantityBackPrepaymentProvider + parseInt(user.quantityBackPrepaymentProvider);
                    result.summFinishedCODProvider = result.summFinishedCODProvider + parseInt(user.summFinishedCODProvider);
                    result.quantityFinishedCODProvider = result.quantityFinishedCODProvider + parseInt(user.quantityFinishedCODProvider);
                    result.summHoldCODProvider = result.summHoldCODProvider + parseInt(user.summHoldCODProvider);
                    result.quantityHoldCODProvider = result.quantityHoldCODProvider + parseInt(user.quantityHoldCODProvider);
                    result.summBackCODProvider = result.summBackCODProvider + parseInt(user.summBackCODProvider);
                    result.quantityBackCODProvider = result.quantityBackCODProvider + parseInt(user.quantityBackCODProvider);
                    result.summHoldProvider = result.summHoldProvider + parseInt(user.summHoldProvider);
                    result.summFinishedProvider = result.summFinishedProvider + parseInt(user.summFinishedProvider);
                    result.summBackProvider = result.summBackProvider + parseInt(user.summBackProvider);
                    result.summBalanceProvider = result.summBalanceProvider + parseInt(user.summBalanceProvider);
                    // Financial
                    result.summInput = result.summInput + parseInt(user.summInput);
                    result.quantityInput = result.quantityInput + parseInt(user.quantityInput);
                    result.summPayed = result.summPayed + parseInt(user.summPayed);
                    result.quantityPayed = result.quantityPayed + parseInt(user.quantityPayed);
                    result.summBalance = result.summBalance + parseInt(user.summBalance);
                    // REFERAL
                    result.referalSummFinished = result.referalSummFinished + parseInt(user.referalSummFinished);
                    result.referaSummlHold = result.referaSummlHold + parseInt(user.referaSummlHold);
                    result.referalQuantityFinished = result.referalQuantityFinished + parseInt(user.referalQuantityFinished);
                    result.referalQuantityHold = result.referalQuantityHold + parseInt(user.referalQuantityHold);
                }
            });
            resolve(result);
        });
    })
}
/*** ================================================================================
 *          Simple firebase operation
 * ================================================================================*/
export function setDocumentToCollection(collection, document) {
    return new Promise(function (resolve, reject) {
        try {
            fire.firestore().collection(collection).add(document)
                .then(r => {
                    resolve({result:r});
                }).catch(e => {
                reject(e);
            })
        }catch (e) {
            reject(e);
        }
    })
}
export function updateDocumentInCollection(collection, idDocumnent, document) {
    return new Promise(function (resolve, reject) {
        try {
            fire.firestore().collection(collection).doc(idDocumnent).update(document).then(r => {
                resolve({result: r})
            }).catch(e => {
                reject(e);
            })
        }catch (e) {
            reject(e);
        }
    })
}
export function getDataFromCollection(collection) {
    return new Promise(function (resolve, reject) {
        try {
            fire.firestore().collection(collection)
                .get()
                .then(querySnapshot => {
                    const data = [];
                    querySnapshot.docs.forEach(d => {
                        data.push({
                            id: d.id,
                            ...d.data()
                        })
                    });
                    resolve({data:data});
                });
        }catch (e) {
            reject(e);
        }
    })
}
export function getDataFromCollectionWhereKeyValue(collection, key, value) {
    return new Promise(function (resolve, reject) {
        try {
            fire.firestore().collection(collection).where(key,"==", value)
                .get()
                .then(querySnapshot => {
                    const data = [];
                    querySnapshot.docs.forEach(d => {
                        data.push({
                            id: d.id,
                            ...d.data()
                        })
                    });
                    resolve({data:data});
                });
        }catch (e) {
            reject(e);
        }
    })
}
export function deleteDocumentFromCollectionWithID(collection, idPost) {
    return new Promise(function (resolve, reject) {
        try {
            fire.firestore().collection(collection).doc(idPost).delete()
                .then( result => {
                    resolve(result)
                }).catch(function(error) {
                reject(error)
            });
        }catch (e) {
            reject(e)
        }
    })
}
/*** ================================================================================
 *          NOVA POSHTA
 * ================================================================================*/
export function checkTTN(order) {
    return new Promise(function (resolve, reject) {
        try {
            $(function () {
                var params = {
                    'apiKey': 'edc4767fd022e269969ba816d503d1b7',
                    'modelName': 'TrackingDocument',
                    'calledMethod': 'getStatusDocuments',
                    'methodProperties': {
                        'Documents': [{
                            'DocumentNumber': order.TTNNP,
                            'Phone': ''
                        }],
                        'Language': 'RU'
                    }
                };
                $.ajax({
                    url: 'https://api.novaposhta.ua/v2.0/json/?' + $.param(params),
                    beforeSend: function (xhrObj) {
                        xhrObj.setRequestHeader('Content-Type', 'application/json');
                        return Number;
                    },
                    type: 'POST',
                    dataType: 'jsonp',
                    data: '{body}'
                }).done(function (jsondb) {
                    resolve({TTNStatusNP: jsondb.data[0].Status})
                }).fail(function (e) {
                    reject(e);
                });
            });
        }catch (e) {
            reject(e);
        }
    })
}
/*** ================================================================================
 *          BALANCE operation
 * ================================================================================*/
export function getAdminRequisites() {
    return new Promise(function (resolve, reject) {
        try {
            fire.firestore().collection("adminSettings").doc('adminSettings')
                .get()
                .then(querySnapshot => {
                    resolve({
                        paymentCard: querySnapshot.data().paymentCard,
                        paymentName: querySnapshot.data().paymentName
                    });
                });
        }catch (e) {
            reject(e);
        }
    })
}
export function getAllBalances() {
    return new Promise(function (resolve, reject) {
        try {
            fire.firestore().collection("usders")
                .get()
                .then(querySnapshot => {
                    const data = [];
                    var summHold= 0;
                    var summFinished = 0
                    var summBack = 0;
                    var summBalance = 0;
                    var summPayed = 0;
                    var summInput = 0;
                    querySnapshot.docs.forEach(d => {
                        data.push({
                            id: d.id,
                            ...d.data()
                        });
                        if (d.data().position !== 'Админ'){
                            summHold = summHold + d.data().summHold;
                            summFinished = summFinished + d.data().summFinished;
                            summBack = summBack + d.data().summBack;
                            summBalance = summBalance + d.data().summBalance;
                            summPayed = summPayed + d.data().summPayed;
                            summInput = summInput + d.data().summInput;
                        }
                    });
                    resolve({
                        summHold: summHold,
                        summFinished: summFinished,
                        summBack: summBack,
                        summBalance: summBalance,
                        summPayed: summPayed,
                        summInput: summInput
                    });
                });
        }catch (e) {
            reject(e);
        }
    })
}
/*** ================================================================================
 *          Update User
 * ================================================================================*/
export function updateUser(uid, document) {
    return new Promise(function (resolve, reject) {
        try {
            fire.firestore().collection("usders").where("uid", "==", uid)
                .get()
                .then(q => {
                    fire.firestore().collection("usders").doc(q.docs[0].id).update(document).then(querySnapshot => {
                        resolve(querySnapshot)
                    }).catch(e => {
                        reject(e);
                    })
                }).catch(e => {
                reject(e);
            });
        }catch (e) {
            reject(e);
        }
    })
}
/*** ================================================================================
 *          Get Admin Settings/ Rules
 * ================================================================================*/
export function getAdminSettings() {
    return new Promise(function (resolve, reject) {
        try {
            fire.firestore().collection("adminSettings").doc('adminSettings').get().then(querySnapshot => {
                resolve(querySnapshot.data());
            }).catch(e => {
                reject(e);
            });
        }catch (e) {
            reject(e);
        }
    })
}
export function getAdminRules() {
    return new Promise(function (resolve, reject) {
        try {
            fire.firestore().collection("adminSettings").doc('adminRules').get().then(querySnapshot => {
                resolve(querySnapshot.data().rules);
            }).catch(e => {
                reject(e);
            });
        }catch (e) {
            reject(e);
        }
    })
}
/*** ================================================================================
 *          XML
 * ================================================================================*/
const builder = require('xmlbuilder');
export function buildXMLToFile(products, categoryList) {
    let xml = builder.create('yml_catalog', {encoding: 'utf-8'})
        .att('date', new Date().toISOString()
            .replace(/T/, ' ')
            .replace(/\..+/, '')
            .substring(0, 16))
        .ele('shop')
        .ele('name', 'name').up()
        .ele('company', 'company').up()
        .ele('url', 'url').up()
        .ele('currencies')
        .ele('currency', {id: 'UAH', rate: 1}).up().up()
        .ele('categories');
    var middleElem = {};
    for (let key of categoryList) {
        if (key.parentId) {
            middleElem = xml.ele('category', {id: key.id, parentId: key.parentId}, key.text).up();
        } else {
            middleElem = xml.ele('category', {id: key.id}, key.text).up();
        }
    }
    middleElem = middleElem.up();
    var smth = middleElem.ele('offers');
    for (let i in products) {
        var elem = smth.ele('offer', {id: products[i].vendoreCodeP, available: products[i].isOnP})
            .ele('url', products[i].idP)
            .up()
            .ele('price', products[i].priceDropP)
            .up()
            .ele('currencyId', 'UAH')
            .up()
            .ele('categoryId', products[i].categoryIdP)
            .up();
        for (let picture in products[i].imagesUrlP) {
            elem
                .ele('picture', products[i].imagesUrlP[picture])
                .up();
        }
        elem
            .ele('vendor', products[i].brandP)
            .up()
            .ele('stock_quantity', products[i].quantityP)
            .up()
            .ele('name', products[i].nameP)
            .up()
            .ele('description')
            .cdata(products[i].descriptionP).up().up();
        for (let prop in products[i].attributesP) {
            elem
                .ele('param', {name: products[i].attributesP[prop].name}, products[i].attributesP[prop].text).up();
        }
    }
    return xml.end({pretty: true});
}
/*** ================================================================================
 *          Telegram
 * ================================================================================*/
export function sendMessageTelegramBotWithSettings(type, info) {
    const bot = '1194738683:AAHm5CFkT6In8MaR7qIxe1hIgAhaNGz2-CM';
    var text = '';
    try{
        getAdminSettings().then(admin => {
            switch (type) {
                case "NEW_USER":
                    text = "Новый пользователь: " + info.user.displayName + ' (' + info.user.email + '). %0A' + '';
                    sendMessageTelegramBotArrayId(text, admin.telegramChatID, bot);
                    return;
                case "NEW_ORDER":
                    text = "Новый заказ от: " + info.user.displayName + ' (' + info.user.email + '). %0A' + '';
                    if (info.user.telegramOrderSend){
                        sendMessageTelegramBotArrayId(text, info.user.telegramChatID, bot);
                    }
                    if (admin.uid === info.order.ownerUIDP){
                        sendMessageTelegramBotArrayId(text, admin.telegramChatID, bot);
                    }else {
                        getDataFromCollectionWhereKeyValue('usders', 'uid', info.order.ownerUIDP).then(owner => {
                            if (owner.data[0].telegramOrderSend){
                                sendMessageTelegramBotArrayId(text, owner.data[0].telegramChatID, bot);
                            }
                            sendMessageTelegramBotArrayId(text, admin.telegramChatID, bot);
                        })
                    }
                    return;
                case "CHANGE_ORDER":
                    getDataFromCollectionWhereKeyValue('usders', 'uid' , info.fromUID).then(from => {
                        getDataFromCollectionWhereKeyValue('usders', 'uid' , info.ownerUIDP).then(owner => {
                            if (from.data[0].telegramOrderSend){
                                text = "Ваш заказ изменен. %0A" +
                                    "▪️ФИО: " + info.order.name + "%0A" +
                                    "▫️Телефон: " + info.order.phone + "%0A" +
                                    "▪️Статус: " + info.order.status + "%0A" +
                                    "▫️ТТН: " + info.order.TTNNP + "%0A" ;
                                sendMessageTelegramBotArrayId(text, from.data[0].telegramChatID, bot);
                            }
                            text = "Заказ изменен. %0A" +
                                "▪️ФИО: " + info.order.name + "%0A" +
                                "▫️Телефон: " + info.order.phone + "%0A" +
                                "▪️Статус: " + info.order.status + "%0A" +
                                "▫️ТТН: " + info.order.TTNNP + "%0A" ;
                            if (admin.uid === info.ownerUIDP){
                                sendMessageTelegramBotArrayId(text, admin.telegramChatID, bot);
                            }else {
                                if (owner.data[0].telegramOrderSend){
                                    sendMessageTelegramBotArrayId(text, owner.data[0].telegramChatID, bot);
                                }
                                sendMessageTelegramBotArrayId(text, admin.telegramChatID, bot);
                            }
                        });
                    });
                    return;
                case "NEW_GET_MONEY_REQUEST":
                    getDataFromCollectionWhereKeyValue('usders', 'uid' , info.fromUID).then(r => {
                        text = "Поступил запрос на выплату суммы (" + info.summ + " грн.) от пользователя " +
                            info.user.displayName + ' (' + info.user.email + '). %0A'
                            + 'Всего выплат ' + r.data[0].summPayed;
                        sendMessageTelegramBotArrayId(text, admin.telegramChatID, bot);
                    });
                    return;
                case "NEW_SEND_MONEY_REQUEST":
                    getDataFromCollectionWhereKeyValue('usders', 'uid' , info.fromUID).then(r => {
                        text = "Поступил запрос на пополнение баланса (" + info.summ + " грн.) от пользователя " +
                            info.user.displayName + ' (' + info.user.email + '). %0A'
                            + 'Всего пополнений ' + r.data[0].summInput;
                        sendMessageTelegramBotArrayId(text, admin.telegramChatID, bot);
                    });
                    return;
                case "CHANGE_BALANCE_REQUEST":
                    text = "Статус операции баланса (" + info.typeOperation + ' - ' + info.summ + " грн.) изменен на \"" + info.status + '\". %0A' + '';
                    sendMessageTelegramBotArrayId(text, admin.telegramChatID, bot);
                    getDataFromCollectionWhereKeyValue('usders', 'uid' , info.fromUID).then(r => {
                        if (r.data[0].telegramPaymentSend){
                            sendMessageTelegramBotArrayId(text, r.data[0].telegramChatID, bot);
                        }
                    });
                    return;
                case "NEW_PRODUCT":
                    text = "Добавлен новый товар: %0A" +
                        "▪️Название: " + info.product.nameP + "%0A" +
                        "▫️Категория: " + info.product.categoryTextP + "%0A" +
                        "▪️Цена: " + info.product.priceDropP + "%0A";
                    sendMessageTelegramBotArrayId(text, admin.telegramChatID, bot);
                    if (info.product.isOnP){
                        getDataFromCollection('usders').then(r => {
                            r.data.forEach( u => {
                                if (u.telegramSystemMessage){
                                    sendMessageTelegramBotArrayId(text, u.telegramChatID, bot);
                                }
                            });
                        });
                    }
                    return;
                case "DELETE_PRODUCT":
                    text = "Удален товар: %0A" +
                        "▪️Название: " + info.nameP + "%0A" +
                        "▫️Категория: " + info.categoryTextP + "%0A" +
                        "▪️Цена: " + info.priceDropP + "%0A";
                    sendMessageTelegramBotArrayId(text, admin.telegramChatID, bot);
                    getDataFromCollection('usders').then(r => {
                        r.data.forEach( u => {
                            if (u.telegramSystemMessage){
                                sendMessageTelegramBotArrayId(text, u.telegramChatID, bot);
                            }
                        });
                    });
                    return;
                case "CHANGE_PRODUCT":
                    text = "Товар изменен: %0A" +
                        "▪️Название: " + info.product.nameP + "%0A" +
                        "▫️Категория: " + info.product.categoryTextP + "%0A" +
                        "▪️Цена: " + info.product.priceDropP + "%0A";
                    sendMessageTelegramBotArrayId(text, admin.telegramChatID, bot);
                    if (admin.uid !== info.product.ownerUIDP){
                        if (info.product.isOnP){
                            getDataFromCollectionWhereKeyValue('usders', 'uid' , info.product.ownerUIDP).then(owner => {
                                if (owner.data[0].telegramSystemMessage){
                                    text = "Ваш товар включен: %0A" +
                                        "▪️Название: " + info.product.nameP + "%0A" +
                                        "▫️Категория: " + info.product.categoryTextP + "%0A" +
                                        "▪️Цена: " + info.product.priceDropP + "%0A";
                                    if (owner.data[0].uid !== admin.uid){
                                        sendMessageTelegramBotArrayId(text, owner.data[0].telegramChatID, bot);
                                    }
                                }
                            });
                        }else if (!info.product.isOnP){
                            getDataFromCollectionWhereKeyValue('usders', 'uid' , info.product.ownerUIDP).then(owner => {
                                if (owner.data[0].telegramSystemMessage){
                                    text = "Ваш товар выключен: %0A" +
                                        "▪️Название: " + info.product.nameP + "%0A" +
                                        "▫️Категория: " + info.product.categoryTextP + "%0A" +
                                        "▪️Цена: " + info.product.priceDropP + "%0A";
                                    if (owner.data[0].uid !== admin.uid){
                                        sendMessageTelegramBotArrayId(text, owner.data[0].telegramChatID, bot);
                                    }
                                }
                            });
                        }
                    }
                    if (info.product.isOnP){
                        getDataFromCollection('usders').then(r => {
                            r.data.forEach( u => {
                                if (u.telegramSystemMessage){
                                    if (u.uid !== admin.uid){
                                        sendMessageTelegramBotArrayId(text, u.telegramChatID, bot);
                                    }
                                }
                            });
                        });
                    }
                    return;
                case "NEWS_IN_STOCK":
                    text = "Новая информация о наличии товара: %0A" +
                        "▪️Название: " + info.nameP + "%0A" +
                        "▫️Категория: " + info.categoryTextP + "%0A" +
                        "▪️Цена: " + info.priceDropP + "%0A" +
                        "▫️Заголовок новости: " + info.headerNewsInStock + "%0A" +
                        "▪️Текст Новости: " + info.textNewsInStock + "%0A" ;
                    sendMessageTelegramBotArrayId(text, admin.telegramChatID, bot);
                    getDataFromCollection('usders').then(r => {
                        r.data.forEach( u => {
                            if (u.telegramSystemMessage){
                                sendMessageTelegramBotArrayId(text, u.telegramChatID, bot);
                            }
                        });
                    });
                    return;
                case "NEW_NEWS":
                    text = "Новость на сайте DropMaster2.0: %0A" +
                        "" + info.headerNews + "%0A" +
                        "" + info.textNews + "%0A" ;
                    sendMessageTelegramBotArrayId(text, admin.telegramChatID, bot);
                    getDataFromCollection('usders').then(r => {
                        r.data.forEach( u => {
                            if (u.telegramSystemMessage){
                                sendMessageTelegramBotArrayId(text, u.telegramChatID, bot);
                            }
                        });
                    });
                    return;
                case "SEND_MESSAGE_LANDING":
                    text = "Сообщение с сайта DropMaster2.0: %0A" +
                        "" + info.name + "%0A" +
                        "" + info.text + "%0A" +
                        "" + info.phone + "%0A";
                    sendMessageTelegramBotArrayId(text, admin.telegramChatID, bot);
                    return;
                default:
                    return;

            }
        });
    }catch (e) {}
}
export function sendMessageTelegramBotArrayId(text, ids, bot){
    return new Promise((resolve, reject) => {
        try {
            ids.forEach(id => {
                sendMessageTelegramBot(text, id, bot).then(r => {

                })
            })
        }catch (e) {
            reject(e);
        }
    });
}
export function sendMessageTelegramBot(text, id, bot){
    return new Promise((resolve, reject) => {
        try {
            fetch('https://us-central1-dropmasterproject.cloudfunctions.net/widgets/sendMessageToTelegramBot/'+ id + '=' + text,
                { mode: 'no-cors'})
            // .then(response => response.json())
            // .then(data => {
            //     resolve(data);
            // })
            // .catch(err => {
            //     reject(err.toString())
            // })
        }catch (e) {
            reject(e);
        }
    });
}
/*** ================================================================================
 *          Parse Date
 * ================================================================================*/
export function getDateFromMilliseconds(num){
    // return new Date(num).toLocaleString('ru', {
    //     year: 'numeric',
    //     month: 'long',
    //     day: 'numeric',
    //     hour: 'numeric',
    //     minute: 'numeric',
    //     second: 'numeric',
    // });
    const date = new Date(num);
    return date.getFullYear() + '-' + date.getMonth() + '-' + date.getUTCDate()+ ' ' + date.getHours()+ ':' + date.getMinutes();
}
/*** ================================================================================
 *          Filter orders
 * ================================================================================*/
export function filterOrdersHold(array) {
    return array.filter(function (el) {
        return el.status.includes('Новый') ||
            el.status.includes('Принят')||
            el.status.includes('Отправлен');
    });
}
export function filterOrdersBack(array) {
    return array.filter(function (el) {
        return el.status.includes("Возврат");
    });
}
export function filterOrdersFinished(array) {
    return array.filter(function (el) {
        return el.status.includes('Завершен');
    });
}
export function filterOrdersPrepayment(array) {
    return array.filter(function (el) {
        return el.paymentType.includes('Предоплата');
    });
}
export function filterOrdersCOD(array) {
    return array.filter(function (el) {
        return el.paymentType.includes('Наложенный платеж');
    });
}
export function summInArray(array, key) {
    var result = 0;
    array.forEach(a => {
        result = result + parseInt(a[key]);
    });
    return result;
}
export function summInArrayMinusSumm(array, keyFirst, keySecond) {
    var result = 0;
    array.forEach(a => {
        result = result + parseInt(parseInt(a[keyFirst]) - parseInt(a[keySecond]));
    });
    return result;
}
export function summInArrayPlusSumm(array, keyFirst, keySecond) {
    var result = 0;
    array.forEach(a => {
        result = result + parseInt(parseInt(a[keyFirst]) + parseInt(a[keySecond]));
    });
    return result;
}
export function filterReferal(array) {
    return array.filter(function (el) {
        return el.referalCode !== '';
    });
}
/*** ================================================================================
 *          Calc Partner summs
 * ================================================================================*/
export function getPartnerSumms(uid) {
    return new Promise(function (resolve, reject) {
        try {
            getDataFromCollectionWhereKeyValue("orders", "ownerUIDP", uid).then(o => {
                const orders = o.data;
                const ordersHold = filterOrdersHold(orders);
                const ordersFinished = filterOrdersFinished(orders);
                const ordersBack = filterOrdersBack(orders);
                // summ
                const summHold = summInArray(ordersHold, 'summ');
                const summFinished = summInArray(ordersFinished, 'summ');
                const summBack = summInArray(ordersBack, 'summBack');
                //summClient
                const summClientHold = summInArray(ordersHold, 'summClient');
                const summClientFinished = summInArray(ordersFinished, 'summClient');
                //summDrop
                const summDropHold = summInArray(ordersHold, 'summDrop');
                const summDropFinished = summInArray(ordersFinished, 'summDrop');
                //summPurchase
                const summPurchaseHold = summInArray(ordersHold, 'summPurchase');
                const summPurchaseFinished = summInArray(ordersFinished, 'summPurchase');
                resolve ({data: {
                        uid: uid,
                        summHold: summHold,
                        summFinished: summFinished,
                        summBack: summBack,
                        //summClient
                        summClientHold: summClientHold,
                        summClientFinished: summClientFinished,
                        //summDrop
                        summDropHold: summDropHold,
                        summDropFinished: summDropFinished,
                        //summPurchase
                        summPurchaseHold: summPurchaseHold,
                        summPurchaseFinished: summPurchaseFinished,
                    }})
            });
        }catch (e) {
            reject(e);
        }
    })
}
/*** ================================================================================
 *          CHECK VERIFICATION
 * ================================================================================*/
export function checkVerified() {
    return new Promise(function (resolve, reject) {
        fire.auth().onAuthStateChanged(function(user) {
            if (user) {
                if (localStorage.getItem('position') === 'Админ'){
                    resolve({
                        isVerified: true,
                        needActive: false,
                        needPhone: false,
                        needEmail: false,
                        needBalance: false,
                    });
                    return;
                }
                getDataFromCollectionWhereKeyValue('usders', 'uid', user.uid).then( u => {
                    const userSettings = u.data[0];
                    var isVerified = true;
                    var needActive = false;
                    var needPhone = false;
                    var needEmail = false;
                    var needBalance = false;
                    if (!userSettings.active){
                        needActive = true;
                        isVerified = false;
                    }
                    if (userSettings.phone === null){
                        needPhone = true;
                        isVerified = false;
                    }
                    if (!user.emailVerified){
                        needEmail = true;
                        // isVerified = false;
                    }
                    if (userSettings.summBalance < 100){
                        needBalance = true;
                        isVerified = false;
                    }
                    resolve({
                        isVerified: isVerified,
                        needActive: needActive,
                        needPhone: needPhone,
                        needEmail: needEmail,
                        needBalance: needBalance,
                    });
                });
            } else {
                resolve({
                    isVerified: false,
                    needActive: false,
                    needPhone: false,
                    needEmail: false,
                    needBalance: false,
                });
            }
        });
    });
}
/*** ================================================================================
 *          CREATE NEW USER
 * ================================================================================*/
export function createNewUserAndGoToAccount(displayName, email, emailVerified, phone, phoneVerified, photoUrl, uid, referal){
    return new Promise(function (resolve, reject) {
        const user = getUserJson();
        user.displayName = displayName;
        user.email = email;
        user.emailVerified = emailVerified;
        user.phone = phone;
        user.phoneVerified = phoneVerified;
        user.photoUrl = photoUrl;
        user.uid = uid;
        user.referal= referal;
        setDocumentToCollection('usders', user).then( r => {
            fire.auth().currentUser.sendEmailVerification();
            goToAccount(user);
            sendMessageTelegramBotWithSettings("NEW_USER", {
                user: user,
            });
            resolve({user: user})
        });
    });

}
export function goToAccount(result){
    localStorage.setItem('displayName' , result.displayName);
    localStorage.setItem('email' , result.email);
    localStorage.setItem('emailVerified' , result.emailVerified);
    localStorage.setItem('phone' , result.phone);
    localStorage.setItem('phoneVerified' , result.phoneVerified);
    localStorage.setItem('photoUrl' , result.photoUrl);
    localStorage.setItem('uid' , result.uid);
    localStorage.setItem('position' , result.position);
    localStorage.setItem('orders' , result.orders);
    localStorage.setItem('products' , result.products);
    localStorage.setItem('active' , result.active);
    localStorage.setItem('balance' , result.summBalance);
    localStorage.setItem('dateCreation' , result.dateCreation);

    window.location.assign(window.location.origin + '/account');
}
/*** ================================================================================
 *          CLEAR PRODUCT / CATEGORIES FROM XML
 * ================================================================================*/
export function deleteProductFromXML() {
    return new Promise(function (resolve, reject) {
        getDataFromCollection('exportToRozetka').then( e => {
            const exports = e.data;
            getDataFromCollection('products').then( p => {
                const products = p.data;
                exports.forEach(ex => {
                    const newProductsID = [];
                    const productsID = ex.productsID;
                    productsID.forEach( xmlID => {
                        products.forEach( pr => {
                            const pID = pr.id;
                            if (xmlID === pID){
                                newProductsID.push(xmlID)
                            }
                        });
                    });
                    updateDocumentInCollection('exportToRozetka', ex.id, {
                        productsID: newProductsID
                    }).then( r => {

                    });
                });
            });
        });
    });
}
export function deleteCategoryFromXML() {
    return new Promise(function (resolve, reject) {
        getDataFromCollection('exportToRozetka').then( e => {
            const exports = e.data;
            fire.firestore().collection('categories').get().then( c => {
                const categories = [];
                c.docs.forEach(d => {
                    categories.push({
                        idDB: d.id,
                        ...d.data()
                    })
                });
                exports.forEach(ex => {
                    const newCategoriesID = [];
                    const categoriesID = ex.categoriesID;
                    categoriesID.forEach( xmlID => {
                        categories.forEach( cat => {
                            const cID = cat.idDB;
                            if (xmlID === cID){
                                newCategoriesID.push(xmlID)
                            }
                        });
                    });
                    updateDocumentInCollection('exportToRozetka', ex.id, {
                        categoriesID: newCategoriesID
                    }).then( r => {
                        resolve(r);
                    });
                });
            });

        });
    });
}
export function addCategoriesToXML(id) {
    return new Promise(function (resolve, reject) {
        getDataFromCollection('exportToRozetka').then( e => {
            const exports = e.data;
            exports.forEach( ex => {
                const categories = ex.categoriesID;
                categories.push(id);
                updateDocumentInCollection('exportToRozetka', ex.id, {
                    categoriesID: categories
                }).then( r => {
                    resolve(id);
                });
            });
        });
    });
}
/*** ================================================================================
 *          REFERAL
 * ================================================================================*/
export function getReferal(){
    return new Promise(function (resolve, reject) {
        getDataFromCollection("usders").then(u => {
            const users = u.data.filter(function (el) {
                return el.position !== 'Админ';
            });
            users.sort(function(a,b){
                return new Date(b.dateLastSignIn) - new Date(a.dateLastSignIn);
            });
            var newUsers = [];
            users.forEach(user => {
                const curUID = user.uid;
                var referalSummFinishedCalc = 0;
                var referaSummlHoldCalc = 0;
                var referalQuantityFinishedCalc = 0;
                var referalQuantityHoldCalc = 0;
                var isReferal = false;
                users.forEach(userNew => {
                    const newUID = userNew.referal;
                    if (newUID === curUID){
                        isReferal = true;
                        referalSummFinishedCalc = referalSummFinishedCalc + userNew.referalSummFinished;
                        referaSummlHoldCalc = referaSummlHoldCalc + userNew.referaSummlHold;
                        referalQuantityFinishedCalc = referalQuantityFinishedCalc + userNew.referalQuantityFinished;
                        referalQuantityHoldCalc = referalQuantityHoldCalc + userNew.referalQuantityHold;
                    }
                });
                if (isReferal){
                    newUsers.push({
                        referalSummFinishedCalc:referalSummFinishedCalc,
                        referaSummlHoldCalc:referaSummlHoldCalc,
                        referalQuantityFinishedCalc:referalQuantityFinishedCalc,
                        referalQuantityHoldCalc:referalQuantityHoldCalc,
                        ...user
                    });
                }
            });
            resolve(newUsers);
        });
    });
}