import React from "react";
import {Button, Col, Row} from "reactstrap";
import ReactTable from "react-table";
import CountUp from "react-countup/build";

const styleCell = {overflow: 'auto', height: '100%', textAlign: 'top'};

export default class App extends React.Component {
    /*** ================================================================================
     *          Else Methods
     * ================================================================================*/
    isProductInXmlList(p){
        return this.props.productsXML.find(el => el.idP === p.idP);
    }
    checkInStock(bool){
        if (bool){
            return 'В наличии';
        }else {
            return 'Нет в наличии';
        }
    }
    /*** ================================================================================
     *          Default Render
     * ================================================================================*/
    render() {
        return (
            <ReactTable
                previousText={'Предыдущая страница'}
                nextText={'Следующая страница'}
                pageText='Страница'
                ofText='из'
                rowsText='строк'
                data={this.props.productsRender}
                columns={[
                    {
                        // Header: 'Пользователи',
                        columns: [
                            {
                                Header: "Фото",
                                id: "photo",
                                // sortable: false,
                                maxWidth: 210,
                                minWidth: 210,
                                accessor: d => (
                                    <div style={{width: '100%', height: '100%'}}>
                                        <div className="ProductRibbonContainer">
                                            {d.isNewP && (
                                                <div className='newProductRibbon productRibbon'>NEW</div>
                                            )}
                                            {d.isTopP && (
                                                <div className='topProductRibbon productRibbon'>TOP</div>
                                            )}
                                            {d.isPriceP && (
                                                <div className='priceProductRibbon productRibbon'>Price</div>
                                            )}
                                        </div>
                                        <img
                                            src={d.imagesUrlP[0]}
                                            alt="slide"
                                            style={{textAlign: 'center', height: '150px', borderRadius: '5%', maxWidth: '100%'}}
                                        />
                                    </div>
                                )
                            },
                            {
                                Header: "Информация",
                                id: "name",
                                maxWidth: 450,
                                accessor: d => (
                                    <div style={{overflow: 'auto', height: '100%', textAlign: 'top'}}>
                                        <div style={{textAlign: 'left'}}>
                                            <b>Названия:</b> {d.nameP}
                                        </div>
                                        <div style={{textAlign: 'left'}}>
                                            <b>Категория:</b> {d.categoryTextP}
                                        </div>
                                        <div style={{textAlign: 'left'}}>
                                            <b>Бренд:</b> {d.brandP}
                                        </div>
                                        <div style={{textAlign: 'left'}}>
                                            <b>Наличие:</b> {this.checkInStock(d.inStockP)}
                                        </div>
                                        {(localStorage.getItem('position') === 'Админ') && (
                                            <div style={{textAlign: 'left'}}>
                                                <b>Владелец:</b> {d.ownerEmailP}
                                            </div>
                                        )}
                                        <div style={{textAlign: 'left'}}>
                                            <b>Ваша цена:</b>
                                            <div
                                                className="widget-numbers widget-numbers-sm text-primary"
                                            />{ parseInt(d.priceDropP) } грн.
                                        </div>
                                    </div>
                                )
                            },
                            {
                                Header: "Описание",
                                id: "description",
                                // maxWidth: 100,
                                accessor: d => (
                                    <div style={{width: '100%', maxHeight: 150, overflow: 'hidden', textAlign: 'top'}}>
                                        {d.descriptionP.replace(/(?!(\<br\>|<br>|\<br\s\/\>))<\/?[^>]+>/g, '').replace(/&nbsp;/gi,'').replace('<br>', ' ')}
                                    </div>
                                )
                            },
                            {
                                Header: "Действия",
                                id: "btns",
                                // sortable: false,
                                maxWidth: 150,
                                minWidth: 150,
                                accessor: d => (
                                    <div style={{overflow: 'none', height: '100%', textAlign: 'top'}}>
                                        <Button style={{textAlign: 'center', width: 130}} className="mb-2 mr-2" color="primary"
                                                onClick={() => this.props.clickDetailProduct(d)}>
                                            Подробнее
                                        </Button>
                                        <Button style={{textAlign: 'center', width: 130}}
                                                className="mb-2 mr-2"
                                                color="primary"
                                                onClick={() => this.props.toBasketClick(d)}
                                                disabled={!d.isOnP}>
                                            В корзину
                                        </Button>
                                        {this.props.change && (
                                            <Button style={{textAlign: 'center', width: 130}} className="mb-2 mr-2" color="primary"
                                                    onClick={() => this.props.clickChangeProduct(d)}>
                                                Редактировать
                                            </Button>
                                        )}
                                        <Button
                                            style={{textAlign: 'center', width: 130}}
                                            className="mb-2 mr-2"
                                            color="primary"
                                            onClick={() => {
                                                if (this.isProductInXmlList(d))
                                                    this.props.deleteProductFromXML(d);
                                                else this.props.addProductToXML(d);
                                            }}>
                                            {this.isProductInXmlList(d)?'Удалить из XML':'Добавить в XML'}
                                        </Button>
                                    </div>
                                )
                            },

                        ]},
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
            />
        )
    }
}