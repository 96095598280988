import React from 'react';
import '../products/TableProductsRealTime.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Tabs, Tab, Button, Modal, Form, Col, Row, } from 'react-bootstrap';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {
    checkTTN,
    updateDocumentInCollection,
    getDataFromCollectionWhereKeyValue,
    calcSummsAndQuantity, sendMessageTelegramBotWithSettings, getDateFromMilliseconds
} from '../firebase/Firebase';
import Select from "react-select";
import TabsTablesOrder from "../orders/TabsTablesOrder";
import {
    addProduct, changeProduct,
    clearAdmin,
    clearUser,
    removeAll,
    removeProduct,
    setAdmin,
    setUser
} from "../storeReducers/basket/actions";
import {connect} from "react-redux";

class App extends React.Component {
    /*** ================================================================================
     *          Main methods
     * ================================================================================*/
    constructor (props, context) {
        super(props, context);
        this.state = {
            // DB Array
            orders: [],
            dataMyOrders: [],
            dataMyProducts: [],
            users: [],
            // Change Order
            isChangeOrder: false,
            currentChangeOrder: {date: ''},
            idCO: '',
            nameCO: '',
            phoneCO: '',
            cityCO: '',
            notesCO: '',
            paymentTypeCO: '',
            statusCO: '',
            statusNPCO: '',
            TTNNPCO: '',
            TTNStatusNPCO: '',
            fromUIDCO: '',
            fromEmailCO: '',
            fromNameCO: '',
            dateCO: '',
            summCO: '',
            productsCO: '',
            activeCO: '',
            summBackCO: '',

            // Show Modal Windows
            isOrdersShow: false,
            isBalanceShow: false,

            ordersCurrentUser: [],
            balanceCurrentUser: [],

            // If user have Products
            isUserHaveProducts: false,
        };
        this.getOrders = this.getOrders.bind(this);
        this.refresh = this.refresh.bind(this);
    }
    componentDidMount() {
        this.refresh();
    }
    refresh(){
        this.getOrders();
    }
    /*** ================================================================================
     *          handle Change Order
     * ================================================================================*/
    updateNameCO = e => {
        this.setState({nameCO: e.target.value});
    };
    updatePhoneCO = e => {
        this.setState({phoneCO: e.target.value});
    };
    updateCityCO = e => {
        this.setState({cityCO: e.target.value});
    };
    updateNotesCO = e => {
        this.setState({notesCO: e.target.value});
    };
    updatePaymentTypeCO = e => {
        this.setState({paymentTypeCO: e.value});
    };
    updateStatusCO = e => {
        this.setState({statusCO: e.value});
    };
    updateTTNNPCO = e => {
        this.setState({TTNNPCO: e.target.value});
    };
    updateSummBackCO = e => {
        this.setState({summBackCO: e.target.value});
    };
    /*** ================================================================================
     *          Data Base
     * ================================================================================*/
    getOrders () {
        getDataFromCollectionWhereKeyValue("orders", "fromUID", localStorage.getItem('uid')).then( r => {
            // Sort Array
            const dataMyOrders = r.data.sort(function(a,b){
                return new Date(b.date) - new Date(a.date);
            });
            this.setState({ dataMyOrders: dataMyOrders });
            // Get orders if have products
            getDataFromCollectionWhereKeyValue("orders", "ownerUIDP", localStorage.getItem('uid')).then( r => {
                // console.log(r.data.length);
                // console.log(r.data);
                if (r.data.length > 0){
                    this.setState({isUserHaveProducts: true})
                }else {
                    this.setState({isUserHaveProducts: false})
                }
                const dataMyProducts = r.data.sort(function(a,b){
                    return new Date(b.date) - new Date(a.date);
                });
                this.setState({ dataMyProducts: dataMyProducts });
            });
        });
    };
    /*** ================================================================================
     *          Update Order
     * ================================================================================*/
    updateOrder(){
        const order = {
            name: this.state.nameCO,
            phone: this.state.phoneCO,
            city: this.state.cityCO,
            notes: this.state.notesCO,
            paymentType: this.state.paymentTypeCO,
            status: this.state.statusCO,
            TTNNP: this.state.TTNNPCO,
            summBack: parseInt(this.state.summBackCO),
        };
        updateDocumentInCollection("orders", this.state.idCO, order ).then( r => {
            calcSummsAndQuantity(this.state.currentChangeOrder.fromUID);
            calcSummsAndQuantity(localStorage.getItem('uid'));
            calcSummsAndQuantity(this.props.adminSettings.uid);
            sendMessageTelegramBotWithSettings("CHANGE_ORDER", {
                fromUID: this.state.currentChangeOrder.fromUID,
                ownerUIDP: this.state.currentChangeOrder.ownerUIDP,
                order: order,
            });
            this.getOrders();
            this.clickChangeOrder();
        });
    }
    /*** ================================================================================
     *          Change Order
     * ================================================================================*/
    clickChangeOrder = (p) => {
        if (!this.state.isChangeOrder){
            this.setState({isChangeOrder: true});
            this.setState({currentChangeOrder: p});
            this.sendHandleChangeOrder(p)
        }else {
            this.setState({isChangeOrder: false});
        }
    };
    sendHandleChangeOrder(o){
        this.setState({idCO: o.id});
        this.setState({nameCO: o.name});
        this.setState({phoneCO: o.phone});
        this.setState({cityCO: o.city});
        this.setState({notesCO: o.notes});
        this.setState({paymentTypeCO: o.paymentType});
        this.setState({statusCO: o.status});
        this.setState({TTNNPCO: o.TTNNP});
        this.setState({summBackCO: o.summBack});
    }
    /*** ================================================================================
     *          Nova Poshta
     * ================================================================================*/
    checkTTN = () => {
        try {
            const data = this.state.orders;
            data.forEach(d => {
                if (d.TTNNP !== 'ТТН не присвоен'){
                    checkTTN(d).then( r => {
                        updateDocumentInCollection("orders", d.id, {TTNStatusNP: r.TTNStatusNP,}).then( u => {
                            this.getOrders();
                        });
                    })
                }
            });
        }catch (e) {
            console.log(e);
        }
    };
    /*** ================================================================================
     *          Default Render
     * ================================================================================*/
    render() {
        return (
            <div className="ProductWindow-Container">
                {/*Change Order*/}
                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    // centered
                    show={this.state.isChangeOrder}
                    onHide={() => this.clickChangeOrder()}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {'Дата создания заказа: ' + getDateFromMilliseconds(this.state.currentChangeOrder.date)}
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="formGroupPassword">
                                    <Form.Label>Тип оплаты</Form.Label>
                                    <Select
                                        value={ {value: this.state.paymentTypeCO, label: this.state.paymentTypeCO} }
                                        onChange={this.updatePaymentTypeCO}
                                        options={[
                                            { value: 'Наложенный платеж', label: 'Наложенный платеж' },
                                            { value: 'Предоплата', label: 'Предоплата' },
                                        ]}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formGroupEmail">
                                    <Form.Label>ФИО покупателя</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Укажите ФИО покупателя"
                                        onChange={this.updateNameCO}
                                        value={this.state.nameCO}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="formGroupEmail">
                                    <Form.Label>Номер телефона</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Укажите номер телефона"
                                        onChange={this.updatePhoneCO}
                                        value={this.state.phoneCO}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formGroupPassword">
                                    <Form.Label>Доставка</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Город и номер отделения новой почты"
                                        onChange={this.updateCityCO}
                                        value={this.state.cityCO}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="formGroupEmail">
                                    <Form.Label>ТТН</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Укажите ТТН"
                                        onChange={this.updateTTNNPCO}
                                        value={this.state.TTNNPCO}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formGroupPassword">
                                    <Form.Label>Статус</Form.Label>
                                    <Select
                                        value={ {value: this.state.statusCO, label: this.state.statusCO} }
                                        onChange={this.updateStatusCO}
                                        options={[
                                            { value: 'Новый', label: 'Новый' },
                                            { value: 'Принят', label: 'Принят' },
                                            { value: 'Отказ', label: 'Отказ' },
                                            { value: 'Отправлен', label: 'Отправлен' },
                                            { value: 'Завершен', label: 'Завершен' },
                                            { value: 'Возврат', label: 'Возврат' },
                                        ]}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Form.Group controlId="summBack">
                                    <Form.Label>Укажите сумму за возврат посылки</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Сумма возврата посылки"
                                        onChange={this.updateSummBackCO}
                                        value={this.state.summBackCO}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Заметки</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows="3"
                                        placeholder="Заметки"
                                        onChange={this.updateNotesCO}
                                        value={this.state.notesCO}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button color="primary" onClick={() => this.updateOrder()}>Сохранить</Button>
                        <Button color="primary" onClick={() => this.clickChangeOrder()}>Закрыть</Button>
                    </Modal.Footer>
                </Modal>
                {/*Content*/}
                {this.state.isUserHaveProducts && (
                    <Tabs variant="pills"  defaultActiveKey="Мои заказы" transition={false} id="noanim-tab-example" >
                        <Tab eventKey="Мои заказы" title={"Мои заказы " + this.state.dataMyOrders.length}>
                            <TabsTablesOrder
                                refresh={this.refresh}
                                orders={this.state.dataMyOrders}
                                partnerNoChange={true}
                                checkTTN={this.checkTTN}
                                getOrders={this.getOrders}
                            />
                        </Tab>
                        <Tab eventKey="Мои отправки" title={"Мои отправки " + this.state.dataMyProducts.length}>
                            <TabsTablesOrder
                                refresh={this.refresh}
                                orders={this.state.dataMyProducts}
                                partnerChange={true}
                                clickChangeOrder={this.clickChangeOrder}
                                checkTTN={this.checkTTN}
                                getOrders={this.getOrders}
                            />
                        </Tab>
                    </Tabs>
                )}
                {!this.state.isUserHaveProducts && (
                    <TabsTablesOrder
                        refresh={this.refresh}
                        orders={this.state.dataMyOrders}
                        partnerNoChange={true}
                        checkTTN={this.checkTTN}
                        getOrders={this.getOrders}
                    />
                )}
            </div>
        )}
}

const mapStateToProps = state => {
    return {
        basketArray: state.basket,
        user: state.basket.user,
        adminSettings: state.basket.adminSettings
    }
};

const mapDispatchToProps = {
    setAdmin,
    clearAdmin,
    setUser,
    clearUser,
    addProduct,
    removeProduct,
    removeAll,
    changeProduct
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
