import React from "react";
import {Col, Form, FormControl, Image, InputGroup, Modal, ProgressBar, Row, Card, } from "react-bootstrap";
import {
    Button,
    CustomInput,
    DropdownMenu,
    DropdownToggle,
    Input,
    InputGroupAddon,
    UncontrolledDropdown
} from "reactstrap";
import Select from "react-select";
import {
    calcSummsAndQuantity,
    fire,
    deleteProductFromXML,
    sendMessageTelegramBotWithSettings
} from "../../firebase/Firebase";
import ReactTable from "react-table";
import * as moment from "moment";
import {
    addProduct, changeProduct,
    clearAdmin,
    clearUser,
    removeAll,
    removeProduct,
    setAdmin,
    setUser
} from "../../storeReducers/basket/actions";
import {connect} from "react-redux";

export function checkInStock(bool){
    if (bool){
        return 'В наличии';
    }else {
        return 'Нет в наличии';
    }
}
export function checkInStockReverse(stock){
    if (stock === 'В наличии'){
        return true;
    }else {
        return false;
    }
}
export function checkIsOn(bool){
    if (bool){
        return 'Включен';
    }else {
        return 'Выключен';
    }
}
export function checkIsOnRevers(stock){
    if (stock === 'Включен'){
        return true;
    }else {
        return false;
    }
}

function getHeaderModal(isCreate, isChange) {
    if (isCreate){
        return'Добавьте новый товар';
    } else if (isChange){
        return'Измените товар';
    }
}

class App extends React.Component{
    /*** ================================================================================
     *          Main
     * ================================================================================*/
    constructor (props, context) {
        super(props, context);
        this.state = {
            // Product elements
            idP: '',
            nameP: '',
            categoryP: {id: 0, parentId: 0, text: ''},
            brandP: '',
            vendoreCodeP: '',
            quantityP: 0,
            pricePurchaseP: '',
            priceDropP: '',
            priceSellingP: '',
            isNewProduct: false,
            isLowerPrice: false,
            descriptionP: '',
            attributesP: [],
            inStockP: 'В наличии',
            isOnP: 'Включен',
            isModeratedP: false,
            otherP: '',
            imagesUrlP: [],
            dateP: '',
            isNewP: false,
            isTopP: false,
            isPriceP: false,
            // Atributes for products
            nameAtribute: '',
            textAtribute: '',
            // For Image adding temporary image file
            image: null,
            progress: 0,
            // News in Stock
            isClickNewsInStock: false,
            headerNewsInStock: '',
            textNewsInStock: '',
            // Categories
            categories: [],
            categoriesRender: [],
        };
        this.deleteProduct = this.deleteProduct.bind(this);
        this.saveNewProduct = this.saveNewProduct.bind(this);
        this.changeProduct = this.changeProduct.bind(this);
        this.sendNewsInStock = this.sendNewsInStock.bind(this);
        this.clearState = this.clearState.bind(this);
    }
    componentDidMount() {
        this.getCategories();
    }

    componentDidUpdate(prevProps) {
        if (this.props.isChange !== prevProps.isChange){
            const p = this.props.currentChangeCreateProduct;
            // console.log(p);
            this.setState({
                currentChangeProduct: p,
                nameP: p.nameP,
                categoryP: {id: p.categoryIdP, parentId: p.categoryParentIdP, text: p.categoryTextP},
                brandP: p.brandP,
                vendoreCodeP: p.vendoreCodeP,
                quantityP: p.quantityP,
                pricePurchaseP: p.pricePurchaseP,
                priceDropP: p.priceDropP,
                descriptionP: p.descriptionP,
                attributesP: p.attributesP,
                inStockP: checkInStock(p.inStockP),
                isOnP: checkIsOn(p.isOnP),
                isModeratedP: p.isModeratedP,
                imagesUrlP: p.imagesUrlP,
                isNewP: p.isNewP,
                isTopP: p.isTopP,
                isPriceP: p.isPriceP,
                isNewProduct: p.isNewProduct,
                isLowerPrice: p.isLowerPrice
            });
        }else if (this.props.isCreate){

        }
    }
    // componentWillUnmount() {
    //     this._isMounted = false;
    // }
    /*** ================================================================================
     *          Data Base
     * ================================================================================*/
    getCategories(){
        try {
            fire.firestore().collection("categories")
                .get()
                .then(querySnapshot => {
                    const data = [];
                    querySnapshot.docs.forEach(d => {
                        data.push({
                            idDB: d.id,
                            ...d.data()
                        })
                    });
                    data.sort(function(a,b){
                        return new Date(b.id) - new Date(a.id);
                    });
                    if (data.length !== this.state.categories.length){
                        this.setState({ categories: data });
                        this.setState({ categoriesRender: data });
                    }
                });
        }catch (e) {
            console.log(e);
        }
    };
    saveNewProduct = () => {
        if (this.state.nameP === '' || this.state.categoryP.text === '' || this.state.brandP === '' || this.state.vendoreCodeP === '' || this.state.quantityP === '' || this.state.priceDropP === 0 || this.state.descriptionP === ''){
            alert('Заполните все поля!');
            return;
        }
        try {
            const product = {
                ownerEmailP: localStorage.getItem('email'),
                ownerUIDP: localStorage.getItem('uid'),
                nameP: this.state.nameP,
                categoryIdP: this.state.categoryP.id,
                categoryParentIdP: this.state.categoryP.parentId,
                categoryTextP: this.state.categoryP.text,
                currencyP: "UAH",
                brandP: this.state.brandP,
                offerIdP: 'postID',
                vendoreCodeP: this.state.vendoreCodeP,
                quantityP: this.state.quantityP,
                pricePurchaseP: this.state.pricePurchaseP,
                priceDropP: this.state.priceDropP,
                priceSellingP: this.state.priceDropP,
                descriptionP:this.state.descriptionP,
                attributesP: this.state.attributesP,
                offerAvailableP: false,
                isOnP: false,
                inStockP: checkInStock(this.state.inStockP),
                isModeratedP: true,
                otherP: "",
                imagesUrlP: this.state.imagesUrlP,
                dateP: new Date().getTime(),
                isNewP: this.state.isNewP,
                isTopP: this.state.isTopP,
                isPriceP: this.state.isPriceP,
            };
            fire.firestore().collection('products').add(product).then( r => {
                sendMessageTelegramBotWithSettings("NEW_PRODUCT", {
                    product: product,
                });
                calcSummsAndQuantity(localStorage.getItem('uid'));
                calcSummsAndQuantity(this.props.adminSettings.uid);
                this.props.refreshPage();
                this.props.clickChangeCreateProduct();
                this.clearState();
            });
        }catch (e) {
            console.log(e);
        }
    };
    deleteProduct(p) {
        try {
            console.log(p)
            fire.firestore().collection('products').doc(p.idP).delete().then(r => {
                // Delete from XML
                deleteProductFromXML().then( r => {

                });

                sendMessageTelegramBotWithSettings("DELETE_PRODUCT", {
                    nameP: this.state.nameP,
                    categoryTextP: this.state.categoryP.text,
                    priceDropP: this.state.priceDropP,
                });
                calcSummsAndQuantity(localStorage.getItem('uid'));
                calcSummsAndQuantity(this.props.currentChangeCreateProduct.ownerUIDP);
                this.props.refreshPage();
                this.props.clickChangeCreateProduct();
                this.clearState();
            }).catch(function(error) {
                console.error("Error removing document: ", error);
            });
        }catch (e) {
            console.log(e);
        }
    };
    changeProduct = () => {
        if (this.state.nameP === '' || this.state.categoryP.text === '' || this.state.brandP === '' || this.state.quantityP === '' || this.state.priceDropP === 0 || this.state.descriptionP === ''){
            alert('Заполните все поля!');
            return;
        }
        try {
            let categoryParentIdP = '';
            if (this.state.categoryP.parentId === undefined){
                categoryParentIdP = '';
            }else {
                categoryParentIdP = this.state.categoryP.parentId
            }
            const product = {
                nameP: this.state.nameP,
                categoryIdP: this.state.categoryP.id,
                categoryParentIdP: categoryParentIdP,
                categoryTextP: this.state.categoryP.text,
                currencyP: "UAH",
                brandP: this.state.brandP,
                offerIdP: this.state.currentChangeProduct.offerIdP,
                vendoreCodeP: this.state.vendoreCodeP,
                isVendorCodeValid: true,
                quantityP: this.state.quantityP,
                pricePurchaseP: this.state.pricePurchaseP,
                priceDropP: this.state.priceDropP,
                priceSellingP: this.state.priceDropP,
                descriptionP:this.state.descriptionP,
                attributesP: this.state.attributesP,
                offerAvailableP: checkIsOnRevers(this.state.isOnP),
                isOnP: checkIsOnRevers(this.state.isOnP),
                inStockP: checkInStockReverse(this.state.inStockP),
                isModeratedP: checkIsOnRevers(this.state.isOnP),
                otherP: "",
                imagesUrlP: this.state.imagesUrlP,
                dateP: new Date().getTime(),
                isNewP: this.state.isNewP,
                isTopP: this.state.isTopP,
                isPriceP: this.state.isPriceP,
            };
            fire.firestore().collection('products').doc(this.state.currentChangeProduct.idP).update(product).then(r => {
                sendMessageTelegramBotWithSettings("CHANGE_PRODUCT", {
                    product: {ownerUIDP: this.props.currentChangeCreateProduct.ownerUIDP, ...product,}
                });
                calcSummsAndQuantity(localStorage.getItem('uid'));
                calcSummsAndQuantity(this.props.currentChangeCreateProduct.ownerUIDP);
                this.props.refreshPage();
                this.props.clickChangeCreateProduct();
                this.clearState();
            });
        }catch (e) {
            console.log(e);
        }
    };
    clearState(){
        this.setState({
            nameP: '',
            categoryP: '',
            brandP: '',
            vendoreCodeP: '',
            quantityP: 0,
            pricePurchaseP: '',
            priceDropP: '',
            priceSellingP: '',
            descriptionP: '',
            attributesP: [],
            inStockP: false,
            isOnP: false,
            isModeratedP: false,
            otherP: '',
            imagesUrlP: [],
            dateP: '',
            isNewP: false,
            isTopP: false,
            isPriceP: false,
        });
    }
    /*** ================================================================================
     *          Handle Add product
     * ================================================================================*/
    handleDeleteImage (p) {
        var imagesUrlP = this.state.imagesUrlP;
        imagesUrlP.splice(imagesUrlP.indexOf(p), 1);
        this.setState({imagesUrlP: imagesUrlP});
    };
    handleUpload = e =>{
        if (e.target.files[0]) {
            this.setState({image: e.target.files[0]});
            var file = e.target.files[0];
            console.log(file);
            var imagesUrlP = this.state.imagesUrlP;
            var storageRef = fire.storage().ref();
            //В админ айди нужно будет вставить индетификатор того кто загружает файлы
            // + время, что бы сделать изображение очень уникальным
            var path = 'images/AdminID' + localStorage.getItem('uid') + file.name;
            const uploadTask = storageRef.child(path).put(file);
            uploadTask.on('state_changed',
                (snapshot) => {
                    // progrss function ....
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                    this.setState({progress: progress});
                },
                (error) => {
                    // error function ....
                    console.log(error);
                },
                () => {
                    // complete function ....
                    storageRef.child(path).getDownloadURL().then(url => {
                        console.log(url)
                        imagesUrlP.push(url);
                        this.setState({imagesUrlP: imagesUrlP});
                    })
                });
        }
    };
    updateNameP = e => {
        this.setState({nameP: e.target.value});
    };
    updateCategoryP = e => {
        this.setState({categoryP: e.target.value})
    };
    updateBrandP = e => {
        this.setState({brandP: e.target.value})
    };
    updateVendoreCodeP = e => {
        fire.firestore()
            .collection('products')
            .where('vendoreCodeP', '==', e.target.value - 0)
            .get()
            .then(snapshot => {
                this.setState({
                    isVendorCodeValid: snapshot.size > 0
                });
            });
        this.setState({vendoreCodeP: e.target.value })
    };
    updateQuantityP = e => {
        this.setState({quantityP: e.target.value})
    };
    updatePricePurchaseP = e => {
        this.setState({pricePurchaseP: e.target.value})
    };
    updatePriceDropP = e => {
        this.setState({priceDropP: e.target.value})
    };
    updatePriceSellingP = e => {
        this.setState({priceSellingP: e.target.value})
    };
    updateIsNewProduct = e => {
        this.setState({isNewP: e.target.checked})
    };
    updateIsTopProduct = e => {
        this.setState({isTopP: e.target.checked})
    };
    updateIsLowerPrice = e => {
        this.setState({isPriceP: e.target.checked})
    };
    updateDescriptionP = e => {
        this.setState({descriptionP: e.target.value})
    };
    updateAttributesP = e => {
        this.setState({attributesP: e.target.value})
    };
    updateInStockP = e => {
        this.setState({inStockP: e.value})
    };
    updateIsOnP = e => {
        this.setState({isOnP: e.value})
    };
    updateNameAttributesP = e => {
        this.setState({nameAtribute: e.target.value})
    };
    updateTextAttributesP = e => {
        this.setState({textAtribute: e.target.value})
    };
    addAtribute(){
        var attrs = this.state.attributesP;
        attrs.push({name: this.state.nameAtribute, text: this.state.textAtribute});
        this.setState({nameAtribute: '', textAtribute: ''});
    };
    removeAtribute = (a) =>{
        var atributes = this.state.attributesP;
        var newArray =[];
        atributes.forEach(atr => {
            if (atr.name + atr.text !== a.name + a.text ){
                newArray.push(atr);
            }
        });
        this.setState({attributesP: newArray});
    };
    /*** ================================================================================
     *          Categories Add Product
     * ================================================================================*/
    handleSearchCat = e => {
        try {
            var newArray = this.state.categories.filter(function (el) {
                return el.text.includes(e.target.value) ||
                    el.id.toString().includes(e.target.value) ||
                    el.parentId.toString().includes(e.target.value);
            });
            this.setState({categoriesRender: newArray});
        }catch (e) {
            console.log(e);
        }
    };
    chooseCatInAddProduct(c){
        this.setState({categoryP: c});
    };
    /*** ================================================================================
     *          News In Stock
     * ================================================================================*/
    handleHeaderNewsInStock = e => {
        this.setState({headerNewsInStock: e.target.value});
    };
    handleTextNewsInStock = e => {
        this.setState({textNewsInStock: e.target.value});
    };
    sendNewsInStock(){
        if (this.state.headerNewsInStock !== '' || this.state.textNewsInStock !== ''){
            try {
                fire.firestore().collection("newsStock").add({
                    date: new Date().getTime(),
                    dateForParse: new Date().getTime(),
                    headerNewsInStock: this.state.headerNewsInStock,
                    textNewsInStock: this.state.textNewsInStock,
                    currentProduct: this.state.currentChangeProduct,
                }).then(q => {
                    sendMessageTelegramBotWithSettings("NEWS_IN_STOCK", {
                        nameP: this.state.currentChangeProduct.nameP,
                        categoryTextP: this.state.currentChangeProduct.categoryTextP,
                        priceDropP: this.state.currentChangeProduct.priceDropP,
                        headerNewsInStock: this.state.headerNewsInStock,
                        textNewsInStock: this.state.textNewsInStock,
                    });
                    this.setState({headerNewsInStock: '', textNewsInStock: ''});
                    this.props.clickChangeCreateProduct();
                }).catch(e => {
                    console.log(e);
                })
            }catch (e) {
                console.log(e);
            }
        }else {
            alert('Заполните все поля!')
        }
    }
    /*** ================================================================================
     *          Default Render
     * ================================================================================*/
    render() {
        return (
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                show={this.props.isChangeCreateProductClick}
                onHide={() => this.props.clickChangeCreateProduct()}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {getHeaderModal(this.props.isCreate, this.props.isChange)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={12} lg={6}>
                            <Row>
                                <Col md={12}>
                                    <Form.Group controlId="1">
                                        <Form.Label>Название:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Введите название"
                                            onChange={this.updateNameP}
                                            value={this.state.nameP}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Form.Group controlId="3">
                                        <Form.Label>Бренд:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Введите бренд товара"
                                            onChange={this.updateBrandP}
                                            value={this.state.brandP}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Form.Group controlId="vendorCode">
                                        <Form.Label>Артикул:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Введите артикул для товара"
                                            onChange={this.updateVendoreCodeP}
                                            value={this.state.vendoreCodeP}
                                            isInvalid={this.state.isVendorCodeValid}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Form.Group controlId="5">
                                        <Form.Label>Цена без комиссии:</Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="Введите цену без комиссии платформы"
                                            onChange={this.updatePricePurchaseP}
                                            value={this.state.pricePurchaseP}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Form.Group controlId="5">
                                        <Form.Label>Цена:</Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="Введите цену товара"
                                            onChange={this.updatePriceDropP}
                                            value={this.state.priceDropP}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Form.Group controlId="5">
                                        <Form.Label>{'Доход платформы от продажи: ' + (this.state.priceDropP - this.state.pricePurchaseP)}</Form.Label>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <CustomInput
                                        id="Check1"
                                        type="checkbox"
                                        onChange={this.updateIsNewProduct}
                                        checked={this.state.isNewP}
                                        label={'Новый товар:'}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <CustomInput
                                        id="Check2"
                                        type="checkbox"
                                        onChange={this.updateIsTopProduct}
                                        checked={this.state.isTopP}
                                        label={'Топ товар:'}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <CustomInput
                                        id="Check3"
                                        type="checkbox"
                                        onChange={this.updateIsLowerPrice}
                                        checked={this.state.isPriceP}
                                        label={'Цена снижена:'}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Form.Group controlId="6">
                                        <Form.Label>Количество:</Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="Укажите количество товара"
                                            onChange={this.updateQuantityP}
                                            value={this.state.quantityP}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Form.Group controlId="formGroupCount">
                                        <Form.Label>Наличие:</Form.Label>
                                        <Select
                                            value={ {value: this.state.inStockP, label: this.state.inStockP} }
                                            onChange={this.updateInStockP}
                                            options={[
                                                { value: 'В наличии', label: 'В наличии' },
                                                { value: 'Нет в наличии', label: 'Нет в наличии' },
                                            ]}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            {(localStorage.getItem('position') === 'Админ') && (
                                <Row>
                                    <Col md={12}>
                                        <Form.Group controlId="isOn">
                                            <Form.Label>Включен:</Form.Label>
                                            <Select
                                                value={ {value: this.state.isOnP, label: this.state.isOnP} }
                                                onChange={this.updateIsOnP}
                                                options={[
                                                    { value: 'Включен', label: 'Включен' },
                                                    { value: 'Выключен', label: 'Выключен' },
                                                ]}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            )}
                        </Col>
                        <Col sm={12} lg={6}>
                            {this.props.isChange && (
                                <Row>
                                    <Col md={12}>
                                        <Form.Group controlId="addNews">
                                            <Form.Label>Добавить новость о наличии товара:</Form.Label>
                                            <UncontrolledDropdown style={{display: 'block', width: '100%',}}>
                                                <DropdownToggle className="mb-2 mr-2" color="primary" style={{display: 'block', width: '100%',}}>
                                                    Добавить новость
                                                </DropdownToggle>
                                                <DropdownMenu right className="dropdown-menu-xl rm-pointers" style={{padding: '0', }}>
                                                    <Card style={{marginTop: 15}}>
                                                        <Card.Body md={12}>
                                                            <Form.Group controlId="summBack">
                                                                <Form.Label>Заголовок</Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Введите заголовок"
                                                                    onChange={this.handleHeaderNewsInStock}
                                                                    value={this.state.headerNewsInStock}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                                                <Form.Label>Текст</Form.Label>
                                                                <Form.Control
                                                                    as="textarea"
                                                                    rows="5"
                                                                    placeholder="Введите текст"
                                                                    onChange={this.handleTextNewsInStock}
                                                                    value={this.state.textNewsInStock}
                                                                />
                                                            </Form.Group>
                                                        </Card.Body>
                                                        <Card.Footer>
                                                            <Button style={{margin: 10}} color="primary" onClick={() => this.sendNewsInStock()}>Отправить</Button>
                                                        </Card.Footer>
                                                    </Card>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            )}
                            <Row>
                                <Col md={12}>
                                    <Form.Group controlId="7">
                                        <Form.Label>Описание:</Form.Label>
                                        <Form.Control
                                            placeholder="Введите описание товара"
                                            as="textarea" rows="5"
                                            onChange={this.updateDescriptionP}
                                            value={this.state.descriptionP}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Form.Group controlId="7">
                                        <Form.Label>{this.state.categoryP.text ?
                                            <b>{this.state.categoryP.text}</b>
                                            : 'Выбирете категорию для товара:'}
                                        </Form.Label>
                                        <UncontrolledDropdown style={{display: 'block', width: '100%',}}>
                                            <DropdownToggle className="mb-2 mr-2" color="primary" style={{display: 'block', width: '100%',}}>
                                                {this.state.categoryP.text ? 'Изменить категорию':'Выбрать категорию'}
                                            </DropdownToggle>
                                            <DropdownMenu right className="dropdown-menu-xl rm-pointers" style={{padding: '0', }}>
                                                <ReactTable
                                                    previousText={'Предыдущая страница'}
                                                    nextText={'Следующая страница'}
                                                    pageText='Страница'
                                                    ofText='из'
                                                    rowsText='строк'
                                                    data={this.state.categoriesRender}
                                                    columns={[
                                                        {
                                                            Header: (
                                                                <InputGroup>
                                                                    <Input
                                                                        placeholder="Поиск категории"
                                                                        onChange={this.handleSearchCat}
                                                                    />
                                                                </InputGroup>
                                                            ),
                                                            columns: [
                                                                {
                                                                    Header: "Название",
                                                                    id: "text",
                                                                    minWidth: 200,
                                                                    maxWidth: 500,
                                                                    accessor: d => (
                                                                        <div style={{textAlign: 'top'}}>
                                                                            {d.text}
                                                                        </div>
                                                                    )
                                                                },
                                                                {
                                                                    Header: "ID",
                                                                    id: "id",
                                                                    minWidth: 100,
                                                                    maxWidth: 300,
                                                                    accessor: d => (
                                                                        <div style={{textAlign: 'top'}}>
                                                                            {d.id}
                                                                        </div>
                                                                    )
                                                                },
                                                                {
                                                                    Header: "Родительский ID",
                                                                    id: "parentId",
                                                                    minWidth: 100,
                                                                    maxWidth: 300,
                                                                    accessor: d => (
                                                                        <div style={{textAlign: 'top'}}>
                                                                            {d.parentId}
                                                                        </div>
                                                                    )
                                                                },
                                                                {
                                                                    Header: "Действия",
                                                                    id: "doing",
                                                                    maxWidth: 200,
                                                                    minWidth: 200,
                                                                    accessor: d => (
                                                                        <div style={{textAlign: 'top'}}>
                                                                            <Button
                                                                                className="mb-2 mr-2"
                                                                                color="primary"
                                                                                onClick={() => this.chooseCatInAddProduct(d)}
                                                                                style={{width: '100%'}}
                                                                            >
                                                                                Выбрать
                                                                            </Button>
                                                                        </div>
                                                                    )
                                                                },
                                                            ]}
                                                    ]}
                                                    defaultPageSize={5}
                                                    className="-striped -highlight"
                                                />
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Form.Group>
                                        <Form.Label>Добавить атрибут:</Form.Label>
                                        <Form.Control
                                            placeholder="Введите заголовок атрибута"
                                            type="text"
                                            onChange={this.updateNameAttributesP}
                                            value={this.state.nameAtribute}
                                        />
                                        <Form.Control
                                            style={{marginTop: 5}}
                                            placeholder="Введите текст атрибута"
                                            type="text"
                                            onChange={this.updateTextAttributesP}
                                            value={this.state.textAtribute}
                                        />
                                        <Button
                                            color="primary"
                                            onClick={() => this.addAtribute()}
                                            style={{display: 'block', width: '100%', marginTop: 5}}
                                        >
                                            Добавить атрибут
                                        </Button>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    {this.state.attributesP.map((a, index) =>
                                        <div className="widget-content p-0" key={index}>
                                            <div className="widget-content-wrapper">
                                                <div className="widget-content-left">
                                                    <div className="widget-heading">
                                                        {a.name}
                                                    </div>
                                                    <div className="widget-subheading">
                                                        {a.text}
                                                    </div>
                                                </div>
                                                <div className="widget-content-right">
                                                    <Button color="primary" onClick={() => this.removeAtribute(a)}>
                                                        Удалить
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {/*Photo*/}
                    <Row>
                        <Col md={12}>
                            {(this.state.imagesUrlP.length <= 7) && (
                                <div className="Landing-File-Add-Container Landing-Margin-1vh">
                                    <Form.Label>Добавить фото:</Form.Label>
                                    {/*<img src={addImage} alt="addImage" className="Landing-File-Add-Image" style={{height: '3rem'}}/>*/}
                                    <FormControl
                                        id="fileUpload"
                                        type="file"
                                        accept=".jpg"
                                        onChange={this.handleUpload}
                                        color="primary"
                                    />
                                </div>
                            )}
                            <Row style={{paddingTop: 10, display: 'inline-block'}}>
                                {this.state.imagesUrlP.map( (p, index) =>
                                    <div key={index} style={{padding: 10}} className="ProductWindow-Image-Adding" >
                                        <Image className="Landing-Margin-1vh " src={p} thumbnail style={{height: '20vh'}}/>
                                        <div className="ProductWindow-Image-Adding-OnHover"  onClick={() => this.handleDeleteImage(p)}>Удалить</div>
                                    </div>
                                )}
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {(this.state.progress !== 100) && (
                                <div>
                                    {(this.state.progress !== 0) && (
                                        <ProgressBar className="Landing-Margin-1vh" animated striped variant="info" now={this.state.progress} />
                                    )}
                                </div>
                            )}
                        </Col>
                    </Row>
                </Modal.Body>
                {this.props.isCreate && (
                    <Modal.Footer>
                        <Button color="primary" onClick={this.saveNewProduct}>Отправить</Button>
                        <Button color="primary" onClick={() => this.props.clickChangeCreateProduct()}>Закрыть</Button>
                    </Modal.Footer>
                )}
                {this.props.isChange && (
                    <Modal.Footer>
                        <Button color="primary" onClick={this.changeProduct}>Сохранить</Button>
                        <Button color="primary" onClick={() => this.deleteProduct(this.state.currentChangeProduct)}>Удалить</Button>
                        <Button color="primary" onClick={() => this.props.clickChangeCreateProduct()}>Закрыть</Button>
                    </Modal.Footer>
                )}
            </Modal>
        )
    }
}
const mapStateToProps = state => {
    return {
        basketArray: state.basket,
        user: state.basket.user,
        adminSettings: state.basket.adminSettings
    }
};

const mapDispatchToProps = {
    setAdmin,
    clearAdmin,
    setUser,
    clearUser,
    addProduct,
    removeProduct,
    removeAll,
    changeProduct
};
export default connect(mapStateToProps, mapDispatchToProps)(App);