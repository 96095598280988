import React from 'react';
import '../products/Products.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import JoditEditor from "jodit-react";
import 'jodit';
import 'jodit/build/jodit.min.css';
import {
    getAdminRules,
    getAdminSettings,
    getDataFromCollectionWhereKeyValue, setDocumentToCollection,
    updateDocumentInCollection
} from '../firebase/Firebase';
import {Card, CardBody, CardHeader, CardFooter, CardTitle, CustomInput, FormGroup, ButtonGroup, Button} from "reactstrap";
import {Col, Row} from "react-bootstrap";
import {setAdmin, clearAdmin, addProduct, changeProduct, clearUser, removeAll, removeProduct, setUser} from "../storeReducers/basket/actions";
import {connect} from "react-redux";



class App extends React.Component {
    /*** ================================================================================
     *          Main methods
     * ================================================================================*/
    constructor (props, context) {
        super(props, context);
        this.state = {
            text: '',
            isAdmin: false,
        };
        this.getText = this.getText.bind(this);
    }
    componentDidMount() {
        if (localStorage.getItem('position') === 'Админ'){
            this.setState({isAdmin: true});
        }else {
            this.setState({isAdmin: false});
        }
        this.getText();
    }
    /*** ================================================================================
     *          Handle And Save
     * ================================================================================*/
    jodit;
    setRef = jodit => this.jodit = jodit;
    handleText(e){
        // updateDocumentInCollection("adminSettings", 'adminRules', {
        //     rules: e
        // }).then( r => {
        //     this.setState({text: e});
        // });
        this.setState({text: e});
    };
    /*** ================================================================================
     *          DataBase
     * ================================================================================*/
    // Get Text
    getText(){
        getAdminRules().then( res => {
            this.setState({text: res});
        })
    }
    /*** ================================================================================
     *          DEFAULT RENDER
     * ================================================================================*/
    render() {
        // const [content, setContent] = useState('')
        return (
            <Row>
                <Col md={12}>
                    <Card style={{minHeight: '100vh'}}>
                        <CardHeader>Правила</CardHeader>
                        <CardBody>
                            <div dangerouslySetInnerHTML={{ __html: this.state.text }} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )}
}
const mapStateToProps = state => {
    return {
        basketArray: state.basket,
        user: state.basket.user,
        adminSettings: state.basket.adminSettings
    }
};

const mapDispatchToProps = {
    setAdmin,
    clearAdmin,
    setUser,
    clearUser,
    addProduct,
    removeProduct,
    removeAll,
    changeProduct
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
