import React from "react";

export default class App extends React.Component{
    render() {
        const array = [];
        for (let i = 0; i < 100; i++) {
            array.push(<div key={i} className="Landing-Container-Fragment1-Man-Dot"></div>)
        }
        return (
            <div>
                {array.map(a => (a))}
            </div>
        )
    }
}