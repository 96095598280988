import React from 'react';
import '../products/TableProductsRealTime.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Tabs, Tab, Button, Form} from 'react-bootstrap';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {
    calcAllUsers,
    filterOrdersBack,
    filterOrdersFinished,
    filterOrdersHold,
    fire, getDataFromCollection, getDataFromCollectionWhereKeyValue,
    getUserJson,
    summInArray, updateDocumentInCollection
} from '../firebase/Firebase';
import {Card, CardBody, CardTitle, Col, Row} from "reactstrap";
import CountUp from "react-countup/build";
import {Pie} from "react-chartjs-2";

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import ReactTable from "react-table";


import Moment from 'react-moment';
import * as moment from 'moment';
import 'moment-timezone';
import CardsStatistic from "./CardsStatistic";



const styleCell = {overflow: "hidden",fontFamily: "Pe-icon-7-stroke", fontWeight: "400",};
const styleCellIn = {fontFamily: "Pe-icon-7-stroke", fontWeight: "550",};

function momentToString(mom){
    return (
        (mom.get('year')) + '-' +
        (mom.get('month') + 1) + '-' +
        (mom.get('date'))
    )
}


class App extends React.Component {
    /*** ================================================================================
     *          Main
     * ================================================================================*/
    componentDidMount() {
        this.setState({pickerStartDate: (moment(new Date()).subtract(7, 'days'))});
        this.setState({pickerEndDate: moment(new Date())});
        this.getUser();
        this.getOrders();
        this.getReferal();
    }
    constructor (props, context) {
        super(props, context);
        this.state = {
            // DB Array
            users: [],
            ordersAll: [],
            ordersPartner: [],
            ordersProvider: [],
            ordersFiltredPartner: [],
            ordersFiltredProvider: [],
            rowsInTable: 8,
            statistic: [],

            pickerStartDate: (moment(new Date()).subtract(1, 'months')),
            pickerEndDate: moment(new Date()),
            dateArray: [moment(new Date())],

            currentUser: getUserJson(),
            // Referal
            summReferalUsers: 0,
            referalSummFinished: 0,
            referaSummlHold: 0,
            referalQuantityFinished: 0,
            referalQuantityHold: 0,
        };
        this.handlePickerApply = this.handlePickerApply.bind(this);
        this.handlePicker = this.handlePicker.bind(this);
        this.createArrayOfDates = this.createArrayOfDates.bind(this);
    }
    /*** ================================================================================
     *          Data Base
     * ================================================================================*/
    getUser(){
        try {
            fire.firestore().collection("usders").where("uid", "==", localStorage.getItem('uid'))
                .get()
                .then(q => {
                    // console.log(q.docs[0].data() );
                    this.setState({ currentUser: q.docs[0].data() });
                });
        }catch (e) {
            console.log(e);
        }
    };
    getOrders () {
        getDataFromCollection("orders").then(o => {
            const ordersAll = o.data;
            const ordersPartner = ordersAll.filter(function (el) {
                return el.fromUID === localStorage.getItem('uid');
            });
            const ordersProvider = ordersAll.filter(function (el) {
                return el.ownerUIDP === localStorage.getItem('uid');
            });
            // console.log(ordersPartner);
            // console.log(ordersProvider);
            this.setState({
                // ordersAll: ordersAll,
                ordersPartner: ordersPartner,
                ordersProvider: ordersProvider,
            });
            this.createArrayOfDates(moment(new Date()).subtract(1, 'months'), moment(new Date())).then( r => {
                this.handlePickerApply(r.dateArray, r.rowsInTable);
            });
        });
    };
    getReferal() {
        getDataFromCollectionWhereKeyValue("usders", "referal", localStorage.getItem('uid')).then( referal => {
            const users = referal.data;
            // console.log(users);
            var referalSummFinished = 0;
            var referaSummlHold = 0;
            var referalQuantityFinished = 0;
            var referalQuantityHold = 0;
            users.forEach( u => {
                referalSummFinished = referalSummFinished + u.referalSummFinished;
                referaSummlHold = referaSummlHold + u.referaSummlHold;
                referalQuantityFinished = referalQuantityFinished + u.referalQuantityFinished;
                referalQuantityHold = referalQuantityHold + u.referalQuantityHold;
            });
            this.setState({
                summReferalUsers: users.length,
                referalSummFinished: referalSummFinished,
                referaSummlHold: referaSummlHold,
                referalQuantityFinished: referalQuantityFinished,
                referalQuantityHold: referalQuantityHold,
            })
        });
    }
    /*** ================================================================================
     *          Handle Date
     * ================================================================================*/
    handlePicker(event, picker) {
        this.createArrayOfDates(moment(picker.startDate), moment(picker.endDate) ).then( r => {
            this.handlePickerApply(r.dateArray, r.rowsInTable);
        });
    }
    createArrayOfDates(sDate, eDate){
        return new Promise(function (resolve, reject) {
            const dateArray = [];
            var rowsInTable = 50;
            while (momentToString(sDate) !== momentToString(eDate)){
                dateArray.push(momentToString(eDate));
                eDate.subtract(1, 'days');
            }
            dateArray.push(momentToString(sDate));
            if (dateArray.length <= 50){
                rowsInTable = dateArray.length;
            }else {
                rowsInTable = 50;
            }
            resolve({dateArray : dateArray, rowsInTable: rowsInTable});
        });
    }
    handlePickerApply(dateArray, rowsInTable){
        var resultArrayPartner = [];
        var resultArrayProvider = [];
        dateArray.forEach(d => {
            var resultPartner = {
                day: '',
                moment: null,
                summHold: 0,
                summFinished: 0,
                summBack: 0,
                quantityOrders: 0,
                quantityHold: 0,
                quantityFinished: 0,
                quantityBack: 0,
            };
            var resultProvider = {
                day: '',
                moment: null,
                summHold: 0,
                summFinished: 0,
                summBack: 0,
                quantityOrders: 0,
                quantityHold: 0,
                quantityFinished: 0,
                quantityBack: 0,
            };
            var dataPartner = this.state.ordersPartner.filter(function (el) {
                return momentToString(moment(el.date)) === (d);
            });
            resultPartner.day = d;
            resultPartner.quantityOrders = dataPartner.length;
            var finishOrdersPartner = filterOrdersFinished(dataPartner);
            var holdOrdersPartner = filterOrdersHold(dataPartner);
            var backOrdersPartner = filterOrdersBack(dataPartner);
            var summFinishedPartner = summInArray(finishOrdersPartner, 'summClient');
            resultPartner.summFinished = summFinishedPartner;
            resultPartner.quantityFinished = finishOrdersPartner.length;
            var summHoldPartner = summInArray(holdOrdersPartner, 'summClient');
            resultPartner.summHold = summHoldPartner;
            resultPartner.quantityHold = holdOrdersPartner.length;
            var summBackPartner = summInArray(backOrdersPartner, 'summBack');
            resultPartner.summBack = summBackPartner;
            resultPartner.quantityBack = backOrdersPartner.length;
            resultArrayPartner.push(resultPartner);
            //
            var dataProvider = this.state.ordersProvider.filter(function (el) {
                return momentToString(moment(el.date)) === (d);
            });
            resultProvider.day = d;
            resultProvider.quantityOrders = dataProvider.length;
            var finishOrdersProvider = filterOrdersFinished(dataProvider);
            var holdOrdersProvider = filterOrdersHold(dataProvider);
            var backOrdersProvider = filterOrdersBack(dataProvider);
            var summFinishedProvider = summInArray(finishOrdersProvider, 'summPurchase');
            resultProvider.summFinished = summFinishedProvider;
            resultProvider.quantityFinished = finishOrdersProvider.length;
            var summHoldProvider = summInArray(holdOrdersProvider, 'summPurchase');
            resultProvider.summHold = summHoldProvider;
            resultProvider.quantityHold = holdOrdersProvider.length;
            var summBackProvider = summInArray(backOrdersProvider, 'summBack');
            resultProvider.summBack = summBackProvider;
            resultProvider.quantityBack = backOrdersProvider.length;
            resultArrayProvider.push(resultProvider);
        });
        this.setState({
            ordersFiltredPartner: resultArrayPartner,
            ordersFiltredProvider: resultArrayProvider,
            rowsInTable: rowsInTable,
        });
        // console.log(resultArray);
    }
    forWeek(){
        this.createArrayOfDates(moment(new Date()).subtract(7, 'days'), moment(new Date())).then( r => {
            this.handlePickerApply(r.dateArray, r.rowsInTable);
        });
    }
    forMonth(){
        this.createArrayOfDates(moment(new Date()).subtract(1, 'months'), moment(new Date())).then( r => {
            this.handlePickerApply(r.dateArray, r.rowsInTable);
        });
    }
    forYear(){
        this.createArrayOfDates(moment(new Date()).subtract(1, 'years'), moment(new Date())).then( r => {
            this.handlePickerApply(r.dateArray, r.rowsInTable);
        });
    }
    /*** ================================================================================
     *          Else Methods
     * ================================================================================*/
    isVerifiedElement(bool, text){
        if (text === undefined){
            text = "Не указан"
        }
        if (bool){
            return (
                <div className="pe-7s-check" style={styleCellIn}>
                    {text}
                </div>
            )
        }else {
            return (
                <div className="pe-7s-attention" style={styleCellIn}>
                    {text}
                </div>
            )
        }
    }
    isActiveAccount(bool){
        if (bool){
            return (
                <div className="pe-7s-check" style={styleCellIn}>
                    Активен
                </div>
            )
        }else {
            return (
                <div className="pe-7s-attention" style={styleCellIn}>
                    Не активен
                </div>
            )
        }
    }

    calcMidleValue(x, y){
        if (parseInt(parseInt(x)/parseInt(y)).toString() === 'NaN'){
            return '0'
        }else {
            return parseInt(parseInt(x)/parseInt(y)).toString();
        }
    }
    calcMidleValuePercent(x, y){
        if (parseInt((parseInt(x)/parseInt(y))*100).toString() === 'NaN'){
            return '0'
        }else {
            return parseInt((parseInt(x)/parseInt(y))*100).toString();
        }
    }
    calcDiagrammQuantity(){
        var data = {
            labels: [
                'Заявки',
                'Забрано',
                'Отказы'
            ],
            datasets: [{
                data: [
                    this.state.currentUser.quantityHold,
                    this.state.currentUser.quantityFinished,
                    this.state.currentUser.quantityBack
                ],
                backgroundColor: [
                    '#8dace7',
                    '#71deb9',
                    '#ef869e'
                ],
                hoverBackgroundColor: [
                    '#8dace7',
                    '#71deb9',
                    '#ef869e'
                ]
            }]};
        return data;
    };
    calcDiagrammSumm(){
        var data = {
            labels: [
                'Заявки, грн',
                'Забрано, грн',
                'Отказы, грн'
            ],
            datasets: [{
                data: [
                    this.state.currentUser.summHold,
                    this.state.currentUser.summFinished,
                    this.state.currentUser.summBack
                ],
                backgroundColor: [
                    '#8dace7',
                    '#71deb9',
                    '#ef869e'
                ],
                hoverBackgroundColor: [
                    '#8dace7',
                    '#71deb9',
                    '#ef869e'
                ]
            }]};
        return data;
    };
    /*** ================================================================================
     *          Default Render
     * ================================================================================*/
    render() {
        return (
            <div>
                <div className="ProductWindow-Container">
                    <div className="ProductWindow-Table-Products-Container">
                        <Tabs variant="pills"  defaultActiveKey="Дропер" transition={false} id="noanim-tab-example">
                            <Tab eventKey="Дропер" title="Дропер">
                                <CardsStatistic
                                    colSize={4}
                                    dataText={[
                                        // 'кол-во заказов',
                                        // 'кол-во заказов',
                                        // 'кол-во заказов',

                                        'Завершен',
                                        'Холд',
                                        'Возврат',

                                        'Завершенные предоплата',
                                        'Холд  предоплата',
                                        'Возврат предоплата',

                                        'кол-во Завершенные предоплата',
                                        'кол-во Холд предоплата',
                                        'кол-во Возврат предоплата',

                                        'Завершенные наложка',
                                        'Холд наложка',
                                        'Возврат наложка',

                                        'кол-во Завершенные наложка',
                                        'кол-во Холд наложка',
                                        'кол-во Возврат наложка',

                                    ]}
                                    dataNumber={[
                                        // this.state.resultUsersCalc.quantityOrdersPartner,
                                        // this.state.resultUsersCalc.quantityOrdersPartner,
                                        // this.state.resultUsersCalc.quantityOrdersPartner,

                                        '₴' + this.state.currentUser.summFinishedPartner,
                                        '₴' + this.state.currentUser.summHoldPartner,
                                        '₴' + this.state.currentUser.summBackPartner,

                                        '₴' + (-this.state.currentUser.summFinishedPrepaymentPartner),
                                        '₴' + (-this.state.currentUser.summHoldPrepaymentPartner),
                                        '₴' + (-this.state.currentUser.summBackPrepaymentPartner),

                                        this.state.currentUser.quantityFinishedPrepaymentPartner,
                                        this.state.currentUser.quantityHoldPrepaymentPartner,
                                        this.state.currentUser.quantityBackPrepaymentPartner,

                                        '₴' + this.state.currentUser.summFinishedCODPartner,
                                        '₴' + this.state.currentUser.summHoldCODPartner,
                                        '₴' + this.state.currentUser.summBackCODPartner,

                                        this.state.currentUser.quantityFinishedCODPartner,
                                        this.state.currentUser.quantityHoldCODPartner,
                                        this.state.currentUser.quantityBackCODPartner,

                                    ]}
                                />
                                <Row>
                                    <Col>
                                        <DateRangePicker
                                            startDate={this.pickerStartDate}
                                            endDate={this.pickerEndDate}
                                            onEvent={this.handlePicker}
                                            onApply={this.handlePickerApply}
                                            locale={{
                                                "format": "DD/MM/YYYY",
                                                "separator": " - ",
                                                "applyLabel": "Применить",
                                                "cancelLabel": "Отмена",
                                                "fromLabel": "От",
                                                "toLabel": "До",
                                                "customRangeLabel": "Произвольно",
                                                "weekLabel": "Нед.",
                                                "daysOfWeek": [
                                                    "Вс",
                                                    "Пн",
                                                    "Вт",
                                                    "Ср",
                                                    "Чт",
                                                    "Пт",
                                                    "Сб"
                                                ],
                                                "monthNames": [
                                                    "Январь",
                                                    "Февраль",
                                                    "Март",
                                                    "Апрель",
                                                    "Май",
                                                    "Июнь",
                                                    "Июль",
                                                    "Август",
                                                    "Сентябрь",
                                                    "Октябрь",
                                                    "Ноябрь",
                                                    "Декабрь"
                                                ],
                                                "firstDay": 2
                                            }}
                                        >
                                            <Button className="mb-2 mr-2" color="primary">Выбрать диапазон времени</Button>
                                        </DateRangePicker>
                                        <Button className="mb-2 mr-2" color="primary" onClick={() => this.forWeek()}>За неделю</Button>
                                        <Button className="mb-2 mr-2" color="primary" onClick={() => this.forMonth()}>За месяц</Button>
                                        <Button className="mb-2 mr-2" color="primary" onClick={() => this.forYear()}>За год</Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <ReactTable
                                            previousText={'Предыдущая страница'}
                                            nextText={'Следующая страница'}
                                            pageText='Страница'
                                            ofText='из'
                                            rowsText='строк'
                                            data={this.state.ordersFiltredPartner}
                                            columns={[
                                                {
                                                    columns: [
                                                        {
                                                            Header: "Дата",
                                                            id: "date",
                                                            maxWidth: 200,
                                                            accessor: d => (
                                                                <div style={{overflow: 'auto', height: '100%', textAlign: 'top'}}>
                                                                    <div>
                                                                        <i className="header-icon pe-7s-date"> </i>
                                                                        {d.day}
                                                                    </div>
                                                                </div>
                                                            )
                                                        },
                                                        {
                                                            Header: "Заявок",
                                                            id: "quantityOrders",
                                                            maxWidth: 200,
                                                            accessor: d => (
                                                                <div style={{overflow: 'auto', height: '100%', textAlign: 'top'}}>
                                                                    <div>
                                                                        {d.quantityOrders}
                                                                    </div>
                                                                </div>
                                                            )
                                                        },
                                                        {
                                                            Header: "Забрано",
                                                            id: "quantityFinishedPercent",
                                                            maxWidth: 200,
                                                            accessor: d => (
                                                                <div style={{overflow: 'auto', height: '100%', textAlign: 'top'}}>
                                                                    <div>
                                                                        {this.calcMidleValuePercent(d.quantityFinished, d.quantityOrders) + '%'}
                                                                    </div>
                                                                </div>
                                                            )
                                                        },
                                                        {
                                                            Header: "Холд",
                                                            id: "quantityHold",
                                                            maxWidth: 200,
                                                            accessor: d => (
                                                                <div style={{overflow: 'auto', height: '100%', textAlign: 'top'}}>
                                                                    <div>
                                                                        {d.quantityHold}
                                                                    </div>
                                                                </div>
                                                            )
                                                        },
                                                        {
                                                            Header: "Отказ",
                                                            id: "quantityBack",
                                                            maxWidth: 200,
                                                            accessor: d => (
                                                                <div style={{overflow: 'auto', height: '100%', textAlign: 'top'}}>
                                                                    <div>
                                                                        {d.quantityBack}
                                                                    </div>
                                                                </div>
                                                            )
                                                        },
                                                        {
                                                            Header: "Завершено",
                                                            id: "quantityFinished",
                                                            maxWidth: 200,
                                                            accessor: d => (
                                                                <div style={{overflow: 'auto', height: '100%', textAlign: 'top'}}>
                                                                    <div>
                                                                        {d.quantityFinished}
                                                                    </div>
                                                                </div>
                                                            )
                                                        },
                                                        {
                                                            Header: "Холд, грн",
                                                            id: "summHold",
                                                            maxWidth: 200,
                                                            accessor: d => (
                                                                <div style={{overflow: 'auto', height: '100%', textAlign: 'top'}}>
                                                                    <div>
                                                                        {d.summHold}
                                                                    </div>
                                                                </div>
                                                            )
                                                        },
                                                        {
                                                            Header: "Отказ, грн",
                                                            id: "summBack",
                                                            maxWidth: 200,
                                                            accessor: d => (
                                                                <div style={{overflow: 'auto', height: '100%', textAlign: 'top'}}>
                                                                    <div>
                                                                        {d.summBack}
                                                                    </div>
                                                                </div>
                                                            )
                                                        },
                                                        {
                                                            Header: "Завершено, грн",
                                                            id: "summFinished",
                                                            maxWidth: 200,
                                                            accessor: d => (
                                                                <div style={{overflow: 'auto', height: '100%', textAlign: 'top'}}>
                                                                    <div>
                                                                        {d.summFinished}
                                                                    </div>
                                                                </div>
                                                            )
                                                        },
                                                    ]}
                                            ]}
                                            defaultPageSize={10}
                                            className="-striped -highlight"
                                        />
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab eventKey="Поставщик" title="Поставщик">
                                <CardsStatistic
                                    colSize={4}
                                    dataText={[
                                        // 'кол-во заказов',
                                        // 'кол-во заказов',
                                        // 'кол-во заказов',

                                        'Завершен',
                                        'Холд',
                                        'Возврат',

                                        'Завершен предоплата',
                                        'Холд предоплата',
                                        'Возврат предоплата',

                                        'кол-во Завершен предоплата',
                                        'кол-во Холд предоплата',
                                        'кол-во Возврат предоплата',

                                        'Завершен наложка',
                                        'Холд наложка',
                                        'Возврат наложка',

                                        'кол-во Завершен наложка',
                                        'кол-во Холд наложка',
                                        'кол-во Возврат наложка',
                                    ]}
                                    dataNumber={[
                                        // this.state.resultUsersCalc.quantityOrdersProvider,
                                        // this.state.resultUsersCalc.quantityOrdersProvider,
                                        // this.state.resultUsersCalc.quantityOrdersProvider,

                                        '₴' + this.state.currentUser.summFinishedProvider,
                                        '₴' + this.state.currentUser.summHoldProvider,
                                        '₴' + this.state.currentUser.summBackProvider,

                                        '₴' + this.state.currentUser.summFinishedPrepaymentProvider,
                                        '₴' + this.state.currentUser.summHoldPrepaymentProvider,
                                        '₴' + this.state.currentUser.summBackPrepaymentProvider,

                                        this.state.currentUser.quantityFinishedPrepaymentProvider,
                                        this.state.currentUser.quantityHoldPrepaymentProvider,
                                        this.state.currentUser.quantityBackPrepaymentProvider,

                                        '₴' + (- this.state.currentUser.summFinishedCODProvider),
                                        '₴' + (- this.state.currentUser.summHoldCODProvider),
                                        '₴' + this.state.currentUser.summBackCODProvider,

                                        this.state.currentUser.quantityFinishedCODProvider,
                                        this.state.currentUser.quantityHoldCODProvider,
                                        this.state.currentUser.quantityBackCODProvider,
                                    ]}
                                />
                                <Row>
                                    <Col>
                                        <DateRangePicker
                                            startDate={this.pickerStartDate}
                                            endDate={this.pickerEndDate}
                                            onEvent={this.handlePicker}
                                            onApply={this.handlePickerApply}
                                            locale={{
                                                "format": "DD/MM/YYYY",
                                                "separator": " - ",
                                                "applyLabel": "Применить",
                                                "cancelLabel": "Отмена",
                                                "fromLabel": "От",
                                                "toLabel": "До",
                                                "customRangeLabel": "Произвольно",
                                                "weekLabel": "Нед.",
                                                "daysOfWeek": [
                                                    "Вс",
                                                    "Пн",
                                                    "Вт",
                                                    "Ср",
                                                    "Чт",
                                                    "Пт",
                                                    "Сб"
                                                ],
                                                "monthNames": [
                                                    "Январь",
                                                    "Февраль",
                                                    "Март",
                                                    "Апрель",
                                                    "Май",
                                                    "Июнь",
                                                    "Июль",
                                                    "Август",
                                                    "Сентябрь",
                                                    "Октябрь",
                                                    "Ноябрь",
                                                    "Декабрь"
                                                ],
                                                "firstDay": 2
                                            }}
                                        >
                                            <Button className="mb-2 mr-2" color="primary">Выбрать диапазон времени</Button>
                                        </DateRangePicker>
                                        <Button className="mb-2 mr-2" color="primary" onClick={() => this.forWeek()}>За неделю</Button>
                                        <Button className="mb-2 mr-2" color="primary" onClick={() => this.forMonth()}>За месяц</Button>
                                        <Button className="mb-2 mr-2" color="primary" onClick={() => this.forYear()}>За год</Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <ReactTable
                                            previousText={'Предыдущая страница'}
                                            nextText={'Следующая страница'}
                                            pageText='Страница'
                                            ofText='из'
                                            rowsText='строк'
                                            data={this.state.ordersFiltredProvider}
                                            columns={[
                                                {
                                                    columns: [
                                                        {
                                                            Header: "Дата",
                                                            id: "date",
                                                            maxWidth: 200,
                                                            accessor: d => (
                                                                <div style={{overflow: 'auto', height: '100%', textAlign: 'top'}}>
                                                                    <div>
                                                                        <i className="header-icon pe-7s-date"> </i>
                                                                        {d.day}
                                                                    </div>
                                                                </div>
                                                            )
                                                        },
                                                        {
                                                            Header: "Заявок",
                                                            id: "quantityOrders",
                                                            maxWidth: 200,
                                                            accessor: d => (
                                                                <div style={{overflow: 'auto', height: '100%', textAlign: 'top'}}>
                                                                    <div>
                                                                        {d.quantityOrders}
                                                                    </div>
                                                                </div>
                                                            )
                                                        },
                                                        {
                                                            Header: "Забрано",
                                                            id: "quantityFinishedPercent",
                                                            maxWidth: 200,
                                                            accessor: d => (
                                                                <div style={{overflow: 'auto', height: '100%', textAlign: 'top'}}>
                                                                    <div>
                                                                        {this.calcMidleValuePercent(d.quantityFinished, d.quantityOrders) + '%'}
                                                                    </div>
                                                                </div>
                                                            )
                                                        },
                                                        {
                                                            Header: "Холд",
                                                            id: "quantityHold",
                                                            maxWidth: 200,
                                                            accessor: d => (
                                                                <div style={{overflow: 'auto', height: '100%', textAlign: 'top'}}>
                                                                    <div>
                                                                        {d.quantityHold}
                                                                    </div>
                                                                </div>
                                                            )
                                                        },
                                                        {
                                                            Header: "Отказ",
                                                            id: "quantityBack",
                                                            maxWidth: 200,
                                                            accessor: d => (
                                                                <div style={{overflow: 'auto', height: '100%', textAlign: 'top'}}>
                                                                    <div>
                                                                        {d.quantityBack}
                                                                    </div>
                                                                </div>
                                                            )
                                                        },
                                                        {
                                                            Header: "Завершено",
                                                            id: "quantityFinished",
                                                            maxWidth: 200,
                                                            accessor: d => (
                                                                <div style={{overflow: 'auto', height: '100%', textAlign: 'top'}}>
                                                                    <div>
                                                                        {d.quantityFinished}
                                                                    </div>
                                                                </div>
                                                            )
                                                        },
                                                        {
                                                            Header: "Холд, грн",
                                                            id: "summHold",
                                                            maxWidth: 200,
                                                            accessor: d => (
                                                                <div style={{overflow: 'auto', height: '100%', textAlign: 'top'}}>
                                                                    <div>
                                                                        {d.summHold}
                                                                    </div>
                                                                </div>
                                                            )
                                                        },
                                                        {
                                                            Header: "Отказ, грн",
                                                            id: "summBack",
                                                            maxWidth: 200,
                                                            accessor: d => (
                                                                <div style={{overflow: 'auto', height: '100%', textAlign: 'top'}}>
                                                                    <div>
                                                                        {d.summBack}
                                                                    </div>
                                                                </div>
                                                            )
                                                        },
                                                        {
                                                            Header: "Завершено, грн",
                                                            id: "summFinished",
                                                            maxWidth: 200,
                                                            accessor: d => (
                                                                <div style={{overflow: 'auto', height: '100%', textAlign: 'top'}}>
                                                                    <div>
                                                                        {d.summFinished}
                                                                    </div>
                                                                </div>
                                                            )
                                                        },
                                                    ]}
                                            ]}
                                            defaultPageSize={10}
                                            className="-striped -highlight"
                                        />
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab eventKey="Реферальная система" title="Реферальная система">
                                <CardsStatistic
                                    colSize={3}
                                    dataText={[
                                        'кол-во завершен',
                                        'кол-во холд',
                                        'Завершен',
                                        'Холд',
                                        'кол-во рефералов',
                                    ]}
                                    dataNumber={[
                                        this.state.referalQuantityFinished,
                                        this.state.referalQuantityHold,
                                        '₴' + this.state.referalSummFinished,
                                        '₴' + this.state.referaSummlHold,
                                        this.state.summReferalUsers,
                                    ]}
                                />
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        )}
}
export default App;
