import React from "react";
import {Button, CardBody, CardHeader, DropdownMenu, DropdownToggle, Input, UncontrolledDropdown} from "reactstrap";
import {Card, Col, InputGroup, Row} from "react-bootstrap";
import ReactTable from "react-table";
import XMLCardDropDown from "./XMLComponents/XMLCardDropDown";

export default class App extends React.Component{
    constructor (props, context) {
        super(props, context);
        this.state = {};
    }
    componentDidMount() {
    }

    render() {
        return (
            <Card className="mb-3">
                <CardBody>
                    <Row style={{width: '100%', height: '100%', display: 'inline!important', textTransform: 'none'}}>
                        <Col md={12} style={{textAlign: 'center'}}>
                            <Button
                                    style={{minWidth: '130px'}}
                                    className="mb-2 mr-2"
                                    color="primary"
                                    onClick={() => this.props.handleShowAll()}>Все товары</Button>
                            <Button
                                    style={{minWidth: '130px'}}
                                    className="mb-2 mr-2"
                                    color="success"
                                    onClick={() => this.props.handleShowNew()}>Новые товары</Button>
                            <Button
                                style={{minWidth: '130px'}}
                                className="mb-2 mr-2"
                                    color="danger"
                                    onClick={() => this.props.handleShowTop()}>Топ продаж</Button>
                            <Button
                                style={{minWidth: '130px'}}
                                className="mb-2 mr-2"
                                    color="warning"
                                    onClick={() => this.props.handleShowPrice()}>Цена снижена</Button>
                            <div style={{minWidth: '130px'}} className={'tooltip-content'}>
                                <Button
                                    style={{minWidth: '130px'}}
                                    className="mb-2 mr-2"
                                        color="primary"
                                        onClick={() => this.props.clickAddProduct()}>Добавить товар</Button>
                                <div className={'card'}>
                                        <span style={{textAlign: 'left'}}>
                                            Если вы хотите стать партнёром DropMaster, нажав на эту клавишу вы можете добавить свой товар.
                                        </span>
                                </div>
                            </div>
                            <UncontrolledDropdown style={{display: 'inline-block', minWidth: '130px'}}>
                                <DropdownToggle className="mb-2 mr-2" color="primary" style={{minWidth: '130px'}}>
                                    Товары в XML
                                </DropdownToggle>
                                <DropdownMenu right className="dropdown-menu-xl rm-pointers" style={{padding: '0', }}>
                                    <XMLCardDropDown
                                        productsXML={this.props.productsXML}
                                        productsXMLLength={this.props.productsXMLLength}
                                        deleteProductFromXML={this.props.deleteProductFromXML}
                                        xmlBuilder={this.props.xmlBuilder}
                                        xmlBuilderChoosed={this.props.xmlBuilderChoosed}
                                        clearProductXML={this.props.clearProductXML}
                                        updateXmlInDB={this.props.updateXmlInDB}
                                    />
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            {/*refresh={this.refreshPage}*/}
                            <Button
                                style={{minWidth: '130px'}}
                                className="mb-2 mr-2"
                                color="primary"
                                onClick={() => this.props.refresh()}>Обновить</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <InputGroup>
                                <Input
                                    placeholder="Поиск по товарам"
                                    onChange={(e) => this.props.handleSearch(e)}
                                />
                            </InputGroup>
                        </Col>
                        <Col md={6}>
                            <InputGroup>
                                <Input
                                    placeholder="Поиск по категориям"
                                    onChange={(e) => this.props.handleSearchByCategory(e)}
                                />
                            </InputGroup>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        )
    }
}