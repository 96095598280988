import React from "react";
import {Button} from "reactstrap";
import {Card, Col} from "react-bootstrap";
import ReactTable from "react-table";
import Select from "react-select";


export default class App extends React.Component{
    /*** ================================================================================
     *          MAIN
     * ================================================================================*/
    constructor (props, context) {
        super(props, context);
        this.state = {
            statusCO: '',
        };
    }
    /*** ================================================================================
     *          handle Change Order
     * ================================================================================*/
    updateStatusCO = e => {
        this.setState({statusCO: e.value});
    };
    /*** ================================================================================
     *          Default RENDER
     * ================================================================================*/
    render() {
        return (
            <Card>
                <Card.Header>
                    Массовое изменение заказов
                </Card.Header>
                <Card.Body style={{overflow: 'auto', height: 200}}>
                    {(this.props.orderChangeAllLength > 0) && (
                        <ReactTable
                            previousText={'Предыдущая страница'}
                            nextText={'Следующая страница'}
                            data={this.props.orderChangeAll}
                            showPagination={false}
                            columns={[
                                {
                                    columns: [
                                        {
                                            Header: "Информация",
                                            id: "info",
                                            minWidth: 300,
                                            accessor: d => (
                                                <div style={{overflow: 'auto', height: '100%', textAlign: 'top', color: '#495057'}}>
                                                    <div style={{textAlign: 'left'}}>
                                                        <b>ID:</b> {d.id}
                                                    </div>
                                                </div>
                                            )
                                        },
                                        {
                                            Header: "Действия",
                                            id: "doing",
                                            minWidth: 150,
                                            accessor: d => (
                                                <div style={{overflow: 'none', height: '100%', textAlign: 'top'}}>
                                                    <div>
                                                        <Button style={{textAlign: 'center', width: 130}} className="mb-2 mr-2" color="primary" onClick={() => this.props.deleteOrderFromChangeAll(d)}>
                                                            Удалить
                                                        </Button>
                                                    </div>
                                                </div>
                                            )
                                        },
                                    ]}
                            ]}
                            defaultPageSize={this.props.orderChangeAllLength}
                            className="-striped -highlight"
                        />
                    )}
                    {!(this.props.orderChangeAllLength > 0) && (
                        "Добавьте заказы для массового изменения"
                    )}
                </Card.Body>
                {(this.props.orderChangeAllLength > 0) && (
                    <Card.Footer>
                        <Col md={8}>
                            <Select
                                className="mb-2 mr-2"
                                value={ {value: this.state.statusCO, label: this.state.statusCO} }
                                onChange={this.updateStatusCO}
                                options={[
                                    { value: 'Новый', label: 'Новый' },
                                    { value: 'Принят', label: 'Принят' },
                                    { value: 'Отказ', label: 'Отказ' },
                                    { value: 'Отправлен', label: 'Отправлен' },
                                    { value: 'Завершен', label: 'Завершен' },
                                    { value: 'Возврат', label: 'Возврат' },
                                ]}
                            />
                        </Col>
                        <Col md={4}>
                            <Button
                                className="mb-2 mr-2"
                                color="primary"
                                onClick={() => this.props.updateOrdersInDB(this.state.statusCO)}>Сохранить</Button>
                        </Col>
                    </Card.Footer>
                )}
            </Card>

        )
    }
}