import React, {Fragment} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Card, Form, FormControl} from 'react-bootstrap';
import '../LandingPage.css';
import {
    CardBody,
    CardHeader,
    CardFooter,
} from "reactstrap";
import {sendMessageTelegramBotWithSettings} from '../../firebase/Firebase'
import NumberFormat from 'react-number-format';


export default class App extends React.Component {
    /*** ================================================================================
     *          Main methods
     * ================================================================================*/
    constructor(props, context) {
        super(props, context);
        this.state = {
            name: '',
            phone: '',
            text: '',
        };
        this.sendMessage = this.sendMessage.bind(this);
    }
    componentWillMount() {

    }
    /*** ================================================================================
     *          Handle
     * ================================================================================*/
    hcName = e => {
        this.setState({name: e.target.value})
    };
    hcPhone = e => {
        this.setState({phone: e.target.value})
    };
    hcText = e => {
        this.setState({text: e.target.value})
    };
    /*** ================================================================================
     *          Send Message
     * ================================================================================*/
    clearSM(){
        this.setState({
            name: '',
            phone: '',
            text: '',
        });
    }
    sendMessage(){
        if (this.state.name === '' || this.state.phone === '' || this.state.text === '' ){
            alert('Заполните все поля!')
        }
        sendMessageTelegramBotWithSettings("SEND_MESSAGE_LANDING", {
            name: this.state.name,
            phone: this.state.phone,
            text: this.state.text,
        });
        this.clearSM();
    }
    /*** ================================================================================
     *          DEFAULT RENDER
     * ================================================================================*/
    render() {
        return (
            <Card >
                <CardHeader className="card-header-tab">
                    <div
                        className="card-header-title font-size-lg text-capitalize font-weight-normal">
                        <i className="header-icon lnr-envelope icon-gradient bg-happy-itmeo"> </i>
                        Напишите нам:
                    </div>
                </CardHeader>
                <CardBody className="p-0">
                    <Form style={{padding: 20}}>
                        <Form.Group controlId="formGroupEmail">
                            <Form.Label>Имя</Form.Label>
                            <FormControl
                                style={{outline: 'none'}}
                                type="text"
                                placeholder="Укажите Ваше имя"
                                onChange={this.hcName}
                                value={this.state.name}
                            />
                        </Form.Group>
                        <Form.Group controlId="formGroupPassword">
                            <Form.Label>Номер телефона</Form.Label>
                            <div>
                                <NumberFormat
                                    type="tel"
                                    format="+38 (###) ###-##-##"
                                    mask=""
                                    placeholder="Укажите Ваш номер телефона"
                                    onChange={this.hcPhone}
                                    value={this.state.phone}
                                    style={{
                                        display: 'block',
                                        width: '100%',
                                        height: 'calc(1.5em + 0.75rem + 2px)',
                                        padding: '0.375rem 0.75rem',
                                        fontSize: '1rem',
                                        fontWeight: '400',
                                        lineHeight: '1.5',
                                        color: '#495057',
                                        backgroundColor: '#fff',
                                        backgroundClip: 'padding-box',
                                        border: '1px solid #ced4da',
                                        borderRadius: '0.25rem',
                                        outline: 'none',
                                    }}
                                />
                            </div>
                        </Form.Group>
                        <Form.Group controlId="formGroupPassword">
                            <Form.Label>Сообщение</Form.Label>
                            <Form.Control
                                style={{outline: 'none'}}
                                placeholder="Введите Ваше сообщение"
                                as="textarea" rows="3"
                                className="ProductWindow-Form-Control"
                                onChange={this.hcText}
                                value={this.state.text}
                            />
                        </Form.Group>
                    </Form>
                </CardBody>
                <CardFooter>
                    <Button style={{marginRight: 10}} color="primary"
                            onClick={this.sendMessage}>Отправить</Button>
                    <Button color="primary" onClick={this.clearSM}>Очистить</Button>
                </CardFooter>
            </Card>
        )
    }
}
