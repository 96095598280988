import React from 'react';
import {Button, FormGroup, } from 'reactstrap';
import { Tab, Tabs} from 'react-bootstrap';
import {Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {
    fire,
    checkVerified,
    deleteProductFromXML,
    setDocumentToCollection,
    updateDocumentInCollection,
    getDataFromCollectionWhereKeyValue,
    getAdminSettings,
    getDataFromCollection,
    calcAllUsers,
    sendMessageTelegramBotWithSettings, getUserJson, calcSummsAndQuantity,
} from '../firebase/Firebase';
import XMLProcessing from "./XMLComponents/XMLProcessing";
import ModalChangeCreateProduct from "./modals/ModalChangeCreateProduct";
import ModalDetailProduct from "./modals/ModalDetailProduct";
import ModalToBasket from "./modals/ModalToBasket";
import ModalIsVerification from "./modals/ModalIsVerification";
import HeaderSearchAndButtonsProducts from "./HeaderSearchAndButtonsProducts";
import TableProducts from "./TableProducts";
import {
    addProduct, changeProduct,
    clearAdmin,
    clearUser,
    removeAll,
    removeProduct,
    setAdmin,
    setUser
} from "../storeReducers/basket/actions";
import {connect} from "react-redux";
import Cookies from 'universal-cookie';
//universal-cookie

const ReactDomServer = require("react-dom/server");

const download = require('image-downloader');



class App extends React.Component {
    /*** ================================================================================
     *          Main methods
     * ================================================================================*/
    constructor (props, context) {
        super(props, context);
        this.productSearchQuery = '';
        this.categorySearchQuery = '';
        this.state = {
            // DB Array
            productsXML: [],
            products: [],
            productsRender: [],
            myProducts: [],
            myProductsRender: [],
            PartnerProducts: [],
            PartnerProductsRender: [],
            // Change Create Product
            isChangeCreateProductClick: false,
            isChangeProduct: false,
            isCreateProduct: false,
            currentChangeCreateProduct: {},
            // To basket send product
            isToBasketClick: false,
            isTobasketCurrentProduct: {},
            // Search bar
            activeSearch: false,
            // Details Products
            isDetailsProduct: false,
            currentDetailProduct: {},
            // XML UPLOAD
            xmlProducts: null,
            isUploadOpen: false,
            uploadId: '',



            // Verefied Profile and Activation
            showVerified: false,


            // Categories
            categories: [],
            categoriesRender: [],
        };
        this.refreshPage = this.refreshPage.bind(this);
        this.handleUploadXMLProducts = this.handleUploadXMLProducts.bind(this);
        this.toBasketClick = this.toBasketClick.bind(this);
        this.toggleXMLUploader = this.toggleXMLUploader.bind(this);
        this.xmlBuilder = this.xmlBuilder.bind(this);
        this.xmlBuilderChoosed = this.xmlBuilderChoosed.bind(this);
        this.searchProducts = this.searchProducts.bind(this);
        this.clearProductXML = this.clearProductXML.bind(this);
        this.getProducts = this.getProducts.bind(this);
        this.clickVerified = this.clickVerified.bind(this);
        this.filterImgStorage = this.filterImgStorage.bind(this);
        this.getAllImages = this.getAllImages.bind(this);
        this.test = this.test.bind(this);
    }
    componentDidMount() {
        this.refreshPage();
        checkVerified().then(r => {
            if (!r.isVerified){
                this.clickVerified();
            }
        });
    }
    /*** ================================================================================
     *          Data Base
     * ================================================================================*/
    refreshPage(){
        this.getCategories();
        this.getProducts();
        this.getXmlProducts();
    }
    getProducts(){
        try {
            fire.firestore().collection('products')
                .get()
                .then( (snapshot) => {
                    const array = [];
                    snapshot.docs.forEach( d => {
                        array.push({
                            idP: d.id,
                            ...d.data()
                        })
                    });
                    this.setState({productsRender: array});
                    this.setState({ products: array });
                    try {
                        getAdminSettings().then(r =>{
                            this.props.setAdmin(r);
                            var otherArray = [];
                            if(localStorage.getItem('position') === 'Партнер'){
                                var myArray = array.filter(function (el) {
                                    if (el.ownerEmailP.includes(localStorage.getItem('email'))){
                                        return el;
                                    }else if (el.ownerUIDP.includes(r.uid)){
                                        otherArray.push(el);
                                    }
                                });
                            }else if(localStorage.getItem('position') === 'Админ'){
                                var myArray = array.filter(function (el) {
                                    if (el.ownerEmailP.includes(localStorage.getItem('email'))){
                                        return el;
                                    }else {
                                        otherArray.push(el);
                                    }
                                });
                            }
                            this.setState({myProducts: myArray});
                            this.setState({myProductsRender: myArray});
                            this.setState({PartnerProducts: otherArray});
                            this.setState({PartnerProductsRender: otherArray});
                        })
                    }catch (e) {
                        console.log(e);
                    }
                });
        }catch (e) {
            console.log(e);
        }
    }
    getCategories(){
        try {
            fire.firestore().collection("categories")
                .get()
                .then(querySnapshot => {
                    const data = [];
                    querySnapshot.docs.forEach(d => {
                        data.push({
                            idDB: d.id,
                            ...d.data()
                        })
                    });
                    data.sort(function(a,b){
                        return new Date(b.id) - new Date(a.id);
                    });
                    if (data.length !== this.state.categories.length){
                        this.setState({ categories: data });
                        this.setState({ categoriesRender: data });
                    }
                });
        }catch (e) {
            console.log(e);
        }
    };
    getXmlProducts(){
        getDataFromCollectionWhereKeyValue("exportToRozetka", 'ownerUID', localStorage.getItem('uid')).then( res => {
            if (!res.data.length > 0){
                // console.log("Нет ХМЛ товаров");
            }else {
                var allProducts = this.state.products;
                var productsID = res.data[0].productsID;
                var pXML = this.state.productsXML;
                productsID.forEach( p => {
                    var product = allProducts.filter((el) => el.idP.includes(p) );
                    pXML.push(product[0]);
                });
                this.setState({productsXML: pXML});
                // console.log(allProducts)
                // console.log(pXML)
            }
        });
    }
    /*** ================================================================================
     *           Change Create Product
     * ================================================================================*/
    clickChangeCreateProduct = () => {
        if (this.state.isChangeCreateProductClick) {
            this.setState({
                isChangeCreateProductClick: false,
                // currentChangeCreateProduct: {},
                isChangeProduct: false,
                isCreateProduct: false,
            });
        }
    };
    clickCreateProduct = () => {
        checkVerified().then(r => {
            if (!r.isVerified){
                this.clickVerified();
                return;
            }else {
                if (!this.state.isChangeCreateProductClick){
                    this.setState({
                        isChangeCreateProductClick: true,
                        isCreateProduct: true,
                        isChangeProduct: false,
                    });
                }
            }
        });
    };
    clickChangeProduct = (e) => {
        checkVerified().then(r => {
            if (!r.isVerified){
                this.clickVerified();
                return;
            }else {
                if (!this.state.isChangeCreateProductClick){
                    this.setState({
                        isChangeCreateProductClick: true,
                        currentChangeCreateProduct: e,
                        isCreateProduct: false,
                        isChangeProduct: true,
                    });
                }
            }
        });
    };
    /*** ================================================================================
     *          Basket
     * ================================================================================*/
    toBasketClick = (p) =>{
        checkVerified().then(r => {
            if (!r.isVerified){
                this.clickVerified();
                return;
            }else {
                if (!this.state.isToBasketClick){
                    this.setState({isTobasketCurrentProduct: p});
                    this.setState({isToBasketClick: true});
                }else {
                    this.setState({isToBasketClick: false});
                    this.setState({isTobasketCurrentProduct: {}});
                }
            }
        });
    };
    /*** ================================================================================
     *          Details Product
     * ================================================================================*/
    clickDetailProduct = (p) => {
        if (!this.state.isDetailsProduct){
            this.setState({isDetailsProduct: true});
            this.setState({currentDetailProduct: p});
        }else {
            this.setState({isDetailsProduct: false});
            this.setState({currentDetailProduct: p});
        }
    };
    /*** ================================================================================
     *          XML
     * ================================================================================*/
    handleUploadXMLProducts = e =>{
        // console.log("Uploading is started");
        // console.log(e);
        const file = e.target.files[0];
        var catArray = this.state.categories;
        if (file) {
            // console.log("Uploading IFFFF");
            var reader = new FileReader();
            reader.onload = function(ev) {
                // console.log("Uploading func");
                let textXML = ev.target.result;
                var parser = require('fast-xml-parser');
                var options = {
                    attributeNamePrefix : "",
                    attrNodeName: "attr", //default is 'false'
                    textNodeName : "text",
                    ignoreAttributes : false,
                    ignoreNameSpace : false,
                    allowBooleanAttributes : false,
                    parseNodeValue : true,
                    parseAttributeValue : true,
                    trimValues: true,
                    cdataTagName: "__cdata", //default is 'false'
                    cdataPositionChar: "\\c",
                    localeRange: "", //To support non english character in tag/attribute values.
                    parseTrueNumberOnly: false,
                    arrayMode: false, //"strict"
                };
                if( parser.validate(textXML) === true) { //optional (it'll return an object in case it's not valid)
                    var jsonObj = parser.parse(textXML,options);
                    // console.log(jsonObj);
                }
                // Intermediate obj
                var tObj = parser.getTraversalObj(textXML,options);
                var jsonObj = parser.convertToJson(tObj,options);
                let shop = jsonObj.yml_catalog.shop;
                let categories = shop.categories;
                let offers = shop.offers.offer;

                // console.log(categories.category.length);
                // console.log(categories);
                //
                // for (let i = 0; i < categories.category.length; i++) {
                //     console.log(i);
                //     var text = categories.category[i].text;
                //     var id = categories.category[i].attr.id;
                //     // var idParent = categories.category[i].attr.parentId;
                //         try {
                //             fire.firestore().collection('categories').add({
                //                 text: text,
                //                 id: id,
                //                 // parentId: idParent,
                //             });
                //         }catch (e) {
                //             console.log(e);
                //             console.log(offers[i].attr.id);
                //         }
                // }

                console.log(offers.length);
                console.log(offers);

                for (let i = 0; i < offers.length; i++) {
                    var params = [];
                    for (let j = 0; j < offers[i].param.length; j++) {
                        var paramText = '';
                        if (offers[i].param[j].text === undefined){
                            paramText = offers[i].param[j].attr.name;
                        }else {
                            paramText = offers[i].param[j].text;
                        }
                        params.push({
                            name: offers[i].param[j].attr.name,
                            text: paramText,
                        })
                    }
                    var photos = [];
                    if (Array.isArray(offers[i].picture)){
                        for (let j = 0; j < offers[i].picture.length; j++) {
                            photos.push(offers[i].picture[j]);
                        }
                    }else {
                        photos.push(offers[i].picture);
                    }
                    var vendorCode = '';
                    if (offers[i].vendorCode === undefined){
                        vendorCode = '';
                    }else {
                        vendorCode = offers[i].vendorCode;
                    }
                    var vendor = '';
                    if (offers[i].vendor === undefined){
                        vendor = '';
                    }else {
                        vendor = offers[i].vendor;
                    }

                    // console.log(catArray);
                    var catResText = 'Неопределено';
                    var catResId = 0;
                    var catResParentId = 0;
                    catArray.forEach(c =>{
                        if (c.id === offers[i].categoryId){
                            catResText = c.text;
                            catResId = c.id;
                            catResParentId = '';
                        }
                    });
                    // console.log(i);


                    try {
                        fire.firestore().collection('products').add({
                            offerIdP: offers[i].attr.id,
                            offerAvailableP: offers[i].attr.available,

                            ownerEmailP: localStorage.getItem('email'),
                            ownerUIDP: localStorage.getItem('uid'),
                            nameP: offers[i].name,
                            categoryIdP: offers[i].categoryId,
                            categoryParentIdP: catResParentId,
                            categoryTextP: catResText,
                            currencyP: offers[i].currencyId,
                            brandP: vendor,
                            vendoreCodeP: vendorCode,
                            quantityP: offers[i].stock_quantity,
                            pricePurchaseP: offers[i].price,
                            priceDropP: offers[i].price,
                            priceSellingP: offers[i].price,
                            descriptionP: offers[i].description.__cdata,
                            attributesP: params,
                            inStockP: true,
                            isOnP: true,
                            isModeratedP: true,
                            otherP: "",
                            imagesUrlP: photos,
                            dateP: new Date().getTime(),
                            isNewP: false,
                            isTopP: false,
                            isPriceP: false,
                        }).then(res => {
                            // console.log(offers[i]);
                        });
                    }catch (e) {
                        console.log(offers[i]);
                        console.log(e);
                    }
                }
            };
            reader.readAsText(file);
        }
    };
    toggleXMLUploader(id = ''){
        this.setState(oldState =>({
            isUploadOpen: !oldState.isUploadOpen,
            uploadId: id,
        }))

    }
    xmlBuilder(){
        checkVerified().then(r => {
            if (!r.isVerified){
                this.clickVerified();
                return;
            }else {
                //let xmlText = buildXMLToFile(this.state.products, this.state.categories);
                var productsID = [];
                this.state.products.forEach(p => {
                    productsID.push(p.idP);
                });
                var categoriesID = [];
                this.state.categories.forEach(p => {
                    categoriesID.push(p.idDB);
                });
                getDataFromCollectionWhereKeyValue("exportToRozetka", "ownerUID", 'allProductsXml').then(r => {
                    try {
                        if (r.data.length === 0){
                            setDocumentToCollection("exportToRozetka", {
                                link: 'https://us-central1-dropmasterproject.cloudfunctions.net/widgets/allProductsXml',
                                productsID: productsID,
                                categoriesID: categoriesID,
                                ownerUID: 'allProductsXml'
                            }).then( r => {
                                window.open("https://us-central1-dropmasterproject.cloudfunctions.net/widgets/allProductsXml", "_blank")
                            })
                        }else {
                            updateDocumentInCollection("exportToRozetka", r.data[0].id, {
                                link: 'https://us-central1-dropmasterproject.cloudfunctions.net/widgets/allProductsXml',
                                productsID: productsID,
                                categoriesID: categoriesID,
                                ownerUID: 'allProductsXml'
                            }).then(r => {
                                window.open("https://us-central1-dropmasterproject.cloudfunctions.net/widgets/allProductsXml", "_blank")
                            });
                        }
                    }catch (e) {

                    }
                });
                // console.log(productsID);
                // console.log(categoriesID);
                // https://us-central1-dropmasterproject.cloudfunctions.net/widgets/       t3N6QVs2KRT2TNv3x2FHv5scCo92
                // this.toggleXMLUploader('https://us-central1-dropmasterproject.cloudfunctions.net/widgets/t3N6QVs2KRT2TNv3x2FHv5scCo92');
                // try {
                //     this.toggleXMLUploader(fire.storage()
                //         .ref()
                //         .child(`xml/${uid(16)}.xml`)
                //         .putString(xmlText, 'raw',{
                //         contentType: 'text/xml'
                //     }));
                // }catch (e) {
                //     console.log(e);
                // }
            }
        });
    }
    xmlBuilderChoosed(){
        this.updateXmlInDB(true);
        //let xmlText = buildXMLToFile(this.state.productsXML, this.state.categories);
        // try {
        //     this.toggleXMLUploader(fire.storage()
        //         .ref()
        //         .child(`xml/${uid(16)}.xml`)
        //         .putString(xmlText, 'raw',{
        //             contentType: 'text/xml'
        //         }));
        // }catch (e) {
        //     console.log(e);
        // }
    }
    updateXmlInDB(open){
        var productsID = [];
        this.state.productsXML.forEach(p => {
            productsID.push(p.idP);
        });
        var categoriesID = [];
        this.state.categories.forEach(p => {
            categoriesID.push(p.idDB);
        });
        getDataFromCollectionWhereKeyValue("exportToRozetka", "ownerUID", localStorage.getItem('uid')).then(r => {
            try {
                if (r.data.length === 0){
                    setDocumentToCollection("exportToRozetka", {
                        link: 'https://us-central1-dropmasterproject.cloudfunctions.net/widgets/' + localStorage.getItem('uid'),
                        productsID: productsID,
                        categoriesID: categoriesID,
                        ownerUID: localStorage.getItem('uid')
                    }).then( r => {
                        if (open){
                            window.open('https://us-central1-dropmasterproject.cloudfunctions.net/widgets/' + localStorage.getItem('uid'), "_blank")
                        }
                    })
                }else {
                    updateDocumentInCollection("exportToRozetka", r.data[0].id, {
                        link: 'https://us-central1-dropmasterproject.cloudfunctions.net/widgets/' + localStorage.getItem('uid'),
                        productsID: productsID,
                        categoriesID: categoriesID,
                        ownerUID: localStorage.getItem('uid')
                    }).then(r => {
                        if (open){
                            window.open('https://us-central1-dropmasterproject.cloudfunctions.net/widgets/' + localStorage.getItem('uid'), "_blank")
                        }
                    });
                }
            }catch (e) {

            }
        });
    }
    // XML small window
    addProductToXML = (p) => {
        checkVerified().then(r => {
            if (!r.isVerified){
                this.clickVerified();
                return;
            }else {
                var pXML = this.state.productsXML;
                pXML.push(p);
                this.setState({productsXML: pXML});
            }
        });
    };
    clearProductXML(){
        this.setState({productsXML: []});
    };
    isProductInXmlList(p){
        return this.state.productsXML.find(el => el.idP === p.idP);
    };
    deleteProductFromXML = (p) =>{
        var pXML = this.state.productsXML;
        var newArray =[];
        pXML.forEach(prod => {
            if (prod.idP !== p.idP){
                newArray.push(prod);
            }
        });
        this.setState({productsXML: newArray});
        // this.updateXmlInDB(false);
    };
    /*** ================================================================================
     *          Buttons To Filter Products *TOP, NEW, ALL
     * ================================================================================*/
    handleShowTop = () => {
        try {
            const newArray = this.state.products
                .filter((el) => el.isTopP);
            this.setState({productsRender: newArray});
            const myArray = this.state.myProducts
                .filter((el) => el.isTopP);
            this.setState({myProductsRender: myArray});
            const partnerArray = this.state.PartnerProducts
                .filter((el) => el.isTopP);
            this.setState({PartnerProductsRender: partnerArray});
        }catch (e) {
            console.log(e);
        }
    };
    handleShowNew = () => {
        try {
            const newArray = this.state.products
                .filter((el) => el.isNewP);
            this.setState({productsRender: newArray});
            const myArray = this.state.myProducts
                .filter((el) => el.isNewP);
            this.setState({myProductsRender: myArray});
            const partnerArray = this.state.PartnerProducts
                .filter((el) => el.isNewP);
            this.setState({PartnerProductsRender: partnerArray});
        }catch (e) {
            console.log(e);
        }
    };
    handleShowPrice = () => {
        try {
            const newArray = this.state.products
                .filter((el) => el.isPriceP);
            this.setState({productsRender: newArray});
            const myArray = this.state.myProducts
                .filter((el) => el.isPriceP);
            this.setState({myProductsRender: myArray});
            const partnerArray = this.state.PartnerProducts
                .filter((el) => el.isPriceP);
            this.setState({PartnerProductsRender: partnerArray});
        }catch (e) {
            console.log(e);
        }
    };
    handleShowAll = () => {
        this.setState({productsRender: this.state.products});
        this.setState({myProductsRender: this.state.myProducts});
        this.setState({PartnerProductsRender: this.state.PartnerProducts});
    };
    /*** ================================================================================
     *          Search
     * ================================================================================*/
    searchProducts = () => {
        const filterFunctionCreator = (query) => {
            return (el) => el.nameP.toUpperCase().includes(query.toUpperCase()) ||
                el.brandP.toUpperCase().includes(query.toUpperCase()) ||
                el.categoryTextP.toUpperCase().includes(query.toUpperCase()) ||
                el.descriptionP.toUpperCase().includes(query.toUpperCase());
        };

        try {
            const newArray = this.state.products
                .filter(filterFunctionCreator(this.productSearchQuery))
                .filter(filterFunctionCreator(this.categorySearchQuery));
            this.setState({productsRender: newArray});
            const myArray = this.state.myProducts
                .filter(filterFunctionCreator(this.productSearchQuery))
                .filter(filterFunctionCreator(this.categorySearchQuery));
            this.setState({myProductsRender: myArray});
            const partnerArray = this.state.PartnerProducts
                .filter(filterFunctionCreator(this.productSearchQuery))
                .filter(filterFunctionCreator(this.categorySearchQuery));
            this.setState({PartnerProductsRender: partnerArray});
        }catch (e) {
            console.log(e);
        }
    };
    handleSearch = e => {
        this.productSearchQuery = e.target.value;
        this.searchProducts();
    };
    handleSearchByCategory = e => {
        this.categorySearchQuery = e.target.value;
        this.searchProducts();
    };
    /*** ================================================================================
     *          Verefied Profile
     * ================================================================================*/
    clickVerified = () => {
        if (!this.state.showVerified){
            this.setState({showVerified: true});
        }else {
            this.setState({showVerified: false});
        }
    };
    /*** ================================================================================
     *          OTHER HELPFULL
     * ================================================================================*/
    changePhotoPathToFirebase(){
        var url = 'https://my.foks.biz/i/61a0ab23e7f6b96e03f050a211bfd849.jpeg';


        // this.setState({image: e.target.files[0]});
        // console.log(e.target.files[0]);
        var file;
        var imagesUrlP = [];
        var storageRef = fire.storage().ref();
        //В админ айди нужно будет вставить индетификатор того кто загружает файлы
        // + время, что бы сделать изображение очень уникальным
        var path = 'images/UID' + localStorage.getItem('uid');

        // Download to a directory and save with the original filename
        const options = {
            headers: {cors: 'no-cors'},
            url: url,
            dest: '/Users/night_stalker/Desktop/image.jpg'// Save to /path/to/dest/image.jpg
        };
        download.image(options)
            .then(({ filename, image }) => {
                // complete function ....
                storageRef.child(path).getDownloadURL().then(url => {
                    console.log(url);
                    console.log(filename);
                    console.log(image);
                    imagesUrlP.push(url);
                    // this.setState({imagesUrlP: imagesUrlP});
                })
            })
            .catch((err) => console.error(err));




        // const uploadTask = storageRef.child(path).put(url);
        // uploadTask.on('state_changed',
        //     (snapshot) => {
        //         // progrss function ....
        //         const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        //         // this.setState({progress: progress});
        //     },
        //     (error) => {
        //         // error function
        //         console.log(error);
        //     },
        //     () => {
        //         // complete function ....
        //         storageRef.child(path).getDownloadURL().then(url => {
        //             console.log(url);
        //             imagesUrlP.push(url);
        //             // this.setState({imagesUrlP: imagesUrlP});
        //         })
        //     });
    }
    testToZeroUsers(){
        getDataFromCollection('usders').then( u => {
            const users = u.data;
            users.forEach( user => {
                updateDocumentInCollection('usders', user.id, {
                    summHoldProvider: 0,
                    summFinishedProvider: 0,
                    summBackProvider: 0,

                    summHoldPartner: 0,
                    summFinishedPartner: 0,
                    summBackPartner: 0,

                    summBalance: 0,
                    summPayed: 0,
                    summInput: 0,

                    quantityHoldProvider: 0,
                    quantityFinishedProvider: 0,
                    quantityBackProvider: 0,

                    quantityHoldPartner: 0,
                    quantityFinishedPartner: 0,
                    quantityBackPartner: 0,

                    quantityBalance: 0,
                    quantityPayed: 0,
                    quantityInput: 0,

                    quantityOrders: 0,
                    quantityProducts: 0,
                }).then( r => {
                    console.log('DONE')
                })
            })
        })
    }
    test(){
        this.getAllImages().then( img => {
            const imagesStorage = img;
            getDataFromCollection('products').then( prRes => {
                const products = prRes.data;
                products.forEach((p, indexProduct) => {
                    const imagesProducts = p.imagesUrlP;
                    imagesProducts.forEach((imgUrl, indexUrl) => {
                        const filterStorageArray = this.filterImgStorage(imagesStorage, indexProduct + '-' + indexUrl);
                        if (filterStorageArray.length > 0){
                            // console.log(filterStorageArray[0].name);
                            filterStorageArray[0].getDownloadURL().then( url => {
                                // console.log( p.id + ' === ' + indexProduct + '-' + indexUrl + ' === ' + url);
                                this.updateImage(p , indexUrl, url);
                            }).catch( error =>  {
                                // console.log(indexProduct + '-' + indexUrl + ' === ' + error);
                            });
                        }
                    });
                })
            })
        });
        // calcAllUsers();
    }
    updateImage(doc, index, url){
        if (index !== -1) {
            doc.imagesUrlP[index] = url;
        }
        updateDocumentInCollection('products', doc.id, doc).then(res => {
            console.log(doc);
        });
    }
    filterImgStorage(array, key){
        return array.filter(function (el) {
            const name = el.name.split('.')[0];
            return name === (key);
        });
    }
    getAllImages(){
        return new Promise(function (resolve, reject) {
            var storageRef = fire.storage().ref("images");
            // Now we get the references of these images
            storageRef.listAll().then(function(result) {
                const images = result.items;
                console.log(images.length);
                // resolve([]);
                resolve(images);
                // result.items.forEach(function(imageRef) {
                //     console.log(imageRef)
                //     // And finally display them
                //     // displayImage(imageRef);
                // });
            }).catch(function(error) {
                // Handle any errors
            });
        });
    }
    displayImage(imageRef) {
        return new Promise(function (resolve, reject) {
            imageRef.getDownloadURL().then( url => {
                // TODO: Display the image on the UI
                resolve(url);
            }).catch( error =>  {
                resolve('errorUrl');
            });
        })
    }

    getRandomNumber(min, max) {
        return Math.round(Math.random() * (max - min) + min);
    }

    addOrders(){
        const paymentTypeArray = ['Наложенный платеж', 'Наложенный платеж'];
        const statusArray = ['Новый', 'Принят', 'Отказ', 'Отправлен', 'Завершен', 'Возврат'];
        for (let i = 0; i < 20000 ; i++) {

            var num1 = 10 * this.getRandomNumber(1, 100);
            var num2 = num1 + (100 * this.getRandomNumber(1, 10));

            var summ = num2;
            var summDrop = num1;
            var summPurchase = num1;
            var summClient = summ - summDrop;
            const order = {
                name: 'TEST#-' + i,
                phone: 'PHONE#-' + i,
                city: 'CITY#-' + i,
                notes: '',
                paymentType: paymentTypeArray[ this.getRandomNumber(0, 1) ],
                status: statusArray[ 4 ],
                statusNP: '',
                TTNNP: 'ТТН не присвоен',
                TTNStatusNP: 'Нет статуса ТТН',
                fromUID: 'SMbHWV6n6Ph363xEHGEqGKJHAPH2',
                fromEmail: 'latnqs@gmail.com',
                fromName: 'TEST',
                date: new Date().getTime(),
                dateForParse: new Date().getTime(),

                summ: summ,
                summDrop: summDrop,
                summClient: summClient,
                summPurchase: summPurchase,

                summBack: 10 * this.getRandomNumber(1, 10),

                products: [],
                ownerUIDP: 't3N6QVs2KRT2TNv3x2FHv5scCo92',
                ownerEmailP: 'dropmaster.director@gmail.com',

                active: false,

                summReferal: 0,
                referalCode: '',
            };



            fire.firestore().collection('orders').add(order).then(q => {
                calcSummsAndQuantity('SMbHWV6n6Ph363xEHGEqGKJHAPH2');
                calcSummsAndQuantity('t3N6QVs2KRT2TNv3x2FHv5scCo92');
                console.log(i);
            });


        }

    }
    /*** ================================================================================
     *          DEFAULT RENDER
     * ================================================================================*/
    render() {

        return (
            <div className="ProductWindow-Container">
                {/*UPLOAD PRODUCTS FROM XML AND PARSE*/}
                {/*<Card>*/}
                {/*    <CardHeader>*/}
                {/*        <div className="Landing-File-Add-Title">UPLOAD XML</div>*/}
                {/*    </CardHeader>*/}
                {/*    <CardBody>*/}
                {/*        <input className="Landing-Input-File" id={"fileUpload"} type="file" name="file" onChange={this.handleUploadXMLProducts}/>*/}
                {/*        /!*<Button onClick={() => this.changePhotoPathToFirebase()}>Test uploading Image</Button>*!/*/}
                {/*    </CardBody>*/}
                {/*</Card>*/}

                {/*TEST*/}
                {/*<Card>*/}
                {/*    <CardHeader>*/}
                {/*        <div className="Landing-File-Add-Title">TEST</div>*/}
                {/*    </CardHeader>*/}
                {/*    <CardBody>*/}
                {/*        <Button onClick={() => calcAllUsers()}>Calc ALL users</Button>*/}
                {/*        <Button onClick={() => this.addOrders()}>Test</Button>*/}
                {/*    </CardBody>*/}
                {/*</Card>*/}

                {/*Modal Windows*/}
                {/*User Verification*/}
                <ModalIsVerification
                    show={this.state.showVerified}
                    clickModal={this.clickVerified}
                />
                {/*Create or Change Product*/}
                <ModalChangeCreateProduct
                    isChangeCreateProductClick={this.state.isChangeCreateProductClick}
                    clickChangeCreateProduct={this.clickChangeCreateProduct}
                    currentChangeCreateProduct={this.state.currentChangeCreateProduct}
                    isChange={this.state.isChangeProduct}
                    isCreate={this.state.isCreateProduct}
                    refreshPage={this.refreshPage}
                />
                {/*XML*/}
                {this.state.isUploadOpen && (
                    <XMLProcessing
                        promice={this.state.uploadId}
                        opened={this.state.isUploadOpen}
                        toggle={this.toggleXMLUploader}
                    />
                )}
                {/*Add To Basket*/}
                {this.state.isToBasketClick && (
                    <ModalToBasket
                        isToBasketClick={this.state.isToBasketClick}
                        toBasketClick={this.toBasketClick}
                        isTobasketCurrentProduct={this.state.isTobasketCurrentProduct}
                    />
                )}
                {/*Detail Product*/}
                {this.state.isDetailsProduct && (
                    <ModalDetailProduct
                        isDetailsProduct={this.state.isDetailsProduct}
                        clickDetailProduct={this.clickDetailProduct}
                        toBasketClick={this.toBasketClick}
                        currentDetailProduct={this.state.currentDetailProduct}
                    />
                )}
                {/*Content*/}
                <div className="ProductWindow-Table-Products-Container">
                    <HeaderSearchAndButtonsProducts
                        refresh={this.refreshPage}
                        productsXML={this.state.productsXML}
                        productsXMLLength={this.state.productsXML.length}

                        handleShowNew={this.handleShowNew}
                        handleShowTop={this.handleShowTop}
                        handleShowPrice={this.handleShowPrice}
                        handleShowAll={this.handleShowAll}
                        clickAddProduct={this.clickCreateProduct}

                        deleteProductFromXML={this.deleteProductFromXML}
                        xmlBuilder={this.xmlBuilder}
                        xmlBuilderChoosed={this.xmlBuilderChoosed}
                        updateXmlInDB={() => this.updateXmlInDB(false)}
                        clearProductXML={this.clearProductXML}

                        handleSearch={this.handleSearch}
                        handleSearchByCategory={this.handleSearchByCategory}
                    />
                    <Row style={{textAlign: 'center'}}>
                        <Col>
                            {(localStorage.getItem('position') === 'Админ') && (
                                <Tabs variant="pills"  defaultActiveKey="Все товары" transition={false} id="noanim-tab-example" onSelect={this.tabHandleSelect}>
                                    <Tab eventKey="Все товары" title={"Все товары " + this.state.productsRender.length}>
                                        <TableProducts
                                            change={true}
                                            // .filter(product => product.isOnP).reverse()
                                            productsRender={this.state.productsRender}
                                            productsXML={this.state.productsXML}
                                            clickDetailProduct={this.clickDetailProduct}
                                            toBasketClick={this.toBasketClick}
                                            clickChangeProduct={this.clickChangeProduct}
                                            deleteProductFromXML={this.deleteProductFromXML}
                                            addProductToXML={this.addProductToXML}
                                        />
                                    </Tab>
                                    <Tab eventKey="Мои товары" title={"Мои товары " + this.state.myProductsRender.length}>
                                        <TableProducts
                                            change={true}
                                            productsRender={this.state.myProductsRender}
                                            productsXML={this.state.productsXML}
                                            clickDetailProduct={this.clickDetailProduct}
                                            toBasketClick={this.toBasketClick}
                                            clickChangeProduct={this.clickChangeProduct}
                                            deleteProductFromXML={this.deleteProductFromXML}
                                            addProductToXML={this.addProductToXML}
                                        />
                                    </Tab>
                                    <Tab eventKey="Товары партнеров" title={"Товары партнеров " + this.state.PartnerProductsRender.length}>
                                        <TableProducts
                                            change={true}
                                            productsRender={this.state.PartnerProductsRender}
                                            productsXML={this.state.productsXML}
                                            clickDetailProduct={this.clickDetailProduct}
                                            toBasketClick={this.toBasketClick}
                                            clickChangeProduct={this.clickChangeProduct}
                                            deleteProductFromXML={this.deleteProductFromXML}
                                            addProductToXML={this.addProductToXML}
                                        />
                                    </Tab>
                                </Tabs>
                            )}
                            {(localStorage.getItem('position') === 'Партнер') && (
                                <Tabs variant="pills"  defaultActiveKey="Все товары" transition={false} id="noanim-tab-example" onSelect={this.tabHandleSelect}>
                                    <Tab eventKey="Все товары" title={"Все товары " + this.state.productsRender.filter(product => product.isOnP).length}>
                                        <TableProducts
                                            change={false}
                                            productsRender={this.state.productsRender.filter(product => product.isOnP)}// .filter(product => product.isOnP)
                                            productsXML={this.state.productsXML}
                                            clickDetailProduct={this.clickDetailProduct}
                                            toBasketClick={this.toBasketClick}
                                            clickChangeProduct={this.clickChangeProduct}
                                            deleteProductFromXML={this.deleteProductFromXML}
                                            addProductToXML={this.addProductToXML}
                                        />
                                    </Tab>
                                    <Tab eventKey="Товары DropMaster" title={"Товары DropMaster " + this.state.PartnerProductsRender.filter(product => product.isOnP).length}>
                                        <TableProducts
                                            change={false}
                                            productsRender={this.state.PartnerProductsRender.filter(product => product.isOnP)}
                                            productsXML={this.state.productsXML}
                                            clickDetailProduct={this.clickDetailProduct}
                                            toBasketClick={this.toBasketClick}
                                            clickChangeProduct={this.clickChangeProduct}
                                            deleteProductFromXML={this.deleteProductFromXML}
                                            addProductToXML={this.addProductToXML}
                                        />
                                    </Tab>
                                    <Tab eventKey="Мои товары" title={"Мои товары " + this.state.myProductsRender.length}>
                                        <TableProducts
                                            change={true}
                                            productsRender={this.state.myProductsRender}
                                            productsXML={this.state.productsXML}
                                            clickDetailProduct={this.clickDetailProduct}
                                            toBasketClick={this.toBasketClick}
                                            clickChangeProduct={this.clickChangeProduct}
                                            deleteProductFromXML={this.deleteProductFromXML}
                                            addProductToXML={this.addProductToXML}
                                        />
                                    </Tab>
                                </Tabs>
                            )}
                        </Col>
                    </Row>
                </div>
            </div>
        )}
}
const mapStateToProps = state => {
    return {
        basketArray: state.basket,
        user: state.basket.user,
        adminSettings: state.basket.adminSettings
    }
};

const mapDispatchToProps = {
    setAdmin,
    clearAdmin,
    setUser,
    clearUser,
    addProduct,
    removeProduct,
    removeAll,
    changeProduct
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
