import React from "react";
import {Col, Modal, Row, Card, Form,} from "react-bootstrap";
import {Button, CardFooter, CardLink, FormGroup, Input, Label, ListGroup, ListGroupItem,} from "reactstrap";
import NumberFormat from "react-number-format";
import {createNewUserAndGoToAccount, fire} from "../firebase/Firebase";

export default class App extends React.Component{
    /*** ================================================================================
     *          Main
     * ================================================================================*/
    constructor (props, context) {
        super(props, context);
        this.state = {
            // registration
            rEmail: '',
            rPhone: '',
            rName: '',
            rPassword: '',
            rPasswordRepeat: '',
            isRegisterBoxClicked: false,
        };
    }
    componentDidMount() {

    }
    /*** ================================================================================
     *          Handle
     * ================================================================================*/
    hcrEmail = e => {
        this.setState({rEmail: e.target.value})
    };
    hcrPhone = e => {
        this.setState({rPhone: e.target.value})
    };
    hcrName = e => {
        this.setState({rName: e.target.value})
    };
    hcrPassword = e => {
        this.setState({rPassword: e.target.value})
    };
    hcrPasswordRepeat = e => {
        this.setState({rPasswordRepeat: e.target.value})
    };
    clickCheckRegister = e => {
        this.setState({isRegisterBoxClicked: e.target.checked});
    };
    /*** ================================================================================
     *          Register
     * ================================================================================*/
    registerUser() {
        if (this.state.isRegisterBoxClicked === false) {
            alert('Вы не приняли условия пользования сайтом!');
            return;
        }
        if (this.state.rEmail === '' || this.state.rPhone === '' || this.state.rName === '' || this.state.rPassword === '' || this.state.rPasswordRepeat === '') {
            alert('Не все поля указаны!');
            return;
        }
        if (this.state.rPassword !== this.state.rPasswordRepeat) {
            alert('Пароли не совпадают');
            return;
        }
        try {
            fire.auth().createUserWithEmailAndPassword(this.state.rEmail, this.state.rPassword)
                .then(res => {
                    fire.auth().currentUser.updateProfile({
                        displayName: this.state.rName,
                        phone: this.state.rPhone
                    });
                    fire.auth().currentUser.sendEmailVerification();
                    // console.log(res);
                    if (res.operationType === 'signIn') {
                        this.saveNewUserToFire(this.state.rName, res.user.email,
                            res.user.emailVerified, res.user.phoneNumber, false,
                            res.user.photoURL, res.user.uid,);
                    }
                }).catch(e => {
                if (e.message.includes('email address')) alert('Адрес электронной почты уже используется другой учетной записью.');
                else alert(e.message);
            })
        } catch (e) {
            console.log(e);
        }
    }
    saveNewUserToFire(displayName, email, emailVerified, phone, phoneVerified, photoUrl, uid) {
        createNewUserAndGoToAccount(displayName, email, emailVerified, phone, phoneVerified, photoUrl, uid, '')
            .then( r => {
                this.props.setUser(r.user);
                this.checkVerefiedAccountComponents();
            });
    }
    goToAccount(result) {
        localStorage.setItem('displayName', result.displayName);
        localStorage.setItem('email', result.email);
        localStorage.setItem('emailVerified', result.emailVerified);
        localStorage.setItem('phone', result.phone);
        localStorage.setItem('phoneVerified', result.phoneVerified);
        localStorage.setItem('photoUrl', result.photoUrl);
        localStorage.setItem('uid', result.uid);
        localStorage.setItem('position', result.position);
        localStorage.setItem('orders', result.orders);
        localStorage.setItem('products', result.products);
        localStorage.setItem('active', result.active);
        localStorage.setItem('balance', result.balance);
        localStorage.setItem('dateCreation', result.dateCreation);

        window.location.pathname = '/account';
    }
    /*** ================================================================================
     *          Default Render
     * ================================================================================*/
    render() {
        return (
            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                // centered
                show={this.props.isRegisterBoxClicked}
                onHide={() => this.props.clickRegisterBox()}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <h4 className="mt-2">
                            <div>Регистрация</div>
                        </h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-body">
                        <Row form>
                            <Col md={12}>
                                <FormGroup>
                                    <Form.Label>Email:</Form.Label>
                                    <Input
                                        style={{outline: 'none'}}
                                        type="email" name="email" id="email"
                                        placeholder="Введите email"
                                        onChange={this.hcrEmail}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <Form.Label>Номер телефона:</Form.Label>
                                    <div>
                                        <NumberFormat
                                            type="tel"
                                            format="+38 (###) ###-##-##"
                                            mask=""
                                            placeholder="Укажите Ваш номер телефона"
                                            onChange={this.hcrPhone}
                                            style={{
                                                display: 'block',
                                                width: '100%',
                                                height: 'calc(1.5em + 0.75rem + 2px)',
                                                padding: '0.375rem 0.75rem',
                                                fontSize: '1rem',
                                                fontWeight: '400',
                                                lineHeight: '1.5',
                                                color: '#495057',
                                                backgroundColor: '#fff',
                                                backgroundClip: 'padding-box',
                                                border: '1px solid #ced4da',
                                                borderRadius: '0.25rem',
                                                outline: 'none',
                                            }}
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <Form.Label>Имя:</Form.Label>
                                    <Input
                                        style={{outline: 'none'}}
                                        autocomplete
                                        type="text" name="name" id="name"
                                        placeholder="Введите Ваше имя"
                                        onChange={this.hcrName}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <Form.Label>Пароль:</Form.Label>
                                    <Input
                                        style={{outline: 'none'}}
                                        type="password" name="password" id="password"
                                        placeholder="Введите пароль"
                                        onChange={this.hcrPassword}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup>
                                    <Form.Label>Повторите пароль:</Form.Label>
                                    <Input
                                        style={{outline: 'none'}}
                                        type="password" name="passwordrep" id="passwordRep"
                                        placeholder="Повторите пароль"
                                        onChange={this.hcrPasswordRepeat}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup className="mt-3" check>
                            <Input
                                type="checkbox" name="check" id="check"
                                onChange={this.clickCheckRegister}
                            />
                            <Label for="exampleCheck" check>Я принимаю <a target='_blank' href={window.location.href + 'rules'}  >Условия пользования сайтом</a>.</Label>
                        </FormGroup>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button color="primary" className="btn-wide btn-pill btn-shadow btn-hover-shine"
                            size="lg"
                            onClick={() => this.registerUser()}
                    >Создать аккаунт</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}