import React from "react";
import {connect } from 'react-redux';

import {addProduct, removeAll, removeProduct, changeProduct} from '../storeReducers/basket/actions';
import ProductsAdminAndPartner from './ProductsAdminAndPartner';


class ProductsReduxContainer extends React.Component{
    render() {
        return <ProductsAdminAndPartner
            basket={ this.props.basketArray }
            addProduct={ this.props.addProduct }
            removeProduct={ this.props.removeProduct }
            removeAll={ this.props.removeAll }
            changeProduct={ this.props.changeProduct }
        />
    }
}

const mapStateToProps = state => {
    return {
        basketArray: state.basket
    }
};

const mapDispatchToProps = {
    addProduct,
    removeProduct,
    removeAll,
    changeProduct
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductsReduxContainer);