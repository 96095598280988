import React from "react";
import {Button, Col, InputGroup, Row} from "react-bootstrap";
import {Input, InputGroupAddon} from "reactstrap";

export default class App extends React.Component {
    /*** ================================================================================
     *          Default Render
     * ================================================================================*/
    render() {
        return(
            <Row style={{paddingBottom: 15}}>
                <Col md={12}>
                    <Input
                        placeholder="Поиск"
                        onChange={(e) => this.props.handleSearch(e)}
                    />
                </Col>
            </Row>
        )
    }
}