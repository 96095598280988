import React from "react";
import {CardBody, CardHeader, Col, Row} from "reactstrap";
import CountUp from "react-countup/build";
import {Card, Button} from "react-bootstrap";
import CardFooter from "reactstrap/es/CardFooter";

export default class App extends React.Component{
    constructor (props, context) {
        super(props, context);
        this.state = {};
    }
    componentDidMount() {
    }

    render() {
        return (
            <Card className="main-card mb-3 CardProductsAnimation" >
                {/*<CardHeader>*/}
                {/*    <div className="card-header-title font-size-lg text-capitalize font-weight-normal">*/}
                {/*        {this.props.name}*/}
                {/*    </div>*/}
                {/*</CardHeader>*/}
                <CardBody>
                    <div style={{
                        position: 'relative',
                        display: 'flex',
                        minHeight: 300,
                    }}>
                        <img
                            // className="d-block w-100"
                            src={this.props.image}
                            alt="First slide"
                            style={{
                                position: 'absolute',
                                width: '100%',
                                height: 'auto!important',
                                top: '50%',
                                left: '50%',
                                marginRight: '-50%',
                                transform: 'translate(-50%, -50%)',
                            }}
                        />
                    </div>
                    <div
                        style={{
                            position: 'relative',
                            minHeight: 65,
                        }}
                        className="widget-content p-0">
                        <div className="widget-heading"
                             style={{
                                 position: 'absolute',
                                 top: '50%',
                                 left: '50%',
                                 marginRight: '-50%',
                                 transform: 'translate(-50%, -50%)',
                                 opacity: .8,
                                 fontWeight: 'bold',
                             }}
                        >
                            {this.props.name}
                        </div>
                    </div>
                    {/*<div className="widget-content p-0">*/}
                    {/*    <div className="widget-content-wrapper">*/}
                    {/*        <div className="widget-content-left">*/}
                    {/*            <div className="widget-heading">*/}
                    {/*                Цена:*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className="widget-content-right">*/}
                    {/*            <div className="widget-numbers widget-numbers-sm text-primary">*/}
                    {/*                <CountUp start={-25.9}*/}
                    {/*                         end={this.props.price}*/}
                    {/*                         separator=""*/}
                    {/*                         decimals={0}*/}
                    {/*                         decimal=","*/}
                    {/*                         prefix=""*/}
                    {/*                         duration="1"/> грн.*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </CardBody>
                <CardFooter className="LandingCardProductsFooter">
                    <Button
                        variant="primary"
                        onClick={() => this.props.click()}
                    >
                        Узнать цену
                    </Button>
                </CardFooter>
            </Card>
        )
    }
}