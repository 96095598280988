import React from 'react';
import '../products/Products.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Tabs, Tab, Button, Modal, Form, Col, Row,} from 'react-bootstrap';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {
    calcSummsAndQuantity,
    getDataFromCollectionWhereKeyValue,
    getDataFromCollection,
    updateDocumentInCollection,
    checkTTN, sendMessageTelegramBotWithSettings, getDateFromMilliseconds,
} from '../firebase/Firebase';
import Select from "react-select";
import TableOrders from "../orders/TableOrders";
import BalanceTable from "../balance/BalanceTable";
import TabsTablesOrder from "../orders/TabsTablesOrder";

class App extends React.Component {
    /*** ================================================================================
     *          Main methods
     * ================================================================================*/
    constructor (props, context) {
        super(props, context);
        this.state = {
            // DB Array
            orders: [],
            ordersAdmin: [],
            ordersPartner: [],
            ordersFiltredAdmin: [],
            ordersFiltredPartner: [],
            users: [],
            // Change Order
            isChangeOrder: false,
            currentChangeOrder: {date: ''},
            idCO: '',
            nameCO: '',
            phoneCO: '',
            cityCO: '',
            notesCO: '',
            paymentTypeCO: '',
            statusCO: '',
            statusNPCO: '',
            TTNNPCO: '',
            TTNStatusNPCO: '',
            fromUIDCO: '',
            fromEmailCO: '',
            fromNameCO: '',
            dateCO: '',
            summCO: '',
            productsCO: '',
            activeCO: '',
            summBackCO: '',
            statusFinishCO: false,

            // Show Modal Windows
            isOrdersShow: false,
            isBalanceShow: false,

            ordersCurrentUser: [],
            balanceCurrentUser: [],

            // Tab Select
            tabSelectAdmin: '',
            tabSelectPartner: '',


        };
        this.getOrders = this.getOrders.bind(this);
        this.refresh = this.refresh.bind(this);
    }
    componentDidMount() {
        this.refresh();
    }
    refresh(){
        this.getOrders();
        this.getUsers();
    }
    /*** ================================================================================
     *          handle Change Order
     * ================================================================================*/
    updateNameCO = e => {
        this.setState({nameCO: e.target.value});
    };
    updatePhoneCO = e => {
        this.setState({phoneCO: e.target.value});
    };
    updateCityCO = e => {
        this.setState({cityCO: e.target.value});
    };
    updateNotesCO = e => {
        this.setState({notesCO: e.target.value});
    };
    updatePaymentTypeCO = e => {
        this.setState({paymentTypeCO: e.value});
    };
    updateStatusCO = e => {
        this.setState({statusCO: e.value});
    };
    updateTTNNPCO = e => {
        this.setState({TTNNPCO: e.target.value});
    };
    updateSummBackCO = e => {
        this.setState({summBackCO: e.target.value});
    };
    updateStatusFinishCO = e => {
        if (e.value === 'В работе'){
            this.setState({statusFinishCO: false});
        }else if (e.value === 'Завершен'){
            this.setState({statusFinishCO: true});
        }

    };
    getStatusFinished(status){
        if (!status){
            return { value: 'В работе', label: 'В работе' };
        }else {
            return { value: 'Завершен', label: 'Завершен' };
        }
    }
    /*** ================================================================================
     *          Data Base
     * ================================================================================*/
    getOrders () {
        getDataFromCollection("orders").then( r => {
            try {
                // Sort Array
                const data = r.data.sort(function(a,b){
                    return new Date(b.date) - new Date(a.date);
                });
                // divide array to partner and own orders
                const dataAdmin = [];
                const dataPartner = [];
                data.forEach(d => {
                    if (d.ownerUIDP !== localStorage.getItem('uid')){
                        dataPartner.push(d);
                    }else if (d.ownerUIDP === localStorage.getItem('uid')){
                        dataAdmin.push(d);
                    }
                });
                getDataFromCollection("usders").then( r => {
                    // Set state
                    this.setState({
                        orders: data,
                        ordersAdmin: dataAdmin,
                        ordersPartner: dataPartner,
                        ordersFiltredAdmin: dataAdmin,
                        ordersFiltredPartner: dataPartner,
                        users: r.data
                    });
                });

            }catch (e) {

            }
        });
    };
    getUsers () {

    };
    getOrdersCurrentUser = (uid) => {
        try {
            const data = this.state.orders.filter(el => el.fromUID === uid);
            this.setState({ordersCurrentUser: data});
        }catch (e) {
            console.log(e);
        }
    };
    getBalanceCurrentUser(uid){
        getDataFromCollectionWhereKeyValue("balance", "fromUID", uid).then( r => {
            const data = r.data.sort(function(a,b){
                return new Date(b.date) - new Date(a.date);
            });
            this.setState({ balanceCurrentUser: data });
        });
    }
    /*** ================================================================================
     *          Update Order
     * ================================================================================*/
    updateOrder(){
        const order = {
            name: this.state.nameCO,
            phone: this.state.phoneCO,
            city: this.state.cityCO,
            notes: this.state.notesCO,
            paymentType: this.state.paymentTypeCO,
            status: this.state.statusCO,
            TTNNP: this.state.TTNNPCO,
            summBack: parseInt(this.state.summBackCO),
            active: this.state.statusFinishCO,
        };
        updateDocumentInCollection("orders", this.state.idCO, order).then( r => {
            calcSummsAndQuantity(this.state.currentChangeOrder.fromUID);
            calcSummsAndQuantity(this.state.currentChangeOrder.ownerUIDP);
            calcSummsAndQuantity(localStorage.getItem('uid'));
            sendMessageTelegramBotWithSettings("CHANGE_ORDER", {
                fromUID: this.state.currentChangeOrder.fromUID,
                ownerUIDP: this.state.currentChangeOrder.ownerUIDP,
                order: order,
            });
            this.refresh();
            this.clickChangeOrder();
        });
    }
    /*** ================================================================================
     *          Modal Windows Click
     * ================================================================================*/
    clickOrdersShow = (uid) => {
        if (!this.state.isOrdersShow){
            this.getOrdersCurrentUser(uid);
            this.setState({isOrdersShow: true});
        }else {
            this.setState({isOrdersShow: false});
        }
    };
    clickBalanceShow = (uid) => {
        if (!this.state.isBalanceShow){
            this.getBalanceCurrentUser(uid);
            this.setState({isBalanceShow: true});
        }else {
            this.setState({isBalanceShow: false});
        }
    };
    /*** ================================================================================
     *          Change Order
     * ================================================================================*/
    clickChangeOrder = (p) => {
        if (!this.state.isChangeOrder){
            this.setState({isChangeOrder: true});
            this.setState({currentChangeOrder: p});
            this.sendHandleChangeOrder(p)
        }else {
            this.setState({isChangeOrder: false});
        }
    };
    sendHandleChangeOrder(o){
        this.setState({
            idCO: o.id,
            nameCO: o.name,
            phoneCO: o.phone,
            cityCO: o.city,
            notesCO: o.notes,
            paymentTypeCO: o.paymentType,
            statusCO: o.status,
            TTNNPCO: o.TTNNP,
            summBackCO: o.summBack,
            statusFinishCO: o.active,
        });
    };
    /*** ================================================================================
     *          Nova Poshta
     * ================================================================================*/
    checkTTN = () => {
        try {
            const data = this.state.orders;
            data.forEach(d => {
                if (d.TTNNP !== 'ТТН не присвоен'){
                    checkTTN(d).then( r => {
                        updateDocumentInCollection("orders", d.id, {TTNStatusNP: r.TTNStatusNP,}).then( u => {
                            this.getOrders();
                        });
                    })
                }
            });
        }catch (e) {
            console.log(e);
        }
    };
    checkTTNPartner = () => {
        try {
            const data = this.state.ordersFiltredPartner;
            data.forEach(d => {
                if (d.TTNNP !== 'ТТН не присвоен'){
                    checkTTN(d).then( r => {
                        updateDocumentInCollection("orders", d.id, {TTNStatusNP: r.TTNStatusNP,}).then( u => {
                            this.getOrders();
                        });
                    })
                }
            });
        }catch (e) {
            console.log(e);
        }
    };
    /*** ================================================================================
     *          Default Render
     * ================================================================================*/
    render() {
        return (
            <div className="ProductWindow-Container">
                {/*UserOrdersShow*/}
                <Modal
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                    show={this.state.isUserOrdersShow}
                    onHide={() => this.setState({
                        isUserOrdersShow: false
                    })}
                >
                    <Modal.Header></Modal.Header>
                    <Modal.Body>
                        <TableOrders
                            isBtnChange={false}
                            users={this.state.users}
                            orders={this.state.ordersCurrentUser}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            onClick={() => this.setState({
                                isUserOrdersShow: false
                            })}
                            className="btn btn-primary">Закрыть</button>
                    </Modal.Footer>
                </Modal>
                {/*Change Order*/}
                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    // centered
                    show={this.state.isChangeOrder}
                    onHide={() => this.clickChangeOrder()}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {'Дата создания заказа: ' + getDateFromMilliseconds(this.state.currentChangeOrder.date)}
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="formGroupPassword">
                                    <Form.Label>Тип оплаты</Form.Label>
                                    <Select
                                        value={ {value: this.state.paymentTypeCO, label: this.state.paymentTypeCO} }
                                        onChange={this.updatePaymentTypeCO}
                                        options={[
                                            { value: 'Наложенный платеж', label: 'Наложенный платеж' },
                                            { value: 'Предоплата', label: 'Предоплата' },
                                        ]}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formGroupEmail">
                                    <Form.Label>ФИО покупателя</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Укажите ФИО покупателя"
                                        onChange={this.updateNameCO}
                                        value={this.state.nameCO}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="formGroupEmail">
                                    <Form.Label>Номер телефона</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Укажите номер телефона"
                                        onChange={this.updatePhoneCO}
                                        value={this.state.phoneCO}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formGroupPassword">
                                    <Form.Label>Доставка</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Город и номер отделения новой почты"
                                        onChange={this.updateCityCO}
                                        value={this.state.cityCO}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="formGroupEmail">
                                    <Form.Label>ТТН</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Укажите ТТН"
                                        onChange={this.updateTTNNPCO}
                                        value={this.state.TTNNPCO}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formGroupPassword">
                                    <Form.Label>Статус</Form.Label>
                                    <Select
                                        value={ {value: this.state.statusCO, label: this.state.statusCO} }
                                        onChange={this.updateStatusCO}
                                        options={[
                                            { value: 'Новый', label: 'Новый' },
                                            { value: 'Принят', label: 'Принят' },
                                            { value: 'Отказ', label: 'Отказ' },
                                            { value: 'Отправлен', label: 'Отправлен' },
                                            { value: 'Завершен', label: 'Завершен' },
                                            { value: 'Возврат', label: 'Возврат' },
                                        ]}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="summBack">
                                    <Form.Label>Укажите сумму за возврат посылки</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Итого возврата посылки"
                                        onChange={this.updateSummBackCO}
                                        value={this.state.summBackCO}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="summBack">
                                    <Form.Label>Закрытие заказа</Form.Label>
                                    <Select
                                        value={ this.getStatusFinished(this.state.statusFinishCO) }
                                        onChange={this.updateStatusFinishCO}
                                        options={[
                                            { value: 'В работе', label: 'В работе' },
                                            { value: 'Завершен', label: 'Завершен' },
                                        ]}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Form.Group controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Заметки</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows="3"
                                        placeholder="Заметки"
                                        onChange={this.updateNotesCO}
                                        value={this.state.notesCO}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button color="primary" onClick={() => this.updateOrder()}>Сохранить</Button>
                        <Button color="primary" onClick={() => this.clickChangeOrder()}>Закрыть</Button>
                    </Modal.Footer>
                </Modal>
                {/*Show Orders*/}
                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    show={this.state.isOrdersShow}
                    onHide={() => this.clickOrdersShow()}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {'Все заказы'}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <TableOrders
                            adminNoChange={true}
                            users={this.state.users}
                            orders={this.state.ordersCurrentUser}
                        />
                    </Modal.Body>
                </Modal>
                {/*Show Balance*/}
                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    show={this.state.isBalanceShow}
                    onHide={() => this.clickBalanceShow()}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {'Все выплаты'}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <BalanceTable balanceOperation={this.state.balanceCurrentUser} isBtnChange={false}/>
                    </Modal.Body>
                </Modal>
                {/*Content*/}
                <Tabs variant="pills"  defaultActiveKey="Мои заказы" transition={false} id="noanim-tab-example" >
                    <Tab eventKey="Мои заказы" title={"Мои заказы " + this.state.ordersAdmin.length}>
                        <TabsTablesOrder
                            refresh={this.refresh}
                            adminChange={true}
                            users={this.state.users}
                            orders={this.state.ordersAdmin}
                            clickBalanceShow={this.clickBalanceShow}
                            clickOrdersShow={this.clickOrdersShow}
                            clickChangeOrder={this.clickChangeOrder}
                            getOrdersCurrentUser={this.getOrdersCurrentUser}
                            checkTTN={this.checkTTN}
                            getOrders={() => this.getOrders()}
                        />
                    </Tab>
                    <Tab eventKey="Заказы партнеров" title={"Заказы партнеров " + this.state.ordersPartner.length}>
                        <TabsTablesOrder
                            refresh={this.refresh}
                            adminChange={true}
                            users={this.state.users}
                            orders={this.state.ordersPartner}
                            clickBalanceShow={this.clickBalanceShow}
                            clickOrdersShow={this.clickOrdersShow}
                            clickChangeOrder={this.clickChangeOrder}
                            getOrdersCurrentUser={this.getOrdersCurrentUser}
                            checkTTN={this.checkTTN}
                            getOrders={this.getOrders}
                        />
                    </Tab>
                </Tabs>
            </div>
        )}
}

export default App;
