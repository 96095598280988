import React from "react";
import {Card, CardBody, CardTitle, Col, Row} from "reactstrap";
import CountUp from "react-countup/build";

const style = {
    textTransform: 'uppercase',
    color: 'rgba(18, 21, 78, 0.7)',
    fontWeight: 'bold',
    fontSize: '0.88rem',
};

export default class App extends React.Component {
    /*** ================================================================================
     *          Default Render
     * ================================================================================*/
    render() {
        return(
            <Row>
                <Col lg="3">
                    <Card className="main-card mb-3">
                        <CardBody>
                            <div style={style}>Холд</div>
                            <h3>₴<CountUp end={this.props.summHold} duration="1"/></h3>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg="3">
                    <Card className="main-card mb-3">
                        <CardBody>
                            <div style={style}>Завершен</div>
                            <h3>₴<CountUp end={this.props.summFinished} duration="2"/></h3>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg="3">
                    <Card className="main-card mb-3">
                        <CardBody>
                            <div style={style}>Возврат</div>
                            <h3>₴<CountUp end={this.props.summBack} duration="3"/></h3>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg="3">
                    <Card className="main-card mb-3">
                        <CardBody>
                            <div style={style}>На балансе</div>
                            <h3>₴<CountUp end={this.props.summBalance} duration="4"/></h3>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }
}