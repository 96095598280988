import React from "react";
import {connect } from 'react-redux';

import { addProduct, removeProduct, removeAll, changeProduct } from '../storeReducers/basket/actions';
import BasketOrders from './BasketOrders';


class BasketOrdersReduxContainer extends React.Component{
    render() {
        return <BasketOrders
            basket={ this.props.basketArray }
            addProduct={ this.props.addProduct }
            removeProduct={ this.props.removeProduct }
            removeAll={ this.props.removeAll }
            changeProduct={ this.props.changeProduct }
        />
    }
}

const mapStateToProps = state => {
    return {
        basketArray: state.basket
    }
};

const mapDispatchToProps = {
    addProduct,
    removeProduct,
    removeAll,
    changeProduct
};

export default connect(mapStateToProps, mapDispatchToProps)(BasketOrdersReduxContainer);