import React from "react";
import CountUp from "react-countup/build";
import {Button} from "react-bootstrap";
import ReactTable from "react-table";

export default class App extends React.Component {
    /*** ================================================================================
     *          Default Render
     * ================================================================================*/
    render() {
        return(
            <ReactTable
                previousText={'Предыдущая страница'}
                nextText={'Следующая страница'}
                pageText='Страница'
                ofText='из'
                rowsText='строк'
                data={this.props.news}
                columns={[
                    {
                        columns: [
                            {
                                Header: "Информация",
                                id: "info",
                                minWidth: 300,
                                accessor: d => (
                                    <div style={{overflow: 'auto', height: '100%', textAlign: 'top', color: '#495057'}}>
                                        <div style={{textAlign: 'left'}}>
                                            <b>Заголовок:</b> {d.headerNews}
                                        </div>
                                        <div style={{textAlign: 'left'}}>
                                            <b>Текст:</b> {d.textNews}
                                        </div>
                                    </div>
                                )
                            },
                            {
                                Header: "Действия",
                                id: "doing",
                                minWidth: 100,
                                accessor: d => (
                                    <div style={{overflow: 'none', height: '100%', textAlign: 'top'}}>
                                        <div>
                                            <Button style={{textAlign: 'center', width: '100%'}} className="mb-2 mr-2" color="primary" onClick={() => this.props.deleteNews(d)}>
                                                Удалить
                                            </Button>
                                        </div>
                                    </div>
                                )
                            },
                        ]}
                ]}
                defaultPageSize={10}
                className="-striped -highlight"
            />
        )
    }
}