import React from "react";
import {CardBody, Col, Row} from "reactstrap";
import {Button, Card} from "react-bootstrap";
import Ionicon from "react-ionicons";
import man from "../../../images/happy man.png";
import Dots from "./Dots";

export default class App extends React.Component{
    constructor (props, context) {
        super(props, context);
        this.state = {};
    }
    componentDidMount() {
    }

    render() {
        return (
            <Row className="Landing-Container-Fragment1">
                <Col sm="12" md="6" className="align-self-center">
                    <Card className="mb-3 no-shadow">
                        <CardBody className="p-0">
                            <div className="Landing-Title-Container">
                                <div className="Landing-Title-Container-Title1">Дропшиппинг платформа </div>
                                <div className="Landing-Title-Container-Title2">DropMaster</div>
                                <div className="Landing-Title-Container-Title3">Зарабатывай без вложений в товар!</div>
                                <Button
                                    color="primary"
                                    className="btn-pill btn-shadow btn-wide fsize-1"
                                    size="lg"
                                    onClick={() => this.props.clickRegisterBox()}
                                >
                                    <span className="mr-2 opacity-7">
                                        <Ionicon color="#ffffff" icon="ios-analytics-outline" beat={true}/>
                                    </span>
                                    <span className="mr-1">
                                        Регистрация
                                    </span>
                                </Button>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col sm="12" md="6" className="Landing-Container-Fragment1-Col2">
                    <div className="Landing-Container-Fragment1-Man-Image-Container">
                        <div className="Landing-Container-Fragment1-Man-RoundFigure2"></div>
                        <div className="Landing-Container-Fragment1-Man-RoundFigure1">
                            <Dots/>
                        </div>
                        <img src={man} alt="man" className="Landing-Container-Fragment1-Man-Image"/>
                    </div>
                </Col>
            </Row>
        )
    }
}