import React from "react";
import {CardBody, Col, Row} from "reactstrap";

export default class App extends React.Component{
    constructor (props, context) {
        super(props, context);
        this.state = {};
    }
    componentDidMount() {
    }

    render() {
        return (
            <Row className="Landing-Container-Fragment2 mb-5">
                <Col>
                    <h1>{this.props.text}</h1>
                </Col>
            </Row>
        )
    }
}