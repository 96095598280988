import React from 'react';
import '../products/Products.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Tabs, Tab, Button, Form} from 'react-bootstrap';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {
    filterOrdersBack,
    filterOrdersFinished,
    filterOrdersHold,
    fire, getAllSummsAndQuantityAllUsers, getCalcJson,
    getDataFromCollection, getDataFromCollectionWhereKeyValue, getUserJson, summInArray, summInArrayMinusSumm
} from '../firebase/Firebase';
import {Card, CardBody, CardTitle, Col, Row} from "reactstrap";
import CardsStatistic from './CardsStatistic'


import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import ReactTable from "react-table";
import './style.css'
import OrdersCards from './OrdersCardsStatistic'
import UserCardsStatistic from './UserCardsStatistic'
import * as moment from 'moment';
import 'moment-timezone';
import {
    addProduct, changeProduct,
    clearAdmin,
    clearUser,
    removeAll,
    removeProduct,
    setAdmin,
    setUser
} from "../storeReducers/basket/actions";
import {connect} from "react-redux";

const styleCell = {overflow: "hidden",fontFamily: "Pe-icon-7-stroke", fontWeight: "400",};
const styleCellIn = {fontFamily: "Pe-icon-7-stroke", fontWeight: "550",};
function momentToString(mom){
    return (
        (mom.get('year')) + '-' +
        (mom.get('month') + 1) + '-' +
        (mom.get('date'))
    )
}

class App extends React.Component {
    /*** ================================================================================
     *          Main
     * ================================================================================*/
    constructor (props, context) {
        super(props, context);
        this.state = {
            // Orders
            orders: [],
            resultOrders: {
                ordersAll: [],
                ordersHoldAll: [],
                ordersFinishedAll: [],
                ordersBackAll: [],
                summHoldAll: 0,
                summFinishedAll: 0,
                summBackAll: 0,

                ordersAdmin: [],
                ordersHoldAdmin: [],
                ordersFinishedAdmin: [],
                ordersBackAdmin: [],
                summHoldAdmin: 0,
                summFinishedAdmin: 0,
                summBackAdmin: 0,

                ordersPartner: [],
                ordersHoldPartner: [],
                ordersFinishedPartner: [],
                ordersBackPartner: [],
                summHoldPartner: 0,
                summFinishedPartner: 0,
                summBackPartner: 0,

                earnedAdmin: 0,
                earnedPartner: 0,
            },
            // Balance Operation
            balanceOperation: [],
            balanceOperationInput: [],
            balanceOperationOutput: [],
            summInput: 0,
            summOutput: 0,
            // Users
            users: [],
            summBalanceUsers: [],
            resultUsersCalc: getCalcJson(),
            adminCalc: getCalcJson(),

            // Date
            rowsInTable: 8,
            statistic: [],

            ordersFiltred: [],

            pickerStartDate: (moment(new Date()).subtract(1, 'months')),
            pickerEndDate: moment(new Date()),
            dateArray: [moment(new Date())],

            currentUser: getUserJson(),
        };
        this.handlePickerApply = this.handlePickerApply.bind(this);
        this.handlePicker = this.handlePicker.bind(this);
        this.createArrayOfDates = this.createArrayOfDates.bind(this);
    }
    componentDidMount() {
        this.getOrders();
        this.getUsers();
        this.getBalanceOperation();
    }
    /*** ================================================================================
     *          Data Base
     * ================================================================================*/
    getOrders(){
        getDataFromCollection("orders").then(o => {
            const ordersAll = o.data;
            const ordersHoldAll = filterOrdersHold(ordersAll);
            const ordersFinishedAll = filterOrdersFinished(ordersAll);
            const ordersBackAll = filterOrdersBack(ordersAll);
            // Admins orders
            const ordersAdmin = [];
            // Partner orders
            const ordersPartner = [];
            // Divide all orders to Admin and Partner orders
            ordersAll.forEach( order => {
                if ((order.summDrop - order.summPurchase) === 0){
                    ordersAdmin.push(order);
                }else if ((order.summDrop - order.summPurchase) > 0){
                    ordersPartner.push(order);
                }
            });
            const ordersHoldAdmin = filterOrdersHold(ordersAdmin);
            const ordersFinishedAdmin = filterOrdersFinished(ordersAdmin);
            const ordersBackAdmin = filterOrdersBack(ordersAdmin);
            //
            const ordersHoldPartner = filterOrdersHold(ordersPartner);
            const ordersFinishedPartner = filterOrdersFinished(ordersPartner);
            const ordersBackPartner = filterOrdersBack(ordersPartner);
            //
            const summHoldAll = summInArray(ordersHoldAll, 'summ');
            const summFinishedAll = summInArray(ordersFinishedAll, 'summ');
            const summBackAll = summInArray(ordersBackAll, 'summBack');
            //
            const summHoldAdmin = summInArray(ordersHoldAdmin, 'summ');
            const summFinishedAdmin = summInArray(ordersFinishedAdmin, 'summ');
            const summBackAdmin = summInArray(ordersBackAdmin, 'summBack');
            //
            const summHoldPartner = summInArray(ordersHoldPartner, 'summ');
            const summFinishedPartner = summInArray(ordersFinishedPartner, 'summ');
            const summBackPartner = summInArray(ordersBackPartner, 'summBack');
            //
            const earnedAdmin = summInArray(ordersFinishedAdmin , 'summPurchase');
            const earnedPartner = summInArray( ordersFinishedPartner , 'summPurchase');
            //
            const resultOrders = {
                ordersAll: ordersAll,
                ordersHoldAll: ordersHoldAll,
                ordersFinishedAll: ordersFinishedAll,
                ordersBackAll: ordersBackAll,
                summHoldAll: summHoldAll,
                summFinishedAll: summFinishedAll,
                summBackAll: summBackAll,

                ordersAdmin: ordersAdmin,
                ordersHoldAdmin: ordersHoldAdmin,
                ordersFinishedAdmin: ordersFinishedAdmin,
                ordersBackAdmin: ordersBackAdmin,
                summHoldAdmin: summHoldAdmin,
                summFinishedAdmin: summFinishedAdmin,
                summBackAdmin: summBackAdmin,

                ordersPartner: ordersPartner,
                ordersHoldPartner: ordersHoldPartner,
                ordersFinishedPartner: ordersFinishedPartner,
                ordersBackPartner: ordersBackPartner,
                summHoldPartner: summHoldPartner,
                summFinishedPartner: summFinishedPartner,
                summBackPartner: summBackPartner,
                //
                earnedAdmin: earnedAdmin,
                earnedPartner: earnedPartner,
            };
            this.setState({
                resultOrders: resultOrders,
                orders: ordersAll,
            });
            this.createArrayOfDates(moment(new Date()).subtract(1, 'months'), moment(new Date())).then( r => {
                this.handlePickerApply(r.dateArray, r.rowsInTable);
            });
        });
    }
    getUsers() {
        getDataFromCollectionWhereKeyValue('usders', 'uid', this.props.adminSettings.uid).then( admin => {
            getDataFromCollection("usders").then(u => {
                getAllSummsAndQuantityAllUsers().then( result => {
                    // console.log(result)
                    this.setState({
                        users: u.data,
                        summBalanceUsers: result.summBalance,
                        resultUsersCalc: result,
                        adminCalc: admin.data[0],
                    });
                });
            });
        });
    }
    getBalanceOperation(){
        getDataFromCollection('balance').then(b => {
            const balanceOperation = b.data;
            const balanceOperationInput = balanceOperation.filter( (el) => el.typeOperation.includes('Пополнение баланса')).filter( (el) => el.status.includes('Завершен') );
            const balanceOperationOutput = balanceOperation.filter( (el) => el.typeOperation.includes('Вывод средств')).filter( (el) => el.status.includes('Завершен') );
            const summInput = summInArray(balanceOperationInput, 'summ');
            const summOutput = summInArray(balanceOperationOutput, 'summ');
            this.setState({
                balanceOperation: balanceOperation,
                balanceOperationInput: balanceOperationInput,
                balanceOperationOutput: balanceOperationOutput,
                summInput: summInput,
                summOutput: summOutput,
            })
        })
    }
    /*** ================================================================================
     *          Handle Date
     * ================================================================================*/
    handlePicker(event, picker) {
        this.createArrayOfDates(moment(picker.startDate), moment(picker.endDate) ).then( r => {
            this.handlePickerApply(r.dateArray, r.rowsInTable);
        });
    }
    createArrayOfDates(sDate, eDate){
        return new Promise(function (resolve, reject) {
            const dateArray = [];
            var rowsInTable = 50;
            while (momentToString(sDate) !== momentToString(eDate)){
                dateArray.push(momentToString(eDate));
                eDate.subtract(1, 'days');
            }
            dateArray.push(momentToString(sDate));
            if (dateArray.length <= 50){
                rowsInTable = dateArray.length;
            }else {
                rowsInTable = 50;
            }
            resolve({dateArray : dateArray, rowsInTable: rowsInTable});
        });
    }
    handlePickerApply(dateArray, rowsInTable){
        var resultArray = [];
        dateArray.forEach(d => {
            var result = {
                day: '',
                moment: null,
                summHold: 0,
                summFinished: 0,
                summBack: 0,
                quantityOrders: 0,
                quantityHold: 0,
                quantityFinished: 0,
                quantityBack: 0,
            };
            var data = this.state.orders.filter(function (el) {
                return momentToString(moment(el.date)) === (d);
            });
            result.day = d;
            result.quantityOrders = data.length;
            var finishOrders = filterOrdersFinished(data);
            var holdOrders = filterOrdersHold(data);
            var backOrders = filterOrdersBack(data);
            var summFinished = summInArray(finishOrders, 'summ');
            result.summFinished = summFinished;
            result.quantityFinished = finishOrders.length;
            var summHold = summInArray(holdOrders, 'summ');
            result.summHold = summHold;
            result.quantityHold = holdOrders.length;
            var summBack = summInArray(backOrders, 'summBack');
            result.summBack = summBack;
            result.quantityBack = backOrders.length;
            resultArray.push(result);
        });
        this.setState({ordersFiltred: resultArray, rowsInTable: rowsInTable});
        // console.log(resultArray);
    }
    forWeek(){
        this.createArrayOfDates(moment(new Date()).subtract(7, 'days'), moment(new Date())).then( r => {
            this.handlePickerApply(r.dateArray, r.rowsInTable);
        });
    }
    forMonth(){
        this.createArrayOfDates(moment(new Date()).subtract(1, 'months'), moment(new Date())).then( r => {
            this.handlePickerApply(r.dateArray, r.rowsInTable);
        });
    }
    forYear(){
        this.createArrayOfDates(moment(new Date()).subtract(1, 'years'), moment(new Date())).then( r => {
            this.handlePickerApply(r.dateArray, r.rowsInTable);
        });
    }
    /*** ================================================================================
     *          Else Methods
     * ================================================================================*/
    isVerifiedElement(bool, text){
        if (text === undefined){
            text = "Не указан"
        }
        if (bool){
            return (
                <div className="pe-7s-check" style={styleCellIn}>
                    {text}
                </div>
            )
        }else {
            return (
                <div className="pe-7s-attention" style={styleCellIn}>
                    {text}
                </div>
            )
        }
    }
    isActiveAccount(bool){
        if (bool){
            return (
                <div className="pe-7s-check" style={styleCellIn}>
                    Активен
                </div>
            )
        }else {
            return (
                <div className="pe-7s-attention" style={styleCellIn}>
                    Не активен
                </div>
            )
        }
    }

    calcMidleValue(x, y){
        if (parseInt(parseInt(x)/parseInt(y)).toString() === 'NaN'){
            return 0
        }else {
            return parseInt(parseInt(x)/parseInt(y)).toString();
        }
    }
    calcMidleValuePercent(x, y){
        if (parseInt((parseInt(x)/parseInt(y))*100).toString() === 'NaN'){
            return '0'
        }else {
            return parseInt((parseInt(x)/parseInt(y))*100).toString();
        }
    }
    calcDiagrammQuantity(){
        var data = {
            labels: [
                'Заявки',
                'Забрано',
                'Отказы'
            ],
            datasets: [{
                data: [
                    this.state.currentUser.quantityHold,
                    this.state.currentUser.quantityFinished,
                    this.state.currentUser.quantityBack
                ],
                backgroundColor: [
                    '#8dace7',
                    '#71deb9',
                    '#ef869e'
                ],
                hoverBackgroundColor: [
                    '#8dace7',
                    '#71deb9',
                    '#ef869e'
                ]
            }]};
        return data;
    };
    calcDiagrammSumm(){
        var data = {
            labels: [
                'Заявки, грн',
                'Забрано, грн',
                'Отказы, грн'
            ],
            datasets: [{
                data: [
                    this.state.currentUser.summHold,
                    this.state.currentUser.summFinished,
                    this.state.currentUser.summBack
                ],
                backgroundColor: [
                    '#8dace7',
                    '#71deb9',
                    '#ef869e'
                ],
                hoverBackgroundColor: [
                    '#8dace7',
                    '#71deb9',
                    '#ef869e'
                ]
            }]};
        return data;
    };
    /*** ================================================================================
     *          Default Render
     * ================================================================================*/
    render() {
        return (
            <div>
                <div className="ProductWindow-Container">
                    <div className="ProductWindow-Table-Products-Container">
                        <Tabs variant="pills"  defaultActiveKey="Общие" transition={false} id="noanim-tab-example">
                            <Tab eventKey="Общие" title="Общие">
                                <CardsStatistic
                                    colSize={4}
                                    dataText={[
                                        'кол-во пользователей',
                                        'Активные пользователи',
                                        'кол-во заказов',

                                        'Заработано Админом',
                                        'Заработано Партнерами',
                                        'Заработано Платформой',

                                        'кол-во Холд',
                                        'кол-во Завершенные',
                                        'кол-во Возврат',

                                        'кол-во Холд (Админ)',
                                        'кол-во Завершенные (Админ)',
                                        'кол-во Возврат (Админ)',

                                        'кол-во Холд (Поставщик)',
                                        'кол-во Завершенные (Поставщик)',
                                        'кол-во Возврат (Поставщик)',

                                        'Пополнения баланса',
                                        'Выплаты баланса',
                                        'Сумма всех балансов',

                                        'кол-во Пополнения баланса',
                                        'кол-во Выплат баланса',
                                        'кол-во всех операций',
                                    ]}
                                    dataNumber={[
                                        this.state.users.length,
                                        this.state.users.filter(user => user.quantityOrders > 0).length,
                                        this.state.orders.length,

                                        '₴' + this.state.resultOrders.earnedAdmin,
                                        '₴' + this.state.resultOrders.earnedPartner,
                                        '₴' + (this.state.resultUsersCalc.summFinishedPrepaymentDropMaster + this.state.resultUsersCalc.summFinishedCODDropMaster),

                                        this.state.resultOrders.ordersHoldAll.length,
                                        this.state.resultOrders.ordersFinishedAll.length,
                                        this.state.resultOrders.ordersBackAll.length,

                                        this.state.resultOrders.ordersHoldAdmin.length,
                                        this.state.resultOrders.ordersFinishedAdmin.length,
                                        this.state.resultOrders.ordersBackAdmin.length,

                                        this.state.resultOrders.ordersHoldPartner.length,
                                        this.state.resultOrders.ordersFinishedPartner.length,
                                        this.state.resultOrders.ordersBackPartner.length,

                                        '₴' + this.state.resultUsersCalc.summInput,
                                        '₴' + this.state.resultUsersCalc.summPayed,
                                        '₴' + this.state.resultUsersCalc.summBalance,

                                        this.state.resultUsersCalc.quantityInput,
                                        this.state.resultUsersCalc.quantityPayed,
                                        (this.state.resultUsersCalc.quantityInput + this.state.resultUsersCalc.quantityPayed),
                                    ]}
                                />
                            </Tab>
                            <Tab eventKey="Платформа" title="Платформа">
                                <CardsStatistic
                                    colSize={4}
                                    dataText={[
                                        'Холд',
                                        'Холд предоплата',
                                        'Холд наложка',

                                        'Завершенные',
                                        'Завершенные предоплата',
                                        'Завершенные наложка',

                                    ]}
                                    dataNumber={[
                                        '₴' + (this.state.resultUsersCalc.summHoldPrepaymentDropMaster + this.state.resultUsersCalc.summHoldCODDropMaster),
                                        '₴' + this.state.resultUsersCalc.summHoldPrepaymentDropMaster,
                                        '₴' + this.state.resultUsersCalc.summHoldCODDropMaster,

                                        '₴' + (this.state.resultUsersCalc.summFinishedPrepaymentDropMaster + this.state.resultUsersCalc.summFinishedCODDropMaster),
                                        '₴' + this.state.resultUsersCalc.summFinishedPrepaymentDropMaster,
                                        '₴' + this.state.resultUsersCalc.summFinishedCODDropMaster,
                                    ]}
                                />
                            </Tab>
                            <Tab eventKey="Дропер" title="Дропер">
                                <CardsStatistic
                                    colSize={4}
                                    dataText={[
                                        // 'кол-во заказов',
                                        // 'кол-во заказов',
                                        // 'кол-во заказов',

                                        'Завершен',
                                        'Холд',
                                        'Возврат',

                                        'Завершенные предоплата',
                                        'Холд  предоплата',
                                        'Возврат предоплата',

                                        'кол-во Завершенные предоплата',
                                        'кол-во Холд предоплата',
                                        'кол-во Возврат предоплата',

                                        'Завершенные наложка',
                                        'Холд наложка',
                                        'Возврат наложка',

                                        'кол-во Завершенные наложка',
                                        'кол-во Холд наложка',
                                        'кол-во Возврат наложка',

                                    ]}
                                    dataNumber={[
                                        // this.state.resultUsersCalc.quantityOrdersPartner,
                                        // this.state.resultUsersCalc.quantityOrdersPartner,
                                        // this.state.resultUsersCalc.quantityOrdersPartner,

                                        '₴' + this.state.resultUsersCalc.summFinishedPartner,
                                        '₴' + this.state.resultUsersCalc.summHoldPartner,
                                        '₴' + this.state.resultUsersCalc.summBackPartner,

                                        '₴' + (-this.state.resultUsersCalc.summFinishedPrepaymentPartner),
                                        '₴' + (-this.state.resultUsersCalc.summHoldPrepaymentPartner),
                                        '₴' + (-this.state.resultUsersCalc.summBackPrepaymentPartner),

                                        this.state.resultUsersCalc.quantityFinishedPrepaymentPartner,
                                        this.state.resultUsersCalc.quantityHoldPrepaymentPartner,
                                        this.state.resultUsersCalc.quantityBackPrepaymentPartner,

                                        '₴' + this.state.resultUsersCalc.summFinishedCODPartner,
                                        '₴' + this.state.resultUsersCalc.summHoldCODPartner,
                                        '₴' + this.state.resultUsersCalc.summBackCODPartner,

                                        this.state.resultUsersCalc.quantityFinishedCODPartner,
                                        this.state.resultUsersCalc.quantityHoldCODPartner,
                                        this.state.resultUsersCalc.quantityBackCODPartner,

                                    ]}
                                />
                            </Tab>
                            <Tab eventKey="Поставщик" title="Поставщик">
                                <CardsStatistic
                                    colSize={4}
                                    dataText={[
                                        // 'кол-во заказов',
                                        // 'кол-во заказов',
                                        // 'кол-во заказов',

                                        'Завершен',
                                        'Холд',
                                        'Возврат',

                                        'Завершен предоплата',
                                        'Холд предоплата',
                                        'Возврат предоплата',

                                        'кол-во Завершен предоплата',
                                        'кол-во Холд предоплата',
                                        'кол-во Возврат предоплата',

                                        'Завершен наложка',
                                        'Холд наложка',
                                        'Возврат наложка',

                                        'кол-во Завершен наложка',
                                        'кол-во Холд наложка',
                                        'кол-во Возврат наложка',
                                    ]}
                                    dataNumber={[
                                        // this.state.resultUsersCalc.quantityOrdersProvider,
                                        // this.state.resultUsersCalc.quantityOrdersProvider,
                                        // this.state.resultUsersCalc.quantityOrdersProvider,

                                        '₴' + this.state.resultUsersCalc.summFinishedProvider,
                                        '₴' + this.state.resultUsersCalc.summHoldProvider,
                                        '₴' + this.state.resultUsersCalc.summBackProvider,

                                        '₴' + this.state.resultUsersCalc.summFinishedPrepaymentProvider,
                                        '₴' + this.state.resultUsersCalc.summHoldPrepaymentProvider,
                                        '₴' + this.state.resultUsersCalc.summBackPrepaymentProvider,

                                        this.state.resultUsersCalc.quantityFinishedPrepaymentProvider,
                                        this.state.resultUsersCalc.quantityHoldPrepaymentProvider,
                                        this.state.resultUsersCalc.quantityBackPrepaymentProvider,

                                        '₴' + (- this.state.resultUsersCalc.summFinishedCODProvider),
                                        '₴' + (- this.state.resultUsersCalc.summHoldCODProvider),
                                        '₴' + this.state.resultUsersCalc.summBackCODProvider,

                                        this.state.resultUsersCalc.quantityFinishedCODProvider,
                                        this.state.resultUsersCalc.quantityHoldCODProvider,
                                        this.state.resultUsersCalc.quantityBackCODProvider,
                                    ]}
                                />
                            </Tab>
                            <Tab eventKey="По датам" title="По датам">
                                <Row>
                                    <Col>
                                        <DateRangePicker
                                            startDate={this.pickerStartDate}
                                            endDate={this.pickerEndDate}
                                            onApply={this.handlePicker}
                                            locale={{
                                                "format": "DD/MM/YYYY",
                                                "separator": " - ",
                                                "applyLabel": "Применить",
                                                "cancelLabel": "Отмена",
                                                "fromLabel": "От",
                                                "toLabel": "До",
                                                "customRangeLabel": "Произвольно",
                                                "weekLabel": "Нед.",
                                                "daysOfWeek": [
                                                    "Вс",
                                                    "Пн",
                                                    "Вт",
                                                    "Ср",
                                                    "Чт",
                                                    "Пт",
                                                    "Сб"
                                                ],
                                                "monthNames": [
                                                    "Январь",
                                                    "Февраль",
                                                    "Март",
                                                    "Апрель",
                                                    "Май",
                                                    "Июнь",
                                                    "Июль",
                                                    "Август",
                                                    "Сентябрь",
                                                    "Октябрь",
                                                    "Ноябрь",
                                                    "Декабрь"
                                                ],
                                                "firstDay": 2
                                            }}
                                        >
                                            <Button className="mb-2 mr-2" color="primary">Выбрать диапазон времени</Button>
                                        </DateRangePicker>
                                        <Button className="mb-2 mr-2" color="primary" onClick={() => this.forWeek()}>За неделю</Button>
                                        <Button className="mb-2 mr-2" color="primary" onClick={() => this.forMonth()}>За месяц</Button>
                                        <Button className="mb-2 mr-2" color="primary" onClick={() => this.forYear()}>За год</Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <ReactTable
                                            previousText={'Предыдущая страница'}
                                            nextText={'Следующая страница'}
                                            pageText='Страница'
                                            ofText='из'
                                            rowsText='строк'
                                            data={this.state.ordersFiltred}
                                            columns={[
                                                {
                                                    columns: [
                                                        {
                                                            Header: "Дата",
                                                            id: "date",
                                                            maxWidth: 200,
                                                            accessor: d => (
                                                                <div style={{overflow: 'auto', height: '100%', textAlign: 'top'}}>
                                                                    <div>
                                                                        <i className="header-icon pe-7s-date"> </i>
                                                                        {d.day}
                                                                    </div>
                                                                </div>
                                                            )
                                                        },
                                                        {
                                                            Header: "Заявок",
                                                            id: "quantityOrders",
                                                            maxWidth: 200,
                                                            accessor: d => (
                                                                <div style={{overflow: 'auto', height: '100%', textAlign: 'top'}}>
                                                                    <div>
                                                                        {d.quantityOrders}
                                                                    </div>
                                                                </div>
                                                            )
                                                        },
                                                        {
                                                            Header: "Забрано",
                                                            id: "quantityFinishedPercent",
                                                            maxWidth: 200,
                                                            accessor: d => (
                                                                <div style={{overflow: 'auto', height: '100%', textAlign: 'top'}}>
                                                                    <div>
                                                                        {this.calcMidleValuePercent(d.quantityFinished, d.quantityOrders) + '%'}
                                                                    </div>
                                                                </div>
                                                            )
                                                        },
                                                        {
                                                            Header: "Холд",
                                                            id: "quantityHold",
                                                            maxWidth: 200,
                                                            accessor: d => (
                                                                <div style={{overflow: 'auto', height: '100%', textAlign: 'top'}}>
                                                                    <div>
                                                                        {d.quantityHold}
                                                                    </div>
                                                                </div>
                                                            )
                                                        },
                                                        {
                                                            Header: "Отказ",
                                                            id: "quantityBack",
                                                            maxWidth: 200,
                                                            accessor: d => (
                                                                <div style={{overflow: 'auto', height: '100%', textAlign: 'top'}}>
                                                                    <div>
                                                                        {d.quantityBack}
                                                                    </div>
                                                                </div>
                                                            )
                                                        },
                                                        {
                                                            Header: "Завершено",
                                                            id: "quantityFinished",
                                                            maxWidth: 200,
                                                            accessor: d => (
                                                                <div style={{overflow: 'auto', height: '100%', textAlign: 'top'}}>
                                                                    <div>
                                                                        {d.quantityFinished}
                                                                    </div>
                                                                </div>
                                                            )
                                                        },
                                                        {
                                                            Header: "Холд, грн",
                                                            id: "summHold",
                                                            maxWidth: 200,
                                                            accessor: d => (
                                                                <div style={{overflow: 'auto', height: '100%', textAlign: 'top'}}>
                                                                    <div>
                                                                        {d.summHold}
                                                                    </div>
                                                                </div>
                                                            )
                                                        },
                                                        {
                                                            Header: "Отказ, грн",
                                                            id: "summBack",
                                                            maxWidth: 200,
                                                            accessor: d => (
                                                                <div style={{overflow: 'auto', height: '100%', textAlign: 'top'}}>
                                                                    <div>
                                                                        {d.summBack}
                                                                    </div>
                                                                </div>
                                                            )
                                                        },
                                                        {
                                                            Header: "Завершено, грн",
                                                            id: "summFinished",
                                                            maxWidth: 200,
                                                            accessor: d => (
                                                                <div style={{overflow: 'auto', height: '100%', textAlign: 'top'}}>
                                                                    <div>
                                                                        {d.summFinished}
                                                                    </div>
                                                                </div>
                                                            )
                                                        },
                                                    ]}
                                            ]}
                                            defaultPageSize={10}
                                            className="-striped -highlight"
                                        />
                                    </Col>
                                </Row>
                            </Tab>
                            <Tab eventKey="Реферальная система" title="Реферальная система">
                                <CardsStatistic
                                    colSize={3}
                                    dataText={[
                                        'кол-во завершен',
                                        'кол-во холд',
                                        'Завершен',
                                        'Холд',

                                    ]}
                                    dataNumber={[
                                        this.state.resultUsersCalc.referalQuantityFinished,
                                        this.state.resultUsersCalc.referalQuantityHold,
                                        '₴' + this.state.resultUsersCalc.referalSummFinished,
                                        '₴' + this.state.resultUsersCalc.referaSummlHold,
                                    ]}
                                />
                            </Tab>
                            {/*<Tab eventKey="Все заказы" title="Все заказы">*/}
                            {/*    <OrdersCards*/}
                            {/*        quantityHold={this.state.resultOrders.ordersHoldAll.length}*/}
                            {/*        quantityFinished={this.state.resultOrders.ordersFinishedAll.length}*/}
                            {/*        quantityBack={this.state.resultOrders.ordersBackAll.length}*/}
                            {/*        summHold={this.state.resultOrders.summHoldAll}*/}
                            {/*        summFinished={this.state.resultOrders.summFinishedAll}*/}
                            {/*        summBack={this.state.resultOrders.summBackAll}*/}
                            {/*    />*/}
                            {/*</Tab>*/}
                            {/*<Tab eventKey="Заказы DropMaster" title="Заказы DropMaster">*/}
                            {/*    <OrdersCards*/}
                            {/*        quantityHold={this.state.resultOrders.ordersHoldAdmin.length}*/}
                            {/*        quantityFinished={this.state.resultOrders.ordersFinishedAdmin.length}*/}
                            {/*        quantityBack={this.state.resultOrders.ordersBackAdmin.length}*/}
                            {/*        summHold={this.state.resultOrders.summHoldAdmin}*/}
                            {/*        summFinished={this.state.resultOrders.summFinishedAdmin}*/}
                            {/*        summBack={this.state.resultOrders.summBackAdmin}*/}
                            {/*    />*/}
                            {/*</Tab>*/}
                            {/*<Tab eventKey="Заказы партнеров" title="Заказы партнеров">*/}
                            {/*    <OrdersCards*/}
                            {/*        quantityHold={this.state.resultOrders.ordersHoldPartner.length}*/}
                            {/*        quantityFinished={this.state.resultOrders.ordersFinishedPartner.length}*/}
                            {/*        quantityBack={this.state.resultOrders.ordersBackPartner.length}*/}
                            {/*        summHold={this.state.resultOrders.summHoldPartner}*/}
                            {/*        summFinished={this.state.resultOrders.summFinishedPartner}*/}
                            {/*        summBack={this.state.resultOrders.summBackPartner}*/}
                            {/*    />*/}
                            {/*</Tab>*/}
                        </Tabs>
                    </div>
                </div>
            </div>
        )}
}
const mapStateToProps = state => {
    return {
        basketArray: state.basket,
        user: state.basket.user,
        adminSettings: state.basket.adminSettings
    }
};

const mapDispatchToProps = {
    setAdmin,
    clearAdmin,
    setUser,
    clearUser,
    addProduct,
    removeProduct,
    removeAll,
    changeProduct
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
