import React, {Fragment, Component} from "react";

import Slider from "react-slick";

import bg3 from '../../../assets/utils/images/originals/citynights.jpg';

import {Col, Modal, Row, Card, Form,} from "react-bootstrap";
import {Button, CardFooter, CardLink, FormGroup, Input, Label, ListGroup, ListGroupItem,} from "reactstrap";
import NumberFormat from "react-number-format";
import {
    createNewUserAndGoToAccount,
    fire,
    getDataFromCollectionWhereKeyValue
} from "../../../componentsWithOutTheme/firebase/Firebase";
import {provider} from "../../../componentsWithOutTheme/firebase/FirebaseProvider";
import {
    addProduct, changeProduct,
    clearAdmin,
    clearUser, removeAll, removeProduct,
    setAdmin,
    setUser
} from "../../../componentsWithOutTheme/storeReducers/basket/actions";
import {connect} from "react-redux";

class App extends Component {
    /*** ================================================================================
     *          Main
     * ================================================================================*/
    constructor (props, context) {
        super(props, context);
        this.state = {
            // registration
            rEmail: '',
            rPhone: '',
            rName: '',
            rPassword: '',
            rPasswordRepeat: '',
            isRegisterBoxClicked: false,
            referal: '',
            referalMessage: '',
            referalMessageStyle: {},
        };
    }
    componentDidMount() {
        try {
            // console.log(window.location);
            // console.log(window.location.pathname);
            const pathName = window.location.pathname;
            const ref= pathName.split('/')[2];
            this.checkReferalCode(ref);
            console.log(ref);
        }catch (e) {

        }
    }
    checkReferalCode(r){
        getDataFromCollectionWhereKeyValue('usders', 'uid', r).then( u => {
            const users = u.data;
            if (users.length > 0){
                const referalUser = users[0];
                if (referalUser.uid === localStorage.getItem('uid')){
                    this.setState({
                        referalMessage: 'Вы не можете быть рефералом сам для себя',
                        referalMessageStyle: {color: 'red'},
                        // referalCode: '',
                    });
                }else {
                    this.setState({
                        referalMessage: 'Ваш реферальный партнер - ' + referalUser.displayName + ' (' + referalUser.email + ')',
                        referalMessageStyle: {color: 'black'},
                        referal: r,
                    });
                }
            }else {
                this.setState({
                    referalMessage: 'Регистрация без реферального партнера.',
                    referalMessageStyle: {color: 'red'},
                });
            }
        });
    }
    /*** ================================================================================
     *          Handle
     * ================================================================================*/
    hcrEmail = e => {
        this.setState({rEmail: e.target.value})
    };
    hcrPhone = e => {
        this.setState({rPhone: e.target.value})
    };
    hcrName = e => {
        this.setState({rName: e.target.value})
    };
    hcrPassword = e => {
        this.setState({rPassword: e.target.value})
    };
    hcrPasswordRepeat = e => {
        this.setState({rPasswordRepeat: e.target.value})
    };
    clickCheckRegister = e => {
        this.setState({isRegisterBoxClicked: e.target.checked});
    };
    /*** ================================================================================
     *          Register
     * ================================================================================*/
    registerUser() {
        if (this.state.isRegisterBoxClicked === false) {
            alert('Вы не приняли условия пользования сайтом!');
            return;
        }
        if (this.state.rEmail === '' || this.state.rPhone === '' || this.state.rName === '' || this.state.rPassword === '' || this.state.rPasswordRepeat === '') {
            alert('Не все поля указаны!');
            return;
        }
        if (this.state.rPassword !== this.state.rPasswordRepeat) {
            alert('Пароли не совпадают');
            return;
        }
        try {
            fire.auth().createUserWithEmailAndPassword(this.state.rEmail, this.state.rPassword)
                .then(res => {
                    fire.auth().currentUser.updateProfile({
                        displayName: this.state.rName,
                        phone: this.state.rPhone
                    });
                    fire.auth().currentUser.sendEmailVerification();
                    // console.log(res);
                    if (res.operationType === 'signIn') {
                        this.saveNewUserToFire(this.state.rName, res.user.email,
                            res.user.emailVerified, res.user.phoneNumber, false,
                            res.user.photoURL, res.user.uid,);
                    }
                }).catch(e => {
                if (e.message.includes('email address')) alert('Адрес электронной почты уже используется другой учетной записью.');
                else alert(e.message);
            })
        } catch (e) {
            console.log(e);
        }
    }
    saveNewUserToFire(displayName, email, emailVerified, phone, phoneVerified, photoUrl, uid) {
        createNewUserAndGoToAccount(displayName, email, emailVerified, phone, phoneVerified, photoUrl, uid, this.state.referal)
            .then( r => {
                this.props.setUser(r.user);
                this.checkVerefiedAccountComponents();
            });
    }
    goToAccount(result) {
        localStorage.setItem('displayName', result.displayName);
        localStorage.setItem('email', result.email);
        localStorage.setItem('emailVerified', result.emailVerified);
        localStorage.setItem('phone', result.phone);
        localStorage.setItem('phoneVerified', result.phoneVerified);
        localStorage.setItem('photoUrl', result.photoUrl);
        localStorage.setItem('uid', result.uid);
        localStorage.setItem('position', result.position);
        localStorage.setItem('orders', result.orders);
        localStorage.setItem('products', result.products);
        localStorage.setItem('active', result.active);
        localStorage.setItem('balance', result.balance);
        localStorage.setItem('dateCreation', result.dateCreation);

        window.location.assign(window.location.origin + '/account');
    }
    /*** ================================================================================
     *          LOG IN/OUT
     * ================================================================================*/
    signInGooglePopUp(){
        if (this.state.isRegisterBoxClicked === false) {
            alert('Вы не приняли условия пользования сайтом!');
            return;
        }
        var currentUser = null;
        fire.auth().signInWithPopup(provider)
            .then(result => {
                fire.firestore().collection("usders")
                    .get()
                    .then(querySnapshot => {
                        querySnapshot.docs.forEach(doc => {
                            if (result.user.email.toString() === doc.data().email){
                                // has signed up
                                this.changeDateLastSignIn(doc);

                                currentUser = doc.data();
                            }
                        });
                        if (currentUser === 'null' ){
                            // this email hasn't signed up yet
                            this.props.setUser(result);
                            this.createNewUserFromGoogleAndGotoAccount(result);
                        } else if (currentUser === null){
                            // this email hasn't signed up yet
                            this.props.setUser(result);
                            this.createNewUserFromGoogleAndGotoAccount(result);
                        }else{
                            this.goToAccount(currentUser);
                        }
                    });
            }).catch(error => {
            console.log(error);
        });
    }

    createNewUserFromGoogleAndGotoAccount(result){
        createNewUserAndGoToAccount(
            result.user.displayName,
            result.user.email,
            result.user.emailVerified,
            result.user.phoneNumber,
            false,
            result.user.photoURL,
            result.user.uid,
            this.state.referal).then( r => {
            this.props.setUser(r.user);
            this.checkVerefiedAccountComponents();
        });
    }
    /*** ================================================================================
     *          Activation Account
     * ================================================================================*/
    checkVerefiedAccountComponents(){
        if (localStorage.getItem('position') === "Партнер"){
            if (localStorage.getItem('emailVerified') === "false"){
                this.setState({isVerifiedEmailFragment: true});
                this.setState({isNeedVerification: true});
            }
            if (localStorage.getItem('phoneVerified') === "false"){
                this.setState({isVerifiedPhoneFragment: true});
                this.setState({isNeedVerification: true});
            }
            if (localStorage.getItem('balance') < 100){
                this.setState({isVerifiedBalanceFragment: true});
                this.setState({isNeedVerification: true});
            }
        }
    }
    /*** ================================================================================
     *          Default Render
     * ================================================================================*/
    render() {
        let settings = {
            dots: true,
            infinite: true,
            speed: 500,
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
            initialSlide: 0,
            autoplay: true,
            adaptiveHeight: true

        };
        return (

            <Fragment>
                <div className="h-100">
                    <Row className="h-100 no-gutters">
                        <Col lg="7" md="12" className="h-100 d-md-flex d-sm-block bg-white justify-content-center align-items-center">
                            <Col lg="9" md="10" sm="12" className="mx-auto app-login-box">
                                <div className="app-logo"/>
                                <h4>
                                    <div>Добро пожаловать,</div>
                                    <span>Регистрация займет <span className="text-success">несколько секунд</span>.</span>
                                </h4>
                                <div>
                                    <Form>
                                        <Row form>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Form.Label>Email:</Form.Label>
                                                    <Input
                                                        style={{outline: 'none'}}
                                                        type="email" name="email" id="email"
                                                        placeholder="Введите email"
                                                        onChange={this.hcrEmail}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Form.Label>Номер телефона:</Form.Label>
                                                    <div>
                                                        <NumberFormat
                                                            type="tel"
                                                            format="+38 (###) ###-##-##"
                                                            mask=""
                                                            placeholder="Укажите Ваш номер телефона"
                                                            onChange={this.hcrPhone}
                                                            style={{
                                                                display: 'block',
                                                                width: '100%',
                                                                height: 'calc(1.5em + 0.75rem + 2px)',
                                                                padding: '0.375rem 0.75rem',
                                                                fontSize: '1rem',
                                                                fontWeight: '400',
                                                                lineHeight: '1.5',
                                                                color: '#495057',
                                                                backgroundColor: '#fff',
                                                                backgroundClip: 'padding-box',
                                                                border: '1px solid #ced4da',
                                                                borderRadius: '0.25rem',
                                                                outline: 'none',
                                                            }}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Form.Label>Имя:</Form.Label>
                                                    <Input
                                                        style={{outline: 'none'}}
                                                        autocomplete
                                                        type="text" name="name" id="name"
                                                        placeholder="Введите Ваше имя"
                                                        onChange={this.hcrName}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Form.Label>Пароль:</Form.Label>
                                                    <Input
                                                        style={{outline: 'none'}}
                                                        type="password" name="password" id="password"
                                                        placeholder="Введите пароль"
                                                        onChange={this.hcrPassword}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <Form.Label>Повторите пароль:</Form.Label>
                                                    <Input
                                                        style={{outline: 'none'}}
                                                        type="password" name="passwordrep" id="passwordRep"
                                                        placeholder="Повторите пароль"
                                                        onChange={this.hcrPasswordRepeat}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <FormGroup className="mt-3" check>
                                            <Input
                                                type="checkbox" name="check" id="check"
                                                onChange={this.clickCheckRegister}
                                            />
                                            <Label for="exampleCheck" check>Я принимаю <a target='_blank' href={window.location.href + 'rules'}  >Условия пользования сайтом</a>.</Label>
                                        </FormGroup>
                                        <Row>
                                            <Col md={6}>
                                                <Button
                                                    color="primary"
                                                    onClick={() => this.signInGooglePopUp()}
                                                    style={{display: 'block', width: '100%', marginTop: 5}}
                                                >Создать аккаунт c Google</Button>
                                            </Col>
                                            <Col md={6}>
                                                <Button
                                                    color="primary"
                                                    onClick={() => this.registerUser()}
                                                    style={{display: 'block', width: '100%', marginTop: 5}}
                                                >Создать аккаунт</Button>
                                            </Col>
                                        </Row>
                                        {/*<div className="mt-4 d-flex align-items-center">*/}
                                        {/*    <h5 className="mb-0">*/}
                                        {/*        Уже есть аккаунт?{' '}*/}
                                        {/*        <a href={window.location.origin} className="text-primary">Войдите</a>*/}
                                        {/*    </h5>*/}
                                        {/*    <div className="ml-auto">*/}
                                        {/*        <Button color="primary"*/}
                                        {/*                className="btn-wide btn-pill btn-shadow btn-hover-shine"*/}
                                        {/*                size="lg"*/}
                                        {/*                onClick={() => this.registerUser()}*/}
                                        {/*        >Создать аккаунт</Button>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </Form>
                                </div>
                            </Col>
                        </Col>
                        <Col lg="5" className="d-lg-flex d-xs-none">
                            <div className="slider-light">
                                <Slider  {...settings}>
                                    <div
                                        className="h-100 d-flex justify-content-center align-items-center bg-premium-dark">
                                        <div className="slide-img-bg"
                                             style={{
                                                 backgroundImage: 'url(' + bg3 + ')'
                                             }}/>
                                        <div className="slider-content">
                                            <h3>Регистрация на платформе DropMaster2.0</h3>
                                            <p>
                                                {this.state.referalMessage}
                                            </p>
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Fragment>
        );
    }
}
const mapStateToProps = state => {
    return {
        basketArray: state.basket,
        user: state.basket.user,
        adminSettings: state.basket.adminSettings
    }
};

const mapDispatchToProps = {
    setAdmin,
    clearAdmin,
    setUser,
    clearUser,
    addProduct,
    removeProduct,
    removeAll,
    changeProduct
};
export default connect(mapStateToProps, mapDispatchToProps)(App);