const BASKET_ADD_PRODUCT = 'BASKET_ADD_PRODUCT';
const BASKET_REMOVE_PRODUCT = 'BASKET_REMOVE_PRODUCT';
const BASKET_REMOVE_ALL = 'BASKET_REMOVE_ALL';
const BASKET_CHANGE_PRODUCT = 'BASKET_CHANGE_PRODUCT';

export const addProduct = (product) => ({
    type: BASKET_ADD_PRODUCT,
    payload: product
});
export const removeProduct = (product) => ({
    type: BASKET_REMOVE_PRODUCT,
    payload: product
});
export const removeAll = () => ({
    type: BASKET_REMOVE_ALL,
    payload: ''
});
export const changeProduct = (oldProduct, newProduct) => ({
    type: BASKET_CHANGE_PRODUCT,
    oldProduct: oldProduct,
    newProduct: newProduct
});

const USER_SET = 'USER_SET';
const USER_CLEAR = 'USER_CLEAR';

export const setUser = (user) => ({
    type: USER_SET,
    payload: user
});
export const clearUser = (user) => ({
    type: USER_CLEAR,
    payload: user
});

const ADMIN_SET = 'ADMIN_SET';
const ADMIN_CLEAR = 'ADMIN_CLEAR';

export const setAdmin = (admin) => ({
    type: ADMIN_SET,
    payload: admin
});
export const clearAdmin = (admin) => ({
    type: ADMIN_CLEAR,
    payload: admin
});