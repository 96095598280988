import React from 'react';
import '../products/Products.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Tabs, Tab, Button, Form} from 'react-bootstrap';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {
    filterOrdersBack,
    filterOrdersFinished,
    filterOrdersHold,
    fire,
    getDataFromCollection
} from '../firebase/Firebase';
import {Card, CardBody, CardTitle, Col, Row} from "reactstrap";
import CountUp from "react-countup/build";
import {Pie} from "react-chartjs-2";

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import ReactTable from "react-table";
import './style.css'

import * as moment from 'moment';
import 'moment-timezone';



const styleCell = {overflow: "hidden",fontFamily: "Pe-icon-7-stroke", fontWeight: "400",};
const styleCellIn = {fontFamily: "Pe-icon-7-stroke", fontWeight: "550",};


function momentToString(mom){
    return (
        (mom.get('year')) + '-' +
        (mom.get('month') + 1) + '-' +
        (mom.get('date'))
    )
}


const colLg = 4;



class App extends React.Component {
    /*** ================================================================================
     *          Main
     * ================================================================================*/
    constructor (props, context) {
        super(props, context);
        this.state = {

        };
    }
    componentDidMount() {

    }
    /*** ================================================================================
     *          Else Methods
     * ================================================================================*/
    calcMidleValue(x, y){
        if (parseInt(parseInt(x)/parseInt(y)).toString() === 'NaN'){
            return 0
        }else {
            return parseInt(parseInt(x)/parseInt(y)).toString();
        }
    }
    calcMidleValuePercent(x, y){
        if (parseInt((parseInt(x)/parseInt(y))*100).toString() === 'NaN'){
            return '0'
        }else {
            return parseInt((parseInt(x)/parseInt(y))*100).toString();
        }
    }
    calcDiagrammQuantity(){
        var data = {
            labels: [
                'Заявки',
                'Забрано',
                'Отказы'
            ],
            datasets: [{
                data: [
                    this.props.quantityHold,
                    this.props.quantityFinished,
                    this.props.quantityBack
                ],
                backgroundColor: [
                    '#8dace7',
                    '#71deb9',
                    '#ef869e'
                ],
                hoverBackgroundColor: [
                    '#8dace7',
                    '#71deb9',
                    '#ef869e'
                ]
            }]};
        return data;
    };
    calcDiagrammSumm(){
        var data = {
            labels: [
                'Заявки, грн',
                'Забрано, грн',
                'Отказы, грн'
            ],
            datasets: [{
                data: [
                    this.props.summHold,
                    this.props.summFinished,
                    this.props.summBack
                ],
                backgroundColor: [
                    '#8dace7',
                    '#71deb9',
                    '#ef869e'
                ],
                hoverBackgroundColor: [
                    '#8dace7',
                    '#71deb9',
                    '#ef869e'
                ]
            }]};
        return data;
    };
    /*** ================================================================================
     *          Default Render
     * ================================================================================*/
    render() {
        return (
            <div>
                <Row>
                    {this.props.dataText.map( (d, index) => {
                        return <Col lg={this.props.colSize} key={index}>
                                    <Card className="main-card mb-3 card-bg-1">
                                        <CardBody>
                                            <CardTitle>{d}</CardTitle>
                                            <h3>{this.props.dataNumber[index]}</h3>
                                        </CardBody>
                                    </Card>
                                </Col>
                    })}
                </Row>
            </div>
        )}
}
export default App;
