import React from "react";
import {Button, Form, FormControl, Modal} from "react-bootstrap";
import NumberFormat from "react-number-format";
import Select from "react-select";
import {
    calcSummsAndQuantity,
    fire, getDataFromCollectionWhereKeyValue,
    sendMessageTelegramBotArrayId,
    sendMessageTelegramBotWithSettings
} from "../firebase/Firebase";
import * as moment from "moment";
import {
    addProduct,
    changeProduct,
    clearAdmin, clearUser,
    removeAll,
    removeProduct,
    setAdmin, setUser
} from "../storeReducers/basket/actions";
import {connect} from "react-redux";

class App extends React.Component{
    /*** ================================================================================
     *          Main
     * ================================================================================*/
    constructor (props, context) {
        super(props, context);
        this.state = {
            // Send order to DB
            isSendOrder: false,
            nameSO: '',
            phoneSO: '',
            citySO: '',
            notesSO: '',
            paymentTypeSO: 'Наложенный платеж',
        };
    }
    componentDidMount() {

    }
    /*** ================================================================================
     *          Handle Change
     * ================================================================================*/
    updateNameSO = e => {
        this.setState({nameSO: e.target.value});
    };
    updatePhoneSO = e => {
        this.setState({phoneSO: e.target.value});
    };
    updateCitySO = e => {
        this.setState({citySO: e.target.value});
    };
    updateNotesSO = e => {
        this.setState({notesSO: e.target.value});
    };
    updatePaymentTypeSO = e => {
        // const summ = this.calcSummBaskey(this.props.basketArray.basketArray);
        // const balance = this.props.user.summBalance;
        // console.log(balance);
        // console.log(summ);
        // if (balance < summ && localStorage.getItem('position') !== 'Админ'){
        //     alert('Сначала необходимо пополнить баланс, что бы выбрать тип оплаты \"Предоплата\".');
        //     return;
        // }

        this.setState({paymentTypeSO: e.value});
    };
    /*** ================================================================================
     *          Send Order
     * ================================================================================*/
    sendOrder = () => {
        if (this.state.paymentTypeSO === 'Предоплата'){
            getDataFromCollectionWhereKeyValue('usders', 'uid', localStorage.getItem('uid')).then( res => {
                const summHoldPrepaymentPartner = res.data[0].summHoldPrepaymentPartner;//
                const summBalance = res.data[0].summBalance;
                var summ = this.calcSummBaskey(this.props.basketArray.basketArray);
                var summDrop = this.calcSummDropBasket(this.props.basketArray.basketArray);
                // console.log(summBalance);
                // console.log(summHoldPrepaymentPartner);
                // console.log(summDrop);
                // console.log(( (summBalance - summHoldPrepaymentPartner) - (summDrop)));
                if ( ( (summBalance - summHoldPrepaymentPartner) - (summDrop)) < 100 ){
                    alert('У вас не хватает суммы на балансе перекрыть возможные убытки!');
                    return;
                }else if ((this.state.nameSO === '') || (this.state.phoneSO === '') || (this.state.citySO === '') ){
                    alert('Укажите все поля!');
                    return;
                }else {
                    this.sendOrderToDB();
                }
            });
        }else {
            if ((this.state.nameSO === '') || (this.state.phoneSO === '') || (this.state.citySO === '') ){
                alert('Укажите все поля!');
                return;
            }else {
                this.sendOrderToDB();
            }
        }
    };
    sendOrderToDB = () => {
        var summ = this.calcSummBaskey(this.props.basketArray.basketArray);
        var summDrop = this.calcSummDropBasket(this.props.basketArray.basketArray);
        var summPurchase = this.calcSummPurchaseBasket(this.props.basketArray.basketArray);
        var summClient = summ - summDrop;
        const order = {
            name: this.state.nameSO,
            phone: this.state.phoneSO,
            city: this.state.citySO,
            notes: this.state.notesSO,
            paymentType: this.state.paymentTypeSO,
            status: 'Новый',
            statusNP: '',
            TTNNP: 'ТТН не присвоен',
            TTNStatusNP: 'Нет статуса ТТН',
            fromUID: localStorage.getItem('uid'),
            fromEmail: localStorage.getItem('email'),
            fromName: localStorage.getItem('displayName'),
            date: new Date().getTime(),
            dateForParse: new Date().getTime(),

            summ: summ,
            summDrop: summDrop,
            summClient: summClient,
            summPurchase: summPurchase,

            summBack: 0,

            products: this.props.basketArray.basketArray,
            ownerUIDP: this.props.basketArray.basketArray[0].ownerUIDP,
            ownerEmailP: this.props.basketArray.basketArray[0].ownerEmailP,

            active: false,

            summReferal: this.props.adminSettings.summReferal,
            referalCode: this.props.user.referal,
        };
        fire.firestore().collection('orders').add(order).then(q => {
            calcSummsAndQuantity(localStorage.getItem('uid'));
            calcSummsAndQuantity(this.props.adminSettings.uid);
            calcSummsAndQuantity(this.props.basketArray.basketArray[0].ownerUIDP);
            sendMessageTelegramBotWithSettings("NEW_ORDER", {
                user: this.props.user,
                order: order,
            });
            this.props.removeAll();
            this.props.clickSendOrder();
        });
        this.setState({
            nameSO: '',
            phoneSO: '',
            citySO: '',
            notesSO: '',
        });
    };
    summInBasketFromRedux = e => {
        var result = 0;
        var array = this.props.basketArray.basketArray;

        array.forEach(a => {
            result += (a.quantity * a.price);
        });
        return result;
    };
    calcSummBaskey(products){
        var res = 0;
        for (let i = 0; i < products.length ; i++) {
            res = res + (products[i].price * products[i].quantity);
        }
        return res;
    }
    calcSummDropBasket(products){
        var res = 0;
        for (let i = 0; i < products.length ; i++) {
            res = res + (products[i].priceMin * products[i].quantity);
        }
        return res;
    }
    calcSummPurchaseBasket(products){
        var res = 0;
        for (let i = 0; i < products.length ; i++) {
            res = res + (products[i].pricePurchase * products[i].quantity);
        }
        return res;
    }
    totalSummOrder(){
        var array = this.props.basket.basketArray;
        var result = 0;
        array.forEach(a => {
            result = result + parseInt(a.quantity) * parseInt(a.price);
        });
        return result;
    }
    /*** ================================================================================
     *          Default Render
     * ================================================================================*/
    render() {
        return (
            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                // centered
                show={this.props.isSendOrder}
                onHide={() => this.props.clickSendOrder()}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Отправка заказа на склад
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="NameSO">
                            <Form.Label>ФИО покупателя</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Укажите ФИО покупателя"
                                onChange={this.updateNameSO}
                                value={this.state.nameSO}
                            />
                        </Form.Group>
                        <Form.Group controlId="phoneSO">
                            <Form.Label>Телефон покупателя</Form.Label>
                            <div>
                                <NumberFormat
                                    placeholder="Укажите телефон покупателя"
                                    onChange={this.updatePhoneSO}
                                    value={this.state.phoneSO}
                                    type="tel"
                                    format="+38 (###) ###-##-##"
                                    mask=""
                                    style={{
                                        display: 'block',
                                        width: '100%',
                                        height: 'calc(1.5em + 0.75rem + 2px)',
                                        padding: '0.375rem 0.75rem',
                                        fontSize: '1rem',
                                        fontWeight: '400',
                                        lineHeight: '1.5',
                                        color: '#495057',
                                        backgroundColor: '#fff',
                                        backgroundClip: 'padding-box',
                                        border: '1px solid #ced4da',
                                        borderRadius: '0.25rem',
                                        outline: 'none',
                                    }}
                                />
                            </div>
                        </Form.Group>
                        <Form.Group controlId="citySO">
                            <Form.Label>Доставка</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Город и номер отделения новой почты"
                                onChange={this.updateCitySO}
                                value={this.state.citySO}
                            />
                        </Form.Group>
                        <Form.Group controlId="paymentTypeSO">
                            <Form.Label>Тип оплаты</Form.Label>
                            <Select
                                value={ {value: this.state.paymentTypeSO, label: this.state.paymentTypeSO} }
                                onChange={this.updatePaymentTypeSO}
                                options={[
                                    { value: 'Наложенный платеж', label: 'Наложенный платеж' },
                                    { value: 'Предоплата', label: 'Предоплата' },
                                ]}
                            />
                        </Form.Group>
                        <Form.Group controlId="notesSO">
                            <Form.Label>Комментарий к заказу</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows="3"
                                placeholder="Комментарий к заказу"
                                onChange={this.updateNotesSO}
                                value={this.state.notesSO}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Modal.Title id="contained-modal-title-vcenter ProductWindow-AddToBasketProduct-SummLable">
                        Сумма: {this.summInBasketFromRedux()}грн.
                    </Modal.Title>
                    <Button color="primary" onClick={() => this.sendOrder()}>Отправить</Button>
                    <Button color="primary" onClick={() => this.props.clickSendOrder()}>Закрыть</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}


const mapStateToProps = state => {
    return {
        basketArray: state.basket,
        user: state.basket.user,
        adminSettings: state.basket.adminSettings
    }
};

const mapDispatchToProps = {
    setAdmin,
    clearAdmin,
    setUser,
    clearUser,
    addProduct,
    removeProduct,
    removeAll,
    changeProduct
};
export default connect(mapStateToProps, mapDispatchToProps)(App);