import React from "react";
import {Form, FormControl, Modal} from "react-bootstrap";
import {Button} from "reactstrap";
import {addProduct, changeProduct, removeAll, removeProduct} from "../../storeReducers/basket/actions";
import {connect} from "react-redux";

function getHeader(isChange){
    if (isChange){
        return 'Измените цену или количество';
    }else {
        return 'Добавьте товар в корзину заказов';
    }
}
function getBtnSave(isChange) {
    if (isChange){
        return 'Изменить';
    }else {
        return 'Добавить';
    }
}

class App extends React.Component{
    /*** ================================================================================
     *          Main
     * ================================================================================*/
    constructor (props, context) {
        super(props, context);
        this.state = {
            isToBasketCurrentProduct: {},
            isToBasketPrice: 0,
            isTobasketMinPrice: 0,
            isTobasketPricePurchase: 0,
            isToBasketQuantity: 1,
        };
    }
    componentDidMount() {
        if (this.props.isChange){
            this.setState({
                isToBasketCurrentProduct: this.props.oldProduct,
                isToBasketPrice: this.props.oldProduct.price,
                isTobasketMinPrice: this.props.oldProduct.priceMin,
                isTobasketPricePurchase: this.props.oldProduct.pricePurchase,
            })
        }else {
            this.setState({
                isToBasketCurrentProduct: this.props.isTobasketCurrentProduct,
                isToBasketPrice: this.props.isTobasketCurrentProduct.priceDropP,
                isTobasketMinPrice: this.props.isTobasketCurrentProduct.priceDropP,
                isTobasketPricePurchase: this.props.isTobasketCurrentProduct.pricePurchaseP,
            })
        }
    }
    /*** ================================================================================
     *          Basket
     * ================================================================================*/
    updateIsToBasketPrice = e => {
        this.setState({isToBasketPrice: e.target.value.replace(/\D/,'')});
    };
    updateIsToBasketQuantity = e => {
        this.setState({isToBasketQuantity: e.target.value});
    };
    toBasketAddProduct(){
        if (parseInt(this.state.isToBasketPrice) < parseInt(this.state.isTobasketMinPrice)){
            alert('Цена указана ниже возможной');
            return;
        }
        const p = this.state.isToBasketCurrentProduct;
        if (this.props.isChange){
            const oldP = this.props.oldProduct;
            this.props.removeProduct(oldP);
            this.props.addProduct({
                id: p.id,
                image: p.image,
                name: p.name,
                ownerUIDP: p.ownerUIDP,
                ownerEmailP: p.ownerEmailP,
                price: this.state.isToBasketPrice,
                priceMin: this.state.isTobasketMinPrice,
                pricePurchase: this.state.isTobasketPricePurchase,
                quantity: this.state.isToBasketQuantity,
            });
        }else {
            this.props.addProduct({
                id: p.idP,
                image: p.imagesUrlP[0],
                name: p.nameP,
                ownerUIDP: p.ownerUIDP,
                ownerEmailP: p.ownerEmailP,
                price: this.state.isToBasketPrice,
                priceMin: this.state.isTobasketMinPrice,
                pricePurchase: this.state.isTobasketPricePurchase,
                quantity: this.state.isToBasketQuantity,
            });
        }
        this.props.toBasketClick();
    }
    /*** ================================================================================
     *          Default Render
     * ================================================================================*/
    render() {
        return (
            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                // centered
                show={this.props.isToBasketClick}
                onHide={() => this.props.toBasketClick()}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {getHeader(this.props.isChange)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="formGroupEmail">
                            <Form.Label>Цена продажи:</Form.Label>
                            <FormControl
                                type="number"
                                oninput="validity.valid||(value='');"
                                placeholder="Укажите цену продажи"
                                onChange={this.updateIsToBasketPrice}
                                value={this.state.isToBasketPrice}
                                min={this.state.isTobasketMinPrice}
                            />
                        </Form.Group>
                        <Form.Group controlId="formGroupPassword">
                            <Form.Label>Количество:</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Укажите количество"
                                onChange={this.updateIsToBasketQuantity}
                                value={this.state.isToBasketQuantity}
                                min={1}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Modal.Title id="contained-modal-title-vcenter ProductWindow-AddToBasketProduct-SummLable">
                        Сумма: {this.state.isToBasketQuantity * this.state.isToBasketPrice}грн.
                    </Modal.Title>
                    <Button
                        className="mb-2 mr-2" color="primary"
                        onClick={() => this.toBasketAddProduct()}>{getBtnSave(this.props.isChange)}</Button>
                    <Button
                        outline className="mb-2 mr-2 border-0 btn-transition" color="primary"
                        onClick={() => this.props.toBasketClick()}>Закрыть</Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

const mapStateToProps = state => {
    return {
        basketArray: state.basket
    }
};

const mapDispatchToProps = {
    addProduct,
    removeProduct,
    removeAll,
    changeProduct
};
export default connect(mapStateToProps, mapDispatchToProps)(App);