import React, {Fragment} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {Button, Card,  CardBody, CardHeader, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import hipster from "../../../images/hipster.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown} from "@fortawesome/free-solid-svg-icons";
import TelegramLogo from '../../../images/telegram.png';

class App extends React.Component {

    /*** ================================================================================
     *          Default Render
     * ================================================================================*/
    render() {
        return (
            <div>
                {/*Basket Container*/}
                <Fragment>
                    <div className="header-dots">
                        <UncontrolledDropdown>
                            <DropdownToggle className="p-0 mr-2" color="link">
                                <img width={42} className="rounded-circle" src={TelegramLogo} alt=""/>
                                {/*<FontAwesomeIcon className="ml-2 opacity-8" icon={faAngleDown}/>*/}
                            </DropdownToggle>
                            <DropdownMenu right className="dropdown-menu-xl rm-pointers" style={{padding: '0'}}>
                                <Card>
                                    <CardHeader>
                                        Телеграм контакты
                                    </CardHeader>
                                    <CardBody>
                                        <Button style={{width: '100%'}}
                                                className="mb-2 mr-2"
                                                color="primary" target='_blank' href="https://t.me/joinchat/AAAAAEjQytCl3nqpz1Ammg">Телеграм канал</Button>
                                        <Button style={{width: '100%'}}
                                                className="mb-2 mr-2"
                                                color="primary" target='_blank' href="http://t.me/dropmaster_sales">Консультант по продажам</Button>
                                        <Button style={{width: '100%'}}
                                                className="mb-2 mr-2"
                                                color="primary" target='_blank' href="http://t.me/dropmaster_manager">Технический консультант</Button>

                                    </CardBody>
                                </Card>

                            </DropdownMenu>
                        </UncontrolledDropdown>
                    </div>
                </Fragment>
            </div>
        )}
}


export default App;
