import React from 'react';
import '../products/Products.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {fire, sendMessageTelegramBotWithSettings, updateDocumentInCollection, getDateFromMilliseconds} from '../firebase/Firebase';
import {Button, Col, Row} from "reactstrap";
import {Form, Modal} from "react-bootstrap";
import Select from "react-select";
import BalannceMainFiguresTop from "./BalannceMainFiguresTop";
import BalanceTable from "./BalanceTable";
import './Balance.css';
import {calcSummsAndQuantity,getAdminRequisites, getDataFromCollection, getAllBalances} from "../firebase/Firebase";
import {
    addProduct, changeProduct,
    clearAdmin,
    clearUser,
    removeAll,
    removeProduct,
    setAdmin,
    setUser
} from "../storeReducers/basket/actions";
import {connect} from "react-redux";
import hipster from '../../images/hipster.png';


class App extends React.Component {
    /*** ================================================================================
     *          Main methods
     * ================================================================================*/
    componentDidMount() {
        // console.log(this.props)
        this.getBalance();
        this.getBalanceOperation();
    }
    constructor (props, context) {
        super(props, context);
        this.state = {
            // DB Array
            orders: [],

            summHold: 0,
            summFinished: 0,
            summBack: 0,
            summBalance: 0,
            summPayed: 0,
            summInput: 0,

            quantityHold: 0,
            quantityFinished: 0,
            quantityBack: 0,
            quantityBalance: 0,
            quantityPayed: 0,
            quantityInput: 0,

            quantityOrders: 0,
            quantityProducts: 0,

            // Change Balance operation
            isChangeBalanceOperation: false,
            currentBalanceOperation: [],
            statusBO: '',
            summBO: 0,

            // Balance operation
            balanceOperation: [],
            adminRequisites: '',
        };
    }
    /*** ================================================================================
     *          Data Base
     * ================================================================================*/
    getBalanceOperation(){
        getDataFromCollection("balance").then( r => {
            const balanceOperation = r.data;
            balanceOperation.sort(function(a,b){
                return new Date(b.date) - new Date(a.date);
            });
            this.setState({ balanceOperation: balanceOperation });
        }).catch(e =>{

        });
    }
    getBalance(){
        getAllBalances().then( r => {
            this.setState({summHold: r.summHold});
            this.setState({summFinished: r.summFinished});
            this.setState({summBack: r.summBack});
            this.setState({summBalance: r.summBalance});
            this.setState({summPayed: r.summPayed});
            this.setState({summInput: r.summInput});
        });
    };
    updateBalanceOperation(){
        updateDocumentInCollection("balance", this.state.currentBalanceOperation.id, {
            status: this.state.statusBO,
            summ: this.state.summBO,
        }).then( r => {
            calcSummsAndQuantity(this.state.currentBalanceOperation.fromUID);
            calcSummsAndQuantity(this.props.adminSettings.uid);
            sendMessageTelegramBotWithSettings("CHANGE_BALANCE_REQUEST", {
                status: this.state.statusBO,
                typeOperation: this.state.currentBalanceOperation.typeOperation,
                fromUID: this.state.currentBalanceOperation.fromUID,
                summ: this.state.summBO,
            });
            this.getBalance();
            this.getBalanceOperation();
            this.clickChangeBalanceOperation();
        });
    }
    /*** ================================================================================
     *          Change Balance Operation
     * ================================================================================*/
    clickChangeBalanceOperation = (p) => {
        if (!this.state.isChangeBalanceOperation){
            this.setState({isChangeBalanceOperation: true});
            this.setState({currentBalanceOperation: p});
            this.setState({summBO: p.summ});
            this.setState({statusBO: p.status});
        }else {
            this.setState({isChangeBalanceOperation: false});
            this.setState({currentBalanceOperation: []});
        }

    };
    updateStatusBO = e => {
        this.setState({statusBO: e.value});
    };
    updateSummBO = e => {
        this.setState({summBO: e.target.value});
    };
    /*** ================================================================================
     *          Render
     * ================================================================================*/
    render() {
        const getImage = (img) =>{
            if (img === ''){
                return hipster
            }else {
                return img;
            }
        };
        return (
            <div>
                {/*Change Balance Operation*/}
                <Modal
                    size="xs"
                    aria-labelledby="contained-modal-title-vcenter"
                    // centered
                    show={this.state.isChangeBalanceOperation}
                    onHide={() => this.clickChangeBalanceOperation()}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {'Дата создания операции: ' + getDateFromMilliseconds(this.state.currentBalanceOperation.date) }
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Row>
                            <Col md={12}>
                                <div style={{width: 'auto', height: 300, textAlign: 'center'}}>
                                    <img
                                        style={{width: 'auto', height: '100%'}}
                                        src={getImage(this.state.currentBalanceOperation.url)}
                                        alt="img"/>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="formGroupStatus">
                                    <Form.Label>Статус</Form.Label>
                                    <Select
                                        value={ {value: this.state.statusBO, label: this.state.statusBO} }
                                        onChange={this.updateStatusBO}
                                        options={[
                                            { value: 'На рассмотрении', label: 'На рассмотрении' },
                                            { value: 'Завершен', label: 'Завершен' },
                                            { value: 'Отказ', label: 'Отказ' },
                                        ]}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formGroupSumm">
                                    <Form.Label>Сумма</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Укажите сумму"
                                        onChange={this.updateSummBO}
                                        value={this.state.summBO}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button color="primary" onClick={() => this.updateBalanceOperation()}>Сохранить</Button>
                        <Button color="primary" onClick={() => this.clickChangeBalanceOperation()}>Закрыть</Button>
                    </Modal.Footer>
                </Modal>
                {/*Content*/}
                <BalannceMainFiguresTop summHold={this.state.summHold}
                                        summFinished={this.state.summFinished}
                                        summBack={this.state.summBack}
                                        summBalance={this.state.summBalance}/>
                <BalanceTable balanceOperation={this.state.balanceOperation}
                              changeCallBack={this.clickChangeBalanceOperation}
                              isBtnChange={true}/>

            </div>
        )}
}
const mapStateToProps = state => {
    return {
        basketArray: state.basket,
        user: state.basket.user,
        adminSettings: state.basket.adminSettings
    }
};

const mapDispatchToProps = {
    setAdmin,
    clearAdmin,
    setUser,
    clearUser,
    addProduct,
    removeProduct,
    removeAll,
    changeProduct
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
