import React, {Fragment} from 'react';
import cx from 'classnames';

import {connect} from 'react-redux';
import {Button} from 'react-bootstrap';
import {
    calcAllUsers,
} from '../../componentsWithOutTheme/firebase/Firebase';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';

import AppLogo from '../AppLogo';

import SearchBox from './Components/SearchBox';
import MegaMenu from './Components/MegaMenu';
import UserBox from './Components/UserBox';
import HeaderRightDrawer from "./Components/HeaderRightDrawer";
import TelegramLogo from '../../assets/utils/images/telegram-logo.png';
import HeaderDots from "./Components/HeaderDots";
import BasketOrdersReduxContainer from "../../componentsWithOutTheme/basket/BasketOrdersReduxContainer";
import '../style.css';
import {fire} from "../../componentsWithOutTheme/firebase/Firebase";
import TelegramDot from './Components/TelegramDot';

class Header extends React.Component {
    /*** ================================================================================
     *          Main methods
     * ================================================================================*/
    constructor(props) {
        super(props);
        this.state = {
            signedIn: false,
        };
    }
    componentDidMount() {
        fire.auth().onAuthStateChanged(function(user) {
            if (user) {
                this.setState({signedIn: true})
            } else {
                this.setState({signedIn: false})
            }
        }.bind(this));
    }
    render() {
        let {
            headerBackgroundColor,
            enableMobileMenuSmall,
            enableHeaderShadow
        } = this.props;
        return (
            <Fragment>
                <ReactCSSTransitionGroup
                    component="div"
                    className={cx("app-header", headerBackgroundColor, {'header-shadow': enableHeaderShadow})}
                    transitionName="HeaderAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={1500}
                    transitionEnter={false}
                    transitionLeave={false}>

                    <AppLogo active={window.location.pathname.toString() === '/account'}/>

                    <div className={cx(
                        "app-header__content",
                        {'header-mobile-open': enableMobileMenuSmall},
                    )}>
                        {this.state.signedIn && (
                            <div className="app-header-left">
                                <MegaMenu/>
                            </div>
                        )}


                        <div className="app-header-right">
                            {/*<div className={'tg-button'} style={{marginRight: 15}}>*/}
                            {/*    <img src={TelegramLogo} alt=""/>*/}
                            {/*    <span>Телеграм канал</span>*/}
                            {/*</div>*/}
                            <TelegramDot/>
                            {/*<Button onClick={()=> calcAllUsers()}>ALL</Button>*/}
                            <br/>
                            {window.location.pathname.toString() === '/account' && (
                                <div>
                                    {this.state.signedIn && (
                                        <BasketOrdersReduxContainer/>
                                    )}
                                </div>
                            )}
                            <UserBox/>
                        </div>
                    </div>
                </ReactCSSTransitionGroup>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
    closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
    headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
    enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
