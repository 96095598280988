import React from 'react';
import '../products/TableProductsRealTime.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import {
    calcSummsAndQuantity,
    fire,
    getAdminRequisites,
    getDataFromCollectionWhereKeyValue, getUserJson, sendMessageTelegramBotWithSettings,
    setDocumentToCollection
} from '../firebase/Firebase';
import {Card, CardBody, Button, ButtonGroup, Col, Row} from "reactstrap";
import {Form, Modal} from "react-bootstrap";
import BalannceMainFiguresTop from "./BalannceMainFiguresTop";
import BalanceTable from "./BalanceTable";
import './Balance.css';
import {
    addProduct, changeProduct,
    clearAdmin,
    clearUser,
    removeAll,
    removeProduct,
    setAdmin,
    setUser
} from "../storeReducers/basket/actions";
import {connect} from "react-redux";

class App extends React.Component {
    /*** ================================================================================
     *          Main methods
     * ================================================================================*/
    componentDidMount() {
        this.refresh();
    }
    constructor (props, context) {
        super(props, context);
        this.state = {
            // DB Array
            orders: [],

            currentUser: getUserJson(),

            // Send/Get Money
            isSendMoney: false,
            isGetMoney: false,

            summGetMoney: 0,
            requisitesGetMoney: '',
            summSendMoney: 0,
            urlSendMoney: '',

            // Balance operation
            balanceOperation: [],
            adminRequisites: '',
            image: null,
            // check not finished
            isNotFinished: false,
            // Referal
            referalSummFinished: 0,
            referaSummlHold: 0,
            referalQuantityFinished: 0,
            referalQuantityHold: 0,
        };

    }
    /*** ================================================================================
     *          Data Base
     * ================================================================================*/
    refresh(){
        this.getRequisites();
        this.getBalance();
        this.getBalanceOperation();
        this.getReferal();
    }
    getRequisites(){
        getAdminRequisites().then( r => {
            this.setState({ adminRequisites: r.paymentCard + ' ' + r.paymentName });
        });
    };
    getBalance(){
        getDataFromCollectionWhereKeyValue("usders", "uid", localStorage.getItem('uid')).then( r => {
            // console.log(r.data[0]);
            this.setState({ currentUser: r.data[0] });
        });
    };
    getBalanceOperation(){
        getDataFromCollectionWhereKeyValue("balance", "fromUID", localStorage.getItem('uid')).then( r => {
            const balanceOperation = r.data;
            balanceOperation.sort(function(a,b){
                return new Date(b.date) - new Date(a.date);
            });

            balanceOperation.forEach( b => {
                if (b.status === "На рассмотрении"){
                    this.setState({isNotFinished: true})
                }
            });
            this.setState({ balanceOperation: balanceOperation });
        });
    };
    getReferal() {
        getDataFromCollectionWhereKeyValue("usders", "referal", localStorage.getItem('uid')).then( referal => {
            const users = referal.data;
            // console.log(users);
            var referalSummFinished = 0;
            var referaSummlHold = 0;
            var referalQuantityFinished = 0;
            var referalQuantityHold = 0;
            users.forEach( u => {
                referalSummFinished = referalSummFinished + u.referalSummFinished;
                referaSummlHold = referaSummlHold + u.referaSummlHold;
                referalQuantityFinished = referalQuantityFinished + u.referalQuantityFinished;
                referalQuantityHold = referalQuantityHold + u.referalQuantityHold;
            });
            this.setState({
                referalSummFinished: referalSummFinished,
                referaSummlHold: referaSummlHold,
                referalQuantityFinished: referalQuantityFinished,
                referalQuantityHold: referalQuantityHold,
            })
        });
    }
    /*** ================================================================================
     *          Send/Get Money
     * ================================================================================*/
    // Click
    clickSendMoney() {
        if (!this.state.isSendMoney){
            this.setState({isSendMoney: true});
        }else {
            this.setState({isSendMoney: false});
        }
    };
    clickGetMoney() {
        if (this.state.currentUser.paymentCard === ''){
            alert('Укажите реквизиты для вывода средств!');
            return;
        }
        if (!this.state.isGetMoney){
            this.setState({isGetMoney: true});
        }else {
            this.setState({isGetMoney: false});
        }
    };
    // To Data Base
    sendGetMoney(){
        if ( parseInt(this.state.currentUser.summBalance + this.state.referalSummFinished) < parseInt(this.state.summGetMoney) ){
            alert('Сумма для вывода меньше суммы на балансе!');
            return;
        }
        setDocumentToCollection('balance', {
            typeOperation: 'Вывод средств',
            date: new Date().getTime(),
            summ: this.state.summGetMoney,
            requisites: this.state.currentUser.paymentCard + ' ' + this.state.currentUser.paymentName,
            url: '',
            status: 'На рассмотрении',
            fromUID: localStorage.getItem('uid'),
            fromEmail: localStorage.getItem('email'),
            fromName: localStorage.getItem('displayName'),
        }).then( r => {
            sendMessageTelegramBotWithSettings("NEW_GET_MONEY_REQUEST", {
                summ: this.state.summGetMoney,
                user: this.props.user,
                admin: this.props.adminSettings,
                fromUID: localStorage.getItem('uid'),
            });
            this.clickGetMoney();
            this.refresh();
        });
    };
    sendSendMoney(){
        if (this.state.image === null) {
            alert("Не указан скриншот оплаты");
            return;
        }
        if (this.state.urlSendMoney === '') {
            alert("Подождите несколько секунд, еще не загружен скриншот оплаты");
            return;
        }
        setDocumentToCollection('balance', {
            typeOperation: 'Пополнение баланса',
            date: new Date().getTime(),
            summ: this.state.summSendMoney,
            requisites: this.state.adminRequisites,
            url: this.state.urlSendMoney,
            status: 'На рассмотрении',
            fromUID: localStorage.getItem('uid'),
            fromEmail: localStorage.getItem('email'),
            fromName: localStorage.getItem('displayName'),
        }).then( r => {
            sendMessageTelegramBotWithSettings("NEW_SEND_MONEY_REQUEST", {
                summ: this.state.summSendMoney,
                user: this.props.user,
                admin: this.props.adminSettings,
                fromUID: localStorage.getItem('uid'),
            });
            this.clickSendMoney();
            this.refresh();
        });
    }
    // Hanndle Get Money
    updateSummGetMoney = e => {
        this.setState({summGetMoney: e.target.value})
    };
    updateRequisites = e => {
        this.setState({requisitesGetMoney: e.target.value})
    };
    // Handle Send Money
    updateSummSendMoney = e => {
        this.setState({summSendMoney: e.target.value})
    };
    handleUpload = e =>{
        if (e.target.files[0]) {
            this.setState({image: e.target.files[0]});
            var file = e.target.files[0];
            // var imagesUrlP = this.state.urlSendMoney;
            var storageRef = fire.storage().ref();
            //В админ айди нужно будет вставить индетификатор того кто загружает файлы
            var path = 'imagesSendMoney/' + localStorage.getItem('uid') + (new Date().toLocaleString()) + file.name;
            const uploadTask = storageRef.child(path).put(file);
            uploadTask.on('state_changed',
                (snapshot) => {
                    // progrss function ....
                },
                (error) => {
                    // error function ....
                },
                () => {
                    // complete function ....
                    storageRef.child(path).getDownloadURL().then(url => {
                        this.setState({urlSendMoney: url});
                    })
                });
        }
    };
    /*** ================================================================================
     *          Default Render
     * ================================================================================*/
    render() {
        return (
            <div>
                {/*Send money*/}
                <Modal
                    size="xs"
                    aria-labelledby="contained-modal-title-vcenter"
                    // centered
                    show={this.state.isSendMoney}
                    onHide={() => this.clickSendMoney()}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {'Пополнить баланс'}
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>


                        <Row>
                            <Col md={12}>
                                {'Пополните баланс на ' + this.state.adminRequisites + ' и загрузите фото с подтверждением оплаты'}
                            </Col>
                        </Row>

                        <Row>
                            <Col></Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <Form.Group controlId="formGroupSumm">
                                    <Form.Label>Введите сумму для выплаты</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Введите сумму"
                                        onChange={this.updateSummSendMoney}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={12}>
                                <Form.Group controlId="formGroupPhoto">
                                    <Form.Label>Загрузите фото с подтверждением оплаты</Form.Label>
                                    <Form.Control
                                        // id="uploadPhoto"
                                        type="file"
                                        accept=".jpg"
                                        onChange={this.handleUpload}
                                        // className="Landing-Input-File"
                                        // variant="outline-dark"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>


                    </Modal.Body>

                    <Modal.Footer>
                        <Button color="primary" onClick={() => this.sendSendMoney()}>Сохранить</Button>
                        <Button color="primary" onClick={() => this.clickSendMoney()}>Закрыть</Button>
                    </Modal.Footer>
                </Modal>
                {/*Get money*/}
                <Modal
                    size="xs"
                    aria-labelledby="contained-modal-title-vcenter"
                    // centered
                    show={this.state.isGetMoney}
                    onHide={() => this.clickGetMoney()}
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            {'Заказать выплату'}
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <ul>
                            <li>{"Вывод средств на карту " + this.state.currentUser.paymentCard + ' ' + this.state.currentUser.paymentName}</li>
                            <li>Оставьте на счету не менее 100грн, что бы дальше продолжать работу.</li>
                        </ul>
                        <Row>
                            <Col md={12}>
                                <Form.Group controlId="formGroupSumm">
                                    <Form.Label>Введите сумму для выплаты:</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Введите сумму"
                                        onChange={this.updateSummGetMoney}
                                        max={this.state.currentUser.summBalance}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="primary"
                                color="primary"
                                onClick={() => this.sendGetMoney()}
                        >Заказать</Button>
                        <Button variant="primary" color="primary" onClick={() => this.clickGetMoney()}>Закрыть</Button>
                    </Modal.Footer>
                </Modal>
                {/*Content*/}
                <BalannceMainFiguresTop
                    summHold={this.state.currentUser.summHold + this.state.referaSummlHold}
                    summFinished={this.state.currentUser.summFinished + this.state.referalSummFinished}
                    summBack={this.state.currentUser.summBack}
                    summBalance={this.state.currentUser.summBalance + parseInt(this.state.referalSummFinished)}
                />
                <Card>
                    <CardBody>
                        <Row>
                            <Col md={12}>
                                {this.state.isNotFinished && (
                                    <div>
                                        <div>Вы сможете пополнить или вывести средства, когда все операции будут завершены.</div>
                                        <br/>
                                    </div>
                                )}
                                {!this.state.isNotFinished && (
                                    <ButtonGroup size="lg" className="mb-2">
                                        <Button className="btn-pill" color="primary" onClick={() => this.clickSendMoney()}>Пополнить</Button>
                                        <Button className="btn-pill" color="primary" onClick={() => this.clickGetMoney()}>Вывести</Button>
                                    </ButtonGroup>
                                )}
                            </Col>
                        </Row>
                        <BalanceTable balanceOperation={this.state.balanceOperation}
                                      changeCallBack={this.clickChangeBalanceOperation}
                                      isBtnChange={false}/>
                    </CardBody>
                </Card>
            </div>
        )}
}
const mapStateToProps = state => {
    return {
        basketArray: state.basket,
        user: state.basket.user,
        adminSettings: state.basket.adminSettings
    }
};

const mapDispatchToProps = {
    setAdmin,
    clearAdmin,
    setUser,
    clearUser,
    addProduct,
    removeProduct,
    removeAll,
    changeProduct
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
