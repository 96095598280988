import React from "react";
import CountUp from "react-countup/build";
import {Button} from "react-bootstrap";
import ReactTable from "react-table";
import {getDateFromMilliseconds} from "../../firebase/Firebase";
import PartnerDataInTable from "../otherComponents/PartnerDataInTable";

const styleCell = {overflow: 'auto', height: '100%', textAlign: 'top'};

export default class App extends React.Component {
    /*** ================================================================================
     *          Change ALL
     * ================================================================================*/
    isOrderInChangeAll(p){
        return this.props.orderChangeAll.find(el => el.id === p.id);
    };
    /*** ================================================================================
     *          Get info about user
     * ================================================================================*/
    getUserInfo(uid){
        var users = this.props.users;
        var result = {
            active: false,
            summHold: 0,
            summFinished: 0,
            summBack: 0,
            summBalance: 0,
            summPayed: 0,
            summInput: 0,

            quantityHold: 0,
            quantityFinished: 0,
            quantityBack: 0,
            quantityBalance: 0,
            quantityPayed: 0,
            quantityInput: 0,

            quantityOrders: 0,
            quantityProducts: 0,
        };
        try {
            users.forEach(u => {
                if (u.uid === uid){
                    result = u;
                }
            });
        }catch (e) {
            console.log(e);
        }
        return result;
    }
    isActiveUser(bool){
        if (bool){
            return 'Активен';
        }else {
            return 'Не активен';
        }
    }
    getUserLine(header, text){
        return <div className="" >
            <b>{header}</b> {text}
        </div>
    }
    /*** ================================================================================
     *          Default Render
     * ================================================================================*/
    render() {

        const info = (d) => (
            <div className=""  style={styleCell}>
                <div>
                    <i className="header-icon pe-7s-user"> </i>
                    {d.displayName}
                </div>
                <div>
                    <i className="header-icon pe-7s-id"> </i>
                    {d.position}
                </div>
                <div>
                    <i className="header-icon pe-7s-mail"> </i>
                    {d.email}
                </div>
                <div>
                    <i className="header-icon pe-7s-star"> </i>
                    {this.isActiveUser(d.active)}
                </div>
                <div>
                    <i className="header-icon pe-7s-date"> </i>
                    {getDateFromMilliseconds(d.dateCreation)}
                </div>
                <div>
                    <i className="header-icon pe-7s-date"> </i>
                    {getDateFromMilliseconds(d.dateLastSignIn)}
                </div>
                <div>
                    <i className="header-icon pe-7s-comment"> </i>
                    {d.comment}
                </div>
            </div>
        );
        const balance = (d) => (
            <div style={styleCell}>
                {/*// Financial*/}
                {this.getUserLine('Кол-во заказов:', d.quantityOrders)}
                {this.getUserLine('Кол-во товаров:', d.quantityProducts)}
                {this.getUserLine('Сумма пополнений', d.summInput)}
                {this.getUserLine('Кол-во пополнений', d.quantityInput)}
                {this.getUserLine('Сумма выплат:', d.summPayed)}
                {this.getUserLine('Кол-во выплат', d.quantityPayed)}
                {this.getUserLine('Баланс:', d.summBalance)}
            </div>
        );
        const ordersPartner = (d) => (
            <div style={styleCell}>
                {/*// Partner*/}
                {this.getUserLine('Кол-во заказов:', d.quantityOrdersPartner)}
                {this.getUserLine('Завершен (предоплата):', d.summFinishedPrepaymentPartner)}
                {this.getUserLine('Кол-во завершен (предоплата):', d.quantityFinishedPrepaymentPartner)}
                {this.getUserLine('Холд (предоплата):', d.summHoldPrepaymentPartner)}
                {this.getUserLine('Кол-во холд (предоплата):', d.quantityHoldPrepaymentPartner)}
                {this.getUserLine('Возврат (предоплата):', d.summBackPrepaymentPartner)}
                {this.getUserLine('Кол-во возврат (предоплата):', d.quantityBackPrepaymentPartner)}
                {this.getUserLine('Завершен (наложка):', d.summFinishedCODPartner)}
                {this.getUserLine('Кол-во завершен (наложка):', d.quantityFinishedCODPartner)}
                {this.getUserLine('Холд (наложка):', d.summHoldCODPartner)}
                {this.getUserLine('Кол-во холд (наложка):', d.quantityHoldCODPartner)}
                {this.getUserLine('Возврат (наложка):', d.summBackCODPartner)}
                {this.getUserLine('Кол-во возврат (наложка):', d.quantityBackCODPartner)}
                {this.getUserLine('Холд:', d.summHoldPartner)}
                {this.getUserLine('Завершен:', d.summFinishedPartner)}
                {this.getUserLine('Возврат:', d.summBackPartner)}
                {this.getUserLine('Итог:', d.summBalancePartner)}
            </div>
        );
        const ordersProvider = (d) => (
            <div style={styleCell}>
                {/*// Provider*/}
                {this.getUserLine('Кол-во заказов:', d.quantityOrdersProvider)}
                {this.getUserLine('Завершен (предоплата):', d.summFinishedPrepaymentProvider)}
                {this.getUserLine('Кол-во завершен (предоплата):', d.quantityFinishedPrepaymentProvider)}
                {this.getUserLine('Холд (предоплата):', d.summHoldPrepaymentProvider)}
                {this.getUserLine('Кол-во холд (предоплата):', d.quantityHoldPrepaymentProvider)}
                {this.getUserLine('Возврат (предоплата):', d.summBackPrepaymentProvider)}
                {this.getUserLine('Кол-во возврат (предоплата):', d.quantityBackPrepaymentProvider)}
                {this.getUserLine('Завершен (наложка):', d.summFinishedCODProvider)}
                {this.getUserLine('Кол-во завершен (наложка):', d.quantityFinishedCODProvider)}
                {this.getUserLine('Холд (наложка):', d.summHoldCODProvider)}
                {this.getUserLine('Кол-во холд (наложка):', d.quantityHoldCODProvider)}
                {this.getUserLine('Возврат (наложка):', d.summBackCODProvider)}
                {this.getUserLine('Кол-во возврат (наложка):', d.quantityBackCODProvider)}
                {this.getUserLine('Холд:', d.summHoldProvider)}
                {this.getUserLine('Завершен', d.summFinishedProvider)}
                {this.getUserLine('Возврат:', d.summBackProvider)}
                {this.getUserLine('Итог:', d.summBalanceProvider)}
            </div>
        );
        const btnAll = (d) => (
            <div>
                <Button
                    className="mb-2 mr-2"
                    color="primary"
                    onClick={() => this.props.clickOrdersShow(d.uid)}
                    style={{width: '100%'}}
                >
                    Заказы
                </Button>
                <Button
                    className="mb-2 mr-2"
                    color="primary"
                    onClick={() => this.props.clickBalanceShow(d.uid)}
                    style={{width: '100%'}}
                >
                    Выплаты
                </Button>
                <Button
                    className="mb-2 mr-2"
                    color="primary"
                    onClick={() => this.props.clickChangeUser(d)}
                    style={{width: '100%'}}
                >
                    Редактировать
                </Button>
            </div>
        );
        return(
            <div>
                <ReactTable
                    previousText={'Предыдущая страница'}
                    nextText={'Следующая страница'}
                    pageText='Страница'
                    ofText='из'
                    rowsText='строк'
                    data={this.props.users}
                    columns={[
                        {
                            columns: [
                                {
                                    Header: "Информация ",
                                    id: "info",
                                    accessor: d => (info(d))
                                },
                                {
                                    Header: "Баланс",
                                    id: "balance",
                                    accessor: d => (balance(d))
                                },
                                {
                                    Header: "Заказы (Дропер)",
                                    id: "orders",
                                    accessor: d => (ordersPartner(d))
                                },
                                {
                                    Header: "Заказы (Поставщик)",
                                    id: "orders1",
                                    accessor: d => (ordersProvider(d))
                                },
                                {
                                    Header: "Действия",
                                    id: "doIt",
                                    maxWidth: 200,
                                    accessor: d => (btnAll(d))
                                },
                            ]},
                    ]}
                    defaultPageSize={10}
                    className="-striped -highlight"
                />
            </div>

        )
    }
}