import React from "react";
import {Card, Tab, Tabs} from "react-bootstrap";
import TableOrders from "./TableOrders";
import SearchCheckTTNOrders from "./SearchCheckTTNOrders";
import {
    calcSummsAndQuantity,
    checkTTN,
    sendMessageTelegramBotWithSettings,
    updateDocumentInCollection
} from "../firebase/Firebase";



export default class App extends React.Component {
    /*** ================================================================================
     *          Main methods
     * ================================================================================*/
    constructor (props, context) {
        super(props, context);
        this.state = {
            // Orders
            orders: [],
            // Tab Select
            tabSelect: '',
            // Orders ChangeAll
            orderChangeAll: [],
        };
        this.updateOrdersInDB = this.updateOrdersInDB.bind(this);
    }
    componentWillReceiveProps(newProps) {
        if (this.state.orders !== newProps.orders) {
            this.setState({orders: newProps.orders});
        }
    }
    /*** ================================================================================
     *          Search
     * ================================================================================*/
    handleSearch = e => {
        try {
            const data = this.props.orders
                .filter(el => el.status.includes(this.state.tabSelect))
                .filter(function (el) {
                    return el.id.includes(e.target.value) ||
                        el.fromEmail.includes(e.target.value) ||
                        el.fromName.includes(e.target.value) ||
                        el.fromUID.includes(e.target.value) ||
                        el.name.includes(e.target.value) ||
                        el.notes.includes(e.target.value) ||
                        el.paymentType.includes(e.target.value) ||
                        el.phone.includes(e.target.value) ||
                        el.status.includes(e.target.value) ||
                        el.TTNStatusNP.includes(e.target.value) ||
                        el.TTNNP.includes(e.target.value) ||
                        el.city.includes(e.target.value);
                });
            this.setState({orders: data});
        }catch (e) {
            console.log(e);
        }
    };
    /*** ================================================================================
     *          Change Tabs on select
     * ================================================================================*/
    tabHandleSelect = key => {
        try {
            this.setState({tabSelectAdmin: key});
        }catch (e) {
            console.log(e);
        }
    };
    /*** ================================================================================
     *          Change ALL
     * ================================================================================*/
    addOrderToChangeAll = (p) => {
        var orderChangeAll = this.state.orderChangeAll;
        orderChangeAll.push(p);
        this.setState({orderChangeAll: orderChangeAll});
    };
    clearOrderChangeAll(){
        this.setState({orderChangeAll: []});
    };
    isOrderInChangeAll(p){
        return this.state.orderChangeAll.find(el => el.id === p.id);
    };
    deleteOrderFromChangeAll = (p) =>{
        var orderChangeAll = this.state.orderChangeAll;
        var newArray =[];
        orderChangeAll.forEach(order => {
            if (order.id !== p.id){
                newArray.push(order);
            }
        });
        this.setState({orderChangeAll: newArray});
    };
    updateOrdersInDB(status) {
        var orders = this.state.orderChangeAll;
        orders.forEach( o => {
            updateDocumentInCollection("orders", o.id, {
                status: status
            }).then(r => {
                calcSummsAndQuantity(o.fromUID);
                calcSummsAndQuantity(o.ownerUIDP);
                calcSummsAndQuantity(localStorage.getItem('uid'));
                sendMessageTelegramBotWithSettings("CHANGE_ORDER", {
                    fromUID: o.fromUID,
                    ownerUIDP: o.ownerUIDP,
                    order: o,
                });
                this.clearOrderChangeAll();
                this.props.getOrders();
            })
        });
    }
    /*** ================================================================================
     *          Default Render
     * ================================================================================*/
    render() {
        return(
            <Card>
                <Card.Body>
                    <SearchCheckTTNOrders
                        refresh={this.props.refresh}
                        handleSearch={this.handleSearch}
                        checkTTN={this.props.checkTTN}
                        adminChange={this.props.adminChange}
                        partnerChange={this.props.partnerChange}

                        orderChangeAll={this.state.orderChangeAll}
                        orderChangeAllLength={this.state.orderChangeAll.length}
                        deleteOrderFromChangeAll={this.deleteOrderFromChangeAll}
                        updateOrdersInDB={this.updateOrdersInDB}
                    />
                    <Tabs variant="pills"  defaultActiveKey="Все" transition={false} id="noanim-tab-example" onSelect={this.tabHandleSelect}>
                        <Tab eventKey="Все" title={`Все ${this.state.orders.length}`}>
                            <TableOrders
                                adminChange={this.props.adminChange}
                                adminNoChange={this.props.adminNoChange}
                                partnerChange={this.props.partnerChange}
                                partnerNoChange={this.props.partnerNoChange}
                                users={this.props.users}
                                orders={this.state.orders}
                                clickBalanceShow={this.props.clickBalanceShow}
                                clickOrdersShow={this.props.clickOrdersShow}
                                clickChangeOrder={this.props.clickChangeOrder}
                                getOrdersCurrentUser={this.props.getOrdersCurrentUser}

                                orderChangeAll={this.state.orderChangeAll}
                                deleteOrderFromChangeAll={this.deleteOrderFromChangeAll}
                                addOrderToChangeAll={this.addOrderToChangeAll}
                            />
                        </Tab>
                        <Tab eventKey="Новый" title={`Новый ${this.state.orders.filter(el => el.status.includes("Новый")).length}`}>
                            <TableOrders
                                adminChange={this.props.adminChange}
                                adminNoChange={this.props.adminNoChange}
                                partnerChange={this.props.partnerChange}
                                partnerNoChange={this.props.partnerNoChange}
                                users={this.props.users}
                                orders={this.state.orders.filter(el => el.status.includes("Новый"))}
                                clickBalanceShow={this.props.clickBalanceShow}
                                clickOrdersShow={this.props.clickOrdersShow}
                                clickChangeOrder={this.props.clickChangeOrder}
                                getOrdersCurrentUser={this.props.getOrdersCurrentUser}

                                orderChangeAll={this.state.orderChangeAll}
                                deleteOrderFromChangeAll={this.deleteOrderFromChangeAll}
                                addOrderToChangeAll={this.addOrderToChangeAll}
                            />
                        </Tab>
                        <Tab eventKey="Принят" title={`Принято ${this.state.orders.filter(el => el.status.includes("Принят")).length}`}>
                            <TableOrders
                                adminChange={this.props.adminChange}
                                adminNoChange={this.props.adminNoChange}
                                partnerChange={this.props.partnerChange}
                                partnerNoChange={this.props.partnerNoChange}
                                users={this.props.users}
                                orders={this.state.orders.filter(el => el.status.includes("Принят"))}
                                clickBalanceShow={this.props.clickBalanceShow}
                                clickOrdersShow={this.props.clickOrdersShow}
                                clickChangeOrder={this.props.clickChangeOrder}
                                getOrdersCurrentUser={this.props.getOrdersCurrentUser}

                                orderChangeAll={this.state.orderChangeAll}
                                deleteOrderFromChangeAll={this.deleteOrderFromChangeAll}
                                addOrderToChangeAll={this.addOrderToChangeAll}
                            />
                        </Tab>
                        <Tab eventKey="Отказ" title={`Отказ ${this.state.orders.filter(el => el.status.includes("Отказ")).length}`} >
                            <TableOrders
                                adminChange={this.props.adminChange}
                                adminNoChange={this.props.adminNoChange}
                                partnerChange={this.props.partnerChange}
                                partnerNoChange={this.props.partnerNoChange}
                                users={this.props.users}
                                orders={this.state.orders.filter(el => el.status.includes("Отказ"))}
                                clickBalanceShow={this.props.clickBalanceShow}
                                clickOrdersShow={this.props.clickOrdersShow}
                                clickChangeOrder={this.props.clickChangeOrder}
                                getOrdersCurrentUser={this.props.getOrdersCurrentUser}

                                orderChangeAll={this.state.orderChangeAll}
                                deleteOrderFromChangeAll={this.deleteOrderFromChangeAll}
                                addOrderToChangeAll={this.addOrderToChangeAll}
                            />
                        </Tab>
                        <Tab eventKey="Отправлен" title={`Отправлено ${this.state.orders.filter(el => el.status.includes("Отправлен")).length}`} >
                            <TableOrders
                                adminChange={this.props.adminChange}
                                adminNoChange={this.props.adminNoChange}
                                partnerChange={this.props.partnerChange}
                                partnerNoChange={this.props.partnerNoChange}
                                users={this.props.users}
                                orders={this.state.orders.filter(el => el.status.includes("Отправлен"))}
                                clickBalanceShow={this.props.clickBalanceShow}
                                clickOrdersShow={this.props.clickOrdersShow}
                                clickChangeOrder={this.props.clickChangeOrder}
                                getOrdersCurrentUser={this.props.getOrdersCurrentUser}

                                orderChangeAll={this.state.orderChangeAll}
                                deleteOrderFromChangeAll={this.deleteOrderFromChangeAll}
                                addOrderToChangeAll={this.addOrderToChangeAll}
                            />
                        </Tab>
                        <Tab eventKey="Завершен" title={`Завершено ${this.state.orders.filter(el => el.status.includes("Завершен")).length}`} >
                            <TableOrders
                                adminChange={this.props.adminChange}
                                adminNoChange={this.props.adminNoChange}
                                partnerChange={this.props.partnerChange}
                                partnerNoChange={this.props.partnerNoChange}
                                users={this.props.users}
                                orders={this.state.orders.filter(el => el.status.includes("Завершен"))}
                                clickBalanceShow={this.props.clickBalanceShow}
                                clickOrdersShow={this.props.clickOrdersShow}
                                clickChangeOrder={this.props.clickChangeOrder}
                                getOrdersCurrentUser={this.props.getOrdersCurrentUser}

                                orderChangeAll={this.state.orderChangeAll}
                                deleteOrderFromChangeAll={this.deleteOrderFromChangeAll}
                                addOrderToChangeAll={this.addOrderToChangeAll}
                            />
                        </Tab>
                        <Tab eventKey="Возврат" title={`Возврат ${this.state.orders.filter(el => el.status.includes("Возврат")).length}`} >
                            <TableOrders
                                adminChange={this.props.adminChange}
                                adminNoChange={this.props.adminNoChange}
                                partnerChange={this.props.partnerChange}
                                partnerNoChange={this.props.partnerNoChange}
                                users={this.props.users}
                                orders={this.state.orders.filter(el => el.status.includes("Возврат"))}
                                clickBalanceShow={this.props.clickBalanceShow}
                                clickOrdersShow={this.props.clickOrdersShow}
                                clickChangeOrder={this.props.clickChangeOrder}
                                getOrdersCurrentUser={this.props.getOrdersCurrentUser}

                                orderChangeAll={this.state.orderChangeAll}
                                deleteOrderFromChangeAll={this.deleteOrderFromChangeAll}
                                addOrderToChangeAll={this.addOrderToChangeAll}
                            />
                        </Tab>
                    </Tabs>
                </Card.Body>
            </Card>
        )
    }
}