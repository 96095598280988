import React from 'react';
import {Button, FormGroup, } from 'reactstrap';
import {Col, Form, Row,  Card, Modal, Tab, Tabs} from 'react-bootstrap';
import NumberFormat from "react-number-format";
import {
    addProduct, changeProduct,
    clearAdmin,
    clearUser,
    removeAll,
    removeProduct,
    setAdmin,
    setUser
} from "../../storeReducers/basket/actions";
import {connect} from "react-redux";
import {
    checkVerified,
    fire,
    getAdminRequisites,
    getDataFromCollectionWhereKeyValue, sendMessageTelegramBotWithSettings,
    setDocumentToCollection
} from "../../firebase/Firebase";


const ReactDomServer = require("react-dom/server");

const download = require('image-downloader');



class App extends React.Component {
    /*** ================================================================================
     *          Main methods
     * ================================================================================*/
    constructor (props, context) {
        super(props, context);
        this.state = {
            isVerified: false,
            needActive: false,
            needPhone: false,
            needEmail: false,
            needBalance: false,

            adminRequisites: '',
            summSendMoney: 0,
            urlSendMoney: '',

            isNotFinished: false,
            balanceOperation: [],
        };
    }
    componentDidMount() {
        this.getRequisites();
        this.getBalanceOperation();
        checkVerified().then(r => {
            if (!r.isVerified){
                this.setState({
                    isVerified: r.isVerified,
                    needActive: r.needActive,
                    needPhone: r.needPhone,
                    needEmail: r.needEmail,
                    needBalance: r.needBalance,
                })
            }
        });
    }
    /*** ================================================================================
     *          Data Base
     * ================================================================================*/
    getRequisites(){
        getAdminRequisites().then( r => {
            this.setState({ adminRequisites: r.paymentCard + ' ' + r.paymentName });
        });
    };
    getBalanceOperation(){
        getDataFromCollectionWhereKeyValue("balance", "fromUID", localStorage.getItem('uid')).then( r => {
            r.data.forEach( b => {
                if (b.status === "На рассмотрении"){
                    this.setState({isNotFinished: true})
                }
            });
            this.setState({ balanceOperation: r.data });
        });
    };
    /*** ================================================================================
     *          Verefied Profile
     * ================================================================================*/
    sendSms(){
        // let xmls='<soapenv:Envelope xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/"'+
        //     'xmlns:web="http://www.webserviceX.NET/"><soapenv:Header/>' +
        //     '<soapenv:Body>\<web:ConversionRate> <web:FromCurrency>INR</web:FromCurrency> <web:ToCurrency>USD</web:ToCurrency> </web:ConversionRate> </soapenv:Body> </soapenv:Envelope>';
        //
        // axios.post('http://www.webservicex.com/CurrencyConvertor.asmx?wsdl',
        //     xmls,
        //     {headers:
        //             {'Content-Type': 'text/xml'}
        //     }).then(res=>{
        //     console.log(res);
        // }).catch(err=>{console.log(err)});

        // var xmlhttp = new XMLHttpRequest();
        // xmlhttp.open('POST', 'http://turbosms.in.ua/api/wsdl.html', true);
        //
        // // build SOAP request
        // var sr =
        //     '<?xml version="1.0" encoding="utf-8"?>' +
        //     '<soapenv:Envelope ' +
        //     'xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" ' +
        //     'xmlns:api="http://127.0.0.1/Integrics/Enswitch/API" ' +
        //     'xmlns:xsd="http://www.w3.org/2001/XMLSchema" ' +
        //     'xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/">' +
        //     '<soapenv:Body>' +
        //     '<api:some_api_call soapenv:encodingStyle="http://schemas.xmlsoap.org/soap/encoding/">' +
        //     '<username xsi:type="xsd:string">login_username</username>' +
        //     '<password xsi:type="xsd:string">password</password>' +
        //     '</api:some_api_call>' +
        //     '</soapenv:Body>' +
        //     '</soapenv:Envelope>';
        //
        // xmlhttp.onreadystatechange = function () {
        //     if (xmlhttp.readyState == 4) {
        //         if (xmlhttp.status == 200) {
        //             alert(xmlhttp.responseText);
        //             // alert('done. use firebug/console to see network response');
        //         }
        //     }
        // }
        // // Send the POST request
        // xmlhttp.setRequestHeader('Content-Type', 'text/xml');
        // xmlhttp.send(sr);
        // // send request
        // // ...

        // const session = new smpp.Session({host: 'smpp.turbosms.ua', port: 29900});
        //
        // let isConnected = false
        // session.on('connect', () => {
        //     isConnected = true;
        //
        //     session.bind_transceiver({
        //         system_id: 'USER_NAME',
        //         password: 'USER_PASSWORD',
        //         interface_version: 1,
        //         system_type: '380666000600',
        //         address_range: '+380666000600',
        //         addr_ton: 1,
        //         addr_npi: 1,
        //     }, (pdu) => {
        //         if (pdu.command_status == 0) {
        //             console.log('Successfully bound')
        //         }
        //
        //     })
        // })
        //
        // session.on('close', () => {
        //     console.log('smpp is now disconnected')
        //
        //     if (isConnected) {
        //         session.connect();    //reconnect again
        //     }
        // })
        //
        // session.on('error', error => {
        //     console.log('smpp error', error)
        //     isConnected = false;
        // });




    }
    login(){
        // login();
    }
    send(){
        // sendSMS('123', '+380730087708', 'Hello');

        // вот тут можешь написать как ???

        console.log('start')

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({"sender":"Mobility","receiver":"380730087708","text":"privet"});

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw
        };

        fetch("https://us-central1-dropmasterproject.cloudfunctions.net/widgets", requestOptions)
            .then(response => response.text())
            .catch(error => console.log('error', error));

    }
    hcrEmailVer = e => {
        this.setState({verEmail: e.target.value})
    };
    hcrPhoneVer = e => {
        this.setState({verPhone: e.target.value})
    };
    /*** ================================================================================
     *          Send Money
     * ================================================================================*/
    updateSummSendMoney = e => {
        this.setState({summSendMoney: e.target.value})
    };
    handleUpload = e =>{
        if (e.target.files[0]) {
            this.setState({image: e.target.files[0]});
            var file = e.target.files[0];
            // var imagesUrlP = this.state.urlSendMoney;
            var storageRef = fire.storage().ref();
            //В админ айди нужно будет вставить индетификатор того кто загружает файлы
            var path = 'imagesSendMoney/' + localStorage.getItem('uid') + (new Date().toLocaleString()) + file.name;
            const uploadTask = storageRef.child(path).put(file);
            uploadTask.on('state_changed',
                (snapshot) => {
                    // progrss function ....
                },
                (error) => {
                    // error function ....
                },
                () => {
                    // complete function ....
                    storageRef.child(path).getDownloadURL().then(url => {
                        this.setState({urlSendMoney: url});
                    })
                });
        }
    };
    sendSendMoney(){
        if (this.state.summSendMoney === 0 || this.state.summSendMoney === ''){
            alert("Не указана сумма для пополнения");
            return;
        }
        if (this.state.image === null) {
            alert("Не указан скриншот оплаты");
            return;
        }
        setDocumentToCollection('balance', {
            typeOperation: 'Пополнение баланса',
            date: new Date().getTime(),
            summ: this.state.summSendMoney,
            requisites: this.state.adminRequisites,
            url: this.state.urlSendMoney,
            status: 'На рассмотрении',
            fromUID: localStorage.getItem('uid'),
            fromEmail: localStorage.getItem('email'),
            fromName: localStorage.getItem('displayName'),
        }).then( r => {
            sendMessageTelegramBotWithSettings("NEW_SEND_MONEY_REQUEST", {
                summ: this.state.summSendMoney,
                user: this.props.user,
                admin: this.props.adminSettings,
                fromUID: localStorage.getItem('uid'),
            });
            this.props.clickModal();
        });
    }
    /*** ================================================================================
     *          DEFAULT RENDER
     * ================================================================================*/
    render() {

        return (
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                // centered
                show={this.props.show}
                onHide={() => this.props.clickModal()}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Для полноценной работы Вам необходимо:
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ul>
                        {this.state.needActive && (
                            <li>
                                Дождаться активации Вашего аккаунта Администратором
                            </li>
                        )}
                        {this.state.needPhone && (
                            <li>
                                Указать в настройках номер телефона
                            </li>
                        )}
                        {this.state.isNotFinished && (
                            <li>
                                Дождитесь проверки баланса Администратором сайта.
                            </li>
                        )}
                        {!this.state.isNotFinished && (
                            <div>
                                {this.state.needBalance && (
                                    <li>
                                        Иметь на счету не менее 100грн.
                                    </li>
                                )}
                            </div>
                        )}
                    </ul>

                    {!this.state.isNotFinished && (
                        <div>
                            {this.state.needBalance && (
                                <div>
                                    <Row>
                                        <Col md={12}>
                                            {'Пополните баланс на ' + this.state.adminRequisites + ' и загрузите фото с подтверждением оплаты'}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Form.Group controlId="formGroupSumm">
                                                {/*<Form.Label>Введите сумму для выплаты</Form.Label>*/}
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Введите сумму"
                                                    onChange={this.updateSummSendMoney}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <Form.Group controlId="formGroupPhoto">
                                                <Form.Label>Загрузите фото с подтверждением оплаты</Form.Label>
                                                <Form.Control
                                                    // id="uploadPhoto"
                                                    type="file"
                                                    // accept=".jpg"
                                                    onChange={this.handleUpload}
                                                    // className="Landing-Input-File"
                                                    // variant="outline-dark"
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </div>
                    )}




                </Modal.Body>
                {!this.state.isNotFinished && (
                    <div>
                        {this.state.needBalance && (
                            <Modal.Footer>
                                <Button color="primary" onClick={() => this.sendSendMoney()}>Сохранить</Button>
                            </Modal.Footer>
                        )}
                    </div>
                )}

            </Modal>
        )}
}
const mapStateToProps = state => {
    return {
        basketArray: state.basket,
        user: state.basket.user,
        adminSettings: state.basket.adminSettings
    }
};
const mapDispatchToProps = {
    setAdmin,
    clearAdmin,
    setUser,
    clearUser,
    addProduct,
    removeProduct,
    removeAll,
    changeProduct
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
