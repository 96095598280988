import React, {Fragment} from 'react';
import {UncontrolledDropdown, DropdownToggle, DropdownMenu, Nav, Col, Row,} from 'reactstrap';
import bg3 from '../../../assets/utils/images/dropdown-header/abstract3.jpg';
import c2 from '../../../assets/utils/images/dropdown-header/city2.jpg';
import {fire} from '../../../componentsWithOutTheme/firebase/Firebase';
import {faAngleDown,} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {getDateFromMilliseconds} from '../../../componentsWithOutTheme/firebase/Firebase';

class MegaMenu extends React.Component {
    // Data Base
    getNews(){
        try {
            fire.firestore().collection("news")
                .get()
                .then(querySnapshot => {
                    const data = [];
                    querySnapshot.docs.forEach(d => {
                        data.push({
                            id: d.id,
                            ...d.data()
                        });

                    });
                    data.sort(function(a,b){
                        return new Date(b.date) - new Date(a.date);
                    });
                    if (data.length !== this.state.news.length){
                        this.setState({ news: data });
                    }

                });
        }catch (e) {
            console.log(e);
        }
    };
    getNewsStock(){
        try {
            fire.firestore().collection("newsStock")
                .get()
                .then(querySnapshot => {
                    const data = [];
                    querySnapshot.docs.forEach(d => {
                        data.push({
                            id: d.id,
                            ...d.data()
                        });

                    });
                    data.sort(function(a,b){
                        return new Date(b.date) - new Date(a.date);
                    });
                    if (data.length !== this.state.newsStock.length){
                        this.setState({ newsStock: data });
                    }

                });
        }catch (e) {
            console.log(e);
        }
    };
    // Main methods
    componentDidMount() {
        this.getNews();
        this.getNewsStock();
    }
    componentDidUpdate() {
        this.getNews();
        this.getNewsStock();
    }
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = {
            dropdownOpen: false,
            popoverOpen: false,
            // DB
            news: [],
            currentNews: {
                date: '',
                headerNews: '',
                textNews: '',
            },
            newsStock: [],
        };
    }
    toggle() {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
            popoverOpen: !this.state.popoverOpen,
        });
    }

    render() {
        const settings = {
            className: "",
            centerMode: false,
            infinite: true,
            slidesToShow: 1,
            speed: 500,
            dots: true,
        };
        return (
            <Fragment>
                <Nav className="header-megamenu">
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav>
                            <i className="nav-link-icon pe-7s-news-paper"> </i>
                            Новости
                            <FontAwesomeIcon className="ml-2 opacity-5" icon={faAngleDown}/>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-rounded dropdown-menu-lg rm-pointers">
                            <div className="dropdown-menu-header">
                                <div className="dropdown-menu-header-inner bg-info">
                                    <div className="menu-header-image opacity-1"
                                         style={{
                                             backgroundImage: 'url(' + c2 + ')'
                                         }}
                                    ></div>
                                    <div className="menu-header-content text-left">
                                        <h5 className="menu-header-title">Новости</h5>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="p-1 slick-slider-sm mx-auto">
                                    {(this.state.news.length > 0) && (
                                        <div style={{maxHeight: '70vh', minWidth: '40vw', overflowY: 'auto'}}>
                                            {this.state.news.map((n, index) =>
                                                <div key={index}>
                                                    <div style={{textAlign: 'left', paddingLeft: 30, paddingRight: 30}}>
                                                        <h3 >{n.headerNews}</h3>
                                                    </div>
                                                    <div>
                                                        <div style={{paddingLeft: 30, paddingRight: 30}}>{getDateFromMilliseconds(n.date)}</div>
                                                    </div>
                                                    <div>
                                                        <div style={{paddingLeft: 30, paddingRight: 30}}>{n.textNews}</div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {(this.state.news.length === 0) && (
                                        <div>
                                            Пока что новостей нет
                                        </div>
                                    )}
                                </div>
                            </div>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <UncontrolledDropdown nav inNavbar>
                        <DropdownToggle nav>
                            <i className="nav-link-icon pe-7s-way"> </i>
                            Наличие товара
                            <FontAwesomeIcon className="ml-2 opacity-5" icon={faAngleDown}/>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-rounded dropdown-menu-lg rm-pointers" style={{minWidth: 600}}>
                            <div className="dropdown-menu-header">
                                <div className="dropdown-menu-header-inner bg-success">
                                    <div className="menu-header-image opacity-1"
                                         style={{
                                             backgroundImage: 'url(' + bg3 + ')'
                                         }}
                                    ></div>
                                    <div className="menu-header-content text-left">
                                        <h5 className="menu-header-title">Новости о наличии товара</h5>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className="p-1 slick-slider-sm mx-auto">
                                    {(this.state.newsStock.length > 0) && (
                                        <div style={{maxHeight: '70vh', minWidth: '40vw', overflowY: 'auto'}}>
                                            {this.state.newsStock.map((n, index) => (
                                                <div key={index}>
                                                    <Row>
                                                        <Col md={12}>
                                                            <div style={{textAlign: 'center',paddingLeft: 30, paddingRight: 30}}>
                                                                <h3 >{n.headerNewsInStock}</h3>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col sm={3}>
                                                            <div style={{width: '100%', height: '100%'}}>
                                                                <img
                                                                    src={n.currentProduct.imagesUrlP[0]}
                                                                    alt="slide"
                                                                    style={{textAlign: 'center', width: '100%', height: '100%', borderRadius: '5%'}}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col sm={9}>
                                                            <div key={n.id} style={{width: '100%', height: '100%'}}>
                                                                <div style={{textAlign: 'left', paddingRight: 30}}>
                                                                    <div >{'Дата: ' + getDateFromMilliseconds(n.date)}</div>
                                                                </div>
                                                                <div style={{textAlign: 'left', paddingRight: 30}}>
                                                                    <div >{n.currentProduct.nameP}</div>
                                                                </div>
                                                                <div>
                                                                    <div style={{textAlign: 'left', paddingRight: 30}}>{n.textNewsInStock}</div>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    {(this.state.newsStock.length === 0) && (
                                        <div>
                                            Пока что новостей нет
                                        </div>
                                    )}
                                </div>
                            </div>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Nav>
            </Fragment>
        )
    }
}

export default MegaMenu;
