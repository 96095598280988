import { createStore, combineReducers } from 'redux';
import reducers from '../reducers';
import {basketReducer} from "../componentsWithOutTheme/storeReducers/basket/reducers";

export default function configureStore() {
    return createStore(
        combineReducers({
            ...reducers,
            basket: basketReducer,
        }),
        {},
    );
}