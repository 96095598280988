import React from "react";
import {CardBody, Col, Row} from "reactstrap";
import {Card} from "react-bootstrap";
import img1 from "../images/1.png";

export default class App extends React.Component{
    constructor (props, context) {
        super(props, context);
        this.state = {};
    }
    componentDidMount() {
    }

    render() {
        return (
            <Row className="Landing-Container-FragmentImages">
                <Col sm={6} className="align-self-center">
                    <div className="Landing-Title-Container">
                        <div className="Landing-Title-Container-Title1">{this.props.title}</div>
                        <div className="Landing-Title-Container-Title0">{this.props.subTitle}</div>
                        <div className="Landing-Title-Container-Title0">{this.props.text}</div>
                    </div>
                </Col>
                <Col sm={6} className="Landing-Container-FragmentImages-Col">
                    <Card className="Landing-Container-FragmentImages-Card">
                        <CardBody>
                            <img src={this.props.image} alt="img" className="Landing-Container-FragmentImages-Image"/>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        )
    }
}