import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import LandingPage from './componentsWithOutTheme/landing/LandingPage';
import AdminConstructor from './componentsWithOutTheme/AdminConstructor';
import './assets/base.scss';
import Main from './DemoPages/Main';
import RulesPage from "./componentsWithOutTheme/landing/RulesPage";
// import RegisterPage from "./componentsWithOutTheme/landing/RegisterPage";
import RegisterPage from "./DemoPages/UserPages/Register/index";
import configureStore from './config/configureStore';
import { Provider } from 'react-redux';

const store = configureStore();
const rootElement = document.getElementById('root');

const renderApp = Component => {
    ReactDOM.render(
        <Provider store={store}>
            <BrowserRouter>
                <Switch>
                    <Route exact path='/' component={LandingPage}/>
                    <Route exact path='/account' component={AdminConstructor}/>
                    <Route exact path='/rules' component={RulesPage}/>
                    <Route exact path='/registration/:ref' component={RegisterPage}/>
                </Switch>
            </BrowserRouter>
        </Provider>,
        rootElement
    );
};

renderApp(Main);

if (module.hot) {
    module.hot.accept('./DemoPages/Main', () => {
        const NextApp = require('./DemoPages/Main').default;
        renderApp(NextApp);
    });
}
serviceWorker.unregister();

