import React from 'react';
import { Col, Modal, Row} from "react-bootstrap";
import {Progress} from 'reactstrap';


export default class XMLProcessing extends React.Component{

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            progress: 0,
            url: ''
        }
    }

    increaseProgress(value){
        if (this.state.progress < 100) {
            this.setState(oldState => ({progress: oldState.progress + value}));
            if (value > 1) setTimeout(() => this.increaseProgress(value - 1), 1000)
        }
    }

    componentWillMount() {
        setTimeout(() => this.increaseProgress(13), 1000);

        this.props.promice.then(snapshot => {
            snapshot.ref.getDownloadURL().then(url => {
                this.setState({progress: 100});
                setTimeout(() => this.setState({
                    isLoading: false,
                    url: url
                }), 1500);
            });
        })
    }

    render() {
        const { progress, isLoading, url } = this.state;
        const { opened, toggle } = this.props;

        return (
            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                show={opened}
                onHide={toggle}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Загрузка XML
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>

                            {isLoading ?
                                <Progress striped bar color="success" value={progress} /> :
                                <a
                                    target="_blank"
                                    href={url}>
                                    {url}
                                </a>
                            }
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

        )
    }

}
